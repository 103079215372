
const options = [
  {
    value: "+1-ca",
    label: "Canada (+1)",
    code: "ca",
    flag: require("../../assets/Canada.jpg"),
  },
  {
    value: "+971",
    label: "UAE (+971)",
    code: "ae",
    flag: require("../../assets/United_Arab_Emirates.jpg"),
  },
  {
    value: "+93-af",
    label: "Afghanistan (+93)",
    code: "af",
    flag: require("../../assets/countryflag/af.webp"),
  },
  {
    value: "+358-ax",
    label: "Aland Island (+358)",
    code: "ax",
    flag: require("../../assets/countryflag/ax.webp"),
  },
  {
    value: "+355-al",
    label: "Albania (+355)",
    code: "al",
    flag: require("../../assets/countryflag/al.webp"),
  },
  {
    value: "+213-dz",
    label: "Algeria (+213)",
    code: "dz",
    flag: require("../../assets/countryflag/dz.webp"),
  },
  {
    value: "+1-684-as",
    label: "American Samoa (+1-684)",
    code: "as",
    flag: require("../../assets/countryflag/as.webp"),
  },
  {
    value: "+376-ad",
    label: "Andorra (+376)",
    code: "ad",
    flag: require("../../assets/countryflag/ad.webp"),
  },
  {
    value: "+244-ao",
    label: "Angola (+244)",
    code: "ao",
    flag: require("../../assets/countryflag/ao.webp"),
  },
  {
    value: "+1-264-ai",
    label: "Anguilla (+1-264)",
    code: "ai",
    flag: require("../../assets/countryflag/ai.webp"),
  },
  {
    value: "+672-aq",
    label: "Antarctica (+672)",
    code: "aq",
    flag: require("../../assets/countryflag/aq.webp"),
  },
  {
    value: "+1-ag",
    label: "Antigua and Barbuda (+1)",
    code: "ag",
    flag: require("../../assets/countryflag/ag.webp"),
  },
  {
    value: "+54-ar",
    label: "Argentina (+54)",
    code: "ar",
    flag: require("../../assets/countryflag/ar.webp"),
  },
  {
    value: "+374-am",
    label: "Armenia (+374)",
    code: "am",
    flag: require("../../assets/countryflag/am.webp"),
  },
  {
    value: "+297-aw",
    label: "Aruba (+297)",
    code: "aw",
    flag: require("../../assets/countryflag/aw.webp"),
  },
  {
    value: "+61-au",
    label: "Australia (+61)",
    code: "au",
    flag: require("../../assets/countryflag/au.webp"),
  },
  {
    value: "+43-at",
    label: "Austria (+43)",
    code: "at",
    flag: require("../../assets/countryflag/at.webp"),
  },
  {
    value: "+994-az",
    label: "Azerbaijan (+994)",
    code: "az",
    flag: require("../../assets/countryflag/az.webp"),
  },
  {
    value: "+1-242-bs",
    label: "Bahamas (+1-242)",
    code: "bs",
    flag: require("../../assets/countryflag/bs.webp"),
  },
  {
    value: "+973-bh",
    label: "Bahrain (+973)",
    code: "bh",
    flag: require("../../assets/countryflag/bh.webp"),
  },
  {
    value: "+880-bd",
    label: "Bangladesh (+880)",
    code: "bd",
    flag: require("../../assets/countryflag/bd.webp"),
  },
  {
    value: "+1-246-bb",
    label: "Barbados (+1-246)",
    code: "bb",
    flag: require("../../assets/countryflag/bb.webp"),
  },
  {
    value: "+375-by",
    label: "Belarus (+375)",
    code: "by",
    flag: require("../../assets/countryflag/by.webp"),
  },
  {
    value: "+32-be",
    label: "Belgium (+32)",
    code: "be",
    flag: require("../../assets/countryflag/be.webp"),
  },
  {
    value: "+501-bz",
    label: "Belize (+501)",
    code: "bz",
    flag: require("../../assets/countryflag/bz.webp"),
  },
  {
    value: "+229-bj",
    label: "Benin (+229)",
    code: "bj",
    flag: require("../../assets/countryflag/bj.webp"),
  },
  {
    value: "+1-441-bm",
    label: "Bermuda (+1-441)",
    code: "bm",
    flag: require("../../assets/countryflag/bm.webp"),
  },
  {
    value: "+975-bt",
    label: "Bhutan (+975)",
    code: "bt",
    flag: require("../../assets/countryflag/bt.webp"),
  },
  {
    value: "+591-bo",
    label: "Bolivia (+591)",
    code: "bo",
    flag: require("../../assets/countryflag/bo.webp"),
  },
  {
    value: "+387-ba",
    label: "Bosnia and Herzegovina (+387)",
    code: "ba",
    flag: require("../../assets/countryflag/ba.webp"),
  },
  {
    value: "+267-bw",
    label: "Botswana (+267)",
    code: "bw",
    flag: require("../../assets/countryflag/bw.webp"),
  },
  {
    value: "+55-br",
    label: "Brazil (+55)",
    code: "br",
    flag: require("../../assets/countryflag/br.webp"),
  },
  {
    value: "+246-io",
    label: "British Indian Ocean Territory (+246)",
    code: "io",
    flag: require("../../assets/countryflag/io.webp"),
  },
  {
    value: "+1-284-vg",
    label: "British Virgin Islands (+1-284)",
    code: "vg",
    flag: require("../../assets/countryflag/vg.webp"),
  },
  {
    value: "+673-bn",
    label: "Brunei (+673)",
    code: "bn",
    flag: require("../../assets/countryflag/bn.webp"),
  },
  {
    value: "+359-bg",
    label: "Bulgaria (+359)",
    code: "bg",
    flag: require("../../assets/countryflag/bg.webp"),
  },
  {
    value: "+226-bf",
    label: "Burkina Faso (+226)",
    code: "bf",
    flag: require("../../assets/countryflag/bf.webp"),
  },
  {
    value: "+257-bi",
    label: "Burundi (+257)",
    code: "bi",
    flag: require("../../assets/countryflag/bi.webp"),
  },
  {
    value: "+855",
    label: "Cambodia (+855)",
    code: "kh",
    flag: require("../../assets/countryflag/kh.webp"),
  },
  {
    value: "+237",
    label: "Cameroon (+237)",
    code: "cm",
    flag: require("../../assets/countryflag/cm.webp"),
  },
  {
    value: "+1-ca",
    label: "Canada (+1)",
    code: "ca",
    flag: require("../../assets/countryflag/ca.webp"),
  },
  {
    value: "+238",
    label: "Cape Verde (+238)",
    code: "cv",
    flag: require("../../assets/countryflag/cv.webp"),
  },
  {
    value: "+1-ky",
    label: "Cayman Islands (+1)",
    code: "ky",
    flag: require("../../assets/countryflag/ky.webp"),
  },
  {
    value: "+236-cf",
    label: "Central African Republic (+236)",
    code: "cf",
    flag: require("../../assets/countryflag/cf.webp"),
  },
  {
    value: "+235-td",
    label: "Chad (+235)",
    code: "td",
    flag: require("../../assets/countryflag/td.webp"),
  },
  {
    value: "+56-cl",
    label: "Chile (+56)",
    code: "cl",
    flag: require("../../assets/countryflag/cl.webp"),
  },
  {
    value: "+86-cn",
    label: "China (+86)",
    code: "cn",
    flag: require("../../assets/countryflag/cn.webp"),
  },
  {
    value: "+61-cx",
    label: "Christmas Island (+61)",
    code: "cx",
    flag: require("../../assets/countryflag/cx.webp"),
  },
  {
    value: "+61-cc",
    label: "Cocos (Keeling) Islands (+61)",
    code: "cc",
    flag: require("../../assets/countryflag/cc.webp"),
  },
  {
    value: "+57-co",
    label: "Colombia (+57)",
    code: "co",
    flag: require("../../assets/countryflag/co.webp"),
  },
  {
    value: "+269-km",
    label: "Comoros (+269)",
    code: "km",
    flag: require("../../assets/countryflag/km.webp"),
  },
  {
    value: "+242-cg",
    label: "Congo (+242)",
    code: "cg",
    flag: require("../../assets/countryflag/cg.webp"),
  },
  {
    value: "+243-cd",
    label: "Congo, The Democratic Republic Of The (+243)",
    code: "cd",
    flag: require("../../assets/countryflag/cd.webp"),
  },
  {
    value: "+682-ck",
    label: "Cook Islands (+682)",
    code: "ck",
    flag: require("../../assets/countryflag/ck.webp"),
  },
  {
    value: "+506-cr",
    label: "Costa Rica (+506)",
    code: "cr",
    flag: require("../../assets/countryflag/cr.webp"),
  },
  {
    value: "+225-ci",
    label: "Cote d'Ivoire (+225)",
    code: "ci",
    flag: require("../../assets/countryflag/ci.webp"),
  },
  {
    value: "+385-hr",
    label: "Croatia (+385)",
    code: "hr",
    flag: require("../../assets/countryflag/hr.webp"),
  },
  {
    value: "+53-cu",
    label: "Cuba (+53)",
    code: "cu",
    flag: require("../../assets/countryflag/cu.webp"),
  },
  {
    value: "+357-cy",
    label: "Cyprus (+357)",
    code: "cy",
    flag: require("../../assets/countryflag/cy.webp"),
  },
  {
    value: "+420-cz",
    label: "Czech Republic (+420)",
    code: "cz",
    flag: require("../../assets/countryflag/cz.webp"),
  },
  {
    value: "+45-dk",
    label: "Denmark (+45)",
    code: "dk",
    flag: require("../../assets/countryflag/dk.webp"),
  },
  {
    value: "+253-dj",
    label: "Djibouti (+253)",
    code: "dj",
    flag: require("../../assets/countryflag/dj.webp"),
  },
  {
    value: "+1-dm",
    label: "Dominica (+1)",
    code: "dm",
    flag: require("../../assets/countryflag/dm.webp"),
  },
  {
    value: "+1-do",
    label: "Dominican Republic (+1)",
    code: "do",
    flag: require("../../assets/countryflag/do.webp"),
  },
  {
    value: "+593",
    label: "Ecuador (+593)",
    code: "ec",
    flag: require("../../assets/countryflag/ec.webp"),
  },
  {
    value: "+20-eg",
    label: "Egypt (+20)",
    code: "eg",
    flag: require("../../assets/countryflag/eg.webp"),
  },
  {
    value: "+503-sv",
    label: "El Salvador (+503)",
    code: "sv",
    flag: require("../../assets/countryflag/sv.webp"),
  },
  {
    value: "+240-gq",
    label: "Equatorial Guinea (+240)",
    code: "gq",
    flag: require("../../assets/countryflag/gq.webp"),
  },
  {
    value: "+291-er",
    label: "Eritrea (+291)",
    code: "er",
    flag: require("../../assets/countryflag/er.webp"),
  },
  {
    value: "+372-ee",
    label: "Estonia (+372)",
    code: "ee",
    flag: require("../../assets/countryflag/ee.webp"),
  },
  {
    value: "+251-et",
    label: "Ethiopia (+251)",
    code: "et",
    flag: require("../../assets/countryflag/et.webp"),
  },
  {
    value: "+500-fk",
    label: "Falkland Islands (+500)",
    code: "fk",
    flag: require("../../assets/countryflag/fk.webp"),
  },
  {
    value: "+298-fo",
    label: "Faroe Islands (+298)",
    code: "fo",
    flag: require("../../assets/countryflag/fo.webp"),
  },
  {
    value: "+679-fj",
    label: "Fiji (+679)",
    code: "fj",
    flag: require("../../assets/countryflag/fj.webp"),
  },
  {
    value: "+358-fi",
    label: "Finland (+358)",
    code: "fi",
    flag: require("../../assets/countryflag/fi.webp"),
  },
  {
    value: "+33-fr",
    label: "France (+33)",
    code: "fr",
    flag: require("../../assets/countryflag/fr.webp"),
  },
  {
    value: "+594-gf",
    label: "French Guiana (+594)",
    code: "gf",
    flag: require("../../assets/countryflag/gf.webp"),
  },
  {
    value: "+689-pf",
    label: "French Polynesia (+689)",
    code: "pf",
    flag: require("../../assets/countryflag/pf.webp"),
  },
  {
    value: "+241-ga",
    label: "Gabon (+241)",
    code: "ga",
    flag: require("../../assets/countryflag/ga.webp"),
  },
  {
    value: "+220-gm",
    label: "Gambia (+220)",
    code: "gm",
    flag: require("../../assets/countryflag/gm.webp"),
  },
  {
    value: "+995-ge",
    label: "Georgia (+995)",
    code: "ge",
    flag: require("../../assets/countryflag/ge.webp"),
  },
  {
    value: "+49-de",
    label: "Germany (+49)",
    code: "de",
    flag: require("../../assets/countryflag/de.webp"),
  },
  {
    value: "+233-gh",
    label: "Ghana (+233)",
    code: "gh",
    flag: require("../../assets/countryflag/gh.webp"),
  },
  {
    value: "+350-gi",
    label: "Gibraltar (+350)",
    code: "gi",
    flag: require("../../assets/countryflag/gi.webp"),
  },
  {
    value: "+30-gr",
    label: "Greece (+30)",
    code: "gr",
    flag: require("../../assets/countryflag/gr.webp"),
  },
  {
    value: "+299-gl",
    label: "Greenland (+299)",
    code: "gl",
    flag: require("../../assets/countryflag/gl.webp"),
  },
  {
    value: "+1473-gd",
    label: "Grenada (+1473)",
    code: "gd",
    flag: require("../../assets/countryflag/gd.webp"),
  },
  {
    value: "+590-gp",
    label: "Guadeloupe (+590)",
    code: "gp",
    flag: require("../../assets/countryflag/gp.webp"),
  },
  {
    value: "+1-671-gu",
    label: "Guam (+1671)",
    code: "gu",
    flag: require("../../assets/countryflag/gu.webp"),
  },
  {
    value: "+502-gt",
    label: "Guatemala (+502)",
    code: "gt",
    flag: require("../../assets/countryflag/gt.webp"),
  },
  {
    value: "+224-gn",
    label: "Guinea (+224)",
    code: "gn",
    flag: require("../../assets/countryflag/gn.webp"),
  },
  {
    value: "+245-gw",
    label: "Guinea-Bissau (+245)",
    code: "gw",
    flag: require("../../assets/countryflag/gw.webp"),
  },
  {
    value: "+592-gy",
    label: "Guyana (+592)",
    code: "gy",
    flag: require("../../assets/countryflag/gy.webp"),
  },
  {
    value: "+509-ht",
    label: "Haiti (+509)",
    code: "ht",
    flag: require("../../assets/countryflag/ht.webp"),
  },
  {
    value: "+504-hn",
    label: "Honduras (+504)",
    code: "hn",
    flag: require("../../assets/countryflag/hn.webp"),
  },
  {
    value: "+852-hk",
    label: "Hong Kong (+852)",
    code: "hk",
    flag: require("../../assets/countryflag/hk.webp"),
  },
  {
    value: "+36-hu",
    label: "Hungary (+36)",
    code: "hu",
    flag: require("../../assets/countryflag/hu.webp"),
  },
  {
    value: "+354-is",
    label: "Iceland (+354)",
    code: "is",
    flag: require("../../assets/countryflag/is.webp"),
  },
  {
    value: "+91-in",
    label: "India (+91)",
    code: "in",
    flag: require("../../assets/countryflag/in.webp"),
  },
  {
    value: "+62-id",
    label: "Indonesia (+62)",
    code: "id",
    flag: require("../../assets/countryflag/id.webp"),
  },
  {
    value: "+98-ir",
    label: "Iran (+98)",
    code: "ir",
    flag: require("../../assets/countryflag/ir.webp"),
  },
  {
    value: "+964-iq",
    label: "Iraq (+964)",
    code: "iq",
    flag: require("../../assets/countryflag/iq.webp"),
  },
  {
    value: "+353-ie",
    label: "Ireland (+353)",
    code: "ie",
    flag: require("../../assets/countryflag/ie.webp"),
  },
  {
    value: "+44-im",
    label: "Isle of Man (+44)",
    code: "im",
    flag: require("../../assets/countryflag/im.webp"),
  },
  {
    value: "+972-il",
    label: "Israel (+972)",
    code: "il",
    flag: require("../../assets/countryflag/il.webp"),
  },
  {
    value: "+39-it",
    label: "Italy (+39)",
    code: "it",
    flag: require("../../assets/countryflag/it.webp"),
  },
  {
    value: "+1-jm",
    label: "Jamaica (+1)",
    code: "jm",
    flag: require("../../assets/countryflag/jm.webp"),
  },
  {
    value: "+81-jp",
    label: "Japan (+81)",
    code: "jp",
    flag: require("../../assets/countryflag/jp.webp"),
  },
  {
    value: "+44-je",
    label: "Jersey (+44)",
    code: "je",
    flag: require("../../assets/countryflag/je.webp"),
  },
  {
    value: "+962-jo",
    label: "Jordan (+962)",
    code: "jo",
    flag: require("../../assets/countryflag/jo.webp"),
  },
  {
    value: "+7-kz",
    label: "Kazakhstan (+7)",
    code: "kz",
    flag: require("../../assets/countryflag/kz.webp"),
  },
  {
    value: "+254-ke",
    label: "Kenya (+254)",
    code: "ke",
    flag: require("../../assets/countryflag/ke.webp"),
  },
  {
    value: "+686-ki",
    label: "Kiribati (+686)",
    code: "ki",
    flag: require("../../assets/countryflag/ki.webp"),
  },
  {
    value: "+383-xk",
    label: "Kosovo (+383)",
    code: "xk",
    flag: require("../../assets/countryflag/xk.webp"),
  },
  {
    value: "+965-kw",
    label: "Kuwait (+965)",
    code: "kw",
    flag: require("../../assets/countryflag/kw.webp"),
  },
  {
    value: "+996-kg",
    label: "Kyrgyzstan (+996)",
    code: "kg",
    flag: require("../../assets/countryflag/kg.webp"),
  },
  {
    value: "+856-la",
    label: "Laos (+856)",
    code: "la",
    flag: require("../../assets/countryflag/la.webp"),
  },
  {
    value: "+371-lv",
    label: "Latvia (+371)",
    code: "lv",
    flag: require("../../assets/countryflag/lv.webp"),
  },
  {
    value: "+961-lb",
    label: "Lebanon (+961)",
    code: "lb",
    flag: require("../../assets/countryflag/lb.webp"),
  },
  {
    value: "+266-ls",
    label: "Lesotho (+266)",
    code: "ls",
    flag: require("../../assets/countryflag/ls.webp"),
  },
  {
    value: "+231-lr",
    label: "Liberia (+231)",
    code: "lr",
    flag: require("../../assets/countryflag/lr.webp"),
  },
  {
    value: "+218-ly",
    label: "Libya (+218)",
    code: "ly",
    flag: require("../../assets/countryflag/ly.webp"),
  },
  {
    value: "+423-li",
    label: "Liechtenstein (+423)",
    code: "li",
    flag: require("../../assets/countryflag/li.webp"),
  },
  {
    value: "+370-lt",
    label: "Lithuania (+370)",
    code: "lt",
    flag: require("../../assets/countryflag/lt.webp"),
  },
  {
    value: "+352-lu",
    label: "Luxembourg (+352)",
    code: "lu",
    flag: require("../../assets/countryflag/lu.webp"),
  },
  {
    value: "+853-mo",
    label: "Macao (+853)",
    code: "mo",
    flag: require("../../assets/countryflag/mo.webp"),
  },
  {
    value: "+389-mk",
    label: "North Macedonia (+389)",
    code: "mk",
    flag: require("../../assets/countryflag/mk.webp"),
  },
  {
    value: "+261-mg",
    label: "Madagascar (+261)",
    code: "mg",
    flag: require("../../assets/countryflag/mg.webp"),
  },
  {
    value: "+265-mw",
    label: "Malawi (+265)",
    code: "mw",
    flag: require("../../assets/countryflag/mw.webp"),
  },
  {
    value: "+60-my",
    label: "Malaysia (+60)",
    code: "my",
    flag: require("../../assets/countryflag/my.webp"),
  },
  {
    value: "+960-mv",
    label: "Maldives (+960)",
    code: "mv",
    flag: require("../../assets/countryflag/mv.webp"),
  },
  {
    value: "+223-ml",
    label: "Mali (+223)",
    code: "ml",
    flag: require("../../assets/countryflag/ml.webp"),
  },
  {
    value: "+356-mt",
    label: "Malta (+356)",
    code: "mt",
    flag: require("../../assets/countryflag/mt.webp"),
  },
  {
    value: "+692-mh",
    label: "Marshall Islands (+692)",
    code: "mh",
    flag: require("../../assets/countryflag/mh.webp"),
  },
  {
    value: "+222-mr",
    label: "Mauritania (+222)",
    code: "mr",
    flag: require("../../assets/countryflag/mr.webp"),
  },
  {
    value: "+230-mu",
    label: "Mauritius (+230)",
    code: "mu",
    flag: require("../../assets/countryflag/mu.webp"),
  },
  {
    value: "+52-mx",
    label: "Mexico (+52)",
    code: "mx",
    flag: require("../../assets/countryflag/mx.webp"),
  },
  {
    value: "+691-fm",
    label: "Micronesia (+691)",
    code: "fm",
    flag: require("../../assets/countryflag/fm.webp"),
  },
  {
    value: "+373-md",
    label: "Moldova (+373)",
    code: "md",
    flag: require("../../assets/countryflag/md.webp"),
  },
  {
    value: "+377-mc",
    label: "Monaco (+377)",
    code: "mc",
    flag: require("../../assets/countryflag/mc.webp"),
  },
  {
    value: "+976-mn",
    label: "Mongolia (+976)",
    code: "mn",
    flag: require("../../assets/countryflag/mn.webp"),
  },
  {
    value: "+382-me",
    label: "Montenegro (+382)",
    code: "me",
    flag: require("../../assets/countryflag/me.webp"),
  },
  {
    value: "+1664-ms",
    label: "Montserrat (+1664)",
    code: "ms",
    flag: require("../../assets/countryflag/ms.webp"),
  },
  {
    value: "+212-ma",
    label: "Morocco (+212)",
    code: "ma",
    flag: require("../../assets/countryflag/ma.webp"),
  },
  {
    value: "+258-mz",
    label: "Mozambique (+258)",
    code: "mz",
    flag: require("../../assets/countryflag/mz.webp"),
  },
  {
    value: "+95-mm",
    label: "Myanmar (+95)",
    code: "mm",
    flag: require("../../assets/countryflag/mm.webp"),
  },
  {
    value: "+264-na",
    label: "Namibia (+264)",
    code: "na",
    flag: require("../../assets/countryflag/na.webp"),
  },
  {
    value: "+674-nr",
    label: "Nauru (+674)",
    code: "nr",
    flag: require("../../assets/countryflag/nr.webp"),
  },
  {
    value: "+977-np",
    label: "Nepal (+977)",
    code: "np",
    flag: require("../../assets/countryflag/np.webp"),
  },
  {
    value: "+31-nl",
    label: "Netherlands (+31)",
    code: "nl",
    flag: require("../../assets/countryflag/nl.webp"),
  },
  {
    value: "+687-nc",
    label: "New Caledonia (+687)",
    code: "nc",
    flag: require("../../assets/countryflag/nc.webp"),
  },
  {
    value: "+64-nz",
    label: "New Zealand (+64)",
    code: "nz",
    flag: require("../../assets/countryflag/nz.webp"),
  },
  {
    value: "+505-ni",
    label: "Nicaragua (+505)",
    code: "ni",
    flag: require("../../assets/countryflag/ni.webp"),
  },
  {
    value: "+227-ne",
    label: "Niger (+227)",
    code: "ne",
    flag: require("../../assets/countryflag/ne.webp"),
  },
  {
    value: "+234-ng",
    label: "Nigeria (+234)",
    code: "ng",
    flag: require("../../assets/countryflag/ng.webp"),
  },
  {
    value: "+683-nu",
    label: "Niue (+683)",
    code: "nu",
    flag: require("../../assets/countryflag/nu.webp"),
  },
  {
    value: "+672-nf",
    label: "Norfolk Island (+672)",
    code: "nf",
    flag: require("../../assets/countryflag/nf.webp"),
  },
  {
    value: "+850-kp",
    label: "North Korea (+850)",
    code: "kp",
    flag: require("../../assets/countryflag/kp.webp"),
  },
  {
    value: "+47-no",
    label: "Norway (+47)",
    code: "no",
    flag: require("../../assets/countryflag/no.webp"),
  },
  {
    value: "+968-om",
    label: "Oman (+968)",
    code: "om",
    flag: require("../../assets/countryflag/om.webp"),
  },
  {
    value: "+92-pk",
    label: "Pakistan (+92)",
    code: "pk",
    flag: require("../../assets/countryflag/pk.webp"),
  },
  {
    value: "+680-pw",
    label: "Palau (+680)",
    code: "pw",
    flag: require("../../assets/countryflag/pw.webp"),
  },
  {
    value: "+970-ps",
    label: "Palestinian Territory (+970)",
    code: "ps",
    flag: require("../../assets/countryflag/ps.webp"),
  },
  {
    value: "+507-pa",
    label: "Panama (+507)",
    code: "pa",
    flag: require("../../assets/countryflag/pa.webp"),
  },
  {
    value: "+675-pg",
    label: "Papua New Guinea (+675)",
    code: "pg",
    flag: require("../../assets/countryflag/pg.webp"),
  },
  {
    value: "+595-py",
    label: "Paraguay (+595)",
    code: "py",
    flag: require("../../assets/countryflag/py.webp"),
  },
  {
    value: "+51-pe",
    label: "Peru (+51)",
    code: "pe",
    flag: require("../../assets/countryflag/pe.webp"),
  },
  {
    value: "+63-ph",
    label: "Philippines (+63)",
    code: "ph",
    flag: require("../../assets/countryflag/ph.webp"),
  },
  {
    value: "+48-pl",
    label: "Poland (+48)",
    code: "pl",
    flag: require("../../assets/countryflag/pl.webp"),
  },
  {
    value: "+351-pt",
    label: "Portugal (+351)",
    code: "pt",
    flag: require("../../assets/countryflag/pt.webp"),
  },
  {
    value: "+1-pr",
    label: "Puerto Rico (+1)",
    code: "pr",
    flag: require("../../assets/countryflag/pr.webp"),
  },
  {
    value: "+974-qa",
    label: "Qatar (+974)",
    code: "qa",
    flag: require("../../assets/countryflag/qa.webp"),
  },
  {
    value: "+262-re",
    label: "Reunion Island (+262)",
    code: "re",
    flag: require("../../assets/countryflag/re.webp"),
  },
  {
    value: "+40-ro",
    label: "Romania (+40)",
    code: "ro",
    flag: require("../../assets/countryflag/ro.webp"),
  },
  {
    value: "+7-ru",
    label: "Russia (+7)",
    code: "ru",
    flag: require("../../assets/countryflag/ru.webp"),
  },
  {
    value: "+250-rw",
    label: "Rwanda (+250)",
    code: "rw",
    flag: require("../../assets/countryflag/rw.webp"),
  },
  {
    value: "+590-bl",
    label: "Saint Barthelemy (+590)",
    code: "bl",
    flag: require("../../assets/countryflag/bl.webp"),
  },
  {
    value: "+590-mf",
    label: "Saint Martin (+590)",
    code: "mf",
    flag: require("../../assets/countryflag/mf.webp"),
  },
  {
    value: "+685-ws",
    label: "Samoa (+685)",
    code: "ws",
    flag: require("../../assets/countryflag/ws.webp"),
  },
  {
    value: "+378-sm",
    label: "San Marino (+378)",
    code: "sm",
    flag: require("../../assets/countryflag/sm.webp"),
  },
  {
    value: "+239-st",
    label: "Sao Tome and Principe (+239)",
    code: "st",
    flag: require("../../assets/countryflag/st.webp"),
  },
  {
    value: "+966-sa",
    label: "Saudi Arabia (+966)",
    code: "sa",
    flag: require("../../assets/countryflag/sa.webp"),
  },
  {
    value: "+221-sn",
    label: "Senegal (+221)",
    code: "sn",
    flag: require("../../assets/countryflag/sn.webp"),
  },
  {
    value: "+381-rs",
    label: "Serbia (+381)",
    code: "rs",
    flag: require("../../assets/countryflag/rs.webp"),
  },
  {
    value: "+248-sc",
    label: "Seychelles (+248)",
    code: "sc",
    flag: require("../../assets/countryflag/sc.webp"),
  },
  {
    value: "+232-sl",
    label: "Sierra Leone (+232)",
    code: "sl",
    flag: require("../../assets/countryflag/sl.webp"),
  },
  {
    value: "+421-sk",
    label: "Slovakia (+421)",
    code: "sk",
    flag: require("../../assets/countryflag/sk.webp"),
  },
  {
    value: "+386-si",
    label: "Slovenia (+386)",
    code: "si",
    flag: require("../../assets/countryflag/si.webp"),
  },
  {
    value: "+677-sb",
    label: "Solomon Islands (+677)",
    code: "sb",
    flag: require("../../assets/countryflag/sb.webp"),
  },
  {
    value: "+252-so",
    label: "Somalia (+252)",
    code: "so",
    flag: require("../../assets/countryflag/so.webp"),
  },
  {
    value: "+27-za",
    label: "South Africa (+27)",
    code: "za",
    flag: require("../../assets/countryflag/za.webp"),
  },
  {
    value: "+82-kr",
    label: "South Korea (+82)",
    code: "kr",
    flag: require("../../assets/countryflag/kr.webp"),
  },
  {
    value: "+211-ss",
    label: "South Sudan (+211)",
    code: "ss",
    flag: require("../../assets/countryflag/ss.webp"),
  },
  {
    value: "+34-es",
    label: "Spain (+34)",
    code: "es",
    flag: require("../../assets/countryflag/es.webp"),
  },
  {
    value: "+94-lk",
    label: "Sri Lanka (+94)",
    code: "lk",
    flag: require("../../assets/countryflag/lk.webp"),
  },
  {
    value: "+1-869",
    label: "Saint Kitts and Nevis (+1-869)",
    code: "kn",
    flag: require("../../assets/countryflag/kn.webp"),
  },
  {
    value: "+1-758",
    label: "Saint Lucia (+1-758)",
    code: "lc",
    flag: require("../../assets/countryflag/lc.webp"),
  },
  {
    value: "+508-pm",
    label: "Saint Pierre and Miquelon (+508)",
    code: "pm",
    flag: require("../../assets/countryflag/pm.webp"),
  },
  {
    value: "+1-784",
    label: "Saint Vincent and the Grenadines (+1-784)",
    code: "vc",
    flag: require("../../assets/countryflag/vc.webp"),
  },
  {
    value: "+249-sd",
    label: "Sudan (+249)",
    code: "sd",
    flag: require("../../assets/countryflag/sd.webp"),
  },
  {
    value: "+597-sr",
    label: "Suriname (+597)",
    code: "sr",
    flag: require("../../assets/countryflag/sr.webp"),
  },
  {
    value: "+47-sj",
    label: "Svalbard and Jan Mayen (+47)",
    code: "sj",
    flag: require("../../assets/countryflag/sj.webp"),
  },
  {
    value: "+268-sz",
    label: "Swaziland (+268)",
    code: "sz",
    flag: require("../../assets/countryflag/sz.webp"),
  },
  {
    value: "+46-se",
    label: "Sweden (+46)",
    code: "se",
    flag: require("../../assets/countryflag/se.webp"),
  },
  {
    value: "+41-ch",
    label: "Switzerland (+41)",
    code: "ch",
    flag: require("../../assets/countryflag/ch.webp"),
  },
  {
    value: "+963-sy",
    label: "Syria (+963)",
    code: "sy",
    flag: require("../../assets/countryflag/sy.webp"),
  },
  {
    value: "+886-tw",
    label: "Taiwan (+886)",
    code: "tw",
    flag: require("../../assets/countryflag/tw.webp"),
  },
  {
    value: "+992-tj",
    label: "Tajikistan (+992)",
    code: "tj",
    flag: require("../../assets/countryflag/tj.webp"),
  },
  {
    value: "+255-tz",
    label: "Tanzania (+255)",
    code: "tz",
    flag: require("../../assets/countryflag/tz.webp"),
  },
  {
    value: "+66-th",
    label: "Thailand (+66)",
    code: "th",
    flag: require("../../assets/countryflag/th.webp"),
  },
  {
    value: "+228-tg",
    label: "Togo (+228)",
    code: "tg",
    flag: require("../../assets/countryflag/tg.webp"),
  },
  {
    value: "+676-to",
    label: "Tonga (+676)",
    code: "to",
    flag: require("../../assets/countryflag/to.webp"),
  },
  {
    value: "+1868-tt",
    label: "Trinidad and Tobago (+1868)",
    code: "tt",
    flag: require("../../assets/countryflag/tt.webp"),
  },
  {
    value: "+216-tn",
    label: "Tunisia (+216)",
    code: "tn",
    flag: require("../../assets/countryflag/tn.webp"),
  },
  {
    value: "+90-tr",
    label: "Turkey (+90)",
    code: "tr",
    flag: require("../../assets/countryflag/tr.webp"),
  },
  {
    value: "+993-tm",
    label: "Turkmenistan (+993)",
    code: "tm",
    flag: require("../../assets/countryflag/tm.webp"),
  },
  {
    value: "+1649-tc",
    label: "Turks and Caicos Islands (+1649)",
    code: "tc",
    flag: require("../../assets/countryflag/tc.webp"),
  },
  {
    value: "+688-tv",
    label: "Tuvalu (+688)",
    code: "tv",
    flag: require("../../assets/countryflag/tv.webp"),
  },
  {
    value: "+256-ug",
    label: "Uganda (+256)",
    code: "ug",
    flag: require("../../assets/countryflag/ug.webp"),
  },
  {
    value: "+44",
    label: "UK (+44)",
    code: "gb",
    flag: require("../../assets/countryflag/gb.webp"),
  },
  {
    value: "+380-ua",
    label: "Ukraine (+380)",
    code: "ua",
    flag: require("../../assets/countryflag/ua.webp"),
  },
  {
    value: "+598-uy",
    label: "Uruguay (+598)",
    code: "uy",
    flag: require("../../assets/countryflag/uy.webp"),
  },
  {
    value: "+1-us",
    label: "USA (+1)",
    code: "us",
    flag: require("../../assets/countryflag/us.webp"),
  },
  {
    value: "+998-uz",
    label: "Uzbekistan (+998)",
    code: "uz",
    flag: require("../../assets/countryflag/uz.webp"),
  },
  {
    value: "+678-vu",
    label: "Vanuatu (+678)",
    code: "vu",
    flag: require("../../assets/countryflag/vu.webp"),
  },
  {
    value: "+58-ve",
    label: "Venezuela (+58)",
    code: "ve",
    flag: require("../../assets/countryflag/ve.webp"),
  },
  {
    value: "+84-vn",
    label: "Vietnam (+84)",
    code: "vn",
    flag: require("../../assets/countryflag/vn.webp"),
  },
  {
    value: "+1-284-vg",
    label: "Virgin Islands, British (+1-284)",
    code: "vg",
    flag: require("../../assets/countryflag/vg.webp"),
  },
  {
    value: "+1-340-vi",
    label: "Virgin Islands, U.S. (+1-340)",
    code: "vi",
    flag: require("../../assets/countryflag/vi.webp"),
  },
  {
    value: "+681-wf",
    label: "Wallis and Futuna (+681)",
    code: "wf",
    flag: require("../../assets/countryflag/wf.webp"),
  },
  {
    value: "+967-ye",
    label: "Yemen (+967)",
    code: "ye",
    flag: require("../../assets/countryflag/ye.webp"),
  },
  {
    value: "+260-zm",
    label: "Zambia (+260)",
    code: "zm",
    flag: require("../../assets/countryflag/zm.webp"),
  },
  {
    value: "+263-zw",
    label: "Zimbabwe (+263)",
    code: "zw",
    flag: require("../../assets/countryflag/zw.webp"),
  },
];
export default options;
