import {
  Avatar,
  Box,
  Center,
  Flex,
  // Image,
  Textarea,
  // VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  // NumberIncrementStepper,
  // NumberDecrementStepper,
} from "@chakra-ui/react";
import axios from "axios";
import userRegisterImage from "../../../assets/userProfileAvatar.jpg";
import { useToast } from "@chakra-ui/react";
// import { MdCheckCircle } from "react-icons/md";
import {
  useDispatch,
  // , useSelector
} from "react-redux";
// import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";
import {
  // EDIT_SINGLE_ASTROLOGER,
  G_GET_SINGLE_ASTROLOGER,
  P_EDIT_ASTROLOGER_DETAILS,
} from "../../../apiLinks";

const skillsOptions = [
  "Vedic astrology",
  "Tarot card reading",
  "Kundli",
  "Palmistry",
  "Numerology",
  "Match making",
  "Counsellor",
  "Face reading",
  "Lal Kitab",
  "Vastu",
  "Angel card healing",
  "Abroad settlement",
  "Aromatherapy",
  "Ashtakavarga",
  "Aura, chakra healing",
  "Ayurveda",
  "Bazi Reading",
  "Birth time rectification",
  "Cartomancy",
  "Child, study",
  "Colour therapy",
  "Crystal ball",
  "Crystal healing",
  "Diabetes",
  "Dream interpretation",
  "Energy, pranic healing",
  "Fengshui",
  "Gemology",
  "Hair Problems",
  "Handwriting reading",
  "Holistic therapist",
  "Horary astrology",
  "Hypnotherapy",
  "Jaimini astrology",
  "Karmkand",
  "KP astrology",
  "Mahavastu",
  "Mantra",
  "Meditation",
  "Medical astrology",
  "Motivational therapy",
  "Muhurthas",
  "Nadi astrology",
  "Name correction",
  "Numerology Card",
  "Oracle card reading",
  "Ottoman astrology",
  "Panchkarma",
  "Pendulum dowsing",
  "Pooja",
  "Prashna",
  "Psychic, spirit reading",
  "Relationship therapy",
  "Reiki healing",
  "Runes, Zibu, tattoo symbols",
  "Sadhana",
  "Skin Problems",
  "Spell Caster",
  "Spiritual healing",
  "Switch words",
  "Talking therapy",
  "Theta healing",
  "Vedic jyotishya",
  "Weight Loss, Obesity",
  "Western astrology",
  "Yijing divination",
  "Yoga",
];

const AddAstrologer = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("guruToken");
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gid, setGid] = useState("");
  const [served, setServed] = useState("");
  const [specialization, setSpecializations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [rating, setRating] = useState("");
  const [experience, setExperience] = useState("");
  const [guruShare, setGuruShare] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [newUserEnteredImage, setNewUserEnteredImage] =
    useState(userRegisterImage);
  const [newShowUserEnteredImage, setShowNewUserEnteredImage] =
    useState(userRegisterImage);

  const [newUserEnteredImageBackground, setNewUserEnteredImageBackground] =
    useState([]);
  const [
    newShowUserEnteredImageBackground,
    setShowNewUserEnteredImageBackground,
  ] = useState(userRegisterImage);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [indianCallFee, setIndianCallFee] = useState("");
  const [indianCallFeeCutted, setIndianCallFeeCutted] = useState("");
  const [internationalCallFee, setInternationalCallFee] = useState("");
  const [internationalCallFeeCutted, setInternationalCallFeeCutted] =
    useState("");
  const [privateFee, setPrivateFee] = useState("");
  const [privateCuttedFee, setPrivateCuttedFee] = useState("");
  const [internationalPrivateFee, setInternationalPrivateFee] = useState("");
  const [internationalPrivateCuttedFee, setInternationalPrivateCuttedFee] =
    useState("");
  const [indianLiveCallFee, setIndianLiveCallFee] = useState("");
  const [indianLiveCallCuttedFee, setIndianLiveCallCuttedFee] = useState("");
  const [internationalLiveCallFee, setInternationalLiveCallFee] = useState("");
  const [internationalLiveCallCuttedFee, setInternationalLiveCallCuttedFee] =
    useState("");
  const [firstOfferNationalFee, setFirstOfferNationalFee] = useState("");
  const [firstOfferNationalCuttedFee, setFirstOfferNationalCuttedFee] =
    useState("");
  const [firstOfferInternationalFee, setFirstOfferInternationalFee] =
    useState("");
  const [
    firstOfferInternationalCuttedFee,
    setFirstOfferInternationalCuttedFee,
  ] = useState("");
  const toast = useToast();
  const [liveRoomId, setLiveRoomId] = useState("");
  const [userLiveRoomId, setUserLiveRoomId] = useState("");
  const [privateRoomId, setPrivateRoomId] = useState("");

  useEffect(() => {
    if (token) {
      axios.get(G_GET_SINGLE_ASTROLOGER(token)).then((response) => {
        const { guru } = response.data;
        setUser(guru.user);
        setFirstName(guru.user.firstName);
        setLastName(guru.user.lastName);
        setPhone(guru.user.phone);
        setGid(guru.gid);
        setIndianCallFee(guru.fee || "");
        setIndianCallFeeCutted(guru.cutfee || "");
        setInternationalCallFee(guru.internationalFee || "");
        setInternationalCallFeeCutted(guru.cutfeeInternational || "");
        setIndianLiveCallFee(guru.live.public.national.callfee || "");
        setIndianLiveCallCuttedFee(guru.live.public.national.callCutfee || "");
        setInternationalLiveCallFee(
          guru.live.public.international.callfee || ""
        );
        setInternationalLiveCallCuttedFee(
          guru.live.public.international.callCutfee || ""
        );
        setPrivateFee(guru.live.private.national.callfee || "");
        setPrivateCuttedFee(guru.live.private.national.callCutfee || "");
        setInternationalPrivateFee(
          guru.live.private.international.callfee || ""
        );
        setInternationalPrivateCuttedFee(
          guru.live.private.international.callCutfee || ""
        );
        setLanguages(guru.languages);
        setRating(guru.rating);
        setHeading(guru.heading);
        setDescription(guru.description);
        setExperience(guru.experience);
        setGuruShare(guru.guruShare);
        setSelectedSkills(guru.skills);
        setSpecializations(guru.specialization);
        setServed(guru.served);
        setLiveRoomId(guru.liveGatewayIDs.astrologerLiveId);
        setUserLiveRoomId(guru.liveGatewayIDs.userLiveId);
        setPrivateRoomId(guru.roomId);
        setFirstOfferNationalFee(
          guru?.firstOfferPrice?.national?.fee
            ? guru?.firstOfferPrice?.national?.fee
            : 0
        );
        setFirstOfferNationalCuttedFee(
          guru?.firstOfferPrice?.national?.cutfee
            ? guru?.firstOfferPrice?.national?.cutfee
            : 0
        );
        setFirstOfferInternationalFee(
          guru?.firstOfferPrice?.international?.cutfee
            ? guru?.firstOfferPrice?.international?.cutfee
            : 0
        );
        setFirstOfferInternationalCuttedFee(
          guru?.firstOfferPrice?.international?.cutfee
            ? guru?.firstOfferPrice?.international?.cutfee
            : 0
        );
      });
    }
  }, [token]);

  const loginToken = localStorage.getItem("loginToken");
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };

    //
    axios
      .post(
        P_EDIT_ASTROLOGER_DETAILS(token),
        {
          firstOfferPrice: {
            national: {
              fee: firstOfferNationalFee,
              cutfee: firstOfferNationalCuttedFee,
            },
            international: {
              fee: firstOfferInternationalFee,
              cutfee: firstOfferInternationalCuttedFee,
            },
          },
          fee: indianCallFee,
          cutfee: indianCallFeeCutted,
          internationalFee: internationalCallFee,
          cutfeeInternational: internationalCallFeeCutted,
          languages,
          rating,
          heading,
          description,
          experience,
          guruShare,
          specialization,
          skills: selectedSkills,
          served,
          file: newUserEnteredImage,
          liveGatewayIDs: {
            userLiveId: userLiveRoomId,
            astrologerLiveId: liveRoomId,
          },
          roomId: privateRoomId,
          live: {
            public: {
              national: {
                callfee: indianLiveCallFee,
                callCutfee: indianLiveCallCuttedFee,
              },
              international: {
                callfee: internationalLiveCallFee,
                callCutfee: internationalLiveCallCuttedFee,
              },
            },
            private: {
              national: {
                callfee: privateFee,
                callCutfee: privateCuttedFee,
              },
              international: {
                callfee: internationalPrivateFee,
                callCutfee: internationalPrivateCuttedFee,
              },
            },
          },
        },
        config
      )
      .then((response) => {
       
        if (response.status === 200) {
          toast({
            title: "Astrologer added Successfully",
            description: "Astrologer added Successfully",
            status: "error",
            duration: 1000,
            isClosable: true,
            position: "top-right",
            render: () => (
              <Button colorScheme="green" size="sm">
                Astrologer Details updated Successfully
              </Button>
            ),
          });
          // window.location.reload();
        }
        // window.location.href = "/admin/showAstrologers";
      })
      .catch((error) => {
        //
        toast({
          title: "Astrologer not added Successfully",
          description: "Astrologer not added Successfully",
          status: "error",
          duration: 1000,
          isClosable: true,
          position: "top-right",
          render: () => (
            <Button colorScheme="red" size="sm">
              {error.response.data.message}
            </Button>
          ),
        });
      });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setNewUserEnteredImage(event.target.files[0]);
      setShowNewUserEnteredImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handlePhoneChange = async () => {
   
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      const response = await axios.post(
        "https://data.gurucool.life/api/v1/admin/updateAstrologerNumber",
        {
          gid,
          phone,
        },
        config
      );
     
    } catch (error) {
      // Handle any errors here
    }
  };

  return (
    <>
      <Flex justifyContent={"flex-end"}>
        <Sidebar />
        <Box w={"80%"} fontSize={"18px"} p={5}>
          <form onSubmit={handleFormSubmit}>
            <Stack spacing={3}>
              <FormControl w={"sm"} isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  isDisabled
                  type="text"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </FormControl>
              <FormControl w={"sm"} isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  isDisabled
                  type="text"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </FormControl>
              {/* <FormControl w={"sm"} isRequired>
                <FormLabel>Phone</FormLabel>
                <Input
                  type="tel"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
                <Button
                  onClick={(e) => {
                    handlePhoneChange(e);
                  }}
                >
                  Update
                </Button>
              </FormControl> */}
              <FormControl id="userImg" w={"sm"} isRequired>
                <FormLabel>Select Astrologer Profile Photo</FormLabel>
                <Stack direction={["column", "row"]}>
                  <Center>
                    <Avatar size="xl" src={newShowUserEnteredImage}></Avatar>
                  </Center>
                  <Center w="full">
                    <Button w="full">
                      <input
                        type="file"
                        onChange={(e) => onImageChange(e)}
                        className="filetype"
                      />
                    </Button>
                  </Center>
                </Stack>
              </FormControl>
              <FormControl w={"sm"}>
                <FormLabel>Served</FormLabel>
                <NumberInput
                  value={served}
                  onChange={(value) => {
                    setServed(value);
                  }}
                >
                  <NumberInputField placeholder="Enter Served" />
                  <NumberInputStepper></NumberInputStepper>
                </NumberInput>
              </FormControl>

              {/* ----New Offer Price National---- */}
              <Flex p={2}>
                <FormControl w={"sm"}>
                  <FormLabel>First offer National Fee(INR)</FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={firstOfferNationalFee}
                    onChange={(value) => setFirstOfferNationalFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w={"sm"}>
                  <FormLabel textDecoration="line-through">
                    First offer National Cutted Fee(INR)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={firstOfferNationalCuttedFee}
                    onChange={(value) => setFirstOfferNationalCuttedFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>

              {/* ----New Offer Price International---- */}
              <Flex p={2}>
                <FormControl w={"sm"}>
                  <FormLabel>First offer InterNational Fee(USD)</FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={firstOfferInternationalFee}
                    onChange={(value) => setFirstOfferInternationalFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w={"sm"}>
                  <FormLabel textDecoration="line-through">
                    First offer International Cutted Fee(USD)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={firstOfferInternationalCuttedFee}
                    onChange={(value) =>
                      setFirstOfferInternationalCuttedFee(value)
                    }
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>

              <Flex p={2}>
                <FormControl w={"sm"}>
                  <FormLabel>Indian Call Fee(INR)</FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={indianCallFee}
                    onChange={(value) => setIndianCallFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w={"sm"}>
                  <FormLabel textDecoration="line-through">
                    Indian Call Cutted Fee(INR)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={indianCallFeeCutted}
                    onChange={(value) => setIndianCallFeeCutted(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>
              {/* live consultation fee */}
              <Flex p={2}>
                <FormControl w={"sm"}>
                  <FormLabel>Indian Live Consultation Fee(INR)</FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={indianLiveCallFee}
                    onChange={(value) => setIndianLiveCallFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w={"sm"}>
                  <FormLabel textDecoration="line-through">
                    Indian Live Consultation Cutted Fee(INR)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={indianLiveCallCuttedFee}
                    onChange={(value) => setIndianLiveCallCuttedFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>
              <Flex p={2}>
                <FormControl w={"sm"}>
                  <FormLabel>Indian Private Video Call Fee(INR)</FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={privateFee}
                    onChange={(value) => setPrivateFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w={"sm"}>
                  <FormLabel textDecoration="line-through">
                    Indian Private Video Call Cutted Fee(INR)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={privateCuttedFee}
                    onChange={(value) => setPrivateCuttedFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>
              {/* international fees */}
              <Flex p={2}>
                <FormControl w={"sm"}>
                  <FormLabel>International Call Fee (USD)</FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={internationalCallFee}
                    onChange={(value) => setInternationalCallFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute in dollars" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w={"sm"}>
                  <FormLabel textDecoration="line-through">
                    International Call Cutted Fee (USD)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={internationalCallFeeCutted}
                    onChange={(value) => setInternationalCallFeeCutted(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute in dollars" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>
              <Flex p={2}>
                <FormControl w={"sm"}>
                  <FormLabel>
                    International Live Consultation Fee(USD)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={internationalLiveCallFee}
                    onChange={(value) => setInternationalLiveCallFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w={"sm"}>
                  <FormLabel textDecoration="line-through">
                    International Live Consultation Cutted Fee(USD)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={internationalLiveCallCuttedFee}
                    onChange={(value) =>
                      setInternationalLiveCallCuttedFee(value)
                    }
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>
              <Flex p={2}>
                <FormControl w={"sm"}>
                  <FormLabel>
                    International Private Video Call Fee(USD)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={internationalPrivateFee}
                    onChange={(value) => setInternationalPrivateFee(value)}
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w={"sm"}>
                  <FormLabel textDecoration="line-through">
                    International Private Video Call Cutted Fee(USD)
                  </FormLabel>
                  <NumberInput
                    w={"90%"}
                    value={internationalPrivateCuttedFee}
                    onChange={(value) =>
                      setInternationalPrivateCuttedFee(value)
                    }
                  >
                    <NumberInputField placeholder="Enter fee per minute" />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Flex>
              <FormControl w={"sm"}>
                <FormLabel>Astrologer Share</FormLabel>
                <Select
                  placeholder="Select Percentage"
                  value={guruShare}
                  onChange={(event) => setGuruShare(event.target.value)}
                >
                  <option value="0.1">10%</option>
                  <option value="0.2">20%</option>
                  <option value="0.3">30%</option>
                  <option value="0.4">40%</option>
                  <option value="0.5">50%</option>
                  <option value="0.6">60%</option>
                  <option value="0.7">70%</option>
                  <option value="0.8">80%</option>
                  <option value="0.9">90%</option>
                  <option value="1">100%</option>
                </Select>
              </FormControl>

              <FormControl w={"sm"}>
                <FormLabel>Experience</FormLabel>
                <NumberInput
                  value={experience}
                  onChange={(value) => setExperience(value)}
                >
                  <NumberInputField placeholder="Enter Experience in years" />
                  <NumberInputStepper></NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl w={"sm"}>
                <FormLabel>Rating</FormLabel>
                <NumberInput
                  value={rating}
                  onChange={(value) => setRating(value)}
                >
                  <NumberInputField placeholder="Enter Ratings" />
                  <NumberInputStepper></NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Languages</FormLabel>
                <Stack>
                  <Checkbox
                    value="Hindi"
                    isChecked={languages.includes("Hindi")}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setLanguages([...languages, "Hindi"]);
                      } else {
                        setLanguages(languages.filter((l) => l !== "Hindi"));
                      }
                    }}
                  >
                    Hindi
                  </Checkbox>
                  <Checkbox
                    value="English"
                    isChecked={languages.includes("English")}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setLanguages([...languages, "English"]);
                      } else {
                        setLanguages(languages.filter((l) => l !== "English"));
                      }
                    }}
                  >
                    English
                  </Checkbox>
                </Stack>
              </FormControl>

              {/* ---------------------------------- */}
              {/* <FormControl id="userImg" w={"sm"} isRequired>
                <FormLabel>Select Astrologer images</FormLabel>
                <Stack direction={["column", "row"]}>
                  <Center>
                    <VStack>
                      {newUserEnteredImageBackground.map((image) => (
                        <Image
                          key={image.previewUrl}
                          src={image.previewUrl}
                          alt={image.file.name}
                          boxSize="100px"
                        />
                      ))}
                    </VStack>
                  </Center>
                  <Center w="full">
                    <Button w="full">
                      <Input
                        multiple
                        accept="image/*"
                        type="file"
                        onChange={(e) => onImageChangeBackground(e)}
                        className="filetype"
                      />
                    </Button>
                  </Center>
                </Stack>
              </FormControl> */}
              {/* ---------------------------------- */}
              {/* <FormControl w={"sm"} isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>
              <FormControl w={"sm"} isRequired>
                <FormLabel>Phone</FormLabel>
                <Input
                  type="tel"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </FormControl> */}
              {/* <FormControl w={"sm"}>
                <FormLabel>Gender</FormLabel>
                <Select
                  placeholder="Select gender"
                  value={gender}
                  onChange={(event) => setGender(event.target.value)}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl> */}
              <FormControl w={"sm"} isRequired>
                <FormLabel>Live Stream Room Id (for astrologer)</FormLabel>
                <Input
                  type="text"
                  value={liveRoomId}
                  onChange={(event) => setLiveRoomId(event.target.value)}
                />
              </FormControl>
              <FormControl w={"sm"} isRequired>
                <FormLabel>Live Stream Room Id (for user)</FormLabel>
                <Input
                  type="text"
                  value={userLiveRoomId}
                  onChange={(event) => setUserLiveRoomId(event.target.value)}
                />
              </FormControl>
              <FormControl w={"sm"} isRequired>
                <FormLabel>Private Call Room Id (for both)</FormLabel>
                <Input
                  type="text"
                  value={privateRoomId}
                  onChange={(event) => setPrivateRoomId(event.target.value)}
                />
              </FormControl>
              <FormControl w={"full"}>
                <FormLabel>Heading</FormLabel>
                <Input
                  value={heading}
                  onChange={(event) => setHeading(event.target.value)}
                />
              </FormControl>
              <FormControl w={"full"}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  h={"200px"}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Specializations</FormLabel>
                <Stack>
                  <Flex>
                    <Checkbox
                      p={2}
                      value="Business"
                      isChecked={specialization.includes("Business")}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSpecializations([...specialization, "Business"]);
                        } else {
                          setSpecializations(
                            specialization.filter((s) => s !== "Business")
                          );
                        }
                      }}
                    >
                      Business
                    </Checkbox>
                    <Checkbox
                      p={2}
                      value="Career"
                      isChecked={specialization.includes("Career")}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSpecializations([...specialization, "Career"]);
                        } else {
                          setSpecializations(
                            specialization.filter((s) => s !== "Career")
                          );
                        }
                      }}
                    >
                      Career
                    </Checkbox>
                    <Checkbox
                      p={2}
                      value="Health"
                      isChecked={specialization.includes("Health")}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSpecializations([...specialization, "Health"]);
                        } else {
                          setSpecializations(
                            specialization.filter((s) => s !== "Health")
                          );
                        }
                      }}
                    >
                      Health
                    </Checkbox>
                    <Checkbox
                      p={2}
                      value="Life"
                      isChecked={specialization.includes("Life")}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSpecializations([...specialization, "Life"]);
                        } else {
                          setSpecializations(
                            specialization.filter((s) => s !== "Life")
                          );
                        }
                      }}
                    >
                      Life
                    </Checkbox>
                    <Checkbox
                      p={2}
                      value="Love"
                      isChecked={specialization.includes("Love")}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSpecializations([...specialization, "Love"]);
                        } else {
                          setSpecializations(
                            specialization.filter((s) => s !== "Love")
                          );
                        }
                      }}
                    >
                      Love
                    </Checkbox>
                    <Checkbox
                      p={2}
                      value="Marriage"
                      isChecked={specialization.includes("Marriage")}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSpecializations([...specialization, "Marriage"]);
                        } else {
                          setSpecializations(
                            specialization.filter((s) => s !== "Marriage")
                          );
                        }
                      }}
                    >
                      Marriage
                    </Checkbox>
                    <Checkbox
                      p={2}
                      value="Tarot"
                      isChecked={specialization.includes("Tarot")}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSpecializations([...specialization, "Tarot"]);
                        } else {
                          setSpecializations(
                            specialization.filter((s) => s !== "Tarot")
                          );
                        }
                      }}
                    >
                      Tarot
                    </Checkbox>
                  </Flex>
                </Stack>
              </FormControl>
              <FormControl w={"100%"}>
                <FormLabel>Skills</FormLabel>
                <Stack>
                  <Flex wrap={"wrap"}>
                    {skillsOptions.map((skill) => (
                      <Checkbox
                        p={2}
                        value={skill}
                        isChecked={selectedSkills.includes(skill)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedSkills([...selectedSkills, skill]);
                          } else {
                            setSelectedSkills(
                              selectedSkills.filter((s) => s !== skill)
                            );
                          }
                        }}
                      >
                        {skill}
                      </Checkbox>
                    ))}
                  </Flex>
                </Stack>
              </FormControl>

              {/* <FormControl w={"sm"}>
                <FormLabel>Rating</FormLabel>
                <Select
                  placeholder="Select rating"
                  value={rating}
                  onChange={(event) => setRating(event.target.value)}
                >
                  <option value="1">1 star</option>
                  <option value="2">2 stars</option>
                  <option value="3">3 stars</option>
                  <option value="4">4 stars</option>
                  <option value="5">5 stars</option>
                </Select>
              </FormControl> */}

              <Button
                w={"sm"}
                type="submit"
                colorScheme="blue"
                onClick={(e) => handleFormSubmit(e)}
              >
                Update Astrologer
              </Button>
            </Stack>
          </form>
        </Box>
      </Flex>
    </>
  );
};

export default AddAstrologer;
