import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Input,
  Center,
  Spinner,
  Text,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { fetchData } from "../../../store/TarotSlice";
import { Navigate, useNavigate } from "react-router";
import Pagination from "react-js-pagination";

const DisabledAstrologer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const ITEMS_PER_PAGE = 12;
  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const data = useSelector((state) => state.data.data);
  const status = useSelector((state) => state.data.status);
  const error = useSelector((state) => state.data.error);

  //
  // useEffect(() => {
  //   dispatch(fetchData());
  // }, [dispatch]);

  useEffect(() => {
    const getAstrologersUrl =
      "https://data.gurucool.life/api/v1/guru/guru";
    axios.get(getAstrologersUrl).then((response) => {
      setUsers(response.data.astrologers.docs);
      //
    });
  }, []);
  //

  // const filteredUsers = users.filter((user) =>
  //   user.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const handleEdit = (userId) => {
    navigate("/admin/editAstrologer");
    //
    //
    const token= localStorage.setItem("guruToken",userId);
    // us
  };

  const handleDelete = (astrologerId) => {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    //
    const deleteAstrologerUrl = `https://data.gurucool.life/api/v1/guru/edit_guru_details?guruId=${astrologerId}`;
    axios
      .post(
        deleteAstrologerUrl,
        {
          status: "active",
        },
        config
      )
      .then((response) => {
        //
        window.location.reload();
      })
      .catch((error) => {
        //
      });
  };
  const handleGoOnline = (astrologerId) => {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
   
    const deleteAstrologerUrl = `https://data.gurucool.life/api/v1/guru/edit_guru_details?guruId=${astrologerId}`;
    axios
      .post(
        deleteAstrologerUrl,
        {
          callAvailability: "online",
        },
        config
      )
      .then((response) => {
        //
        window.location.reload();
      })
      .catch((error) => {
        //
      });
  };
  const handleGoOffline = (astrologerId) => {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
   
    const deleteAstrologerUrl = `https://data.gurucool.life/api/v1/guru/edit_guru_details?guruId=${astrologerId}`;
    axios
      .post(
        deleteAstrologerUrl,
        {
          callAvailability: "offline",
        },
        config
      )
      .then((response) => {
        //
        window.location.reload();
      })
      .catch((error) => {
        //
      });
  };

  if (status === "loading") {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  } else if (status === "succeeded") {
    return (
      <>
        <Flex justifyContent={"flex-end"}>
          <Sidebar />
          <Box w={"80%"} fontSize={"18px"} p={5}>
            <Input
              w={"sm"}
              placeholder="Search users"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <Table>
              <Thead>
                <Tr>
                  <Th>UID</Th>
                  <Th>GID</Th>
                  <Th>Profile Photo</Th>
                  <Th>User Name</Th>
                  <Th>Online</Th>
                  <Th>Fee</Th>
                  <Th>Status</Th>
                  <Th>Date of Joining</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((currElem) => (
                  <Tr key={currElem._id}>
                    <Td>{currElem.user.uid}</Td>
                    <Td>{currElem.gid}</Td>
                    <Td>
                      <Avatar
                        src={currElem.user.avatar.url}
                        alt={`Profile of ${currElem.user.firstName}`}
                      />
                    </Td>
                    <Td>
                      {currElem.user.firstName} {currElem.user.lastName}
                    </Td>
                    <Td>{currElem.callAvailability}</Td>
                    <Td>{currElem.internationalFee}</Td>
                    <Td>{currElem.user.status}</Td>
                    <Td>{currElem.user.createdAt.slice(0, 10)}</Td>
                    <Td>
                      <Button
                        size="sm"
                        m={2}
                        onClick={() => handleEdit(currElem.user.guru)}
                      >
                        Edit
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleDelete(currElem.user.guru)}
                      >
                        Disable
                      </Button>
                      {currElem.callAvailability == "online" ||
                      currElem.callAvailability == "busy" ? (
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => handleGoOffline(currElem.user.guru)}
                        >
                          Go Offline
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          colorScheme="green"
                          onClick={() => handleGoOnline(currElem.user.guru)}
                        >
                          Go Online
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Flex justifyContent={"center"} alignItems={"center"} m="70px">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={ITEMS_PER_PAGE}
          totalItemsCount={data.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          prevPageText="Previous"
          nextPageText="Next"
        />
      </Flex>
        </Flex>
      </>
    );
  } else if (status === "failed") {
    return (
      <Center>
        <Text color="red">{error}</Text>
      </Center>
    );
  }

  return null;
};

export default DisabledAstrologer;
