import React, { useEffect } from "react";
import { firebaseApp, analytics, logEvent } from "../firebaseEvents/firebase";

function ScrollTracker() {
    useEffect(() => {
        // Function to log the scroll event
        function logScrollEvent() {
            logEvent(analytics, "scroll_event", {
                page: window.location.pathname,
            });
        }

        // Add event listener to track scroll events
        window.addEventListener("scroll", logScrollEvent);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener("scroll", logScrollEvent);
        };
    }, []);

    return null; // This component doesn't render anything
}

export default ScrollTracker;
