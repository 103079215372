import {
  Button,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiDeleteBinFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoggedInUserDetails } from "../../../store/LoggedInUserDetails";
import { toast } from "react-toastify";
import {RiDeleteBin5Fill} from "react-icons/ri"
import  deleteIcon  from "../Icons/_Group_.svg";

export default function Deletepopup({ handleDeleteBirthDetails,birthId,userDetails }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {/* <Button
        display={{ base: "none", md: "block" }}
        color={"#FF4C00"}
        bg={"inherit"}
        leftIcon={<RiDeleteBinFill color={"#FF4C00"} />}
        onClick={onOpen}
      >
        Delete
      </Button> */}
      <Image
      src={deleteIcon}
        // display={{ base: "block", md: "none" }}
        alignSelf={"center"}
        bg={"none"}
        size={"25px"}
        color={"#E9513C"}
        // p={4}
        cursor={"pointer"}
        h={"22px"}
        w={"22px"}
        onClick={onOpen}
      />
      <Modal  isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}></ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody textAlign={"center"}>
            Do You Want to Delete ({userDetails?.userDetails?.birthDetails?.filter((obj) => {
        return obj._id === birthId;
      })[0]?.fullName})  ?
          </ModalBody>
          <ModalFooter m={"auto"}>
            <Button
              colorScheme="orange"
              width={{ base: "150px", sm: "200px" }}
              borderColor={"#DDDDDD"}
              color={"blackAlpha.820"}
              onClick={onClose}
              variant="outline"
              borderLeft={"none"}
              borderBottom={"none"}
              borderRadius={"0px"}
            >
              No
            </Button>
            <Button
              colorScheme="red"
              width={{ base: "150px", sm: "200px" }}
              borderColor={"#DDDDDD"}
              color={"blackAlpha.820"}
              variant="outline"
              borderLeft={"none"}
              borderRight={"none"}
              borderBottom={"none"}
              borderRadius={"0px"}
              onClick={handleDeleteBirthDetails}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
