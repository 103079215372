import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  // Button,
  // Input,
  Center,
  Spinner,
  Text,
  // Avatar,
  NumberInput,
  NumberInputField,
  Icon,
  FormLabel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { Navigate, useNavigate } from "react-router";

import Pagination from "react-js-pagination";
import { fetchConsultations } from "../../../store/getConsultationSlice";
// import { Link } from "react-router-dom";
import {
  // FiFileText,
  FiPlay,
  FiXCircle,
} from "react-icons/fi";

function convertIst(date) {
  const utcDate = new Date(date);
  const istDate = new Date(
    utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );

  const day = istDate.getDate();
  const month = istDate.toLocaleString("default", { month: "long" });
  const year = istDate.getFullYear();
  const hours = istDate.getHours();
  const minutes = istDate.getMinutes();
  const seconds = istDate.getSeconds();

  return `${day} ${month} ${year}, ${hours}:${minutes}:${seconds}`;
}
const GetConsultations = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [gidSearchTerm, setGidSearchTerm] = useState("");
  const ITEMS_PER_PAGE = 20;
  const [activePage, setActivePage] = useState(1);
  //
  useEffect(() => {
    dispatch(
      fetchConsultations({
        pageNumber: activePage,
        perPage: `${ITEMS_PER_PAGE}`,
        uid: searchTerm,
      })
    );
    // eslint-disable-next-line
  }, [activePage]);

  const data = useSelector((state) => state.allConsultationsData.data);
  const total = useSelector((state) => state.allConsultationsData.total);
  const status = useSelector((state) => state.allConsultationsData.status);
  const error = useSelector((state) => state.allConsultationsData.error);
 

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const onSearch = (e, gid) => {
    setSearchTerm(e);
    setGidSearchTerm(gid);
    setActivePage(1);

    dispatch(
      fetchConsultations({
        pageNumber: 1,
        perPage: `${ITEMS_PER_PAGE}`,
        uid: e,
        gid: gid,
      })
    );
  };

  function getStatusInfo(callStatus) {
    switch (callStatus) {
      case "started":
        return { color: "orange", text: "Started" };
      case "failed":
        return { color: "red", text: "Failed" };
      case "success":
        return { color: "green", text: "Success" };
      default:
        return { color: "black", text: callStatus };
    }
  }

  if (status === "failed") {
    return (
      <Center>
        <Text color="red">{error}</Text>
      </Center>
    );
  }
  return (
    <>
      <Flex justifyContent={"flex-end"}>
        <Sidebar />
        <Box w={"80%"} fontSize={"18px"} p={5}>
          {/* <NumberInput w={"sm"} value={searchTerm} onChange={onSearch}>
            <NumberInputField placeholder="Enter UID to search consultation details" />
          </NumberInput> */}
          <Box width={"100%"} display={"flex"} alignItems="center" mb={3}>
            <FormLabel htmlFor="gid-search-input" mb={0} color="gray.700">
              Search by UID:
            </FormLabel>
            <NumberInput
              w={"sm"}
              value={searchTerm}
              onChange={(valueString) => onSearch(valueString, gidSearchTerm)}
              mr={{ md: "24px" }}
              width={{ md: "70px" }}
              focusBorderColor="gray.500"
            >
              <NumberInputField placeholder=" UID " px={1} />
            </NumberInput>
            <FormLabel htmlFor="gid-search-input" mb={0} color="gray.700">
              Search by GID:
            </FormLabel>
            <NumberInput
              w={"sm"}
              value={gidSearchTerm}
              onChange={(valueString) => onSearch(searchTerm, valueString)}
              width={{ md: "70px" }}
              focusBorderColor="gray.500"
            >
              <NumberInputField placeholder=" GID " px={1} />
            </NumberInput>
          </Box>

          {status === "loading" && (
            <Center>
              <Spinner size="xl" />
            </Center>
          )}
          <Table>
            <Thead>
              <Tr>
                <Th>UID</Th>
                <Th>GID</Th>
                {/* <Th>Consultation Type</Th> */}
                <Th>Call Duration</Th>
                <Th>Call Status</Th>
                <Th>Consultation Type</Th>
                <Th>Amount</Th>
                <Th>Date & Time</Th>
                <Th>Recording</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((currElem) => {
                const { color, text } = getStatusInfo(currElem.callStatus);
                return (
                  <Tr key={currElem._id}>
                    <Td>{currElem.uid}</Td>
                    <Td>{currElem.gid}</Td>
                    {currElem.timeDuration ? (
                      <Td>
                        {" "}
                        {parseFloat(currElem.timeDuration).toFixed(2)} min{" "}
                      </Td>
                    ) : (
                      <Td>0</Td>
                    )}

                    <Td style={{ color: color }}>{text}</Td>
                    <Td>{currElem.consultationType}</Td>
                    {currElem.amount ? (
                      currElem.region === "International" ? (
                        <Td> $ {parseFloat(currElem.amount).toFixed(2)} </Td>
                      ) : (
                        <Td> ₹ {parseFloat(currElem.amount).toFixed(2)} </Td>
                      )
                    ) : (
                      <Td>0</Td>
                    )}
                    <Td>{convertIst(currElem.createdAt)}</Td>
                    <Td>
                      <Box>
                        {currElem.RecordingUrl ? (
                          <a
                            href={currElem.RecordingUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icon as={FiPlay} />
                          </a>
                        ) : (
                          <Text>
                            <Icon as={FiXCircle} />{" "}
                          </Text>
                        )}
                      </Box>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <Flex justifyContent={"center"} alignItems={"center"} m="70px">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={ITEMS_PER_PAGE}
          totalItemsCount={total}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          prevPageText="Previous"
          nextPageText="Next"
        />
      </Flex>
    </>
  );
};

export default GetConsultations;
