import React, { useEffect, useRef, useState } from "react";
import {
  Flex,
  Avatar,
  AvatarBadge,
  Text,
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [time, setTime] = useState(720);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal
        size={{ md: "md", base: "xs" }}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalCloseButton /> */}
          <ModalBody
            fontSize={{ md: "18px", base: "" }}
            borderRadius={"12px 12px 0 0"}
          >
            <Text
              textAlign={"center"}
              fontSize={"18px"}
              fontWeight={"400"}
              color={" var(--grey-light-900, #222)"}
              mt={{ md: "13px" }}
              mb={0}
            >
              Are you sure to exit from Chat ?
            </Text>
          </ModalBody>

          <ModalFooter
            px={0}
            pb={0}
            justifyContent={"center"}
            borderRadius={"0 0 12px 12px"}
          >
            <Button
              variant={"solid"}
              display={"flex"}
              w={"100%"}
              h={{ md: "65px", base: "44px" }}
              justifyContent={"center"}
              alignItems={"center"}
              p={"14.887px 69.97px"}
              gap={"14px"}
              borderRadius={"0 0 0 12px"}
              borderTop={"1px solid #DDD"}
              borderRight={"1px solid #DDD"}
              bg={"white"}
            >
              Yes
            </Button>
            <Button
              variant={"solid"}
              display={"flex"}
              w={"100%"}
              h={{ md: "65px", base: "44px" }}
              justifyContent={"center"}
              alignItems={"center"}
              p={"14.887px 69.97px"}
              gap={"14px"}
              borderRadius={"0 0 12px 0"}
              borderTop={"1px solid #DDD"}
              bg={"white"}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        w="100%"
        justifyContent={"space-between"}
        alignItems={{ md: "center", base: "flex-end" }}
        h={{ md: "85px", base: "77px" }}
        boxShadow={"0px 2px 4px 0px rgba(0, 0, 0, 0.25)"}
        p={{ md: "13px", base: "8px" }}
        bg={"#fff"}
        zIndex={1}
      >
        <Flex alignItems={"center"}>
          <Avatar
            boxSize={{ md: "60px", base: "35px" }}
            name="Dan Abrahmov"
            src="https://bit.ly/dan-abramov"
          >
            <AvatarBadge
              boxSize={{ md: "1.25em", base: "1em" }}
              bg="green.500"
            />
          </Avatar>
          <Flex flexDirection="column" mx="5" justify="center">
            <Text fontSize={{ md: "20px", base: "" }} fontWeight="500" mb={0}>
              Ferin Patel
            </Text>
            <Text display={{base: "block", md: "none"}} fontSize={"13px"} fontWeight={"400"} mb={0} color={"#FF4C00"}>
              Time Left {" "}
              <Text
                as={"span"}
                fontSize={"13px"}
                fontWeight={"400"}
                mb={0}
                color={"#FF4C00"}
              >
                {formatTime(time)} mins
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Text
            fontSize={{ md: "20px" }}
            fontWeight={"700"}
            mb={0}
            color={"#FF4C00"}
            display={{ base: "none", md: "block" }}
          >
            Time Left :
            <Text
              as={"span"}
              fontSize={{ md: "20px" }}
              fontWeight={"700"}
              mb={0}
              color={"#FF4C00"}
              ml={{md: "20px"}}
            >
              {formatTime(time)} mins
            </Text>
          </Text>
        </Flex>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Button
            fontSize={{ md: "16px", base: "14px" }}
            color="#FF4C00"
            border={"1px solid #FF4C00"}
            variant="outline"
            onClick={onOpen}
          >
            End
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
