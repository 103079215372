export const homepageViewEvent = () => {
  window.fbq("init", "647781774079058");
  window.fbq("track", "HomepageViewEvent");
};
export const purchaseEvent = (selectedCurrency, amount) => {
  window.fbq("init", "647781774079058");
  window.fbq("track", "PurchaseTracker", {
    currency: selectedCurrency,
    value: amount,
  });
};
