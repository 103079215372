import { Box, Text } from "@chakra-ui/react";
import React from "react";

export default function Timer(props) {
	return (
		<Box>
			<Text as={"span"} color={"white"}>
				{("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
			</Text>
			<Text as={"span"} color={"white"}>
				{("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}.
			</Text>
			<Text as={"span"} color={"red"}>
				{("0" + ((props.time / 10) % 100)).slice(-2)}
			</Text>
		</Box>
	);
}
