import React from "react";
import {
  Box,
  Image,
  Container,
  Stack,
  useDisclosure,
  Img,
} from "@chakra-ui/react";
import {
  Modal,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";

import banner1 from "../../assets/LiveBannerDesktop1.jpg";
import banner2 from "../../assets/LiveBannerDesktop2.jpg";
import banner3 from "../../assets/LiveBannerMobile1.jpg";
import banner4 from "../../assets/LiveBannerMobile2.jpg";
import banner5 from "../../assets/banners/free.jpg";
import banner6 from "../../assets/banners/free.jpg";
import banner7 from "../../assets/banners/free.jpg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import LazyLoad from "react-lazy-load";
import Login from "../Login&Signup/Login";

import footerLine from '../../assets/footerLine.png'

import '../LiveAstrologers/LiveBanner.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const LiveBanner = () => {
  const isInternational = localStorage.getItem("isInternational") === "true";
  const options = {
    loop: true,
    // controlsClass: 'owl-controls',
    // center: true,
    // nav: true,
    dots: true,
    // margin: 18,
    // dotsEach: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
        // margin: 8,
        // nav: false,
        dots: false,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const loginToken = localStorage.getItem("loginToken");
  const navigate = useNavigate();

  const handleClick = () => {
    if (loginToken) {
      navigate("/wallet");
    } else {
      onOpen();
    }
  };
  return (
    <>
      <Modal
        isCentered
        size={{ base: "xs", md: "4xl" }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Login />
      </Modal>
      <Container
        maxW={"full"}
        id="hero-banner"
        spacing={2}
        direction="row"
        pt={{ md: "55px" }}
        pb={{ md: "25px" }}
        display={{ base: "none", md: "flex" }}
        // bg={"#F2D2BA"}
        mb={'-30px'}
        
      >
        <Container maxW={"6xl"}  id="hero-banner" >
          <OwlCarousel className="owl-theme" loop {...options}>
            <Box w={"100%"} mb={{ md: "11px", base: "9px" }}>
              <LazyLoadImage
                objectFit={"cover"}
                src={banner1}
                alt="hero-banner"
                w="100%"
                effect="blur"
              />
            </Box>

            {isInternational ? (
              <Box
                w={"100%"}
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"
              >
                <LazyLoadImage
                  objectFit={"cover"}
                  src={banner2}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            ) : (
              <Box
                w={"100%"}
                mb={{ md: "11px", base: "9px" }}
                onClick={() => handleClick()}
                cursor="pointer"

              >
                <LazyLoadImage
                  objectFit={"cover"}
                  src={banner2}
                  alt="hero-banner"
                  w="100%"
                  effect="blur"
                />
              </Box>
            )}
          </OwlCarousel>
        </Container>
      </Container>
      <Stack
        id="hero-banner"
        spacing={2}
        direction="row"
        py={0}
        display={{ base: "flex", md: "none" }}
        h={{sm:"11rem", base:"11rem"}}
      >
        <OwlCarousel className="owl-theme" loop {...options} >
          <Box w={"100%"} mb={{ md: "11px", base: "9px" }}>
            <LazyLoadImage
              objectFit={"cover"}
              src={banner3}
              alt="hero-banner"
              width="100%"
              effect="blur"
            />
          </Box>
          {isInternational ? (
            <Box
              w={"100%"}
              mb={{ md: "11px", base: "9px" }}
              onClick={() => handleClick()}
            >
              <LazyLoadImage
                objectFit={"cover"}
                src={banner4}
                alt="hero-banner"
                width="100%"
                effect="blur"
              />
            </Box>
          ) : (
            <Box
              w={"100%"}
              mb={{ md: "11px", base: "9px" }}
              onClick={() => handleClick()}
            >
              <LazyLoadImage
                objectFit={"cover"}
                src={banner4}
                alt="hero-banner"
                effect="blur"
                width="100%"
              />
            </Box>
          )}
        </OwlCarousel>
      </Stack>

      <Container maxW={"6xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 4, md: 14, lg: 16 }}
        
        ></Stack>
        <Img src={footerLine} alt="footer-line"/>
      </Container>
    </>
  );
};

export default LiveBanner;
