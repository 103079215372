import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import logo from "../../assets/roundLogo.png";
import {
  Stack,
  Button,
  Box,
  Text,
  Flex,
  Heading,
  HStack,
  Divider,
  Input,
  VStack,
  Icon,
  ChakraProvider,
} from "@chakra-ui/react";
import theme from "./theme";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import "../Wallet/Wallet.css";
import { useNavigate } from "react-router-dom";
import {
  G_RAZORPAY_CALLBACK_URL,
  G_RAZORPAY_CHECKOUT_URL,
  G_RAZORPAY_PAYMENT_KEY_URL,
  G_RAZORPAY_CALLBACK_URL_V2,
  G_RAZORPAY_CHECKOUT_URL_V2,
  G_RAZORPAY_PAYMENT_KEY_URL_V2,
  P_STRIPE_CHECKOUT,
  P_STRIPE_CHECKOUT_INTERNATIONAL,
} from "../../apiLinks";

import Marquee from "react-fast-marquee";
import { purchaseEvent } from "../../facebookEvents/pixelEvents";
// import { useEffect } from "react";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../firebaseEvents/firebase";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Global, css } from "@emotion/react";
import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";

export default function Wallet({ onDataChange }) {
  const isInternational = localStorage.getItem("isInternational") === "true";
  // const isInternational = false;
  const loginToken = localStorage.getItem("loginToken");
  const loggedInUserDetails = useSelector((state) => state.loggedInUserDetails);
  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedPriceId, setSelectedPriceId] = useState(0);
  const [selectedDicsount, setSelectedDiscount] = useState(0);
  const [selectedCashback, setSelectedCashback] = useState(0);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAppliedSuccess, setCouponAppliedSuccess] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponMsg, setCouponMsg] = useState("");
  const [couponType, setCouponType] = useState("");
  //
  const [selectedButton, setSelectedButton] = useState(null);
  const [showIcon, setShowIcon] = useState(true);
  const [filteredCoupons, setFilteredCoupons] = useState([]);

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
    setShowIcon(true);
  };

  // zero gst for international
  // let gst = 0;
  let gst =
    isInternational || selectedPrice === 1 ? 0 : (selectedPrice * 18) / 100;
  let totalPrice = selectedPrice + gst;
  const [offerPrice, setOfferPrice] = useState(0);
  // const offerPrice =

  // indian prices
  const indianPrice =
    loggedInUserDetails?.data?.userDetails?.consultationCount === 1 ||
    loggedInUserDetails?.data?.userDetails?.consultationCount === 0 ||
    loggedInUserDetails?.data?.userDetails?.normalUserJourney2 === false ||
    loggedInUserDetails?.data?.userDetails?.paymentsDetails?.length === 0 ||
    loggedInUserDetails?.data?.userDetails?.StripeInvoice?.length === 0
      ? [
          {
            id: 1,
            currency: "₹",
            price: 1,
            discount: 99,
            note: "Get " + 100,
          },
          // { id: 2, currency: "₹", price: 100 },
          { id: 3, currency: "₹", price: 200 },
          { id: 4, currency: "₹", price: 500, note: "Most popular" },
          { id: 5, currency: "₹", price: 800 },
          { id: 6, currency: "₹", price: 1000 },
          { id: 7, currency: "₹", price: 2000 },
          { id: 9, currency: "₹", price: 3000 },
        ]
      : [
          // { id: 2, currency: "₹", price: 100 },
          { id: 3, currency: "₹", price: 200 },
          { id: 4, currency: "₹", price: 500, note: "Most popular" },
          { id: 5, currency: "₹", price: 800 },
          { id: 6, currency: "₹", price: 1000 },
          { id: 7, currency: "₹", price: 2000 },
          { id: 9, currency: "₹", price: 3000 },
        ];
  // international prices with offer
  const internationalPrice = [
    {
      id: 8,
      currency: "USD",
      price: 0.11,
      discount: 4.89,
      note: "Get 5$",
    },
    { id: 1, currency: "USD", price: 5, discount: 0 },
    { id: 2, currency: "USD", price: 10, discount: 0 },
    { id: 3, currency: "USD", price: 15, discount: 0 },
    { id: 4, currency: "USD", price: 20, discount: 0 },
    { id: 5, currency: "USD", price: 30, discount: 0 },
    { id: 6, currency: "USD", price: 50, discount: 0 },
    { id: 7, currency: "USD", price: 100, discount: 0 },
  ];

  useEffect(() => {
    if (
      loggedInUserDetails?.data?.userDetails?.consultationCount === 0 &&
      loggedInUserDetails?.data?.userDetails?.paymentsDetails.length === 0 &&
      loggedInUserDetails?.data?.userDetails?.StripeInvoice?.length === 0
    ) {
      getPrice(1, 1, 99, isInternational ? "USD" : "₹", differenceAmount);
    }
  }, [loginToken, loggedInUserDetails]);

  function differenceAmount() {
    const userWallet = isInternational
      ? loggedInUserDetails?.data?.userDetails?.internationalWallet
      : loggedInUserDetails?.data?.userDetails?.wallet;

    const rechargeAmount = isInternational ? 3 : 100;

    if (userWallet !== undefined && userWallet < rechargeAmount) {
      return (rechargeAmount - userWallet).toFixed(2);
    }

    return 0;
  }
  const price = isInternational ? internationalPrice : indianPrice;
  console.log("Price >>>>>>>>>>>>>", price);
  const host = localStorage.getItem("host");

  const getPrice = (price, id, discount, currency, offerPrice) => {
    setSelectedPrice(price);
    setSelectedPriceId(id);
    setSelectedDiscount(discount);
    setSelectedCurrency(currency);
    if (price === 1) {
      setOfferPrice(100);
    } else {
      setOfferPrice(price);
    }
    onDataChange(price);
  };
  const handleColor = (id) => {
    if (selectedPriceId === id) {
      return "selected";
    } else {
      if (loggedInUserDetails?.data?.userDetails?.website === "gurucool.co") {
        return "unselected";
      } else if (
        loggedInUserDetails?.data?.userDetails?.timeWallet === 4 ||
        loggedInUserDetails?.data?.userDetails?.normalUserJourney2 ||
        loggedInUserDetails?.data?.userDetails?.paymentsDetails?.length >= 1 ||
        loggedInUserDetails?.data?.userDetails?.consultationCount >= 1 ||
        loggedInUserDetails?.data?.userDetails?.StripeInvoice?.length >= 1
      ) {
        return "unselected";
      } else {
        return "unselected blur";
      }
    }
  };

  const [couponDetails, setCouponDetails] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      // console.log(selectedPrice);
      try {
        const response = await axios.get(
          "https://data.gurucool.life/api/v1/user/getValidCoupon",
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
            params: {
              price: selectedPrice,
            },
          }
        );
        //

        setCouponDetails(response.data.validCoupon);
        //
      } catch (error) {
        console.error(error); // Handle any error that occurs during the request
      }
    };

    fetchData();
  }, [selectedPrice]);

  // console.log(couponDetails);
  const forAmountsArray = couponDetails.map((coupon) => coupon.forAmounts);
  // console.log(forAmountsArray);
  // console.log(couponDetails);
  const checkOutHandler = async () => {
    purchaseEvent(selectedCurrency, totalPrice);
    const loginToken = localStorage.getItem("loginToken");
    const {
      data: { key },
    } = await axios.get(G_RAZORPAY_PAYMENT_KEY_URL);
    const {
      data: { checkout },
    } = await axios.post(
      loggedInUserDetails?.data?.userDetails?.website[0] === "gurucool.co"
        ? G_RAZORPAY_CHECKOUT_URL_V2
        : G_RAZORPAY_CHECKOUT_URL,
      {
        // amount: loggedInUserDetails?.data?.userDetails?.website[0] === "gurucool.co" || (loggedInUserDetails?.data?.userDetails?.consultation >= 1 && loggedInUserDetails?.data?.userDetails?.paymentsDetails?.length )? selectedPrice : offerPrice,
        amount:
          loggedInUserDetails?.data?.userDetails?.website[0] !==
            "gurucool.co" &&
          loggedInUserDetails?.data?.userDetails?.consultationCount === 0 &&
          loggedInUserDetails?.data?.userDetails?.paymentsDetails?.length === 0
            ? offerPrice
            : loggedInUserDetails?.data?.userDetails?.website[0] ===
                "gurucool.co" &&
              loggedInUserDetails?.data?.userDetails?.consultationCount === 1 &&
              loggedInUserDetails?.data?.userDetails?.paymentsDetails
                ?.length === 0 &&
              !loggedInUserDetails?.data?.userDetails?.normalUserJourney2
            ? offerPrice
            : selectedPrice,
        gst,
        offerPrice: totalPrice,
        couponCode,
        couponType,
      },
      {
        headers: { Authorization: `Bearer ${loginToken}` },
      }
    );
    const options = {
      key,
      amount: checkout.amount,
      currency: "INR",
      name: "Gurucool.life",
      description: "Recharge Wallet",
      image: { logo },
      order_id: checkout.orderId,
      callback_url:
        loggedInUserDetails?.data?.userDetails?.website[0] === "gurucool.co"
          ? G_RAZORPAY_CALLBACK_URL_V2
          : G_RAZORPAY_CALLBACK_URL,
      prefill: {
        name: loggedInUserDetails.firstName + loggedInUserDetails.lastName,
        email: loggedInUserDetails.email,
        contact: loggedInUserDetails.phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  const handleRemoveCoupon = () => {
    setCouponApplied(false);
    setCouponCode("");
    setShowIcon(false); // Hide the icon when the coupon is removed
    setCouponAppliedSuccess(false);
  };

  async function validateCoupon(couponCode) {
    try {
      const response = await axios.get(
        `https://data.gurucool.life/api/v1/admin/getCoupon?couponCode=${couponCode}&forAmounts=${selectedPrice}`,
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );
      // console.log(response);
      // console.log(response?.data?.coupons?.couponType);
      if (response.data.success) {
        // Success is true
        //

        setCouponApplied(true);
        setShowIcon(true);
        setSelectedDiscount(
          selectedPrice * response?.data?.coupons?.percentage
        );
        setCouponType(response?.data?.coupons?.couponType);
        setCouponMsg("Coupon applied successfully!!!");
        setCouponAppliedSuccess(true);
        // Handle the response data here
      }
    } catch (error) {
      console.error("An error occurred during the request:", error);

      setCouponMsg(error.response.data.message);
      setCouponApplied(false);
      setCouponAppliedSuccess(true);
      // Handle the error case here
    }
  }
  // console.log(couponType);
  useEffect(() => {
    setCouponApplied(false);
    setCouponAppliedSuccess(false);
    setCouponCode("");
    setCouponType("");
    setCouponMsg("");
  }, [selectedPrice]);

  const navigate = useNavigate();
  const [showRazorpayButton, setShowRazorpayButton] = useState(false);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
        "Content-Type": "application/json",
      },
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://data.gurucool.life/api/v1/admin/getRazorpayState",
          config
        );
        const data = response?.data?.razorpayState?.button;

        // Assuming the API response contains the value for razorpayButton

        setShowRazorpayButton(data);
      } catch (error) {
        // Handle any error that occurred during the API request
      }
    };
    fetchData();
  }, []);

  console.log("loggedInUserDetails", loggedInUserDetails?.data?.userDetails);
  const GlobalStyle = () => (
    <Global
      styles={css`
        @import url("https://fonts.googleapis.com/css2?family=Fondamento&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Ultra&display=swap");
      `}
    />
  );
  // console.log(couponDetails);

  // useEffect(() => {
  //   console.log("use effect running");
  //   setFilteredCoupons(couponDetails.filter((item) => item.forAmounts.includes(800)))
  //   console.log("filtered cpns",filteredCoupons);
  //   console.log("selected price",selectedPrice);
  // }, [selectedPrice])

  return (
    <ChakraProvider theme={theme}>
      <GlobalStyle />
      <section style={{ backgroundColor: "#eee" }}>
        <MDBContainer className="py-0 py-md-5">
          <MDBRow>
            <MDBCol lg="12">
              <MDBRow>
                <MDBCol md="8" className="px-0">
                  <MDBCard className="mb-md-0 border_radius_desktop">
                    <MDBCardBody>
                      <MDBCardText className="mb-4">
                        <Flex
                          justifyContent={"center"}
                          direction={"column"}
                          alignItems={"center"}
                        >
                          <Box
                            p={2}
                            display={"flex"}
                            borderRadius={"4px"}
                            border={"1px solid lightgray"}
                            px={{ md: "12px" }}
                            py={{ md: "6px" }}
                            maxW={"-webkit-fit-content"}
                          >
                            <Heading
                              as="h5"
                              m="0"
                              color={"gray.700"}
                              fontSize={{ md: "18px", base: "16px" }}
                              fontWeight={500}
                            >
                              Wallet Balance :
                            </Heading>

                            {loggedInUserDetails.data.userDetails ? (
                              <Heading
                                as="h5"
                                m="0"
                                color={"black"}
                                fontSize={{ md: "18px", base: "16px" }}
                                fontWeight={700}
                              >
                                {isInternational
                                  ? "USD " +
                                    parseFloat(
                                      loggedInUserDetails.data.userDetails
                                        .internationalWallet
                                    ).toFixed(2)
                                  : "₹ " +
                                    Math.round(
                                      loggedInUserDetails.data.userDetails
                                        .wallet
                                    ).toFixed(2)}
                              </Heading>
                            ) : (
                              <Heading
                                as="h5"
                                color={"black"}
                                fontSize={{ md: "18px" }}
                                fontWeight={700}
                              >
                                &nbsp; ₹ 0
                              </Heading>
                            )}
                          </Box>
                          <Text
                            color={"#DD7323"}
                            fontSize={{ md: "16px" }}
                            fontWeight={600}
                            mt={2}
                          >
                            Please Select Your Recharge Amount
                          </Text>
                        </Flex>
                        {loggedInUserDetails.data.userDetails &&
                        loggedInUserDetails.data.userDetails
                          .new_user_offer_used ? null : (
                          <Marquee
                            // gradient="false"
                            gradientWidth={100}
                            // gradientColor= {[255, 255, 255]}
                            speed="70"
                            style={{ color: "green", fontSize: "12px" }}
                          >
                            {isInternational && (
                              <>
                                Get 5 $ in Your Wallet on your first recharge of
                                JUST 11¢ as a new user. &nbsp;
                              </>
                            )}
                          </Marquee>
                        )}
                        <Flex
                          mt={5}
                          gap={{ base: "16PX", md: 4 }}
                          wrap="wrap"
                          justifyContent={"center"}
                        >
                          {loggedInUserDetails.data.userDetails &&
                            price.map((currElem) => {
                              if (
                                currElem.id === 8 &&
                                // loggedInUserDetails.data
                                loggedInUserDetails.data.userDetails
                                  .new_user_offer_used
                              ) {
                                // exclude item with id 8 if new_user_offer_used is true
                                return null;
                              } else {
                                // render the other items
                                return (
                                  <Box
                                    className={handleColor(currElem.id)}
                                    key={currElem.id}
                                    w={{ base: "95px", md: "140px" }}
                                    h={{ base: "54px", md: "80px" }}
                                    // border={"2px solid #C27935 "}
                                    borderRadius={"6px"}
                                    // display="flex"
                                    display={
                                      (loggedInUserDetails?.data?.userDetails
                                        ?.paymentsDetails?.length >= 1 ||
                                        loggedInUserDetails?.data?.userDetails
                                          ?.normalUserJourney2) &&
                                      currElem.price === 1
                                        ? "none"
                                        : "flex"
                                    }
                                    flexDirection={"column"}
                                    justifyContent={"center"}
                                    cursor={"pointer"}
                                    alignItems={"center"}
                                    backdropFilter="blur(8px)"
                                    onClick={() => {
                                      getPrice(
                                        currElem.price,
                                        currElem.id,
                                        currElem.discount,
                                        currElem.currency
                                      );
                                      setShowIcon(false);
                                    }}
                                  >
                                    {currElem.price === 0.11 ||
                                    currElem.price === 1 ? (
                                      <Box
                                        bg={"#F3520A"}
                                        w="100%"
                                        fontSize={{ md: "14px", base: "10px" }}
                                        fontWeight={"700"}
                                        color="#FFFFFF"
                                        h={{ md: "30px", base: "22px" }}
                                        display="flex"
                                        justifyContent={"center"}
                                        alignItems="center"
                                        borderRadius={"6px 6px 0px 0px"}
                                        mt={{ md: "-14px", base: "" }}
                                      >
                                        ONE TIME OFFER
                                      </Box>
                                    ) : null}

                                    <Box
                                      key={currElem.id}
                                      bg={"#F3520A"}
                                      w="100%"
                                      fontSize={{ md: "14px", base: "10px" }}
                                      fontWeight={"700"}
                                      color="#FFFFFF"
                                      h={{ md: "30px", base: "20px" }}
                                      borderRadius={"6px 6px 0px 0px"}
                                      display={
                                        currElem.note === "Most popular"
                                          ? "flex"
                                          : "none"
                                      }
                                      justifyContent={"center"}
                                      alignItems="center"
                                      // borderRadius={"6px 6px 0px 0px"}
                                      // visibility={currElem.note === "Most popular" ? "visible" : "hidden"}
                                    >
                                      MOST POPULAR
                                    </Box>

                                    <Box
                                      display={"flex"}
                                      flexDirection={"column"}
                                      alignItems="center"
                                      h={{
                                        md:
                                          currElem.note === "Most popular"
                                            ? "50px"
                                            : "auto",
                                      }}
                                      mb={"0 !important"}
                                    >
                                      <Text
                                        pt={{ base: "2px", md: "0px" }}
                                        fontSize={{ md: "24px", base: "22px" }}
                                        fontWeight={700}
                                        m={"0 !important"}
                                        // h={{ base: "4px", md: "8px" }}
                                      >
                                        {currElem.currency} {currElem.price}
                                        {currElem.price === 0.11 ||
                                        currElem.price === 1 ? (
                                          <Text
                                            as={"span"}
                                            color={"#1AB700"}
                                            fontSize={{
                                              md: "16px",
                                              base: "11px",
                                            }}
                                            pl={{ md: "14px", base: "8px" }}
                                          >
                                            {loggedInUserDetails?.data
                                              ?.userDetails?.website[0] !==
                                              "gurucool.co" &&
                                            loggedInUserDetails?.data
                                              ?.userDetails
                                              ?.consultationCount === 0 &&
                                            loggedInUserDetails?.data
                                              ?.userDetails?.paymentsDetails
                                              ?.length === 0
                                              ? "Get ₹ 100"
                                              : loggedInUserDetails?.data
                                                  ?.userDetails?.website[0] ===
                                                  "gurucool.co" &&
                                                loggedInUserDetails?.data
                                                  ?.userDetails
                                                  ?.consultationCount === 1 &&
                                                loggedInUserDetails?.data
                                                  ?.userDetails?.paymentsDetails
                                                  ?.length === 0 &&
                                                !loggedInUserDetails?.data
                                                  ?.userDetails
                                                  ?.normalUserJourney2
                                              ? "Get ₹ 100"
                                              : "Get 2 Min"}
                                          </Text>
                                        ) : null}
                                      </Text>
                                      {/* <Text
                                      fontSize={{ md: "17px", base: "12px" }}
                                      fontWeight={600}
                                      color={"#FF5E19"}
                                      mb={"0 !important"}
                                      // ml={{ md: "12px", base: "6px" }}
                                    >
                                      {currElem.note}
                                    </Text> */}
                                    </Box>
                                  </Box>
                                );
                              }
                            })}
                        </Flex>
                      </MDBCardText>
                      {selectedPrice <= 200 ? null : (
                        <Box display={{ base: "block", md: "none" }}>
                          <MDBCard
                            style={{ boxShadow: "none" }}
                            className="mb-md-0 border_radius_desktop coupons"
                          >
                            <MDBCardBody className="p-0">
                              <MDBCardText className="mb-4">
                                <Flex
                                  mt={5}
                                  gap={{ base: 2, md: 4 }}
                                  wrap="wrap"
                                  justifyContent={"center"}
                                  flexWrap={"nowrap"}
                                >
                                  {couponDetails.map((item) => (
                                    <Button
                                      px={"0 !important"}
                                      pb={"20px"}
                                      display={"flex"}
                                      flexDirection="column"
                                      id="d-wrapper"
                                      w={{ md: "131px", base: "131px" }}
                                      h={{ md: "187px", base: "122px" }}
                                      borderRadius={"6px"}
                                      bg={"#FCFCFC"}
                                      onClick={() => {
                                        handleButtonClick(item._id);
                                        setCouponCode(item.couponCode);
                                        validateCoupon(item.couponCode);
                                        //  setCouponApplied(true)
                                      }}
                                      _selected={{ bg: "#FCFCFC" }}
                                      _onClick={{ bg: "#FCFCFC" }}
                                      _active={{ bg: "#FCFCFC" }}
                                      _hover={{ bg: "#FCFCFC" }}
                                    >
                                      <Box
                                        mt={{ base: "14px", md: "auto" }}
                                        w={{ md: "100%", base: "100%" }}
                                        // w={"auto"}
                                        h={{ md: "135px", base: "75px" }}
                                        style={{
                                          backgroundColor:
                                            item.couponType === "Cashback"
                                              ? "#FFD260"
                                              : "#FF7C44",
                                          borderRadius: "6px 6px 0 0",
                                        }}
                                        className={
                                          item.couponType === "Cashback"
                                            ? "zig-zag-bottom"
                                            : "zig-zag-bottom-orange"
                                        }
                                      >
                                        <Text
                                          color={
                                            item.couponType === "Cashback"
                                              ? "#FF4545"
                                              : "#fff"
                                          }
                                          fontSize={{
                                            md: "16px",
                                            base: "11px",
                                          }}
                                          fontWeight={"700"}
                                          letterSpacing={{
                                            md: "1.5px",
                                            base: "1.5px",
                                          }}
                                          pt={{ base: "14px", base: "12px" }}
                                          mb={{
                                            base: "0 !important",
                                            md: "auto",
                                          }}
                                        >
                                          {/* {item.couponCode} */}
                                          MEGA OFFER
                                        </Text>

                                        {item.couponType === "Discount" ? (
                                          <HStack pt={"3px"}>
                                            <Heading
                                              color={"#752300"}
                                              letterSpacing={"1px"}
                                              style={{ fontFamily: "ultra" }}
                                              mt={{ base: "0px", md: "auto" }}
                                              fontSize={{
                                                base: "20px",
                                                md: "20px",
                                              }}
                                            >
                                              {item.percentage * 100}
                                            </Heading>
                                            <Heading
                                              color={"#752300"}
                                              letterSpacing={"1px"}
                                              pb={"8px"}
                                              style={{ fontFamily: "ultra" }}
                                              mt={{ base: "0px", md: "auto" }}
                                              fontSize={"14px"}
                                            >
                                              % OFF
                                            </Heading>
                                          </HStack>
                                        ) : (
                                          <VStack pt={"3px"}>
                                            <Heading
                                              color={"#4F3800"}
                                              mb={"0 !important"}
                                              letterSpacing={"1px"}
                                              style={{ fontFamily: "ultra" }}
                                              mt={{ base: "0px", md: "auto" }}
                                              fontSize={{
                                                base: "20px",
                                                md: "20px",
                                              }}
                                            >
                                              {item.percentage * 100} %
                                            </Heading>
                                            <Heading
                                              color={"#4F3800"}
                                              fontWeight={"100"}
                                              m={"0 !important"}
                                              letterSpacing={"1px"}
                                              pb={{ base: "8px" }}
                                              style={{ fontFamily: "ultra" }}
                                              mt={{ base: "0px", md: "auto" }}
                                              fontSize={"12px"}
                                            >
                                              {" "}
                                              CASHBACK
                                            </Heading>
                                          </VStack>
                                        )}
                                      </Box>
                                      <HStack
                                        alignSelf={"baseline"}
                                        gap={{ md: "50px", base: "0" }}
                                        justifyContent={"space-between"}
                                        ml={"5px"}
                                        mt={"5px"}
                                      >
                                        <VStack
                                          pl={{ md: "9px", base: "0" }}
                                          pt={{ md: "16px", base: "14px" }}
                                          alignItems={"flex-start"}
                                          gap={"2px"}
                                        >
                                          <Text
                                            m={"0 !important"}
                                            color={"#676767"}
                                            fontSize={"9px"}
                                            letterSpacing={"1px"}
                                          >
                                            Expire Date:
                                          </Text>
                                          <Text
                                            letterSpacing={"1px"}
                                            mt={{
                                              base: "0 !important",
                                              md: "auto",
                                            }}
                                            fontSize={{
                                              md: "12px",
                                              base: "10px",
                                            }}
                                          >
                                            {new Date(
                                              item.expireAt
                                            ).toLocaleDateString("en-GB")}{" "}
                                          </Text>
                                        </VStack>
                                        {showIcon &&
                                          selectedButton === item._id && (
                                            <Icon
                                              boxSize={6}
                                              alignSelf="end"
                                              color="#039A00"
                                            >
                                              <BsFillCheckCircleFill />
                                            </Icon>
                                          )}
                                      </HStack>
                                    </Button>
                                  ))}
                                </Flex>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCard>
                        </Box>
                      )}
                      <MDBCard className="mb-md-0 price_total_card">
                        <MDBCardBody className="price_total_card_body">
                          <MDBCardText className="mb-4">
                            {" "}
                            <Heading
                              textAlign={"center"}
                              fontSize={{ md: "22px" }}
                            >
                              Recharge Details
                            </Heading>
                            <Flex direction={"column"} mt={4}>
                              <HStack
                                my={0}
                                w={"100%"}
                                justify={"space-between"}
                              >
                                <Text
                                  color={"gray.700"}
                                  fontSize={{ md: "18px" }}
                                  fontWeight={600}
                                >
                                  Subtotal :{" "}
                                </Text>
                                <Text>
                                  {selectedCurrency} {selectedPrice}
                                </Text>
                              </HStack>

                              {couponApplied && (
                                <HStack
                                  my={2}
                                  w={"100%"}
                                  alignItems={"start"}
                                  justify={"space-between"}
                                >
                                  <Text
                                    color={"gray.700"}
                                    fontSize={{ md: "18px" }}
                                    fontWeight={600}
                                  >
                                    <Text
                                      color={"gray.700"}
                                      fontSize={{ md: "18px" }}
                                      fontWeight={600}
                                    >
                                      {couponType === "Cashback"
                                        ? "Cashback"
                                        : "Discount"}{" "}
                                      :
                                    </Text>
                                  </Text>
                                  <Text>
                                    {selectedCurrency} {selectedDicsount}
                                  </Text>
                                </HStack>
                              )}
                              {!isInternational && (
                                <HStack
                                  my={0}
                                  w={"100%"}
                                  alignItems={"start"}
                                  justify={"space-between"}
                                >
                                  <Text
                                    color={"gray.700"}
                                    fontSize={{ md: "18px" }}
                                    fontWeight={600}
                                  >
                                    GST :{" "}
                                  </Text>
                                  <Text>₹ {gst}</Text>
                                </HStack>
                              )}
                              {/* {couponType === "Discount" ? null : 
                              <Text display={couponApplied ? "block" : "none"} color={"green"} mb={"0 !important"} fontSize={"12px"}>You'll get ₹{selectedDicsount} cashback in your wallet after Recharge</Text>
                              } */}
                              <Divider
                                h={"0px"}
                                borderStyle={"dotted"}
                                my={3}
                              />
                              <HStack
                                my={0}
                                w={"100%"}
                                alignItems={"start"}
                                justify={"space-between"}
                              >
                                <Text
                                  color={"gray.700"}
                                  fontSize={{ md: "18px" }}
                                  fontWeight={600}
                                >
                                  Total :{" "}
                                </Text>
                                {/* <Text>
                                  {selectedCurrency}
                                  {couponAppliedSuccess
                                    ? offerPrice - selectedDicsount + gst
                                    : totalPrice}
                                </Text> */}
                                <Text>
                                  {selectedCurrency}
                                  {couponType === "Cashback"
                                    ? offerPrice + gst
                                    : couponType === "Discount"
                                    ? offerPrice - selectedDicsount + gst
                                    : totalPrice}
                                </Text>
                              </HStack>
                              {couponType === "Discount" ? null : (
                                <Text
                                  display={couponApplied ? "block" : "none"}
                                  color={"green"}
                                  mb={"0 !important"}
                                  fontSize={"12px"}
                                >
                                  You'll get ₹{selectedDicsount} cashback in
                                  your wallet after Recharge
                                </Text>
                              )}
                              <Divider h={"0px"} borderStyle={"dotted"} m={0} />
                              {selectedPrice <= 99 ? null : (
                                <HStack
                                  my={2}
                                  w={"100%"}
                                  flexDirection={"column"}
                                  alignItems={"flex-start"}
                                  justify={"space-between"}
                                >
                                  {couponApplied ? (
                                    <Text
                                      m={0}
                                      color={
                                        couponAppliedSuccess ? "green" : "red"
                                      }
                                      as={"span"}
                                    >
                                      {couponAppliedSuccess && couponMsg}
                                    </Text>
                                  ) : (
                                    <Text
                                      m={0}
                                      color={
                                        couponAppliedSuccess ? "red" : "red"
                                      }
                                      as={"span"}
                                    >
                                      {couponAppliedSuccess && couponMsg}
                                    </Text>
                                  )}
                                  <Box display={"flex"} m={"0 !important"}>
                                    <Input
                                      value={couponCode}
                                      disabled={couponApplied}
                                      outlineOffset={"orange"}
                                      _focus={{ outlineColor: "#DCDCDC" }} // Change outline color when clicked
                                      borderRadius={"6px 0 0 6px"}
                                      pr="4.5rem"
                                      placeholder="Enter Coupon..."
                                      onChange={(e) =>
                                        setCouponCode(e.target.value)
                                      }
                                    />
                                    {couponApplied ? (
                                      // <Button
                                      //   isDisabled
                                      //   background="#FF5E19"
                                      //   color={"white"}
                                      //   borderRadius={"0 4px 4px 0"}
                                      // >
                                      //   Applied
                                      // </Button>
                                      <Button
                                        background="#FF5E19"
                                        color={"white"}
                                        borderRadius={"0 4px 4px 0"}
                                        onClick={() => handleRemoveCoupon()}
                                        _selected={{ bg: "#FF5E19" }}
                                        _onClick={{ bg: "#FF5E19" }}
                                        _active={{ bg: "#FF5E19" }}
                                      >
                                        Remove
                                      </Button>
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          validateCoupon(couponCode)
                                        }
                                        background="#FF5E19"
                                        color={"white"}
                                        borderRadius={"0 4px 4px 0 !important"}
                                        _selected={{ bg: "#FF5E19" }}
                                        _onClick={{ bg: "#FF5E19" }}
                                        _active={{ bg: "#FF5E19" }}
                                        _hover={{ bg: "#FF5E19" }}
                                      >
                                        Apply
                                      </Button>
                                    )}
                                  </Box>
                                </HStack>
                              )}
                            </Flex>
                          </MDBCardText>

                          <Stack
                            spacing={"10px"}
                            direction={["column", "row"]}
                            mb={{ base: "70px", md: "unset" }}
                          >
                            {selectedPrice === 0 ? (
                              <Button
                                mb={"10px"}
                                bg="#dd732387"
                                color={"white"}
                                w="full"
                                _hover={{
                                  bg: "#dd732387",
                                }}
                                style={{
                                  cursor: "no-drop",
                                  marginBottom: "12px",
                                }}
                              >
                                Pay
                              </Button>
                            ) : isInternational ? (
                              <form
                                style={{ width: "100%" }}
                                action={P_STRIPE_CHECKOUT_INTERNATIONAL(
                                  offerPrice,
                                  "usd",
                                  loggedInUserDetails.data.userDetails
                                    .stripe_customer_id,
                                  loggedInUserDetails.data.userDetails.user,
                                  totalPrice,
                                  couponCode,
                                  couponType
                                )}
                                method="POST"
                              >
                                <Button
                                  bg="#DD7323"
                                  mb={"12px"}
                                  color={"white"}
                                  w="full"
                                  _hover={{
                                    bg: "#BB5507",
                                  }}
                                  type="submit"
                                  mt={"10px"}
                                >
                                  Pay
                                </Button>
                              </form>
                            ) : (
                              <>
                                <form
                                  display={
                                    host === "myguruji" &&
                                    loggedInUserDetails.data.userDetails
                                      .website[0] === "gurucool.co"
                                      ? "none"
                                      : "block"
                                  }
                                  style={{ width: "100%" }}
                                  action={P_STRIPE_CHECKOUT(
                                    offerPrice,
                                    "inr",
                                    loggedInUserDetails.data.userDetails
                                      .stripe_customer_id,
                                    loggedInUserDetails.data.userDetails.user,
                                    gst,
                                    totalPrice,
                                    couponCode,
                                    couponType
                                  )}
                                  method="POST"
                                >
                                  <Button
                                    bg="#DD7323"
                                    mb={"12px"}
                                    color={"white"}
                                    w="full"
                                    _hover={{
                                      bg: "#BB5507",
                                    }}
                                    type="submit"
                                    mt={"10px"}
                                  >
                                    Pay with Card
                                  </Button>
                                </form>
                                {/* razorpay */}
                                {showRazorpayButton && (
                                  <Button
                                    bg="#DD7323"
                                    mb={"12px"}
                                    color={"white"}
                                    w="full"
                                    _hover={{
                                      bg: "#BB5507",
                                    }}
                                    type="submit"
                                    mt={"10px"}
                                    onClick={() => checkOutHandler()}
                                  >
                                    Pay with UPI
                                  </Button>
                                )}
                              </>
                            )}
                          </Stack>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCardBody>
                  </MDBCard>
                  {selectedPrice <= 200 ? null : (
                    <Box mt={"30px"} display={{ base: "none", md: "block" }}>
                      <MDBCard
                        style={{ minHeight: "auto !important" }}
                        className="mb-md-0  coupons"
                      >
                        <MDBCardBody className="p-0">
                          <MDBCardText className="mb-4">
                            <Flex
                              mt={5}
                              gap={{ base: 2, md: 4 }}
                              wrap="wrap"
                              justifyContent={"center"}
                            >
                              {couponDetails.map((item) => (
                                <Button
                                  px={"0 !important"}
                                  pb={"20px"}
                                  display={"flex"}
                                  flexDirection="column"
                                  id="d-wrapper"
                                  w={{ md: "177px", base: "90px" }}
                                  h={{ md: "174px", base: "112px" }}
                                  borderRadius={"6px"}
                                  bg={"#FCFCFC"}
                                  onClick={() => {
                                    handleButtonClick(item._id);
                                    setCouponCode(item.couponCode);
                                    validateCoupon(item.couponCode);
                                    //  setCouponApplied(true)
                                  }}
                                  _selected={{ bg: "#FCFCFC" }}
                                  _onClick={{ bg: "#FCFCFC" }}
                                  _active={{ bg: "#FCFCFC" }}
                                  _hover={{ bg: "#FCFCFC" }}
                                >
                                  <Box
                                    mt={{ base: "14px", md: "auto" }}
                                    w={{ md: "100%", base: "100%" }}
                                    bg={
                                      item.percentage <= 0.1
                                        ? "#FFD260"
                                        : "#FF7C44"
                                    }
                                    h={{ md: "110px", base: "75px" }}
                                    style={{
                                      // backgroundColor: "#FFD260",
                                      backgroundColor:
                                        item.couponType === "Cashback"
                                          ? "#FFD260"
                                          : "#FF7C44",
                                      borderRadius: "6px 6px 0 0",
                                    }}
                                    className={
                                      item.couponType === "Cashback"
                                        ? "zig-zag-bottom"
                                        : "zig-zag-bottom-orange"
                                    }
                                  >
                                    <Text
                                      color={
                                        item.couponType === "Cashback"
                                          ? "#FF4545"
                                          : "#fff"
                                      }
                                      fontSize={{ md: "16px", base: "11px" }}
                                      fontWeight={"700"}
                                      letterSpacing={{
                                        md: "2.5px",
                                        base: "1px",
                                      }}
                                      pt={{ base: "19px", md: "31px" }}
                                      mb={{ base: "0 !important", md: "auto" }}
                                      fontFamily={"Poppins"}
                                    >
                                      {/* {item.couponCode} */}
                                      MEGA OFFER
                                    </Text>
                                    {/* <HStack pt={"4px"}>
                                        <Heading style={{ fontFamily: "ultra" }} mt={{ base: "0px", md: "auto" }} fontSize={"28px"}>{item.percentage * 100}</Heading>
                                        <Heading style={{ fontFamily: "ultra" }} mt={{ base: "0px", md: "auto" }} fontSize={"20px"} pb={"5px"}>% OFF</Heading>
                                      </HStack> */}
                                    {item.couponType === "Discount" ? (
                                      <HStack pt={"3px"}>
                                        <Heading
                                          color={"#752300"}
                                          style={{ fontFamily: "ultra" }}
                                          mt={{ base: "0px", md: "auto" }}
                                          fontSize={"28px"}
                                        >
                                          {item.percentage * 100}
                                        </Heading>
                                        <Heading
                                          color={"#752300"}
                                          style={{ fontFamily: "ultra" }}
                                          mt={{ base: "0px", md: "auto" }}
                                          fontSize={"20px"}
                                        >
                                          % OFF
                                        </Heading>
                                      </HStack>
                                    ) : (
                                      <VStack pt={"3px"}>
                                        <Heading
                                          color={"#4F3800"}
                                          mb={"0 !important"}
                                          letterSpacing={"1px"}
                                          style={{ fontFamily: "ultra" }}
                                          mt={{ base: "0px", md: "auto" }}
                                          fontSize={{
                                            base: "20px",
                                            md: "28px",
                                          }}
                                        >
                                          {item.percentage * 100} %
                                        </Heading>
                                        <Heading
                                          fontWeight={"10"}
                                          color={"#4F3800"}
                                          letterSpacing={"1px"}
                                          style={{ fontFamily: "ultra" }}
                                          mt={{ base: "0px", md: "auto" }}
                                          fontSize={"20px"}
                                          m={"0 !important"}
                                        >
                                          {" "}
                                          CASHBACK
                                        </Heading>
                                      </VStack>
                                    )}
                                  </Box>
                                  <HStack
                                    alignSelf={"baseline"}
                                    gap={{ md: "50px", base: "0" }}
                                    justifyContent={"space-between"}
                                  >
                                    <VStack
                                      pl={{ md: "9px", base: "0" }}
                                      pt={{ md: "23px", base: "14px" }}
                                      alignItems={"flex-start"}
                                    >
                                      <Text
                                        m={"0 !important"}
                                        color={"#676767"}
                                        fontSize={"10px"}
                                        letterSpacing={"1.5px"}
                                      >
                                        Expire Date:
                                      </Text>
                                      <Text
                                        letterSpacing={"1.5px"}
                                        mt={{
                                          base: "0 !important",
                                          md: "auto",
                                        }}
                                        fontSize={{ md: "12px", base: "8px" }}
                                      >
                                        {new Date(
                                          item.expireAt
                                        ).toLocaleDateString("en-GB")}{" "}
                                      </Text>
                                    </VStack>
                                    {showIcon &&
                                      selectedButton === item._id && (
                                        <Icon
                                          boxSize={6}
                                          alignSelf="end"
                                          color="#039A00"
                                        >
                                          <BsFillCheckCircleFill />
                                        </Icon>
                                      )}
                                  </HStack>
                                </Button>
                              ))}
                            </Flex>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </Box>
                  )}
                </MDBCol>

                <MDBCol md="4" className="desktop_only">
                  <MDBCard className="mb-4 mb-md-0">
                    <MDBCardBody>
                      <MDBCardText className="mb-4">
                        {" "}
                        <Heading textAlign={"center"} fontSize={{ md: "22px" }}>
                          Recharge Details
                        </Heading>
                        <Flex direction={"column"} mt={4}>
                          <HStack my={3} w={"100%"} justify={"space-between"}>
                            <Text
                              color={"gray.700"}
                              fontSize={{ md: "18px" }}
                              fontWeight={600}
                            >
                              Subtotal :{" "}
                            </Text>
                            <Text>
                              {selectedCurrency} {selectedPrice}
                            </Text>
                          </HStack>
                          {!isInternational && (
                            <HStack my={3} w={"100%"} justify={"space-between"}>
                              <Text
                                color={"gray.700"}
                                fontSize={{ md: "18px" }}
                                fontWeight={600}
                              >
                                GST :{" "}
                              </Text>
                              <Text>₹ {gst}</Text>
                            </HStack>
                          )}
                          {couponAppliedSuccess && (
                            <HStack my={3} w={"100%"} justify={"space-between"}>
                              <Text
                                color={"gray.700"}
                                fontSize={{ md: "18px" }}
                                fontWeight={600}
                              >
                                {couponType === "Cashback"
                                  ? "Cashback"
                                  : "Discount"}{" "}
                                :
                              </Text>
                              <Text>
                                {selectedCurrency} {selectedDicsount}
                              </Text>
                            </HStack>
                          )}
                          {couponType === "Discount" ? null : (
                            <Text
                              display={couponApplied ? "block" : "none"}
                              color={"green"}
                              mb={"0 !important"}
                              fontSize={"14px"}
                            >
                              You'll get {selectedCurrency}
                              {selectedDicsount} cashback in your wallet after
                              Recharge
                            </Text>
                          )}
                          <Divider borderStyle={"dotted"} />
                          <HStack my={2} w={"100%"} justify={"space-between"}>
                            <Text
                              color={"gray.700"}
                              fontSize={{ md: "18px" }}
                              fontWeight={600}
                            >
                              Total :{" "}
                            </Text>
                            <Text>
                              {selectedCurrency}
                              {couponType === "Cashback"
                                ? offerPrice + gst
                                : couponType === "Discount"
                                ? offerPrice - selectedDicsount + gst
                                : totalPrice}
                            </Text>
                          </HStack>
                          {selectedPrice < 200 ? null : (
                            <HStack
                              my={2}
                              w={"100%"}
                              flexDirection={"column"}
                              alignItems={"flex-start"}
                              justify={"space-between"}
                            >
                              {couponApplied ? (
                                <Text
                                  m={0}
                                  color={couponAppliedSuccess ? "green" : "red"}
                                  as={"span"}
                                >
                                  {couponAppliedSuccess && couponMsg}
                                </Text>
                              ) : (
                                <Text
                                  m={0}
                                  color={couponAppliedSuccess ? "red" : "red"}
                                  as={"span"}
                                >
                                  {couponAppliedSuccess && couponMsg}
                                </Text>
                              )}
                              <Box display={"flex"}>
                                <Input
                                  w={"100%"}
                                  value={couponCode}
                                  disabled={couponApplied}
                                  outlineOffset={"orange"}
                                  _focus={{ outlineColor: "#DCDCDC" }} // Change outline color when clicked
                                  borderRadius={"6px 0 0 6px"}
                                  pr="4.5rem"
                                  placeholder="Enter Coupon..."
                                  onChange={(e) =>
                                    setCouponCode(e.target.value)
                                  }
                                />
                                {couponApplied ? (
                                  <Button
                                    background="#FF5E19"
                                    color={"white"}
                                    borderRadius={"0 4px 4px 0"}
                                    onClick={() => handleRemoveCoupon()}
                                    _selected={{ bg: "#FF5E19" }}
                                    _onClick={{ bg: "#FF5E19" }}
                                    _active={{ bg: "#FF5E19" }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => validateCoupon(couponCode)}
                                    background="#FF5E19"
                                    color={"white"}
                                    borderRadius={"0 4px 4px 0 !important"}
                                    _selected={{ bg: "#FF5E19" }}
                                    _onClick={{ bg: "#FF5E19" }}
                                    _active={{ bg: "#FF5E19" }}
                                    _hover={{ bg: "#FF5E19" }}
                                  >
                                    Apply
                                  </Button>
                                )}{" "}
                              </Box>
                            </HStack>
                          )}
                        </Flex>
                      </MDBCardText>

                      <Stack spacing={6} direction={["column", "row"]}>
                        {selectedPrice === 0 ? (
                          <Button
                            bg="#DD7323"
                            color={"white"}
                            w="full"
                            _hover={{
                              bg: "#BB5507",
                            }}
                            isDisabled
                          >
                            Pay
                          </Button>
                        ) : isInternational ? (
                          <form
                            style={{ width: "100%" }}
                            action={P_STRIPE_CHECKOUT_INTERNATIONAL(
                              offerPrice,
                              "usd",
                              loggedInUserDetails.data.userDetails
                                .stripe_customer_id,
                              loggedInUserDetails.data.userDetails.user,
                              totalPrice,
                              couponCode,
                              couponType
                            )}
                            method="POST"
                          >
                            <Button
                              bg="#DD7323"
                              color={"white"}
                              w="full"
                              _hover={{
                                bg: "#BB5507",
                              }}
                              type="submit"
                            >
                              Pay with Card
                            </Button>
                          </form>
                        ) : (
                          <>
                            <form
                              style={{ width: "100%" }}
                              action={P_STRIPE_CHECKOUT(
                                offerPrice,
                                "inr",
                                loggedInUserDetails.data.userDetails
                                  .stripe_customer_id,
                                loggedInUserDetails.data.userDetails.user,
                                gst,
                                totalPrice,
                                couponCode,
                                couponType
                              )}
                              method="POST"
                            >
                              <Button
                                bg="#DD7323"
                                color={"white"}
                                w="full"
                                _hover={{
                                  bg: "#BB5507",
                                }}
                                type="submit"
                              >
                                Pay with Card
                              </Button>
                            </form>
                            {showRazorpayButton && (
                              <Button
                                bg="#DD7323"
                                mb={"12px"}
                                color={"white"}
                                w="full"
                                _hover={{
                                  bg: "#BB5507",
                                }}
                                type="submit"
                                mt={"10px"}
                                onClick={() => checkOutHandler()}
                              >
                                Pay with UPI
                              </Button>
                            )}
                          </>
                        )}
                      </Stack>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </ChakraProvider>
    // </>
  );
}
