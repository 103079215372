import {
  Box,
  Container,
  // Divider,
  Flex,
  HStack,
  // Icon,
  Image,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import { GiBeveledStar } from "react-icons/gi";
import Arti from "../../../assets/ArtiGyan/Arti.webp";
import Chalisa from "../../../assets/ArtiGyan/Chalisa.webp";
import Katha from "../../../assets/ArtiGyan/Katha.webp";
import God from "../../../assets/ArtiGyan/God.webp";
import Om from "../../../assets/ArtiGyan/Om.png";
import Shiva from "../../../assets/ArtiGyan/Shiva.png";
import Mata from "../../../assets/ArtiGyan/Mata.png";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import footerLine from "../../../assets/footerLine.png";
import LazyLoad from "react-lazy-load";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Aos from "aos";
import "aos/dist/aos.css";
import {firebaseApp,analytics,logEvent} from '../../../firebaseEvents/firebase';
const Artigyan = () => {
  const chalisa = [
    {
      id: 1,
      image: Arti,
      heading: "Aarti's",
      path: "aartis",
      eventName:"Artigyan_Arti_pageview"
    },
    {
      id: 2,
      image: Chalisa,
      heading: "Chalisa",
      path: "all-chalisa",
      eventName:"Artigyan_Chalisa_pageview"
    },
    {
      id: 3,
      image: Katha,
      heading: "Katha's",
      path: "all-kathas",
      eventName:"Artigyan_Kathas_pageview"
    },
    {
      id: 4,
      image: God,
      heading: "God's",
      path: "gods",
      eventName:"Artigyan_Gods_pageview"
    },
  ];

  const Blogs = [
    {
      id: 1,
      image:
        "https://gyan.gurucool.life/wp-content/uploads/2023/06/Untitled-design-1.png",
      heading:
        "Birth Chart: Discovering 5 Powerful Aspects Within",
      decriptions:
        "Astrology is a belief system that associates the positions and movements of celestial bodies with various aspects of human life and personality......",
      link: "https://gyan.gurucool.life/birth/",
    },
    {
      id: 2,
      image:
        "https://gyan.gurucool.life/wp-content/uploads/2023/06/710x590-18.png",
      heading: "Astrological Forecast for July: Provide a comprehensive...",
      decriptions:
      "Astrology provides us with insights into planetary movements and their effects on our lives, offering a map to navigate the cosmic currents.This month of July invites....",
       link: "https://gyan.gurucool.life/astrological-forecast-for-july/",
      },
    {
      id: 3,
      image:
        "https://gyan.gurucool.life/wp-content/uploads/2023/06/Untitled-design-2.png",
      heading:
        "Unlocking the Celestial Path: Empowering Childbirth with Vedic...",
      decriptions:
        "Astrology provides us with insights into planetary movements and their effects on our lives, offering a map to navigate the cosmic currents.This month of July invites....",
        link: "https://gyan.gurucool.life/empowering-childbirth-with-vedic-astrology/",
      },
  ];

  const isHoverEnabled = useBreakpointValue({ base: false, md: true });

  const [isLoaded, setIsLoaded] = useState(false);

  const truncateText = (text, limit) => {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  };

  // useEffect(() => {
  //   Aos.init({ duration: 2000 });
  // }, []);

  return (
    <div>
      <Container maxW={"6xl"} my={{ md: "30px", base: "15px" }} id="artigyan">
        <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ md: "1px", base: "1px" }}
        >
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          <Text
            fontSize={{ base: "18px", md: "32px" }}
            fontWeight={"700"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
          >
            Worship A God
          </Text>
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
        </Box>
        <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ md: "30px", base: "16px" }}
          className={"section-heading"}
        >
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          <Text
            fontSize={{ base: "12px", md: "20px" }}
            fontWeight={"500"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
            textAlign={"center"}
          >
            Enhance your spiritual practice with divine lyrics, powerful chants,
            and uplifting slogans.
          </Text>

          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
        </Box>

        <HStack
          spacing={{ md: "15px", base: "7px" }}
          justify={"space-between"}
          overflow={{ base: "scroll", md: "scroll", lg: "unset" }}
          pb={"6px"}
          sx={{
            // Hide scrollbar in webkit-based browsers
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          // data-aos="fade-up"
        >
          {chalisa.map((curr, index) => {
            return (
              <NavLink to={`https://gyan.gurucool.life/${curr.path}`}>
                {isHoverEnabled ? (
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={()=>{
                      logEvent(analytics,curr.eventName);
                    }} 
                    sx={{
                      "& .bg-image": {
                        transition: "transform 0.5s ease-in-out",
                      },
                      "&:hover .bg-image": {
                        transform: "scale(1.01)",
                      },
                    }}
                  >
                    <LazyLoad height={366} once>
                      <Box
                        borderRadius={"6px"}
                        maxW={{ lg: "294px", md: "294px", base: "156px" }}
                        h={{ md: "366px", base: "202px" }}
                        // backgroundImage= {`url(${curr.image})`}
                        //   backgroundSize= {"cover"}
                        sx={{
                          backgroundImage: `url(${curr.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          maxW: { lg: "294px", md: "294px", base: "156px" },
                          height: { base: "202px", md: "366px" },
                        }}
                      >
                        <Flex
                          align={"flex-end"}
                          h={"100%"}
                          p={{ md: "18px", base: "9px" }}
                          maxW={{ lg: "294px", md: "294px", base: "156px" }}
                          mr={{ lg: "8rem", md: "6rem", base: "4rem" }}
                        >
                          <Text
                            fontSize={{ md: "34px", base: "18px" }}
                            fontWeight={"600"}
                            color={"white"}
                          >
                            {curr.heading}
                          </Text>
                        </Flex>
                      </Box>
                    </LazyLoad>
                  </motion.div>
                ) : (
                  <Box
                    borderRadius={"6px"}
                    maxW={{ lg: "294px", md: "294px", base: "156px" }}
                    h={{ md: "366px", base: "202px" }}
                    // backgroundImage= {`url(${curr.image})`}
                    //   backgroundSize= {"cover"}
                    sx={{
                      backgroundImage: `url(${curr.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      maxW: { lg: "294px", md: "294px", base: "156px" },
                      height: { base: "202px", md: "366px" },
                    }}
                  >
                    <Flex
                      align={"flex-end"}
                      h={"100%"}
                      p={{ md: "18px", base: "9px" }}
                      maxW={{ lg: "294px", md: "294px", base: "156px" }}
                      mr={{ lg: "8rem", md: "6rem", base: "4rem" }}
                    >
                      <Text
                        fontSize={{ md: "34px", base: "18px" }}
                        fontWeight={"600"}
                        color={"white"}
                      >
                        {curr.heading}
                      </Text>
                    </Flex>
                  </Box>
                )}
              </NavLink>
            );
          })}
        </HStack>
        <Flex mt={{ md: "24px" }} justify={"flex-end"}>
          <NavLink to={`https://gyan.gurucool.life`}>
            <Text
              fontSize={{ md: "18px" }}
              fontWeight={"600"}
              color={"#A95210"}
            >
              Learn More
            </Text>
          </NavLink>
        </Flex>
        <Image src={footerLine} alt="footer-line" />
      </Container>
      {/* <Divider
        w={"80%"}
        h={"2px"}
        mx={"auto"}
        mt={{ md: "60px", base: "30px" }}
        color="gray.400"
      /> */}
      <Container
        maxW={"6xl"}
        mt={{ md: "40px", base: "21px" }}
        id="artigyan-blog"
      >
        <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ md: "1px", base: "1px" }}
        >
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          <Text
            fontSize={{ base: "18px", md: "32px" }}
            fontWeight={"700"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
          >
            Blogs
          </Text>
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
        </Box>
        <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ md: "30px", base: "16px" }}
          className={"section-heading"}
        >
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          <Text
            fontSize={{ base: "12px", md: "20px" }}
            fontWeight={"500"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
            textAlign={"center"}
          >
            Explore our comprehensive astrology blogs for valuable insights,
            tips, and guidance in the realm of astrology.
          </Text>

          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
        </Box>

        <HStack
        // data-aos="fade-up"
          spacing={{ md: "16px", base: "8px" }}
          overflow={{ base: "scroll", md: "unset" }}
          pb={"6px"}
          sx={{
            // Hide scrollbar in webkit-based browsers
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {Blogs.map((curr, index) => {
            return (
              <VStack borderRadius={"4px"}>
                <NavLink to={curr.link} aria-label="View artigyan blog">
                  <Box
                    w={{ md: "auto", base: "233px" }}
                    h={{ md: "244px", base: "176px" }}
                    borderRadius={"4px"}
                    onClick={() => {
                      logEvent(analytics, "Blogs_Trigger");
                    }}
                  >
                    <LazyLoadImage
                      src={curr.image}
                      w={"100%"}
                      h={"100%"}
                      objectFit={"cover"}
                      borderRadius={"4px 4px 0 0"}
                      style={{ height: "100%" }}
                    />
                  </Box>
                </NavLink>
                <Box onClick={() => {
                      logEvent(analytics, "Blogs_Trigger");
                    }}>
                  <NavLink to={curr.link} aria-label="View More artigyan blog">
                  <Text
                    fontSize={{ md: "22px", base: "16px" }}
                    fontWeight={"600"}
                    color={"#212121"}
                  >
                    {truncateText(curr.heading, 9)}
                  </Text>
                  </NavLink>
                </Box>
                <Box display={{ md: "block", base: "none" }}>
                  <Text
                    fontSize={{ md: "16px" }}
                    fontWeight={"400"}
                    color={"#313131"}
                  >
                    {curr.decriptions}
                  </Text>
                </Box>
                <Box w={"100%"} onClick={() => {
                      logEvent(analytics, "Blogs_Trigger");
                    }}>
                  <NavLink to={curr.link} aria-label="Read More artigyan blog">
                  <Text
                    textAlign={"left"}
                    fontSize={{ md: "16px", base: "14px" }}
                    fontWeight={"600"}
                    color={"#A95210"}
                  >
                    Learn More
                  </Text>
                  </NavLink>
                </Box>
              </VStack>
            );
          })}
        </HStack>
        <Image src={footerLine} alt="footer-line" />
      </Container>
      {/* <Divider
        w={"80%"}
        h={"2px"}
        mx={"auto"}
        mt={{ md: "60px", base: "30px" }}
        color="gray.400"
      /> */}
    </div>
  );
};

export default Artigyan;
