import { Box, Button, Heading, Image, Modal, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import icon1 from "./image/Frame.svg";
import icon2 from "./image/Frame (1).svg";
import icon3 from "./image/Frame (2).svg";
//Zodiac Sign Icon
import icon5 from "./image/Group.svg";
import icon6 from "./image/Group (1).svg";
import icon7 from "./image/Group (2).svg";
import icon8 from "./image/Group 52384.svg";
import icon9 from "./image/Group (3).svg";
import icon10 from "./image/Group (4).svg";
import icon11 from "./image/Group (5).svg";
import icon12 from "./image/Group (6).svg";
import icon13 from "./image/Group (7).svg";
import icon14 from "./image/Group (8).svg";
import icon15 from "./image/Group (9).svg";
import icon16 from "./image/Group (10).svg";
import {BsBrightnessHigh} from "react-icons/bs";
import Login from "../Login&Signup/Login";

export default function Zodiacsign({name,daily,monthly,yearly}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content,setContent]=useState("");
  const [daily1,setDaily]=useState(true);
  const [monthly1,setMonthly]=useState(false);
  const [yearly1,setYearly]=useState(false);

  let icon=null;
  switch (name) {
    case "Aries":
      icon=icon5;
      break;
    case "Taurus":
      icon=icon6;
      break;
    case "Gemini":
       icon=icon7;
      break;
    case "Cancer":
      icon=icon8;
      break;
    case "Leo":
      icon=icon9;
      break;
    case "Virgo":
      icon=icon10;
      break;
    case "Libra":
      icon=icon11;
      break;
    case "Sagitarius":
      icon=icon12;
      break;
    case "Capricorn":
      icon=icon13;
      break;
    case "Aquarius":
      icon=icon14;
      break;
    case "Pisces":
      icon=icon15;
      break;
    case "Scorpio":
      icon=icon16;
      break;
    default:
  }
  

  const handleDaily=()=>{
    setDaily(true);
    setMonthly(false);
    setYearly(false);
    setContent(daily);
  }

  const handleMonthly=()=>{
    setMonthly(true);
    setDaily(false);
    setYearly(false);
    setContent(monthly);
  }

  const handleYearly=()=>{
    setYearly(true);
    setDaily(false);
    setMonthly(false);
    setContent(yearly);
  }


  return (
    <>
      <Box display={"grid"} justifyContent={"center"} alignItems={"center"}>
        <Box display={"grid"} gridTemplateColumns={"repeat(3,1fr)}"}>
          <Button
            borderRadius={"48px"}
            leftIcon={daily1?<Image src={icon1}></Image>:<BsBrightnessHigh  size={"23px"} />}
            variant={daily1?"solid":"outline"}
            color={daily1?"white":"#737373"}
            bg={daily1?"#FF4C00":""}
            borderColor={daily1?"none":"#737373"}
            margin={"10px"}
            onClick={handleDaily}
          >
            Today
          </Button>
          <Button
            leftIcon={<Image src={icon2}></Image>}
            bg={monthly1?"#FF4C00":""}
            borderColor={monthly?"none":"#737373"}
            color={monthly1?"white":"#737373"}
            borderRadius={"48px"}
            variant={monthly1?"solid":"outline"}
            margin={"10px"}
            onClick={handleMonthly}
          >
            Monthly
          </Button>
          <Button
            leftIcon={<Image src={icon3}></Image>}
            variant={yearly1?"solid":"outline"}
            color={yearly1?"white":"#737373"}
            bg={yearly1?"#FF4C00":""}
            borderColor={yearly1?"none":"#737373"}
            borderRadius={"48px"}
            margin={"10px"}
            onClick={handleYearly}
          >
            2023
          </Button>
        </Box>
        <Box display={"grid"} justifyContent={"center"} alignItems={"center"}>
          <Box
            backgroundColor={"#FFDED0"}
            borderRadius={"50%"}
            height={"211px"}
            width={"211px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"10px"}
          >
            <Image src={icon}></Image>
          </Box>
        </Box>
      </Box>
      <Box width={{base:"85%",sm:"75%"}} margin={"auto"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        
          <Heading fontSize={"25px"} textAlign={"center"} margin={"10px"}>
            {name}
          </Heading>
          <Text textAlign={"center"} p={"0px "} >
           {content?content:daily}
          </Text>
          <Button backgroundColor={"#FF4C00"} color={"white"} margin={"15px"}  onClick={onOpen}>View More</Button>
          <Modal
          isCentered
          size={{ base: "xs", md: "4xl" }}
          isOpen={isOpen} onClose={onClose}
           >
           <Login />
        </Modal>
      </Box>
      
    </>
  );
}
