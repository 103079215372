import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Box,
    Image,
    Avatar,
    Text,
  } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react'

export default function Userdetails() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    //
    return (
      <>
        <Button onClick={onOpen}>Open Modal</Button>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign={"center"}>User Details</ModalHeader>
            <hr style={{width:"80%",margin:"auto",color:"#898989"}} />
            <ModalBody>
             <Box display={"flex"} flexDirection={"column"}>
               <Avatar margin={"auto"} name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
               
                <Box display={"grid"} mt={"5px"} gridTemplateColumns={"repeat(2,1fr)"} 
                // justifyItems={""} alignItems={"start"}
                columnGap={"10px"} rowGap={"10px"}
                 >
                    <Box bg={""}><Text as={"span"} color={"#898989"} >User Type : </Text><Text as={"span"} color={"#009519"} >New User</Text></Box>
                    <Box bg={""}><Text as={"span"} color={"#898989"} >Duration : </Text><Text as={"span"} color={"#009519"} >20 mins</Text></Box>
                    <Box bg={""}><Text as={"span"} color={"#898989"} >Name : </Text><Text as={"span"} color={""} >Gourab hd</Text></Box>
                    <Box bg={""}><Text as={"span"} color={"#898989"} >Gender : </Text><Text as={"span"} color={""} >Male</Text></Box>
                    <Box bg={""}><Text as={"span"} color={"#898989"} >DOB : </Text><Text as={"span"} color={""} >20/01/2022</Text></Box>
                    <Box bg={""}><Text as={"span"} color={"#898989"} >POB : </Text><Text as={"span"} color={""} >Patna , Bihar</Text></Box>
                </Box>
             </Box>
            </ModalBody>
            <ModalFooter margin={"auto"}>
              <Button variant={'outline'} color={"#FF4C00"}  colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}
