import {
  Box,
  Container,
  Flex,
  // Icon,
  Image,
  Text,
  Heading,
  O,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";

import "./Unification.css";
import iconx1 from "../../../assets/newhomepage/iconx1.svg";
import Business from "../../../assets/newhomepage/Business.svg";
import healths from "../../../assets/newhomepage/healths.svg";
import iconx2 from "../../../assets/newhomepage/iconx2.svg";
import iconx3 from "../../../assets/newhomepage/iconx3.svg";
import iconx4 from "../../../assets/newhomepage/iconx4.svg";
import iconx5 from "../../../assets/newhomepage/iconx5.svg";
import love from "../../../assets/newhomepage/love.svg";
import bussiness from "../../../assets/newhomepage/bussiness.svg";
import health from "../../../assets/newhomepage/health.svg";
import cards from "../../../assets/newhomepage/cards.svg";
import laalkitab from "../../../assets/newhomepage/lalkitab.svg";
import marriages from "../../../assets/newhomepage/marriages.svg";
import fortunes from "../../../assets/newhomepage/fortunes.svg";
import palm from "../../../assets/newhomepage/palm.svg";
import growths from "../../../assets/newhomepage/growths.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Aos from "aos";
import "aos/dist/aos.css";

import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase";

const Unification = () => {
  const ourData = [
    {
      id: 1,
      solution: "Love",
      image: iconx1,
      href: "http://localhost:3000/astrologers/Love",
    },
    {
      // id: 2,
      // solution: "Palmistry",
      // image: iconx2,
      id: 2,
      solution: "Health",
      image: healths,
      href: "http://localhost:3000/astrologers/Health",
    },
    {
      id: 3,
      solution: "Tarot",
      image: iconx3,
      href: "http://localhost:3000/astrologers/Tarot",
    },
    {
      // id: 4,
      // solution: "Lal Kitab",
      // image: iconx4,
      // href:"http://localhost:3000/astrologers/Lolkitab"
      id: 4,
      solution: "Marriage",
      image: marriages,
      href: "http://localhost:3000/astrologers/Marriage",
    },
    {
      id: 5,
      solution: "Career",
      image: growths,
      href: "http://localhost:3000/astrologers/Bussiness",
    },
    {
      id: 6,
      solution: "Business",
      image: Business,
      href: "http://localhost:3000/astrologers/Bussiness",
    },
  ];

  const options = {
    loop: false,
    dots: false,
    autoplay: false,
    nav: true,
    slideBy: 3,
    // autoplayTimeout: 10000,
    // autoplayHoverPause: false,
    responsive: {
      0: {
        items: 4,
        dots: false,
      },
      600: {
        items: 6,
      },
      1000: {
        items: 6,
      },
    },
  };

  const containerStyle = {
    bg: "#fff",
    my: 2,
    p: 0,
    maxW: { md: "8xl", base: "auto" },
    h: { md: "9rem", base: "9rem" },
    overflowX: "auto",
    css: {
      "&::-webkit-scrollbar": {
        width: "0",
      },
      scrollbarWidth: "none",
    },
  };

  return (
    <>
      <Container
        maxW={{ md: "full", base: "100vw" }}
        bg={"white"}
        mt={{ base: "-1px" }}
        display={{ base: "block", md: "none" }}
      >
        <Container
          id="unification"
          pl={"1rem"}
          my={{ md: "20px" }}
          mb={{ md: "20px" }}
          {...containerStyle}
          h={{ base: "auto", md: "27rem" }}
          maxW={{ md: "6xl" }}
        >
          <Heading
            as={"h2"}
            fontWeight={"700"}
            color={"var(--black-75, #414141);"}
            fontSize={{ md: "34px", base: "18px" }}
            ml={"10px"}
            mt={{ md: "30px", base: "20px" }}
            display={"flex"}
            justifyContent={{ base: "flex-start", md: "center" }}
            position={{ base: "relative" }}
            left={{ base: "5px" }}
          >
            Skills and Specialities
          </Heading>
          <Flex
            flexDirection={"row"}
            w={{ base: "auto" }}
            mt={{ base: "0", md: "0" }}
            justifyContent={"space-between"}
            position={"relative"}
            left={{ base: "10px", md: "0rem" }}
            pt={{ base: "0px", md: "2.5rem" }}
            gap={{ md: "5rem" }}
          >
            <OwlCarousel
              style={{ height: "0 !important" }}
              className="owl-theme"
              id="loveBanner"
              loop
              {...options}
            >
              {ourData.map((el, id) => {
                return (
                  <Box
                    as="a"
                    w={{ base: "150px", md: "10rem" }}
                    h={"80%"}
                    mt={"15px"}
                    ml={{ base: "0px" }}
                    pl={{ md: "10px" }}
                    href={`/astrologers/${el.solution}`}
                    display={{ md: "flex" }}
                    flexDir={{ base: "column", md: "column" }}
                    alignItems={{ md: "center", base: "center" }}
                    onClick={logEvent(analytics, "filter_click")}
                  >
                    <Image
                      w={{ base: "auto", md: "6.775rem" }}
                      h={{ base: "60px", md: "6.775rem" }}
                      src={el.image}
                      alt="imgs"
                    />
                    <Text
                      mt={{ md: "1.5rem" }}
                      textAlign={"center"}
                      fontWeight={"500"}
                      w={"auto"}
                      fontSize={{ base: "13px", md: "18px" }}
                      color={"gray"}
                      mr={{ base: "28px" }}
                      // position={{base:"relative"}}
                      // right={{base:"17px"}}
                      // textAlign={"center"}
                    >
                      {el.solution}
                    </Text>
                  </Box>
                );
              })}
            </OwlCarousel>
          </Flex>
        </Container>
      </Container>

      {/* for web  */}

      <Container
        display={{ base: "none", md: "block" }}
        maxW={{ md: "full", base: "100vw" }}
        bg={"white"}
        mt={{ md: "20px" }}
      >
        <Container
          id="unification"
          pl={"1rem"}
          my={{ md: "20px" }}
          mb={{ md: "20px" }}
          {...containerStyle}
          h={{ base: "auto", md: "27rem" }}
          maxW={{ md: "6xl" }}
        >
          <Heading
            as={"h2"}
            fontWeight={"700"}
            color={"var(--black-75, #414141);"}
            fontSize={{ md: "34px", base: "18px" }}
            ml={"10px"}
            mt={{ md: "30px", base: "20px" }}
            display={"flex"}
            justifyContent={{ base: "flex-start", md: "center" }}
            position={{ base: "relative" }}
            left={{ base: "5px" }}
          >
            Skills and Specialities
          </Heading>
          <Flex
            flexDirection={"row"}
            w={{ base: "auto" }}
            mt={{ base: "0", md: "0" }}
            justifyContent={"space-between"}
            position={"relative"}
            left={{ base: "10px", md: "0rem" }}
            pt={{ base: "0px", md: "2.5rem" }}
            gap={{ md: "5rem" }}
          >
            <OwlCarousel
              style={{ height: "0 !important" }}
              className="owl-theme"
              id="loveBanner"
              loop
              {...options}
            >
              {ourData.map((el, id) => {
                return (
                  <Box
                    as="a"
                    w={{ base: "150px", md: "10rem" }}
                    h={"80%"}
                    mt={"15px"}
                    ml={{ base: "0px" }}
                    pl={{ md: "10px" }}
                    href={`/astrologers/${el.solution}`}
                    display={{ md: "flex" }}
                    flexDir={{ base: "column", md: "column" }}
                    alignItems={{ md: "center", base: "center" }}
                  >
                    <Image
                      w={{ base: "auto", md: "6.775rem" }}
                      h={{ base: "60px", md: "6.775rem" }}
                      src={el.image}
                      alt="imgs"
                    />
                    <Text
                      mt={{ md: "1.5rem" }}
                      fontWeight={"500"}
                      w={"auto"}
                      fontSize={{ base: "14px", md: "18px" }}
                      color={"gray"}
                      // position={{base:"relative"}}
                      // right={{base:"17px"}}
                      // textAlign={"center"}
                    >
                      {el.solution}
                    </Text>
                  </Box>
                );
              })}
            </OwlCarousel>
          </Flex>
        </Container>
      </Container>
    </>
  );
};

export default Unification;
