import { Icon } from "@chakra-ui/icon";
import React from "react";
import Calender from "../AstrologerProfileDashboard/ProfileAssets/Calender.png";
import {
  Container,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { Link } from "react-router-dom";

const TimeTable = ({ astroData }) => {
 
  const id = astroData.user._id
 
  return (
    <Container maxW={"6xl"}>
      <HStack
        bg="#FEF1E6"
        h={{ md: "206px", base: "auto" }}
        borderRadius="12px"
        mt="32px"
        aspectRatio={"initial"}
        display={"flex"}
        justifyContent={"space-between"}
        px={{ base: "19px", md: "35px" }}
      >
        <Flex justify={"space-evenly"} gap={{ base: "8px", md: "16px" }}>
          <Image src={Calender} />
          <Text
            color={"#B5570F"}
            fontWeight={"500"}
            fontSize={{ base: "12px", md: "24px" }}
            alignSelf={"end"}
          >0
            Weekly Time - Table
          </Text>
        </Flex>
        <Flex gap={"10px"}>
          <Button
            bg={"#FEF1E6"}
            borderRadius={{ md: "8px" }}
            border={"1px solid #B5570F"}
            color={"#B5570F"}
          >
            <Link to={`/myProfile/edit/${id}`}>Edit</Link>
          </Button>

          <Button bg={"#B5570F"} color={"white"}>
            <Link to={`/myProfile/check/${id}`}>Check</Link>
          </Button>
        </Flex>
      </HStack>
    </Container>
  );
};

export default TimeTable;
