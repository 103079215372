import { selectPeers, useHMSActions, useHMSStore } from "@100mslive/react-sdk";
import {
  Button,
  HStack,
  Text,
  Icon,
  VStack,
  WrapItem,
  Avatar,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdCallEnd, MdCall } from "react-icons/md";
import {
  P_LIVE_END_CALL,
  P_LIVE_GET_LIVE_TRIGGER,
  P_LIVE_START_CALL,
} from "../../apiLinks";
import { useSelector } from "react-redux";
import axios from "axios";
import { IoVolumeHighOutline } from "react-icons/io5";
import pic from "../../assets/live-stream/pic.png";
const ShowIncomingCallPrompt = () => {
  const peers = useHMSStore(selectPeers);
  const localUser = peers.filter((peer) => peer.isLocal);
  const localUserRole = localUser[0]?.roleName;
  const [callDetails, setCallDetails] = useState();
  const [seconds, setSeconds] = useState(0);
  const [showIncomingCall, setShowIncomingCall] = useState(null);
  const [callOngoing, setCallOngoing] = useState(false);
  const astroDetails = useSelector((state) => state.astrologerProfile.data);
  const [incomingPicked, setIncomingPicked] = useState(false);
  const hasPaidViewer = peers.some((item) =>
    item.roleName.includes("paid-viewer")
  );

  const fetchData = async () => {
   
    try {
      const response = await axios.get(P_LIVE_GET_LIVE_TRIGGER, {
        params: {
          astrologer: astroDetails?.user?.guru,
        },
      });
      if (response.status === 200) {
       
        setShowIncomingCall(true);
        setCallDetails(response.data);
      }
    } catch (error) {
      setShowIncomingCall(false);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Fetch every 10 seconds

  /**
   * The function handles the click event for an incoming call and sends a request to start the call
   * with the provided details.
   */
  async function incomingCallClickHandler(res) {
    if (showIncomingCall) {
      setShowIncomingCall(false);
      setIncomingPicked(res);
      setCallOngoing(res);
      const loginToken = localStorage.getItem("loginToken");
      try {
        const response = await axios.post(
          P_LIVE_START_CALL,
          {
            publicCallTriggerId: callDetails?.publicCallTrigger,
            accept: res,
          },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        );
        if (response.data.success) {
          localStorage.setItem("callTicket", response?.data?.callTicket);
          setCountdown(0);
        }
      } catch (error) {
        console.error("Error making request:", error);
      }
    }
  }
  /**
   * This function sends a post request to end a live call and sets a state variable to false.
   */
  async function handleLiveCallEndHandler() {
    setCallOngoing(false);
    const loginToken = localStorage.getItem("loginToken");
    const callTicket = localStorage.getItem("callTicket");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      const response = await axios.post(
        P_LIVE_END_CALL,
        {
          callTicketId: callTicket,
        },
        config
      );
      //
      if (response.status === 200) {
        setIncomingPicked(false);
        localStorage.removeItem("callTicket");
      }
    } catch (error) {
     
    }
  }

  useEffect(() => {
    let interval;
    if (localUserRole === "paid-viewer" || localUserRole === "astrologer") {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [localUserRole]);

  // const formatTime = (time) => {
  //   let minutes = Math.floor(time / 60);
  //   let seconds = time % 60;
  //   return `${minutes < 10 ? "0" : ""}${minutes}:${
  //     seconds < 10 ? "0" : ""
  //   }${seconds}`;
  // };

  useEffect(() => {
    const fetchData = () => {
      const callTicket = localStorage.getItem("callTicket");
      if (incomingPicked) {
        const apiUrl = `https://data.gurucool.life/api/v1/live/callEndStatus?publicCallTicket=${callTicket}`;

        axios
          .get(apiUrl)
          .then((response) => {
           
            if (response.data.success && response.data.status === "ended") {
              localStorage.removeItem("callTicket");
              setIncomingPicked(false);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    };

    const interval = setInterval(fetchData, 10000); // Call fetchData every 10 seconds (10000 milliseconds)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [incomingPicked]);

  const [countdown, setCountdown] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown + 1);
    }, 1000); // Update countdown every second

    return () => {
      clearInterval(interval); // Clean up the interval when component unmounts
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <>
      <HStack
        width={"70%"}
        height={"20%"}
        p={"4px"}
        bg={"#FFFFFF"}
        borderRadius={"24px"}
        position={"absolute"}
        top={"30%"}
        left={"50%"}
        transform={"translate(-50%, -50%)"}
        zIndex={"999999999"}
        background={"white"}
        display={showIncomingCall && !hasPaidViewer ? "flex" : "none"}
        flexDirection={"column"}
        justify={"space-around"}
      >
        <Text fontSize={"18px"} fontWeight={"500"} color={"black"} mx={"13px"}>
          Incoming Call !!!
        </Text>

        <Box
          w={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
        >
          <Button
            onClick={() => {
              incomingCallClickHandler(false);
            }}
            colorScheme="red"
            borderRadius={"6px"}
            p={{ base: "8px" }}
          >
            Decline
          </Button>
          <Button
            onClick={() => {
              incomingCallClickHandler(true);
            }}
            colorScheme="green"
            borderRadius={"6px"}
            p={{ base: "8px" }}
          >
            Accept
          </Button>
        </Box>
      </HStack>
      {/* Show the user details to the astrologer during private consultation. */}
      <VStack
        p={"10px"}
        bg={"rgba(255, 255, 255, 0.7)"}
        borderRadius={"6px"}
        position={"absolute"}
        top={"20%"}
        left={"5%"}
        zIndex={"1000"}
        display={{
          base:
            incomingPicked && localUserRole === "astrologer" ? "flex" : "none",
        }}
        alignItems={"baseline"}
      >
        <Text m={"0"} fontSize={"12px"} fontWeight={"500"}>
          Name: {callDetails?.birthDetails?.fullName}
        </Text>
        <Text fontSize={"12px"} fontWeight={"500"}>
          Gender: {callDetails?.birthDetails?.gender}
        </Text>
        <Text fontSize={"12px"} fontWeight={"500"}>
          Date: {callDetails?.birthDetails?.birthDate}
        </Text>
        <Text fontSize={"12px"} fontWeight={"500"}>
          Time: {callDetails?.birthDetails?.birthTime}
        </Text>
        <Text fontSize={"12px"} fontWeight={"500"}>
          Place: {callDetails?.birthDetails?.birthPlace}
        </Text>
      </VStack>

      {/* Stack for call timer */}
      <HStack
        p={"8px 12px"}
        bg={"rgba(255, 177, 120, 0.5)"}
        borderRadius={"12px"}
        position={"absolute"}
        top={{ base: "10%", lg: "18%" }}
        left={{ base: "5%", lg: "20%" }}
        zIndex={"1000"}
        display={incomingPicked ? "flex" : "none"}
      >
        {/* <WrapItem>
          <Avatar name="Dan Abrahmov" src={pic} size="sm" />
        </WrapItem> */}
        <Icon as={IoVolumeHighOutline} boxSize={23} color={"white"} />
        <Text fontSize={"18px"} fontWeight={"500"} color={"#FFFFFF"}>
          {formatTime(countdown)}
        </Text>
        <Button
          colorScheme="whiteAlpha"
          borderRadius={"50%"}
          p={{ base: "8px" }}
          onClick={() => {
            handleLiveCallEndHandler();
          }}
        >
          <Icon as={MdCallEnd} boxSize={"17px"} color={"red"} />
        </Button>
      </HStack>
    </>
  );
};

export default ShowIncomingCallPrompt;
