import { Box, Flex, HStack, Text, Stack , Heading} from "@chakra-ui/layout";
import { Icon, Select } from "@chakra-ui/react";
import React, { useState } from "react";
import { GiBigDiamondRing } from "react-icons/gi";
import { FaHeart, FaHeartbeat, FaHandHoldingHeart } from "react-icons/fa";
import { MdBusiness, MdBusinessCenter } from "react-icons/md";
import { GiCardRandom } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router";

const Specialities = ({ handleOptionChange }) => {
  const [specialities, setSpecialities] = useState([
    {
      id: 1,
      name: "Love",
      icon: FaHeart,
    },
    {
      id: 2,
      name: "Marriage",
      icon: GiBigDiamondRing,
    },
    {
      id: 3,
      name: "Career",
      icon: MdBusinessCenter,
    },
    {
      id: 4,
      name: "Business",
      icon: MdBusiness,
    },
    {
      id: 5,
      name: "Life",
      icon: FaHandHoldingHeart,
    },
    {
      id: 6,
      name: "Health",
      icon: FaHeartbeat,
    },
    {
      id: 7,
      name: "Tarot",
      icon: GiCardRandom,
    },
  ]);

  const [selectedSkill, setSelectedSkill] = useState("");
  const [skills, setSkills] = useState([
    {
      id: 1,
      skill: "Tarot card reading",
    },
    {
      id: 2,
      skill: "Aura, chakra healing",
    },
    {
      id: 3,
      skill: "Angel card healing",
    },
    {
      id: 4,
      skill: "Vedic astrology",
    },
    {
      id: 5,
      skill: "Hair Problems",
    },
    {
      id: 6,
      skill: "Handwriting reading",
    },
    {
      id: 7,
      skill: "Bazi Reading",
    },
    {
      id: 8,
      skill: "Face reading",
    },
    {
      id: 9,
      skill: "Palmistry",
    },
    {
      id: 10,
      skill: "Kundli",
    },
    {
      id: 11,
      skill: "Match making",
    },
    {
      id: 12,
      skill: "Motivational therapy",
    },
    {
      id: 13,
      skill: "Colour therapy",
    },
    {
      id: 14,
      skill: "Crystal ball",
    },
    {
      id: 15,
      skill: "Psychic",
    },
    {
      id: 16,
      skill: "spirit reading",
    },
    {
      id: 17,
      skill: "Lal Kitab",
    },
    {
      id: 18,
      skill: "Vastu",
    },
    {
      id: 19,
      skill: "Numerology",
    },
  ]);
  //
  const location = useLocation();
  const { pathname } = location;
  const exactPath = pathname.replace("/astrologers/", "/");

  let color;

  switch (exactPath) {
    case "/astrologers":
      color = "#A95210";
      break;
    case "/Love":
      color = "#FF1E1E";
      break;
    case "/Career":
      color = "#008BAA";
      break;
    case "/Marriage":
      color = "#008BAA";
      break;
    case "/Life":
      color = "#BC148D";
      break;
    case "/Health":
      color = "#D08622";
      break;
    case "/Business":
      color = "#665AED";
      break;
    case "/Tarot":
      color = "#8D8800";
      break;
    default:
      color = "black";
      break;
  }
  const isAstrologersPage = location.pathname === "/astrologers";

  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(`/astrologers/${path}`);
  };

  // for skills filter
  const handleSelectChange = (event) => {
    const option = event.target.value;
    setSelectedSkill(option);
    handleOptionChange(option); // Call the callback function and pass the selected option
  };

  return (
    <>
      <Stack
        direction={{ md: "row", base: "column" }}
        // mt={{ md: "37px", base: "24px" }}
        spacing={{ md: "58px", base: "16px" }}
      >
        <Box>
          {/* <Heading
          as="h6"
            mb={{ md: "35px", base: "10px" }}
            fontSize={{ md: "26px", sm: "20px", base: "20px" }}
            fontWeight={"500"}
            color={"#3D3D3D"}
            textAlign={"left"}
          >
            Specialities
          </Heading> */}
          <HStack
            spacing={{ base: "20px" }}
            gap={{sm:"20px", base:"20px"}}
            alignItems={"center"}
            overflowX={{ md: "unset", base: "auto" }}
            sx={{
              // Hide scrollbar in webkit-based browsers
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {/* <Text
              fontSize={{ md: "20px", base: "20px", sm: "20px" }}
              fontWeight={"500"}
              color={color}
              mb={0}
              mr={{ md: "56px", base: "20px" }}
              onClick={() => navigate("/astrologers")}
              cursor={"pointer"}
            >
              All
            </Text> */}
{/* 
            {specialities.map((curr, index) => {
              return (
                <Flex
                  flexDirection={{ md: "row", base: "column" }}
                  mr={{ md: "20px" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                  cursor={"pointer"}
                  key={curr.id}
                  display={exactPath === `/${curr.name}` ? "flex" : "none"}
                  
                >
                  <Icon
                    as={curr.icon}

                    key={curr.id}
                    boxSize={{ md: "36px",sm: "30px", base: "30px"  }}
                    color={color}
                    display={exactPath === `/${curr.name}` ? "block" : "none"}
                  />
                  <Text
                    ml={{ md: "11px" }}
                    // mr={{ md: "20px" }}
                    mb={0}
                    fontSize={{ md: "18px", base: "14px", sm: "14px" }}
                    fontWeight={"500"}
                    color={color}
                    onClick={() => handleNavigate(curr.name)}
                    display={exactPath === `/${curr.name}` ? "block" : "none"}
                  >
                    {curr.name}
                  </Text>
                </Flex>
              );
            })} */}

            {/* {specialities.map((curr, index) => {
              return (
                <Flex
                  flexDirection={{ md: "row", base: "column" }}
                  gap="0px"
                  mr={{ md: "0px", base: "20px" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                  cursor={"pointer"}
                  key={curr.id}
                  display={exactPath === `/${curr.name}` ? "none" : "flex"}
                  onClick={() => handleNavigate(curr.name)}
                >
                  <Icon
                    as={curr.icon}
                    key={curr.id}
                    boxSize={{ md: "36px",sm: "30px", base: "30px"  }}
                    color={"gray.500"}
                    display={exactPath === `/${curr.name}` ? "none" : "block"}
                  />
                  <Text
                    ml={{ md: "10px" }}
                    // mr={{ md: "0px" }}
                    mb={0}
                    fontSize={{ md: "18px" }}
                    fontWeight={"500"}
                    color={"gray.500"}
                    onClick={() => handleNavigate(curr.name)}
                    display={exactPath === `/${curr.name}` ? "none" : "block"}
                  >
                    {curr.name}
                  </Text>
                </Flex>
              );
            })} */}
          </HStack>
        </Box>
        {/* <Box
          w={"100%"}
          ml={{ md: "58px" }}
          display={pathname === "/astrologers" ? "block" : "block"}
        >
          <Text
          mt={{md:"20px"}}
            mb={{md:"20px", base: "10px" }}
            fontSize={{ md: "20px" }}
            fontWeight={"500"}
            color={"#3D3D3D"}
            textAlign={"left"}
          >
            Skills
          </Text>
          <Select
            placeholder="Select Skills"
            value={selectedSkill}
            // onChange={(e) => setSelectedSkill(e.target.value)}
            onChange={handleSelectChange}
            m
          >
            {skills.map((skill) => (
              <option key={skill.id} value={skill.skill}>
                {skill.skill}
              </option>
            ))}
          </Select>
        </Box> */}
      </Stack>
    </>
  );
};

export default Specialities;
