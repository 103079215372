import FloatButton from "./FloatButton";
import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Stack,
  Box,
  Text,
  HStack,
  VStack,
  Button,
  Image,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Spinner,
  useDisclosure,
  Modal,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  color,
  Icon,
  Card,
  Divider,
  StatDownArrow,
  Heading,
  Avatar,
  AvatarBadge,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  Img,
} from "@chakra-ui/react";
import "./AstroProfile.css";
import { IoCall, IoCallOutline } from "react-icons/io5";
import { ArrowDownIcon } from "@chakra-ui/icons";

import Health from "../../assets/Health.png";
import Love from "../../assets/Love.png";
import Career from "../../assets/Career.png";
import Marriage from "../../assets/Marriage.png";
import Life from "../../assets/Life.webp";
import Business from "../../assets/Business.webp";
import Tarot from "../../assets/Tarot.webp";
import Sexual_Wellness_Expert from "../../assets/Sexual_Wellness_Expert.webp";

// import { StarIcon } from "@chakra-ui/icons";
import Review from "./Review/Review";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { fetchAstrologerProfileData } from "../../store/astrologerProfileSlice";

import { motion, useScroll } from "framer-motion";
import {
  // useTransform,
  useViewportScroll,
} from "framer-motion";
import ShowMoreText from "react-show-more-text";
import { useCallback } from "react";
import { useMemo } from "react";
import axios from "axios";
import { selectedAstrologerDetailsAction } from "../../store/selectedAstrologerDetailsSlice";
import CallFlowModal from "../CallFlow/CallFlowModal";
import Login from "../Login&Signup/Login";
import { AiFillCaretDown } from "react-icons/ai";
import {
  MdArrowDropDown,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdVideocam,
  MdVideocamOff,
} from "react-icons/md";
import { MdPhoneEnabled, MdPhoneDisabled } from "react-icons/md";
import FreeLiveAstrologyProfileButton from "../../assets/FreeLiveAstrologyProfileButton.ico";
import FreeLiveAstrologyProfileButtonBlack from "../../assets/FreeLiveAstrologyProfileButtonBlack.png";
// import { useMenuButtonContext } from "@chakra-ui/menu";
import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";
import { P_FOLLOW_GURU, P_UNFOLLOW_GURU } from "../../apiLinks";
import { FiPhoneCall, FiPhone } from "react-icons/fi";
import FreeLiveOffline from "../../assets/freeLiveOfflineBig.svg";
import AstrologerAvailability from "../../assets/AstrologeAvailability.png";
import { Helmet } from "react-helmet";
import PrimaryLoading from "../Loaders/PrimaryLoading";
import { FaUserFriends, FaGraduationCap } from "react-icons/fa";
// import { serve } from "../../assets/"
// import { RiGraduationCapFill } from "react-icons/ri";
// import { Button, ButtonGroup } from '@chakra-ui/react';
import Live from "../../assets/freeLive.svg";
import servedImage from "../../assets/Astro profile/served.svg";
import call from "../../assets/Astro profile/call.png";
import Video from "../../assets/Astro profile/video.png";
import { SlUserFollow } from "react-icons/sl";
import { BsCameraVideoFill } from "react-icons/bs";
import { ImUserPlus } from "react-icons/im";
import { ImUserMinus } from "react-icons/im";

import { useParams } from "react-router-dom";
// import { LiaLanguageSolid } from "react-icons/lia";
import { FaLanguage, FaShare } from "react-icons/fa";
// import { IoCallOutline } from "react-icons/io5";
import { BsFillChatSquareDotsFill } from "react-icons/bs";
import { MdTextsms, MdAccountCircle } from "react-icons/md";
import MostChoice from "../../assets/MostChoice.svg";
import OfferBannerNew from "../../assets/OfferBannerNew.png";

import DocData from "../Utils/data";

const AstroProfile = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const guruToken = searchParams.get("id");
  const { id } = useParams();
  console.log("DocData", DocData);
  const [firstNameState, setFirstName] = useState("");
  // const [lastNameState, setLastName] = useState("");
  console.log("id>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", id);
  // useEffect(() => {
  //   setFirstName(id);
  //   // setLastName(lastName);
  // }, [id]);

  // useEffect(() => {
  //   dispatch(fetchAstrologerProfileData(guruToken));
  // }, [guruToken, dispatch]);
  // console.log("FirstName>>>>>",firstNameState);

  // useEffect(() => {
  //   if (firstNameState) {
  //     dispatch(
  //       fetchAstrologerProfileData({ userName: firstNameState, isToken: false })
  //     );
  //   }
  // }, [firstNameState, dispatch]);

  // useEffect(() => {
  //   dispatch(fetchAstrologerProfileDataByName(firstName, lastName));
  // }, [firstName, lastName, dispatch]);
  const loginToken = localStorage.getItem("loginToken");
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );
  const isInternational = localStorage.getItem("isInternational") === "true";
  const astroDetails = useSelector((state) => state?.astrologerProfile?.data);
  console.log("Astrodetails", astroDetails);
  const timeSchedule = astroDetails?.timeSchedule;
  // console.log(astroDetails);
  // useEffect(() => {
  //   if (astroDetails?.user) {
  //     setFirstName(astroDetails.user.firstName);
  //     setLastName(astroDetails.user.lastName);
  //   }
  // }, [astroDetails?.user]);
  console.log(
    "loggedInUserDetails?.userDetails?.userDetails?.normalUserJourney2",
    loggedInUserDetails?.userDetails?.normalUserJourney2
  );

  const status = useSelector((state) => state?.astrologerProfile?.status);
  const error = useSelector((state) => state?.astrologerProfile?.error);
  function getPrice(isInternationalUser, userDetails) {
    if (isInternationalUser) {
      if (userDetails?.userDetails?.consultationCount === 0) {
        // todo return first price
        return astroDetails?.firstOfferPrice?.international?.fee;
      } else {
        return astroDetails?.internationalFee;
      }
    } else {
      if (userDetails?.userDetails?.consultationCount === 0) {
        // todo return first price
        return astroDetails?.firstOfferPrice?.national?.fee;
      } else {
        return astroDetails?.fee;
      }
    }
  }

  function getCuttedprice(isInternationalUser, userDetails) {
    if (isInternationalUser) {
      if (userDetails?.userDetails?.consultationCount === 0) {
        // todo return first price
        return "$" + astroDetails?.internationalFee + "/Min";
      } else {
        return "";
      }
    } else {
      if (userDetails?.userDetails?.consultationCount === 0) {
        // todo return first price
        return "₹" + astroDetails?.fee + "/Min";
      } else {
        return "";
      }
    }
  }
  //

  // const [timeSlots, setTimeSlots] = useState([]);
  // const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  // const currentDate = new Date();
  // const presentDayIndex = currentDate.getDay();
  // const nextSevenDays = [];
  // const weeklyDates = [];
  // const isFollowing = loggedInUserDetails?.userDetails?.following?.some(
  //   (item) => item.user.guru.includes(astroDetails?.user?.guru)
  // );
  //
  // for (let i = 0; i < 7; i++) {
  //   const nextDayIndex = (presentDayIndex + i) % 7;
  //   nextSevenDays.push(daysOfWeek[nextDayIndex]);
  // }
  // for (const day of nextSevenDays) {
  //   // weeklyDates.push(currentDate.getDate() + nextSevenDays.indexOf(day));
  //   const date = currentDate.getDate() + nextSevenDays.indexOf(day);
  //   const month = currentDate.toLocaleString("en-US", { month: "long" });

  //   weeklyDates.push(`${date} ${month}`);
  // }
  //
  //
  // const dayAndDate = nextSevenDays.map((day, index) => ({
  //   day: day,
  //   date: weeklyDates[index],
  // }));

  //

  // const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  // const weekDays = {
  //   0: "Sunday",
  //   1: "Monday",
  //   2: "Tuesday",
  //   3: "Wednesday",
  //   4: "Thursday",
  //   5: "Friday",
  //   6: "Saturday",
  // };
  const [timeSlots, setTimeSlots] = useState([]);
  const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const currentDate = new Date();
  const presentDayIndex = currentDate.getDay();
  const nextSevenDays = [];
  const weeklyDates = [];
  const isFollowing = loggedInUserDetails?.userDetails?.following?.some(
    (item) => item.user.guru.includes(astroDetails?.user?.guru)
  );

  for (let i = 0; i < 7; i++) {
    const nextDayIndex = (presentDayIndex + i) % 7;
    nextSevenDays.push(daysOfWeek[nextDayIndex]);
  }

  for (const day of nextSevenDays) {
    const nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + nextSevenDays.indexOf(day));
    const date = nextDate.getDate();
    const month = nextDate.toLocaleString("en-US", { month: "long" });

    weeklyDates.push(`${date} ${month}`);
  }

  const dayAndDate = nextSevenDays.map((day, index) => ({
    day: day,
    date: weeklyDates[index],
  }));

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const weekDays = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  //
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered
    ? FreeLiveAstrologyProfileButton
    : FreeLiveAstrologyProfileButtonBlack;

  const options = useMemo(
    () => ({
      nav: true,
      dots: false,
      margin: 4,
      responsive: {
        0: {
          items: 2.3,
          margin: 8,
          nav: false,
          loop: true,
        },
        600: {
          items: 3.2,
          loop: true,
        },
        1000: {
          items: 4,
        },
      },
    }),
    []
  );
  const served = Math.floor(Math.random() * 500) + 99;
  const text = astroDetails.description;
  const heading = astroDetails.heading;
  const [showAllSkills, setShowAllSkills] = useState(false);
  const handleShowAllSkills = useCallback(() => setShowAllSkills(true), []);
  const boxVariants = {
    initial: { opacity: 0, y: -10 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };
  const { scrollY } = useViewportScroll();
  const containerHeight = scrollY.get() === 0 ? "100%" : "50%";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [callBtnClicked, setCallBtnClicked] = useState(false);

  const callClickedHandler = () => {
    localStorage.setItem("guruToken", astroDetails.user.guru);
    axios
      .get(
        `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${astroDetails?.user?.guru}`
      )
      .then((response) => {
        //
        dispatch(
          selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
        );
      });
    setCallBtnClicked(true);
    onOpen();
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          url: window.location.href,
        });
      } else {
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const followUnfollowGuruHandler = async (res) => {
    const loginToken = localStorage.getItem("loginToken");

    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };

    if (res === "follow") {
      if (loginToken) {
        openFollowModal();
        try {
          const response = await axios.put(
            P_FOLLOW_GURU,
            {
              guruId: astroDetails?.user?.guru,
            },
            config
          );
          //
          if (response.status === 200) {
            dispatch(fetchLoggedInUserDetails(loginToken));
          }
        } catch (error) {}
      } else {
        onOpen();
      }
    } else {
      try {
        const response = await axios.put(
          P_UNFOLLOW_GURU,
          {
            guruId: astroDetails?.user?.guru,
          },
          config
        );
        //
        if (response.status === 200) {
          dispatch(fetchLoggedInUserDetails(loginToken));
        }
      } catch (error) {
        //
      }
    }
  };
  console.log(
    "loggedInUserDetails?.data?.userDetails",
    loggedInUserDetails?.userDetails?.permissions?.followButtonPopup
  );

  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const openFollowModal = () => {
    setIsFollowModalOpen(true);
  };

  const closeFollowModal = () => {
    setIsFollowModalOpen(false);
  };

  // const openContactModal = () => {
  //   setIsContactModalOpen(true);
  // };

  // const closeContactModal = () => {
  //   setIsContactModalOpen(false);
  // };
  const doctors = DocData[id-1];
  console.log("doctors", doctors);
  console.log("{astroDetails?.user?", astroDetails?.callAvailability);
  const [isExpanded, setIsExpanded] = useState(false);
  const host = localStorage.getItem("host");

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const navigate = useNavigate();

  const handleBannerNavigate = () => {
    navigate("/wallet");
  };

  // if (status === "loading") {
  //   return <PrimaryLoading />;
  // } else if (status === "succeeded") {
    return (
      <>
        {/* <Helmet>
          <title>
            Gurucool || {astroDetails?.user?.firstName}{" "}
            {astroDetails?.user?.lastName}
          </title>
        </Helmet> */}
        {!loginToken ? (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "4xl" }}
          >
            <Login />
          </Modal>
        ) : (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "md" }}
          >
            {callBtnClicked && <CallFlowModal />}
          </Modal>
        )}

        {loggedInUserDetails?.userDetails?.permissions?.followButtonPopup && (
          <Modal
            isCentered
            isOpen={isFollowModalOpen}
            onClose={closeFollowModal}
            p="20px"
          >
            <ModalOverlay />
            <ModalContent borderRadius={"20px"}>
              <ModalHeader p="0">
                <Box
                  bgColor="#FF570FCC"
                  color="#FFF"
                  px="20px"
                  py="10px"
                  borderTopLeftRadius={"20px"}
                  borderTopRightRadius={"20px"}
                >
                  <Heading as="h1" fontSize="24px" fontWeight={"600"}>
                    Important
                  </Heading>
                  <Text as="p" fontSize="18px" fontWeight={"500"} m="0px">
                    During A Call Consultation
                  </Text>
                </Box>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody p="0px">
                <Box p="15px" fontSize={"16px"} bgColor={"#FFF"} pr="30px">
                  <ul p="0px">
                    <li>
                      <Text pl="10px">
                        Following Astrologers, you will be notified through SMS
                        <MdTextsms style={{ display: "inline" }} /> once the
                        astrologers are online again.
                      </Text>
                    </li>
                    <li>
                      <Text pl="10px">
                        You can enable and disable this option under the profile
                        <MdAccountCircle style={{ display: "inline" }} /> page.
                      </Text>
                    </li>
                    {/* <li>
                    {" "}
                    <Text pl="10px">
                      If you face any issues during the call, contact{" "}
                      <Text as="span" color="#FF4D00">
                        support
                      </Text>{" "}
                      for assistance. They're here to help!
                    </Text>
                  </li>
                  <li>
                    <Text pl="10px">
                      Your Call will automatically be disconnected based on your
                      wallet balance.
                    </Text>
                  </li> */}
                  </ul>

                  <Text m="auto" color="red" textAlign={"center"} w="300px">
                    **Do not be spammed with SMS, please follow only limited
                    astrologers.**
                  </Text>

                  <Box bgColor={"#FFFFFF"} px="20px">
                    <Checkbox
                      mt="20px"
                      display="flex"
                      justifyContent={"center"}
                      isChecked={isChecked}
                      gap="12px"
                      color="#969696"
                      onChange={handleChange}
                    >
                      Don't Show this again.
                    </Checkbox>
                  </Box>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  // w="7rem"
                  // isDisabled={step === 3}
                  onClick={() => {
                    //   setStep(step + 1);
                    if (isChecked) {
                      const apiUrl =
                        "https://data.gurucool.life/api/v1/user/setPermissions";
                      const requestData = { followButtonPopup: false };

                      axios
                        .put(apiUrl, null, {
                          headers: {
                            Authorization: `Bearer ${loginToken}`,
                          },
                          params: requestData, // Send data as URL parameters
                        })
                        .then((response) => {
                          console.log(response.data);
                          // Handle the response data if needed
                        })
                        .catch((error) => {
                          console.error("Error:", error.message);
                        });
                    }
                    dispatch(fetchLoggedInUserDetails(loginToken));
                    setIsFollowModalOpen(false);
                  }}
                  // colorScheme="green"
                  bgColor={host === "myguruji" ? "#FF4C00" : "#FF4C00"}
                  color={host === "myguruji" ? "#FBFBFB" : "#FBFBFB"}
                  // colorScheme="orange"
                  variant="solid"
                  w={"100%"}
                  h={isChecked ? "40px" : "37px"}
                  _hover={{}}
                >
                  Next
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        {/* Hero Section Container start */}
        
          <Box
            style={{
              filter: isOpen ? "blur(3px)" : "blur(0px)",
            }}
          >
            {" "}
            <Stack
              className="astroProfile"
              justify={"center"}
              // position={"sticky"}
              top={"80px"}
              zIndex="1000"
              id="astro-profile"
            >
              <Container
                maxW={"7xl"}
                position="relative"
                p={{ md: "0", base: 4 }}
              >
                <Flex
                  justify={"space-between"}
                  align={"center"}
                  px={{ md: "115px", base: "18px" }}
                  pt={{ base: "18px" }}
                  display={{ md: "flex", base: "none" }}
                >
                  <Box>
                    <HStack align={"baseline"} pr={"10px"}>
                      <Heading
                        as="h1"
                        fontSize={{ md: "48px", base: "18px" }}
                        fontWeight={"600"}
                        mr={{ md: "19px" }}
                        color="#F6F6F6"
                        w={{ base: "-webkit-fit-content", md: "auto" }}
                      >
                        {doctors.firstName} {doctors.lastName}
                      </Heading>
                      {isFollowing ? (
                        <>
                          <Button
                            fontSize={{ md: "16px", base: "8px" }}
                            display={{ md: "flex", base: "flex" }}
                            bg={"gray"}
                            color={"white"}
                            borderRadius={"2px"}
                            size="xs"
                            onClick={() => {
                              followUnfollowGuruHandler();
                            }}
                          >
                            Unfollow
                          </Button>
                          <Button
                            p={"0 !important"}
                            _hover={{ backgroundColor: "transparent" }}
                            bg={"transparent"}
                            display={{ base: "none", md: "none" }}
                            onClick={() => {
                              followUnfollowGuruHandler();
                            }}
                          >
                            {/* <Icon boxSize={5} color={"#FF4C00"} as={ImUserMinus} /> */}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            fontSize={{ md: "16px", base: "8px" }}
                            display={{ md: "flex", base: "flex" }}
                            // colorScheme={"orange"}
                            bg={"#FF4C00"}
                            color={"white"}
                            borderRadius={"2px"}
                            size="xs"
                            onClick={() => {
                              followUnfollowGuruHandler("follow");
                            }}
                          >
                            <Box>
                              <image src={SlUserFollow} />

                              <Text fontSize={{ base: "none", md: "" }}>
                                Follow
                              </Text>
                            </Box>
                          </Button>
                          <Button
                            p={"0 !important"}
                            _hover={{ backgroundColor: "transparent" }}
                            bg={"transparent"}
                            display={{ base: "none", md: "none" }}
                            onClick={() => {
                              followUnfollowGuruHandler("follow");
                            }}
                          >
                            <Icon
                              boxSize={5}
                              color={"#FF4C00"}
                              as={ImUserPlus}
                            />
                          </Button>
                        </>
                      )}
                    </HStack>
                    <Text
                      fontSize={{ md: "40px", base: "16px" }}
                      fontWeight={{ md: "700" }}
                      color="#F6F6F6"
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Heading as="h2">
                        {" "}
                        {!loginToken ? (
                          "2 Min Free"
                        ) : (
                          <>
                            {isInternational
                              ? !loggedInUserDetails?.userDetails
                                  ?.normalUserJourney2 &&
                                loggedInUserDetails?.userDetails?.website[0] ===
                                  "gurucool.co"
                                ? "Free "
                                : `$ ${getPrice(true, loggedInUserDetails)}`
                              : !loggedInUserDetails?.userDetails
                                  ?.normalUserJourney2 &&
                                loggedInUserDetails?.userDetails?.website[0] ===
                                  "gurucool.co"
                              ? "Free"
                              : `₹ ${getPrice(false, loggedInUserDetails)}`}

                            {!loggedInUserDetails?.userDetails
                              ?.normalUserJourney2 &&
                            loggedInUserDetails?.userDetails?.website[0] ===
                              "gurucool.co" ? (
                              <Text as="p" fontSize="24px">
                                {`( ${loggedInUserDetails?.userDetails?.timeWallet} Min )`}
                              </Text>
                            ) : (
                              <Text as="span" fontSize="24px">
                                /Min
                              </Text>
                            )}
                          </>
                        )}
                        {/* <Heading
                      as="span"
                      fontSize={{ md: "26px", base: "10px" }}
                      fontWeight={{ md: "700" }}
                      color="#F6F6F6"
                    >
                     
                      
                    </Heading> */}
                      </Heading>
                      {/* <Heading as="h2" ml={"10px"} textDecoration={"line-through"} fontSize={"22px"} color={"#C0C0C0"} mt={"6px"}>
                    {" "}
                    {isInternational
                      ?getCuttedprice(true, loggedInUserDetails)
                      :getCuttedprice(false, loggedInUserDetails)}
                  </Heading> */}

                      {/* {astroDetails?.callAvailability === "online" ||
                      astroDetails?.callAvailability === "busy" ||
                      astroDetails?.liveStatus ? (
                        <Box
                          marginTop={{ base: 1, md: 3 }}
                          p={{ base: 0.5, md: 2 }}
                          borderRadius={10}
                          bg={"gray.900"}
                          marginLeft={{ base: "2", md: "10" }}
                          width={"fit-content"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text
                            width={"10px"}
                            height={"10px"}
                            background={"#5CE449"}
                            mr={2}
                            borderRadius={"50%"}
                          ></Text>
                          <Text
                            textAlign={"right"}
                            as="span"
                            fontSize={{ md: "16px", base: "8px" }}
                            color="#5CE449"
                          >
                            Online
                          </Text>
                        </Box>
                      ) : null} */}
                    </Text>
                    {astroDetails?.callAvailability === "offline" &&
                    !astroDetails?.liveStatus ? (
                      <Button color={"white"} background="gray">
                        Offline
                      </Button>
                    ) : (
                      <Menu zIndex="100000">
                        {/* <MenuButton
                      w={{ base: "100px", md: "120px" }}
                      as={Button}
                      rightIcon={<AiFillCaretDown color="white" />}
                      bg="#FF4C00"
                      borderRadius="4px"
                      color="white"
                      variant="solid"
                      size={{ base: "sm", md: "md" }}
                      display={{ md: "flex", base: "flex" }}
                      // zIndex={'10000'}
                      // onClick={callClickedHandler}
                      _hover={{
                        transform: "scale(1.02)", // Increase the size slightly on hover
                      }}
                      _active={{
                        bg: "#FF4C00", // Override the default active background color
                      }}
                      sx={{
                        "@media screen and (max-width: 480px)": {
                          fontSize: "21px", // Reset font size for screen sizes above 768px
                        },
                      }}
                    >
                      Consult Now 
                    </MenuButton> */}

                        <HStack
                          direction="row"
                          spacing={3}
                          gridColumnGap={{ md: "32px", base: "8px" }}
                          paddingTop={{ md: "60px", base: "9px" }}
                        >
                          {astroDetails?.liveStatus ? (
                            <Button
                              bg={"#FF4C00"}
                              w={{ md: "120px", base: "50px" }}
                              h={{ md: "45px", base: "32px" }}
                              justifyContent={"flex-start"}
                              _hover={{ bg: "#C73B00" }}
                              onClick={() => {
                                window.location.href = `/live/${astroDetails?.user?.firstName}${astroDetails.user.lastName}?id=${astroDetails?.user?.guru}`;
                              }}
                            >
                              <Box gap={{ md: "32px" }}>
                                <Image
                                  src={Live}
                                  h={{ md: "30px", base: "22px" }}
                                  w={{ md: "26px", base: "22px" }}
                                />
                              </Box>
                              <Text
                                color={"white"}
                                fontSize={{ md: "21px", base: "none" }}
                                marginLeft={{ md: "15px" }}
                                display={{ md: "inline", base: "none" }}
                              >
                                Live
                              </Text>
                            </Button>
                          ) : (
                            <Button
                              p={{ base: "12px !important" }}
                              bg={"#C0C0C0"}
                              w={{ md: "auto", base: "49px" }}
                              h={{ md: "45px", base: "32px" }}
                              justifyContent={"flex-start"}
                              _hover={{ bg: "#C0C0C0" }}
                            >
                              <Image
                                boxSize={{ base: "22", md: "26px" }}
                                src={FreeLiveOffline}
                              />

                              <Text
                                color={"#414141"}
                                fontSize={{ md: "21px", base: "none" }}
                                marginLeft={{ md: "15px" }}
                                display={{ md: "inline", base: "none" }}
                              >
                                Offline
                              </Text>
                            </Button>
                          )}
                          {astroDetails?.callAvailability === "busy" && (
                            <Button
                              px={{ base: "12px !important" }}
                              bg={
                                astroDetails?.callAvailability === "online"
                                  ? "#FF4C00"
                                  : "#C0C0C0"
                              }
                              w={{ md: "120px", base: "50px" }}
                              h={{ md: "45px", base: "32px" }}
                              _hover={{ bg: "#C73B00" }}
                              display={"flex"}
                              justifyContent={"center"}
                              onClick={() => {
                                if (
                                  astroDetails?.callAvailability === "online"
                                ) {
                                  callClickedHandler();
                                }
                              }}
                            >
                              <Icon
                                as={MdPhoneDisabled}
                                color={"gray.500"}
                                boxSize={{ md: "30px" }}
                                dis
                              />
                              <Text
                                as={"span"}
                                mb={0}
                                display={{ base: "none", md: "flex" }}
                                h={{ md: "32px" }}
                                w={{ md: "42px" }}
                                fontSize={{ md: "21px" }}
                                lineHeight={"30px"}
                              >
                                Busy
                              </Text>
                            </Button>
                          )}

                          {astroDetails?.callAvailability === "online" && (
                            <Button
                              px={{ base: "12px !important" }}
                              bg={
                                astroDetails?.callAvailability === "online"
                                  ? "#FF4C00"
                                  : "#C0C0C0"
                              }
                              w={{ md: "120px", base: "50px" }}
                              h={{ md: "46px", base: "32px" }}
                              fontSize={{ md: "21px" }}
                              fontWeight={"500"}
                              color={"white"}
                              _hover={{ bg: "#C73B00" }}
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              onClick={() => {
                                if (
                                  astroDetails?.callAvailability === "online"
                                ) {
                                  callClickedHandler();
                                }
                              }}
                              isDisabled={astroDetails?.mostTrusted}
                            >
                              <Icon
                                as={IoCall}
                                color={"white"}
                                boxSize={{ md: "30px" }}
                                mr={{ md: "9px" }}
                                h={{ base: "20px", md: "30px" }}
                                w={{ base: "20px", md: "30px" }}
                              />
                              <Text
                                as={"span"}
                                mb={0}
                                display={{ base: "none", md: "flex" }}
                                h={{ md: "32px" }}
                                w={{ md: "42px" }}
                                fontSize={{ md: "21px" }}
                                lineHeight={"30px"}
                              >
                                Call
                              </Text>
                            </Button>
                          )}

                          {astroDetails?.callAvailability === "offline" && (
                            <Button
                              px={{ base: "12px !important" }}
                              bg={
                                astroDetails?.callAvailability === "online"
                                  ? "#FF4C00"
                                  : "#C0C0C0"
                              }
                              w={{ md: "120px", base: "33px" }}
                              h={{ md: "45px", base: "28px" }}
                              fontSize={{ md: "21px" }}
                              fontWeight={"500"}
                              color={"white"}
                              _hover={{ bg: "#C73B00" }}
                              display={"flex"}
                              justifyContent={"center"}
                              onClick={() => {
                                if (
                                  astroDetails?.callAvailability === "online"
                                ) {
                                  callClickedHandler();
                                }
                              }}
                            >
                              <Icon
                                as={IoCall}
                                color={"white"}
                                boxSize={{ md: "30px" }}
                                mr={{ md: "9px" }}
                              />
                              <Text
                                as={"span"}
                                mb={0}
                                display={{ base: "none", md: "flex" }}
                              >
                                Offline
                              </Text>
                            </Button>
                          )}

                          {/* <Button bg={astroDetails?.liveCallAvailability?.private ? "#FF4C00" : "#C0C0C0"} w={{ md: "120px", base: "33px" }} h={{ md: "45px", base: "25px" }} justifyContent={"flex-start"} _hover={astroDetails?.liveCallAvailability?.private ? "#C73B00" : "#C0C0C0"} display={{ md: "flex", base: "none" }} lineHeight={" 0 !important"}>

                        <Icon boxSize={{ md: "6" }} color={astroDetails?.liveCallAvailability?.private ? "white" : "#414141"} as={astroDetails?.liveCallAvailability?.private ? MdVideocam : MdVideocamOff} />

                        {astroDetails?.liveCallAvailability?.private ? (
                          <Text color={"white"} fontSize={{ md: "21px", base: "none" }} marginLeft={{ md: "15px" }} display={{ md: "inline", base: "none" }} pt={{ md: "10px" }} h={{ md: "38px" }}>
                            P.Live
                          </Text>
                        ) : (
                          <Text alignSelf={"end"} color={"#414141"} fontSize={{ md: "21px", base: "none" }} marginLeft={{ md: "10px" }} display={{ md: "inline", base: "none" }} h={{ md: "23px" }}>
                            Offline
                          </Text>
                        )}

                      </Button> */}
                        </HStack>

                        <MenuList
                          style={{
                            zIndex: "100000",
                          }}
                        >
                          {astroDetails?.callAvailability === "offline" && (
                            <MenuItem
                              as={Button}
                              // onClick={callClickedHandler}
                              _hover={{
                                backgroundColor: "#C0C0C0",
                                color: "#414141",
                              }}
                            >
                              <Icon as={MdPhoneDisabled} boxSize={6} mr={2} />
                              {/* <Icon  boxSize={4} mr={2} >{FreeLiveAstrologyProfileButton}</Icon> */}
                              Offline
                            </MenuItem>
                          )}
                          {astroDetails?.callAvailability === "busy" && (
                            <MenuItem
                              as={Button}
                              // onClick={callClickedHandler}
                              _hover={{
                                backgroundColor: "#C0C0C0",
                                color: "#FF3030",
                                border: "1px solid #FF3030",
                              }}
                            >
                              <Icon as={MdPhoneDisabled} boxSize={6} mr={2} />
                              {/* <Icon  boxSize={4} mr={2} >{FreeLiveAstrologyProfileButton}</Icon> */}
                              Busy
                            </MenuItem>
                          )}
                          {astroDetails?.callAvailability === "online" && (
                            <MenuItem
                              as={Button}
                              // onClick={callClickedHandler}
                              color={"#414141"}
                              _hover={{
                                backgroundColor: "#FF4C00",
                                color: "white",
                              }}
                              onClick={() => {
                                callClickedHandler();
                              }}
                            >
                              <Icon as={MdPhoneEnabled} boxSize={6} mr={2} />
                              {/* <Icon  boxSize={4} mr={2} >{FreeLiveAstrologyProfileButton}</Icon> */}
                              Call Now
                            </MenuItem>
                          )}
                          {astroDetails?.liveStatus ? (
                            <MenuItem
                              as={Button}
                              _hover={{
                                backgroundColor: "#FF4C00",
                                color: "white",
                              }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => {
                                window.location.href = `/live/${astroDetails.user.firstName}${astroDetails.user.lastName}?id=${guruToken}`;
                              }}
                            >
                              <Image pr={"10px"} src={imageSrc} />
                              Free Live
                            </MenuItem>
                          ) : (
                            <MenuItem
                              as={Button}
                              _hover={{
                                backgroundColor: "#C0C0C0",
                                color: "#414141",
                              }}
                              color={"#414141"}
                              // onMouseEnter={handleMouseEnter}
                              // onMouseLeave={handleMouseLeave}
                            >
                              <Image pr={"10px"} src={FreeLiveOffline} />
                              Offline
                            </MenuItem>
                          )}

                          {astroDetails?.liveCallAvailability?.private ===
                            false && (
                            <MenuItem
                              // leftIcon={AiFillVideoCamera}
                              as={Button}
                              _hover={{
                                backgroundColor: "#FF4C00",
                                color: "#414141",
                              }}
                            >
                              <Icon as={MdVideocamOff} boxSize={6} mr={2} />{" "}
                              Offline
                            </MenuItem>
                          )}
                          {astroDetails?.liveCallAvailability?.private && (
                            <MenuItem
                              // leftIcon={AiFillVideoCamera}
                              as={Button}
                              _hover={{
                                backgroundColor: "#FF4C00",
                                color: "white",
                              }}
                              color={"#414141"}
                              marginTop={{ md: "10px" }}
                            >
                              <Icon as={MdVideocam} boxSize={6} mr={2} /> Pvt.
                              Live
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </Box>

                  <Box maxW={{ base: "50%", md: "385px" }}>
                    <Image
                      src={doctors.imageURL}
                      alt={doctors.firstName}
                      objectFit="cover"
                      maxWidth="385px"
                      width="100%"
                    />
                  </Box>
                </Flex>

                <Flex
                  display={{ base: "flex", md: "none" }}
                  flexDirection={"column"}
                  border="2px solid #FFB392"
                  borderRadius={"10px"}
                >
                  <Box
                    textAlign="right"
                    display={astroDetails?.mostTrusted ? "block" : "none"}
                  >
                    <Flex justifyContent="flex-end">
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        border="2px solid #FFB392"
                        borderTop="none"
                        borderRight="none"
                        p="8px"
                        borderBottomLeftRadius="10px"
                        borderTopRightRadius={"8px"}
                        fontSize="14px"
                        fontWeight="500"
                        color="#3E3E3E"
                        bgColor={"#D8F4FF"}
                      >
                        <Img src={MostChoice} boxSize="20px" mr="8px" /> Most
                        Choice
                      </Text>
                    </Flex>
                  </Box>
                  <HStack
                    px={"18px"}
                    pt={astroDetails?.mostTrusted ? "5px" : "20px"}
                    spacing={"16px"}
                    alignItems={"flex-start"}
                  >
                    <Box>
                      <Avatar src={doctors.imageURL} boxSize={"74px"}>
                        {/* <AvatarBadge
                          boxSize="1.25em"
                          bg={
                            astroDetails?.callAvailability === "online"
                              ? "#14A400"
                              : "#FF4545"
                          }
                        /> */}
                      </Avatar>
                    </Box>
                    <Box>
                      <Text
                        fontSize={"18px"}
                        fontWeight={"500"}
                        color={"#000"}
                        mb={0}
                      >
                        {/* Astrologer Name here */}
                        {doctors.firstName} {doctors.lastName}
                      </Text>
                      {/* <HStack spacing={"6px"} mt={"11px"}>
                        <Icon
                          as={FaLanguage}
                          boxSize={"18px"}
                          color={"#FF834F"}
                        /> */}
                      {/* <Text
                          fontSize={"12px"}
                          fontWeight={"500"}
                          color={"#000"}
                          mb={0}
                          textColor={"#544C4C"}
                        >
                          {astroDetails?.languages?.join(", ")}
                        </Text> */}
                      {/* </HStack> */}
                      <Flex mt={"8.5px"}>
                        <HStack spacing={"6px"}>
                          <Icon
                            as={IoCallOutline}
                            boxSize={"18px"}
                            color={"#FF834F"}
                          />
                          <Text
                            fontSize={"12px"}
                            fontWeight={"500"}
                            color={"#000"}
                            mb={0}
                            textColor={"#544C4C"}
                          >
                            {doctors.served}K
                          </Text>
                        </HStack>

                        <HStack spacing={"6px"} ml={"12px"}>
                          <Icon
                            as={BsFillChatSquareDotsFill}
                            boxSize={"18px"}
                            color={"#FF834F"}
                          />
                          <Text
                            fontSize={"12px"}
                            fontWeight={"500"}
                            color={"#000"}
                            mb={0}
                            textColor={"#544C4C"}
                          >
                            {doctors.served}K
                          </Text>
                        </HStack>
                      </Flex>
                    </Box>
                  </HStack>
                  <HStack
                    w={"100%"}
                    justifyContent={"space-around"}
                    borderTop={"1px solid #544C4C"}
                    borderBottom={"1px solid #544C4C"}
                    mt={"13px"}
                  >
                    <Box textAlign={"center"}>
                      <Text
                        fontSize={"24px"}
                        fontWeight={"600"}
                        mb={0}
                        mx={"auto"}
                        textAlign={"center"}
                      >
                        {doctors?.rating}
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mb={0}
                        mx={"auto"}
                      >
                        Rating
                      </Text>
                    </Box>
                    <Box w={"1px"} h={"68px"} bg={"#FFCDB8"}></Box>
                    <Box textAlign={"center"}>
                      <Text
                        fontSize={"24px"}
                        fontWeight={"600"}
                        mb={0}
                        mx={"auto"}
                      >
                        {`${doctors.exp} Years`}
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mb={0}
                        mx={"auto"}
                      >
                        Experience
                      </Text>
                    </Box>
                    <Box w={"1px"} h={"68px"} bg={"#FFCDB8"}></Box>
                    <Box textAlign={"center"}>
                      <Text
                        fontSize={"24px"}
                        fontWeight={"600"}
                        mb={0}
                        mx={"auto"}
                      >
                        {doctors.followers}K
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"500"}
                        mb={0}
                        mx={"auto"}
                      >
                        Followers
                      </Text>
                    </Box>
                  </HStack>
                  <HStack
                    mt={"16px"}
                    mb={"20px"}
                    px={4}
                    spacing={"30px"}
                    justifyContent={"space-between"}
                  >
                    <Button
                      leftIcon={<FaShare />}
                      colorScheme="orange"
                      variant="solid"
                      w={"100%"}
                      onClick={() => handleShare()}
                    >
                      Share
                    </Button>

                    {isFollowing ? (
                      <>
                        <Button
                          leftIcon={<ImUserPlus />}
                          colorScheme="orange"
                          variant="solid"
                          w={"100%"}
                          onClick={() => {
                            followUnfollowGuruHandler();
                          }}
                        >
                          UnFollow
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          leftIcon={<ImUserPlus />}
                          colorScheme="orange"
                          variant="solid"
                          w={"100%"}
                          onClick={() => {
                            followUnfollowGuruHandler("follow");
                          }}
                        >
                          Follow
                        </Button>
                      </>
                    )}
                  </HStack>
                </Flex>
              </Container>
            </Stack>
            {/* Hero Section Container End */}
            {/* Body Content Container Start */}
            <Container
              maxW={"6xl"}
              // bg={"#FFEFE3"}
              px={{ md: "32px", base: "16px" }}
              id="astro-profile-details"
              mb={{ md: "24px", base: "24px" }}
            >
              {/* <HStack
            m={"auto"}
            //   mt={{md: "-5.5%"}}
            mb={{ md: "40px" }}
            w={"max-content"}
            spacing={{ md: "40px", base: "24px" }}
            py={{ md: "20px", base: "10px" }}
            px={{ md: "33px", base: "18px" }}
            boxShadow="0px 3.33643px 33.3643px rgba(0, 0, 0, 0.08);"
            bg="white"
            borderRadius={"13px"}
            zIndex="1000"
            position="absolute"
            left="50%"
            //   top="50%"
            transform="translate(-50%, -50%)"
          >
            <VStack>
              <Text fontSize={{ md: "30px", base: "18px" }} fontWeight={"600"}>
                {astroDetails.served}k
              </Text>
              <Text fontSize={{ base: "12px", md: "18px" }} color={"#8F8F8F"}>
                Served
              </Text>
            </VStack>
            <VStack>
              <Text fontSize={{ md: "30px", base: "18px" }} fontWeight={"600"}>
                {astroDetails.experience}
              </Text>
              <Text fontSize={{ base: "12px", md: "18px" }} color={"#8F8F8F"}>
                Exp(Years)
              </Text>
            </VStack>
            <VStack>
              <Text fontSize={{ md: "30px", base: "18px" }} fontWeight={"600"}>
                20K
              </Text>
              <Text fontSize={{ base: "12px", md: "18px" }} color={"#8F8F8F"}>
                Followers
              </Text>
            </VStack>
          </HStack> */}
              <Box display={{ md: "block", base: "none" }}>
                <HStack
                  // m={"auto"}
                  // mb={{ md: "40px" }}
                  w={"100%"}
                  h={{ md: "60px", base: "28px" }}
                  spacing={{ md: "40px", base: "0px" }}
                  py={{ md: "20px", base: "10px" }}
                  px={{ md: "120px", base: "18px" }}
                  // boxShadow="0px 3.33643px 33.3643px rgba(0, 0, 0, 0.08);"
                  bg="#FFC293"
                  // mt={{ md: "30px", base: "15px" }}
                  zIndex="1000"
                  // position="absolute"
                  // left="50%"
                  //  ml={"202px"}
                  //   top="50%"
                  // transform="translate(-50%, -50%)"
                  justifyContent={"space-between"}
                >
                  <HStack>
                    <Icon
                      as={FaUserFriends}
                      boxSize={{ base: "20px", md: "62px" }}
                      color={"#FF4C00"}
                      h={{ md: "30px", base: "" }}
                      w={{ md: "46px", base: "" }}
                    />
                    <Heading
                      as={"h3"}
                      fontSize={{ md: "28px", base: "12px" }}
                      fontWeight={"600"}
                      display={"inline-flex"}
                      alignItems={"center"}
                    >
                      {doctors.followers}K
                    </Heading>
                    <Heading
                      as={"h3"}
                      fontSize={{ base: "12px", md: "28px" }}
                      color={"363636"}
                    >
                      Followers
                    </Heading>
                  </HStack>
                  <HStack>
                    <Icon
                      as={FaGraduationCap}
                      boxSize={{ md: "45px" }}
                      color={"#FF4C00"}
                      h={{ md: "30px", base: "16px" }}
                      w={{ base: "27px", md: "50px" }}
                    />
                    {/* <Image
                  boxSize={{ base: 5, md: 16 }}
                  src={servedImage}
                  h={{ md: "30px", base: "16px" }}
                  w={{ base: "27px", md: "50px" }}
                /> */}
                    <Heading
                      fontSize={{ md: "28px", base: "12px" }}
                      fontWeight={"600"}
                    >
                      {doctors.exp}
                    </Heading>

                    <Heading
                      as={"h3"}
                      fontSize={{ base: "12px", md: "28px" }}
                      color={"363636"}
                    >
                      Years (Exp)
                    </Heading>
                  </HStack>
                  <HStack>
                    <Image
                      boxSize={{ base: 5, md: 16 }}
                      src={servedImage}
                      h={{ md: "30px", base: "16px" }}
                      w={{ base: "27px", md: "50px" }}
                    />
                    {/* <Icon
                  as={FaGraduationCap}
                  boxSize={{ md: "45px" }}
                  color={"#FF4C00"}
                  h={{ md: "30px", base: "16px" }}
                  w={{ base: "27px", md: "50px" }}
                /> */}
                    <Heading
                      fontSize={{ md: "28px", base: "12px" }}
                      fontWeight={"600"}
                    >
                      {doctors.served}K
                    </Heading>
                    {/* <Text fontSize={{ base: "12px", md: "18px" }} color={"black"}>
                  Followers
                </Text>  */}
                  </HStack>

                  {/* <Flex>
                <icon >
                  <text>

                  </text>
                </icon>
              </Flex>  */}
                </HStack>
              </Box>

              {/* New Offer Bannner */}
              <HStack
                mb="15px"
                display={{ base: "flex", md: "none" }}
                onClick={handleBannerNavigate}
              >
                <Box maxWidth="100%">
                  <Img src={OfferBannerNew} width="400px" />
                </Box>
              </HStack>

              <Box
                border={"2px solid #FFC293"}
                borderTop={{ md: "none", base: "2px solid #FFC293" }}
                borderRadius={{ md: "0 0 24px 24px", base: "8px" }}
                p={{ md: "28px", base: "10px" }}
                display={{ md: "block", base: "none" }}
                // paddingTop={{ md: "30px", base: "" }}
                // gap={{ md: "40px" }}
              >
                {/* <Text
              //  paddingBottom={{md:"98px"}}
              w={"max-content"}
              fontSize={{ md: "18px", base: "14px" }}
              fontWeight={"600"}
              // color="#8B8B8B"
              // borderBottom={"1px solid orange"}
              mb={{ md: "16px", base: "8px" }}
              paddingBottom={{ base: "11px", md: "auto" }}
              paddingTop={{ base: "30px", md: "80px", sm: "30px" }}
            >
              Heading
            </Text> */}
                {/* <Text
                  textAlign={"center"}
                  fontSize={{ md: "18px", base: "14px" }}
                  fontWeight={{ md: "500" }}
                  color={"#252525"}
                  display={{
                    md:
                      heading.length === 1 && heading === "."
                        ? "none"
                        : "block",
                    base: "none",
                  }}
                  // paddingtop={{md:"92px"}}
                >
                  {heading}
                </Text> */}

                {/* <Box pt={{ md: "24px", base: "0px" }}>
                  <Heading
                    as="h5"
                    w={"max-content"}
                    fontSize={{ md: "18px", base: "14px" }}
                    fontWeight={"600"}
                    color="#8B8B8B"
                    borderBottom={"1px solid orange"}
                    // paddingTop={{ md: "10px", base: "", sm: "" }}
                    border={{ md: "12px" }}
                  >
                    Specialities
                  </Heading>
                  <HStack
                    mt={{ md: "16px", base: "12px" }}
                    spacing={{ md: "31px", base: "16px" }}
                  >
                    {astroDetails.specialization.map((curr, index) => {
                      let imageSrc = null;
                      let text = null;
                      let link = null;
                      if (curr === "Love") {
                        imageSrc = Love;
                        text = "Love";
                        link = "/astrologers/Love";
                      } else if (curr === "Marriage") {
                        imageSrc = Marriage;
                        text = "Marriage";
                        link = "/astrologers/Marriage";
                      } else if (curr === "Career") {
                        imageSrc = Career;
                        text = "Career";
                        link = "/astrologers/Career";
                      } else if (curr === "Life") {
                        imageSrc = Life;
                        text = "Life";
                        link = "/astrologers/Life";
                      } else if (curr === "Health") {
                        imageSrc = Health;
                        text = "Health";
                        link = "/astrologers/Health";
                      } else if (curr === "Business") {
                        imageSrc = Business;
                        text = "Business";
                        link = "/astrologers/Business";
                      } else if (curr === "Sexual Wellness Expert") {
                        imageSrc = Sexual_Wellness_Expert;
                        text = "Sexual Wellness Expert";
                      } else if (curr === "Tarot") {
                        imageSrc = Tarot;
                        text = "Tarot";
                        link = "/astrologers/Tarot";
                      }
                      return (
                        <motion.div
                          key={index}
                          variants={boxVariants}
                          initial="initial"
                          animate="animate"
                        >
                          <Link to={link}>
                            <VStack>
                              <Box boxSize={{ md: "50px", base: "30px" }}>
                                <Image
                                  src={imageSrc}
                                  alt="Dan Abramov"
                                  objectFit="cover"
                                />
                              </Box>
                              <Text
                                fontSize={{ md: "18px", base: "10px" }}
                                fontWeight={"400"}
                                color=" #161616"
                              >
                                {text}
                              </Text>
                            </VStack>
                          </Link>
                        </motion.div>
                      );
                    })}
                  </HStack>
                </Box> */}

                {/* Skills Section */}
                <Box
                  mt={{ md: "40px", base: "16px" }}
                  display={{ md: "block", base: "none" }}
                >
                  <Heading
                    as="h5"
                    w={"max-content"}
                    fontSize={{ md: "18px", base: "14px" }}
                    fontWeight={"600"}
                    color="#8B8B8B"
                    borderBottom={"1px solid orange"}
                  >
                    Skills
                  </Heading>
                  <Flex
                    flexWrap={"wrap"}
                    gap={2}
                    mt={{ md: "16px", base: "12px" }}
                  >
                    {doctors.specialization.map((curr, index) => {
                      return (
                        <motion.div
                          key={index}
                          variants={boxVariants}
                          initial="initial"
                          animate="animate"
                        >
                          <Box
                            w={"auto"}
                            p={{ md: "4px 14px", base: "3px 10px" }}
                            border="1px solid #C47D48"
                            borderRadius={"24px"}
                            fontSize={{ md: "16px", base: "12px" }}
                            fontWeight={"500"}
                            color={"#C37D48"}
                          >
                            {curr}
                          </Box>
                        </motion.div>
                      );
                    })}
                  </Flex>
                  {/* {astroDetails.skills.length > 7 && (
                    <Button
                      colorScheme="orange"
                      variant="link"
                      mt={{ md: "8px", base: "6px" }}
                      fontSize={{ md: "18px", base: "11px" }}
                      onClick={() => setShowAllSkills(!showAllSkills)}
                    >
                      {showAllSkills ? "Show Less" : "Show More"}
                    </Button>
                  )} */}
                </Box>
              </Box>
              {/* 
              <Accordion
                allowMultiple
                w={"100%"}
                borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                border={"2px solid #FFC293"}
                display={{ md: "none" }}
              >
                <AccordionItem border={"none"}>
                  <h2 style={{ marginBottom: "0px" }}>
                    <AccordionButton
                      justifyContent={"center"}
                      borderRadius={{
                        md: "18px",
                        sm: "8px",
                        base: "8px",
                      }}
                    >
                      <Heading
                        as="h3"
                        textAlign={"center"}
                        fontSize={{ md: "18px", base: "14px" }}
                        fontWeight={"500"}
                        ml={{ md: 4, base: 2 }}
                        mb={0}
                      >
                        Specialities
                      </Heading>
                     
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={"10px"}>
                    <HStack
                      mt={{ md: "16px", base: "12px" }}
                      spacing={{ md: "31px", base: "16px" }}
                    >
                      {astroDetails.specialization.map((curr, index) => {
                        let imageSrc = null;
                        let text = null;
                        let link = null;
                        if (curr === "Love") {
                          imageSrc = Love;
                          text = "Love";
                          link = "/astrologers/Love";
                        } else if (curr === "Marriage") {
                          imageSrc = Marriage;
                          text = "Marriage";
                          link = "/astrologers/Marriage";
                        } else if (curr === "Career") {
                          imageSrc = Career;
                          text = "Career";
                          link = "/astrologers/Career";
                        } else if (curr === "Life") {
                          imageSrc = Life;
                          text = "Life";
                          link = "/astrologers/Life";
                        } else if (curr === "Health") {
                          imageSrc = Health;
                          text = "Health";
                          link = "/astrologers/Health";
                        } else if (curr === "Business") {
                          imageSrc = Business;
                          text = "Business";
                          link = "/astrologers/Business";
                        } else if (curr === "Sexual Wellness Expert") {
                          imageSrc = Sexual_Wellness_Expert;
                          text = "Sexual Wellness Expert";
                        } else if (curr === "Tarot") {
                          imageSrc = Tarot;
                          text = "Tarot";
                          link = "/astrologers/Tarot";
                        }
                        return (
                          <motion.div
                            key={index}
                            variants={boxVariants}
                            initial="initial"
                            animate="animate"
                          >
                            <Link to={link}>
                              <VStack>
                                <Box boxSize={{ md: "50px", base: "30px" }}>
                                  <Image
                                    src={imageSrc}
                                    alt="Dan Abramov"
                                    objectFit="cover"
                                  />
                                </Box>
                                <Text
                                  fontSize={{ md: "18px", base: "10px" }}
                                  fontWeight={"400"}
                                  color=" #161616"
                                >
                                  {text}
                                </Text>
                              </VStack>
                            </Link>
                          </motion.div>
                        );
                      })}
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion> */}

              {/* Skills Section */}
              <Accordion
                defaultIndex={[0]}
                allowMultiple
                w={"100%"}
                borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                border={"2px solid #FFC293"}
                mt={"16px"}
                display={{ md: "none" }}
              >
                <AccordionItem border={"none"}>
                  <h2 style={{ marginBottom: "0px" }}>
                    <AccordionButton
                      justifyContent={"center"}
                      borderRadius={{
                        md: "18px",
                        sm: "8px",
                        base: "8px",
                      }}
                    >
                      <Heading
                        as="h3"
                        textAlign={"center"}
                        fontSize={{ md: "18px", base: "14px" }}
                        fontWeight={"500"}
                        ml={{ md: 4, base: 2 }}
                        mb={0}
                      >
                        Skills
                      </Heading>
                      {/* <Flex justifyContent={"flex-end"}> */}
                      <AccordionIcon />
                      {/* </Flex> */}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={"10px"}>
                    <Box display={"flex"} flexWrap={"wrap"} gap="10px">
                      {doctors.specialization.map((curr, index) => {
                        return (
                          <Flex>
                            <Box
                              key={index}
                              h="38px"
                              w="auto"
                              p="8px 20px"
                              borderRadius={"56px"}
                              bgColor="#F3F3F3"
                              display={"flex"}
                              justifyContent={"center"}
                              gap="20px"
                            >
                              <Text
                                justifyContent="center"
                                alignItems="center"
                                gap="20px"
                              >
                                {curr}
                              </Text>
                            </Box>
                          </Flex>
                        );
                      })}
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              {/* About Me Section */}
              <Accordion
                allowMultiple
                defaultIndex={[0]}
                w={"100%"}
                borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                border={"2px solid #FFC293"}
                mt={"16px"}
                display={{ md: "none" }}
                mb="150px"
              >
                <AccordionItem border={"none"}>
                  <h2 style={{ marginBottom: "0px" }}>
                    <AccordionButton
                      justifyContent={"center"}
                      borderRadius={{
                        md: "18px",
                        sm: "8px",
                        base: "8px",
                      }}
                    >
                      <Heading
                        as="h3"
                        textAlign={"center"}
                        fontSize={{ md: "18px", base: "14px" }}
                        fontWeight={"500"}
                        ml={{ md: 4, base: 2 }}
                        mb={0}
                      >
                        About Me
                      </Heading>
                      {/* <Flex justifyContent={"flex-end"}> */}
                      <AccordionIcon />
                      {/* </Flex> */}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={"10px"}>
                    <Box mt={{ md: "16px", base: "12px" }}>
                      <ShowMoreText
                        lines={2}
                        more={
                          <Button
                            colorScheme="orange"
                            variant="link"
                            mt={{ md: "8px", base: "6px" }}
                            fontSize={{ md: "16px", base: "12px" }}
                          >
                            Show more
                          </Button>
                        }
                        less={
                          <Button
                            colorScheme="orange"
                            variant="link"
                            mt={{ md: "8px", base: "6px" }}
                            fontSize={{ md: "16px", base: "12px" }}
                          >
                            Show Less
                          </Button>
                        }
                        // anchorClass="text-orange"
                        mb={"8px"}
                      >
                        <Text
                          fontSize={{ md: "16px", base: "14px" }}
                          fontWeight={{ md: "400" }}
                          color={"#252525"}
                        >
                          {doctors.description}
                        </Text>
                      </ShowMoreText>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Box
                // display={{
                //   // md: text.length > 5 ? "block" : "none",
                //   base: "none",
                // }}
                mt={{ md: "15px", base: "16px" }}
                border={"2px solid #FFC293"}
                borderRadius={{ md: "24px", base: "8px" }}
                p={{ md: "24px", base: "10px" }}
              >
                <Heading
                  as="h5"
                  w={"max-content"}
                  fontSize={{ md: "18px", base: "14px" }}
                  fontWeight={"600"}
                  color="#8B8B8B"
                  borderBottom={"1px solid orange"}
                  mb={{ md: "16px", base: "8px" }}
                >
                  About Me
                </Heading>
                <motion.div
                  //   key={index}
                  variants={boxVariants}
                  initial="initial"
                  animate="animate"
                >
                  <Box mt={{ md: "16px", base: "12px" }}>
                    <ShowMoreText
                      lines={2}
                      more={
                        <Button
                          colorScheme="orange"
                          variant="link"
                          mt={{ md: "8px", base: "6px" }}
                          fontSize={{ md: "16px", base: "12px" }}
                        >
                          Show more
                        </Button>
                      }
                      less={
                        <Button
                          colorScheme="orange"
                          variant="link"
                          mt={{ md: "8px", base: "6px" }}
                          fontSize={{ md: "16px", base: "12px" }}
                        >
                          Show Less
                        </Button>
                      }
                      // anchorClass="text-orange"
                      mb={"8px"}
                    >
                      <Text
                        fontSize={{ md: "16px", base: "14px" }}
                        fontWeight={{ md: "400" }}
                        color={"#252525"}
                      >
                        {doctors.description}
                      </Text>
                    </ShowMoreText>
                  </Box>
                </motion.div>
              </Box>

              {/* Certificate And Images Section */}
              {/* <Accordion
                allowMultiple
                w={"100%"}
                borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                border={"2px solid #FFC293"}
                mt={"16px"}
                // display={{
                //   md: "none",
                //   sm: astroDetails.images.length === 0 ? "none" : "block",
                //   base: astroDetails.images.length === 0 ? "none" : "block",
                // }}
              >
                <AccordionItem border={"none"}>
                  <h2 style={{ marginBottom: "0px" }}>
                    <AccordionButton
                      justifyContent={"center"}
                      borderRadius={{
                        md: "18px",
                        sm: "8px",
                        base: "8px",
                      }}
                    >
                      <Heading
                        as="h3"
                        textAlign={"center"}
                        fontSize={{ md: "18px", base: "14px" }}
                        fontWeight={"500"}
                        ml={{ md: 4, base: 2 }}
                        mb={0}
                      >
                        Images
                      </Heading>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={"10px"}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(auto-fill, minmax(94px, 1fr))"
                      gridGap="8px"
                    >
                      {astroDetails.images.map((curr, index) => {
                        return (
                          <motion.div
                            key={index}
                            variants={boxVariants}
                            initial="initial"
                            animate="animate"
                          >
                            <Box key={index} w="94px" h="102px">
                              <Image
                                src={curr?.url}
                                alt={curr.public_id}
                                objectFit="cover"
                                w="100%"
                                h="100%"
                                borderRadius="6px"
                              />
                            </Box>
                          </motion.div>
                        );
                      })}
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion> */}

              {/* <Box
                mt={{ md: "15px", base: "16px" }}
                border={"2px solid #FFC293"}
                borderRadius={{ md: "24px", base: "8px" }}
                p={{ md: "24px", base: "10px" }}
                display={astroDetails.images.length === 0 ? "none" : "block"}
              >
                <Heading
                  as="h5"
                  w={"max-content"}
                  fontSize={{ md: "18px", base: "14px" }}
                  fontWeight={"600"}
                  color="#8B8B8B"
                  borderBottom={"1px solid orange"}
                  mb={{ md: "24px" }}
                >
                  Certificate And Images
                </Heading>
                <Box mt={{ md: "24px", base: "12px" }}>
                  <OwlCarousel className="owl-theme" {...options}>
                    {astroDetails.images.map((curr, index) => {
                      return (
                        <motion.div
                          key={index}
                          variants={boxVariants}
                          initial="initial"
                          animate="animate"
                        >
                          <Box boxSize={{ md: "250px", base: "150px" }}>
                            <Image
                              src={curr?.url}
                              alt={curr.public_id}
                              objectFit="cover"
                              boxSize={"100%"}
                              borderRadius={"6px"}
                            />
                          </Box>
                        </motion.div>
                      );
                    })}
                  </OwlCarousel>
                </Box>
              </Box> */}

              {/* Astrologer Availability Section  and Rating and Review Section Desktop*/}
              {/* <Flex
                display={{ md: "flex", sm: "none", base: "none" }}
                direction={{ md: "row", base: "column-reverse" }}
                gap={{ md: "15px", base: "16px" }}
              >
                <Flex
                  display={{ md: "block", sm: "none", base: "none" }}
                  direction={"column"}
                  border={"2px solid #FFC293"}
                  borderRadius={{ md: "24px", base: "8px" }}
                  p={{ md: "24px", base: "10px" }}
                  mt={{ md: "15px" }}
                >
                  <Review astroDetails={astroDetails} served={served} />
                </Flex>
                <Box
                  w={"-webkit-fill-available"}
                  mt={{ base: "16px" }}
                  
                  border={"2px solid #FFC293"}
                  borderRadius={{ md: "24px", base: "8px" }}
                  p={{ md: "24px", base: "10px" }}
                >
                  <HStack dir="row" gap={"8px"}>
                    
                    <Flex justifyContent={"space-between"} gap={"105px"}>
                      <Heading
                        as="h5"
                        w={"max-content"}
                        fontSize={{ md: "18px", base: "14px" }}
                        fontWeight={"600"}
                        color="#414141"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        Availability
                      </Heading>
                      <Icon
                        as={
                          isExpanded
                            ? MdKeyboardArrowRight
                            : MdKeyboardArrowDown
                        }
                        onClick={toggleExpansion}
                        ml={2}
                        color="#8B8B8B"
                        cursor="pointer"
                        display={{ base: "block", md: "none" }}
                        boxSize={8}
                      />
                    </Flex>
                  </HStack>
                  <Box
                    display={{
                      base: isExpanded ? "block" : "none",
                      md: "block",
                    }}
                  >
                    {dayAndDate.map((item, index) => (
                      <Box key={index} mt={{ md: "24px" }}>
                        <Flex gap={"12px"}>
                          <Text
                            fontSize={{ md: "18px" }}
                            fontWeight={"500"}
                            color={"#494949"}
                            display={"none"}
                          >
                            {item?.day}
                          </Text>
                          <Text
                            color={"#ACACAC"}
                            fontWeight={"500"}
                            fontSize={{ md: "12px" }}
                            alignSelf={"center"}
                          >
                            {item?.date}
                          </Text>
                        </Flex>
                        <Flex
                          direction={"column"}
                          gap={"8px"}
                          flexWrap={"wrap"}
                          mt={"16px"}
                          mb={"8px"}
                        >
                          {
                           
                            <>
                              <Text>Morning</Text>
                              <Card
                                w={"50%"}
                                borderRadius={"2px"}
                                h={{ base: "29px", md: "35px" }}
                                bg={"#FFEEE6"}
                                boxShadow={"none !important"}
                              >
                                <Text
                                  color={"#505050"}
                                  fontWeight={"400"}
                                  py={{ base: "6px" }}
                                  px={{ base: "12px" }}
                                  fontSize={{ base: "12px", md: "16px" }}
                                >
                                  {
                                    timeSchedule[item?.day]?.morning !==
                                    "00:00 00:00"
                                      ? timeSchedule[item?.day]?.morning
                                      : "Time not Updated"
                                    
                                  }
                                </Text>
                              </Card>
                            </>
                          }

                          <>
                            <Text>Morning</Text>
                            <Card
                              w={"50%"}
                              borderRadius={"2px"}
                              h={{ base: "29px", md: "35px" }}
                              bg={"#FFEEE6"}
                              boxShadow={"none !important"}
                            >
                              <Text
                                color={"#505050"}
                                fontWeight={"400"}
                                py={{ base: "6px" }}
                                px={{ base: "12px" }}
                                fontSize={{ base: "12px", md: "16px" }}
                              >
                                {timeSchedule[item.day].evening !==
                                "00:00 00:00"
                                  ? timeSchedule[item.day].evening
                                  : "Time not Updated"}
                              </Text>
                            </Card>
                          </>
                        
                        </Flex>
                        <Divider
                          style={{
                            margin: "0 !important",
                            color: "#EBEBEB",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                 
                </Box>
              </Flex> */}

              {/* Astrologer Availability Section  and Rating and Review Section Mobile*/}
              {/* 
              <Accordion
                allowMultiple
                w={"100%"}
                borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                border={"2px solid #FFC293"}
                mt={"16px"}
                display={{ md: "none" }}
              >
                <AccordionItem border={"none"}>
                  <h2 style={{ marginBottom: "0px" }}>
                    <AccordionButton
                      justifyContent={"center"}
                      borderRadius={{
                        md: "18px",
                        sm: "8px",
                        base: "8px",
                      }}
                    >
                      <Heading
                        as="h3"
                        textAlign={"center"}
                        fontSize={{ md: "18px", base: "14px" }}
                        fontWeight={"500"}
                        ml={{ md: 4, base: 2 }}
                        mb={0}
                      >
                        Avalibility
                      </Heading>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={"10px"}>
                    <Box
                    
                    >
                      <Box
                      
                      >
                        {dayAndDate.map((item, index) => (
                          <Box key={index} mt={{ md: "24px" }}>
                            <Flex gap={"12px"}>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                color={"#414141"}
                                display={"none"}
                              >
                                {item?.day}
                              </Text>
                              <Text
                                color={"#414141"}
                                fontWeight={"500"}
                                fontSize={"14px"}
                                alignSelf={"center"}
                              >
                                {item?.date}
                              </Text>
                            </Flex>
                            <Flex
                              direction={"row"}
                              gap={"8px"}
                              flexWrap={"wrap"}
                              mt={"16px"}
                              mb={"8px"}
                            >
                             
                              <Text
                                borderRadius={"27px"}
                                color={"#505050"}
                                fontWeight={"400"}
                                py={{ base: "8px", sm: "8px" }}
                                px={{ base: "12px", sm: "12px" }}
                                fontSize={{ base: "12px", md: "16px" }}
                                border="1px solid #E6E6E6"
                              >
                                {
                                  timeSchedule[item?.day]?.morning !==
                                  "00:00 00:00"
                                    ? timeSchedule[item?.day]?.morning
                                    : "Time not Updated"
                                  
                                }
                              </Text>
                              
                              <Text
                                borderRadius={"27px"}
                                // h={{ base: "29px", md: "35px" }}
                                // bg={"#FFEEE6"}
                                color={"#505050"}
                                fontWeight={"400"}
                                py={{ base: "8px", sm: "8px" }}
                                px={{ base: "12px", sm: "12px" }}
                                fontSize={{ base: "12px", md: "16px" }}
                                border="1px solid #E6E6E6"
                              >
                                {timeSchedule[item.day].evening !==
                                "00:00 00:00"
                                  ? timeSchedule[item.day].evening
                                  : "Time not Updated"}
                              </Text>

                            </Flex>

                            <Divider
                              style={{
                                margin: "0 !important",
                                color: "#EBEBEB",
                              }}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion> */}

              {/* Rating and Review Section Mobile*/}
              {/* <Accordion
                allowMultiple
                w={"100%"}
                borderRadius={{ md: "18px", sm: "8px", base: "8px" }}
                border={"2px solid #FFC293"}
                mt={"16px"}
                display={{ md: "none" }}
                mb={{ base: "90px" }}
                defaultIndex={[0]}
              >
                <AccordionItem border={"none"}>
                  <h2 style={{ marginBottom: "0px" }}>
                    <AccordionButton
                      justifyContent={"center"}
                      borderRadius={{
                        md: "18px",
                        sm: "8px",
                        base: "8px",
                      }}
                    >
                      <Heading
                        as="h3"
                        textAlign={"center"}
                        fontSize={{ md: "18px", base: "14px" }}
                        fontWeight={"500"}
                        ml={{ md: 4, base: 2 }}
                        mb={0}
                      >
                        Reviews
                      </Heading>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={"10px"}>
                    <Flex
                      display={{ md: "none", sm: "block", base: "block" }}
                      direction={"column"}
                      // border={"2px solid #FFC293"}
                      borderRadius={{ md: "24px", base: "8px" }}
                      p={{ md: "24px", base: "10px" }}
                      mt={{ md: "15px" }}
                    >
                      <Review astroDetails={astroDetails} served={served} />
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion> */}
            </Container>
          </Box>
        
        <FloatButton astroDetails={astroDetails} />
      </>
    );
  // } else if (status === "failed") {
  //   return (
  //     <Center>
  //       <Text color="red">{error}</Text>
  //     </Center>
  //   );
  // }

  // return null;
};

export default AstroProfile;
