import React from "react";
import { useNavigate } from "react-router-dom";
import {
  // Divider,
  // Textarea,
  // VStack,
  Box,
  // Flex,
  Heading,
  Center,
  // IconButton,
  // Text,
  // Link,
  // Container,
  // Icon,
  Image,
  Button,
  // Modal,
  // useDisclosure,
} from "@chakra-ui/react";

import Noaccess from "../assets/Noaccess.webp";

const NoAccess = () => {


  const navigate = useNavigate();

  function handleGoBack() {
    navigate("/");
  }

  return (
    <>
      <Center h="100vh">
        <Box bg="white" p="4" >
          <Box w="full" textAlign="center">
            <Image
              src={Noaccess}
              alt="Access Denied"
              mx="auto"
              w={"130px"}
              h={"144px"}
            />
          </Box>
          <Box mt="4">
            <Heading color="red.500" fontSize="34px" fontWeight={700} textAlign="center">
              No access
            </Heading>
            <Box mt="2" textAlign="center" color={"#7C7C7C"}>
              Sorry, You don’t have permission to view this page.
            </Box>
            <Box mt="8" textAlign="center">
              <Button colorScheme="orange" onClick={handleGoBack} px="34px" >Go Back</Button>
            </Box>
          </Box>
        </Box>
      </Center>
    </>
  );
};

export default NoAccess;
