import React from 'react'
import Clickviewmore from '../../Clickviewmore';
import Horosscope from '../Horosscope';
import Formpersonalized2 from '../Formpersonalized2';

export default function Libra1() {
  return (
    <>
    <Clickviewmore name={"Libra"} />
    <Horosscope/>
    <Formpersonalized2/>
    </>
  )
}
