import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  // , useParams
} from "react-router-dom";
import {
  Container,
  Box,
  Heading,
  // Image,
  Text,
  Avatar,
  Center,
  Button,
  Image,
  AvatarGroup
} from "@chakra-ui/react";

// import userPic from "../../../assets/_user.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { callConsultationEndedDetailsAction } from "../../../store/callConsultationEndedDetailsSlice";
import { useLocation } from "react-router-dom";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase";
import icon from "./Group 52752.svg";

const QueueConsultationStarted = ({astroDetails}) => {
  // const astroDetails = useSelector(
  //   (state) => state.selectedAstrologerDetails.astroDetails
  // );


  // const reloadPage = () => {
  //   window.location.reload();
  // };

  // useEffect(() => {
  //   // Set the time (in milliseconds) after which you want to reload the page
  //   const reloadTime = 5000; // 5000 milliseconds = 5 seconds

  //   // Set the timeout to trigger the page reload after the specified time
  //   const reloadTimeout = setTimeout(reloadPage, reloadTime);

  //   // Clean up the timeout when the component is unmounted to avoid memory leaks
  //   return () => clearTimeout(reloadTimeout);
  // }, []);


  return (
    <>
        <Container
          bg="#EDEDED"
          maxW="6xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          // height="100vh"
          padding="3%"
        >
          <Box
            bg="#FCFCFC"
            width={{ md: "fit-content" }}
            height={{ md: "fit-content", base: "auto" }}
            borderRadius={{ md: "12px", base: "9px" }}
            mx="auto"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            p={8}
          >
            <Box >
              <Heading
                fontSize={{ md: "20px", base: "16px" }}
                fontWeight={500}
                textAlign="center"
                color={"#222222"}
                mt={{ md: "26px", base: "20px" }}
                mb={{ md: "22px", base: "18px" }}
              >
                  Connecting to Astrologer
              </Heading>
              <Box display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"end"}>
              
                {astroDetails?.callQueue?.map((purchaseId,index)=>{
                  if(localStorage.getItem("purchaseId")===purchaseId){
                    if(astroDetails.callQueue.length-1===index){
                      return  <Box>
                      <Image ml={"3.5%"} height={"60px"}  width="75px" mb={{base:"-10px",md:"-10px"}} src={icon}></Image>
                      <Avatar
                        mt={"0px"}
                        width="77.64px"
                        height="77.64px"
                        src={"https://bit.ly/dan-abramov'"}
                        alt="user"
                      />
                      </Box>
                    }
                    else {
                      return <Box mr={"-37px"}>
                      <Image ml={"3.5%"} height={"60px"}  width="75px" mb={{base:"-10px",md:"-10px"}} src={icon}></Image>
                      <Avatar
                        // mx={"auto"}
                        width="77.64px"
                        height="77.64px"
                        // mr={"-38px"}
                        src={"https://bit.ly/dan-abramov'"}
                        alt="user"
                        // maxW={{ base: "25%", md: "100%" }}
                      />
                      </Box>
                    }
                    
                  }

                  else if(astroDetails.callQueue.length-1===index){
                    return  <Box >
                    <Avatar
                      width="77.64px"
                      height="77.64px"
                      src={"https://bit.ly/dan-abramov'"}
                      alt="user"
                    />
                    </Box>
                  }

                  else {
                    return  <Box mr={"-37px"}>
                    <Avatar
                      width="77.64px"
                      height="77.64px"
                      src={"https://bit.ly/dan-abramov'"}
                      alt="user"
                    />
                    </Box>
                  }

                })}

              </Box>
                

                {/* <AvatarGroup size='md' max={5}>
                <Box>
                <Image src={icon}></Image>
                <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                 </Box>
                 <Box>
                <Image src={icon}></Image>
                <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
                 </Box>
  <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
  <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
  <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
  <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast' />
</AvatarGroup> */}
                  
            
              <Heading
                fontSize={{ md: "20px", base: "16px" }}
                fontWeight={500}
                textAlign="center"
                mt={{ md: "26px", base: "20px" }}
                mb={{ md: "12px", base: "8px" }}
                color={"#15AD00"}
              >
                {/* Your consultation ends in */}
                
              </Heading>
            </Box>
            <Box>
              {/* {callStatus === "busy" ||
              callStatus === "failed" ||
              callStatus === "no-answer" ? (
                <Button
                  variant={"solid"}
                  colorScheme="orange"
                  w={"100%"}
                  onClick={handleNavigate}
                >
                  Go Back To Home Page
                </Button>
              ) : */}
                <>
                  <Text
                    fontSize={{ md: "12px", base: "10px" }}
                    fontWeight={600}
                    textAlign="center"
                    mb={{ md: "15px", base: "11px" }}
                    color="#797979"
                  >
                    {/* {CallMsg} */}
                    Connecting you to an astrologer,Your Position is{" "}{astroDetails?.callQueue?.findIndex((purchaseId)=>localStorage.getItem("purchaseId")===purchaseId)+1}
                    <Text as={"span"} color=" #FF6644">
                     {"  ,"}Please wait for a Min
                    </Text>
                  </Text>
                  <Text textAlign="center" color="#15AD00">
                    {/* {callMsg} */}
                    {/* {formatTime(remainingTime)}{" "} */}
                    <span style={{ textAlign: "center", color: "#797979" }}>
                      Please Wait for your turn you're in the Queue
                    </span>
                  </Text>
                </>
              
            </Box>
          </Box>
        </Container>
    </>
  );
};

export default QueueConsultationStarted;
