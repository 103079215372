import {
  Button,
  Container,
  Icon,
  Switch,
  Tooltip,
  // , Container
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  // Card,
  // CardHeader,
  Text,
  // CardBody,
  // CardFooter,
  Heading,
  // Switch,
  // FormControl,
  // FormLabel,
  Stack,
  VStack,
  Image,
  // useBreakpointValue,
} from "@chakra-ui/react";

// import ToggleSwitch from "./ToggleSwitch";
import Call from "../AstrologerProfileDashboard/ProfileAssets/Call.png";
import callOff from "../AstrologerProfileDashboard/ProfileAssets/CallOff.png";
// import Chat from "../AstrologerProfileDashboard/ProfileAssets/chat.png";
import LiveStreaming from "../AstrologerProfileDashboard/ProfileAssets/liveStreaming.png";
import liveStreamingOff from "../AstrologerProfileDashboard/ProfileAssets/liveStreamingOff.png";
// import AvailabilityStatusButton from "./AvailabilityStatusButton";
import chatOff from "../AstrologerProfileDashboard/ProfileAssets/chatOff.png";

import axios from "axios";
import { P_CHANGE_CALLS_AVAILABILITY } from "../../../apiLinks";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { SearchIcon, WarningIcon } from "@chakra-ui/icons";
import "./toggle.css";
import LoggedInUserDetails, {
  fetchLoggedInUserDetails,
} from "../../../store/LoggedInUserDetails";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import Callqueuetoggle from "./Callqueuetoggle"



const Navigator = ({ astrologer }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.loggedInUserDetails?.data);
  const [changeCallAvailabilityStatus, setChangeCallAvailabilityStatus] =
    useState(astrologer?.guru?.callAvailability);
  const [
    changePublicLiveAvailabilityStatus,
    setChangePublicLiveAvailabilityStatus,
  ] = useState(astrologer?.guru?.liveStatus);

  const [
    changePrivateLiveAvailabilityStatus,
    setChangePrivateLiveAvailabilityStatus,
  ] = useState(astrologer?.guru?.liveCallAvailability?.private);
  const [changeChatAvailabilityStatus, setChangeChatAvailabilityStatus] =
    useState(true);
  const [heading, setHeading] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const toggleButton = [
    {
      id: "1",
      heading: "Call",
      backgroundColor: "#C6F9BF",
      mainColor: "#15AD00",
      icon: Call,
      offIcon: callOff,
      price: astrologer?.guru?.fee,
      internationalPrice: astrologer?.guru?.internationalFee,
      time: "10-12-2023, 10 : 30 AM",
      status: astrologer?.guru?.callAvailability === "online" ? true : false,
    },
    // {
    //   id: "2",
    //   heading: "Chat",
    //   backgroundColor: "#AAF3FC",
    //   mainColor: "#2A9AA8",
    //   icon: Chat,
    //   price: "35",
    //   internationalPrice: "2",
    //   time: "10-12-2023, 10 : 30 AM",
    //   // handleClick: () => {
    //   //   setChangeChatAvailabilityStatus(!changeChatAvailabilityStatus);
    //   // }
    // },
    {
      id: "3",
      heading: "Live",
      backgroundColor: "#F2D7E0",
      mainColor: "#EE4D4D",
      icon: LiveStreaming,
      offIcon: liveStreamingOff,
      price: astrologer?.guru?.live?.public?.national?.callfee,
      internationalPrice:
        astrologer?.guru?.live?.public?.international?.callfee,
      time: "10-12-2023, 10 : 30 AM",
      status: astrologer?.guru?.liveStatus,
    },
    // {
    //   id: "4",
    //   heading: "Pvt Live",
    //   backgroundColor: "#AAF3FC",
    //   mainColor: "#2A9AA8",
    //   icon: LiveStreaming,
    //   offIcon: chatOff,
    //   price: "35",
    //   internationalPrice: "2",
    //   time: "10-12-2023, 10 : 30 AM",
    //   status: astrologer?.guru?.liveCallAvailability?.private,
    // },
  ];
  useEffect(() => {
    // Update the availability status when the component initially renders
    if (astrologer?.guru?.callAvailability !== undefined) {
      setChangeCallAvailabilityStatus(astrologer?.guru?.callAvailability);
    }
  }, [astrologer?.guru?.callAvailability]);

  const handleButtonClick = async (title, isChecked) => {
    setIsLoading(true);

    // Call the respective API here
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };

    if (title === "Call" && isChecked === false) {
      try {
        const res = await axios.post(
          P_CHANGE_CALLS_AVAILABILITY,
          {
            callAvailability: "online",
          },
          config
        );
        //
        await dispatch(fetchLoggedInUserDetails(loginToken));
        setIsLoading(false);
      } catch (error) {
        //
      }
    } else if (title === "Call" && isChecked === true) {
      try {
        await axios.post(
          P_CHANGE_CALLS_AVAILABILITY,
          {
            callAvailability: "offline",
          },
          config
        );
        await axios.put(`https://data.gurucool.life/api/v1/guru/enableQueue?enableQueue=${false}&gid=${astrologer?.guru?.gid}`,{},config);
        await dispatch(fetchLoggedInUserDetails(loginToken));
        setIsLoading(false);
       
      } catch (error) {}
    } else if (title === "Chat") {
      // todo while working for chat
    } else if (title === "Live") {
      try {
        await axios.post(
          P_CHANGE_CALLS_AVAILABILITY,
          {
            liveStatus: !isChecked,
          },
          config
        );
        if (!isChecked) {
          window.location.href = `/live-streaming/${astrologer?.user?.firstName}${astrologer?.user?.lastName}?id=${astrologer?.user?.guru}`;
        } else {
          await dispatch(fetchLoggedInUserDetails(loginToken));
          setIsLoading(false);
        }
      } catch (error) {}
    } else if (title === "Pvt Live") {
      try {
        await axios.post(
          P_CHANGE_CALLS_AVAILABILITY,
          {
            liveCallAvailability: {
              private: !isChecked,
            },
          },
          config
        );
        await dispatch(fetchLoggedInUserDetails(loginToken));
        setIsLoading(false);
      } catch (error) {}
    }
  };

  const handleClick = (heading) => {
    if (heading === "Call") {
      setHeading("Call");
      setChangeCallAvailabilityStatus(!changeCallAvailabilityStatus);
    } else if (heading === "Chat") {
      setHeading("Chat");
      setChangeChatAvailabilityStatus(!changeChatAvailabilityStatus);
    } else if (heading === "Live") {
      setHeading("Live");
      setChangePublicLiveAvailabilityStatus(
        !changePublicLiveAvailabilityStatus
      );
    }
  };

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  return (
    <>
      <Container maxW={"6xl"}>
        <Box
          display={"flex"}
          // bg={"#FEF1E6"}
          bg={{ base: "none", md: "none", lg: "#FEF1E6" }}
          h={{ base: "auto", md: "auto", lg: "206px" }}
          borderRadius="12px"
          // w="81%"
          mt="20px"
          justifyContent="center"
          alignItems="center"
          gap="16px"
          flexDirection={{ base: "column", md: "column", lg: "row" }}
          px={"10px"}
        >
          {/* <Callqueuetoggle astrologer={astrologer} /> */}
          {toggleButton &&
            toggleButton.map((toggleButton,index) => {
              
              // <Flex
              //   key={toggleButton.id}
              //   h={{ base: "auto", md: "auto", lg: "144px" }}
              //   w={{ base: "360px", md: "372px", lg: "365px" }}
              //   bgColor={
              //     toggleButton.status ? toggleButton.backgroundColor : "#C8C8C8"
              //   }
              //   borderRadius="12px"
              //   justifyContent={"space-between"}
              //   p={2}
              // >
              //   <VStack
              //     w={{ base: "auto", md: "auto", lg: "auto" }}
              //     // mt={"5px"}
              //     // mb={"5px"}
              //     // ml={"5px"}
              //   >
              //     <Flex
              //       h={"100%"}
              //       direction="column"
              //       alignItems={{
              //         base: "flex-start",
              //         md: "flex-start",
              //         lg: "flex-start",
              //       }}
              //       justifyContent="space-between"
              //     >
              //       <Box
              //         mb={{ base: "auto", md: "auto", lg: "0" }}
              //         display={"flex"}
              //         // flexDirection={"column"}
              //         alignItems={"center"}
              //         justifyContent={"center"}
              //       >
              //         <Image
              //           src={
              //             toggleButton.status
              //               ? toggleButton.icon
              //               : toggleButton.offIcon
              //           }
              //         />
              //         <Heading
              //           color={
              //             toggleButton.status
              //               ? toggleButton.mainColor
              //               : "#3A3A3A"
              //           }
              //           size={"sm"}
              //           marginBottom={0}
              //         >
              //           {toggleButton.heading}
              //         </Heading>
              //         {toggleButton.heading === "Live" &&
              //           toggleButton.status && (
              //             <>
              //               <Button
              //                 ml="20px"
              //                 border={
              //                   toggleButton.status
              //                     ? "2px solid #2764FF"
              //                     : "2px solid #5A5A5A"
              //                 }
              //                 borderRadius="4px"
              //                 color={
              //                   toggleButton.status ? "#2764FF" : "#5A5A5A"
              //                 }
              //                 bg="transparent"
              //                 size="xs"
              //                 _hover={{
              //                   bg: "transparent",
              //                 }}
              //                 isDisabled={!toggleButton.status}
              //                 onClick={() =>
              //                   handleButtonClick(
              //                     toggleButton.heading,
              //                     toggleButton.status
              //                   )
              //                 }
              //               >
              //                 Re-Join
              //               </Button>
              //               {/* <Tooltip label="Search places" bg="red.600">
              //               <Icon as={HiOutlineExclamationCircle}></Icon>
              //             </Tooltip> */}
              //               {/* <Tooltip label="Hey">
              //               <Icon as={HiOutlineExclamationCircle} />
              //             </Tooltip> */}
              //               <Tooltip
              //                 hasArrow
              //                 label="You can enter the Live again from here"
              //                 bg="green.300"
              //                 color="white"
              //                 display={toggleButton.status ? "block" : "none"}
              //               >
              //                 <WarningIcon ml={"8px"} color={"#5A5A5A"} />
              //               </Tooltip>
              //             </>
              //           )}
              //       </Box>
              //       <Box>
              //         <Flex
              //           direction="column"
              //           alignItems={{
              //             base: "flex-start",
              //             md: "flex-start",
              //             lg: "flex-start",
              //           }}
              //           w={"max"}
              //         >
              //           <Text
              //             size="sm"
              //             color={toggleButton.status ? "#636363" : "#5A5A5A"}
              //             mb={"0"}
              //           >
              //             Next Online Timing
              //           </Text>
              //           <Text
              //             fontSize={{ base: "14px", md: "16px" }}
              //             fontWeight={"700"}
              //             color={
              //               toggleButton.status
              //                 ? toggleButton.mainColor
              //                 : "#3A3A3A"
              //             }
              //             marginBottom={0}
              //           >
              //             {toggleButton.time}
              //           </Text>
              //         </Flex>
              //       </Box>
              //     </Flex>
              //   </VStack>

              //   <VStack
              //     // w={{ base: "100%", md: "100%", lg: "50%" }}
              //     display={"flex"}
              //     alignItems={"end"}
              //     // mr={"10px"}
              //   >
              //     <Flex
              //       flexDirection={"column"}
              //       justifyContent="space-around"
              //       h={"100%"}
              //     >
              //       <Box>
              //         <Flex flexDirection={"column"}>
              //           <Text
              //             fontSize={{ base: "11px", md: "14px" }}
              //             color={toggleButton.status ? "#636363" : "#5A5A5A"}
              //             marginBottom={0}
              //             textAlign={"center"}
              //           >
              //             Price
              //           </Text>
              //           <Text
              //             fontSize={{ md: "18px", base: "16px" }}
              //             fontWeight={"700"}
              //             textAlign={"right"}
              //             color={
              //               toggleButton.status
              //                 ? toggleButton.mainColor
              //                 : "#3A3A3A"
              //             }
              //             marginBottom={0}
              //           >
              //             ₹{toggleButton.price}/Min
              //           </Text>
              //           <Text
              //             fontSize={{ md: "18px", base: "16px" }}
              //             fontWeight={"700"}
              //             textAlign={"right"}
              //             color={
              //               toggleButton.status
              //                 ? toggleButton.mainColor
              //                 : "#3A3A3A"
              //             }
              //             marginBottom={0}
              //           >
              //             ${toggleButton.internationalPrice}/Min
              //           </Text>
              //         </Flex>
              //       </Box>
              //       <Stack>
              //         <Box alignSelf={"end"}>
              //           {isLoading ? (
              //             <Spinner />
              //           ) : (
              //             <Switch
              //               isChecked={toggleButton?.status}
              //               onChange={() =>
              //                 handleButtonClick(
              //                   toggleButton?.heading,
              //                   toggleButton?.status
              //                 )
              //               }
              //               colorScheme="teal"
              //               size="lg"
              //             />
              //           )}

              //           {/* <Button
              //             size={"sm"}
              //             onClick={() =>
              //               handleButtonClick(
              //                 toggleButton.heading,
              //                 toggleButton.status
              //               )
              //             }
              //             border={
              //               toggleButton.status ? "1px solid #15AD00" : "none"
              //             }
              //             color={toggleButton.status ? "#616161" : "#15AD00"}
              //             bg={toggleButton.status ? "white" : "#B0FBA6"}
              //           >
              //             {toggleButton.status ? "Go Offline" : "Go Online"}
              //           </Button> */}
              //         </Box>
              //       </Stack>
              //     </Flex>
              //   </VStack>
              // </Flex>
              if(index===0){
                return (
                  <>
                <Flex
                  key={toggleButton.id}
                  h={{ base: "auto", md: "auto", lg: "144px" }}
                  w={{ base: "360px", md: "372px", lg: "365px" }}
                  bgColor={
                    toggleButton.status ? toggleButton.backgroundColor : "#C8C8C8"
                  }
                  borderRadius="12px"
                  justifyContent={"space-between"}
                  p={2}
                >
                  <VStack
                    w={{ base: "auto", md: "auto", lg: "auto" }}
                    // mt={"5px"}
                    // mb={"5px"}
                    // ml={"5px"}
                  >
                    <Flex
                      h={"100%"}
                      direction="column"
                      alignItems={{
                        base: "flex-start",
                        md: "flex-start",
                        lg: "flex-start",
                      }}
                      justifyContent="space-between"
                    >
                      <Box
                        mb={{ base: "auto", md: "auto", lg: "0" }}
                        display={"flex"}
                        // flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Image
                          src={
                            toggleButton.status
                              ? toggleButton.icon
                              : toggleButton.offIcon
                          }
                        />
                        <Heading
                          color={
                            toggleButton.status
                              ? toggleButton.mainColor
                              : "#3A3A3A"
                          }
                          size={"sm"}
                          marginBottom={0}
                        >
                          {toggleButton.heading}
                        </Heading>
                        {toggleButton.heading === "Live" &&
                          toggleButton.status && (
                            <>
                              <Button
                                ml="20px"
                                border={
                                  toggleButton.status
                                    ? "2px solid #2764FF"
                                    : "2px solid #5A5A5A"
                                }
                                borderRadius="4px"
                                color={
                                  toggleButton.status ? "#2764FF" : "#5A5A5A"
                                }
                                bg="transparent"
                                size="xs"
                                _hover={{
                                  bg: "transparent",
                                }}
                                isDisabled={!toggleButton.status}
                                onClick={() =>
                                  handleButtonClick(
                                    toggleButton.heading,
                                    toggleButton.status
                                  )
                                }
                              >
                                Re-Join
                              </Button>
                              {/* <Tooltip label="Search places" bg="red.600">
                              <Icon as={HiOutlineExclamationCircle}></Icon>
                            </Tooltip> */}
                              {/* <Tooltip label="Hey">
                              <Icon as={HiOutlineExclamationCircle} />
                            </Tooltip> */}
                              <Tooltip
                                hasArrow
                                label="You can enter the Live again from here"
                                bg="green.300"
                                color="white"
                                display={toggleButton.status ? "block" : "none"}
                              >
                                <WarningIcon ml={"8px"} color={"#5A5A5A"} />
                              </Tooltip>
                            </>
                          )}
                      </Box>
                      <Box>
                        <Flex
                          direction="column"
                          alignItems={{
                            base: "flex-start",
                            md: "flex-start",
                            lg: "flex-start",
                          }}
                          w={"max"}
                        >
                          <Text
                            size="sm"
                            color={toggleButton.status ? "#636363" : "#5A5A5A"}
                            mb={"0"}
                          >
                            Next Online Timing
                          </Text>
                          <Text
                            fontSize={{ base: "14px", md: "16px" }}
                            fontWeight={"700"}
                            color={
                              toggleButton.status
                                ? toggleButton.mainColor
                                : "#3A3A3A"
                            }
                            marginBottom={0}
                          >
                            {toggleButton.time}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </VStack>
  
                  <VStack
                    // w={{ base: "100%", md: "100%", lg: "50%" }}
                    display={"flex"}
                    alignItems={"end"}
                    // mr={"10px"}
                  >
                    <Flex
                      flexDirection={"column"}
                      justifyContent="space-around"
                      h={"100%"}
                    >
                      <Box>
                        <Flex flexDirection={"column"}>
                          <Text
                            fontSize={{ base: "11px", md: "14px" }}
                            color={toggleButton.status ? "#636363" : "#5A5A5A"}
                            marginBottom={0}
                            textAlign={"center"}
                          >
                            Price
                          </Text>
                          <Text
                            fontSize={{ md: "18px", base: "16px" }}
                            fontWeight={"700"}
                            textAlign={"right"}
                            color={
                              toggleButton.status
                                ? toggleButton.mainColor
                                : "#3A3A3A"
                            }
                            marginBottom={0}
                          >
                            ₹{toggleButton.price}/Min
                          </Text>
                          <Text
                            fontSize={{ md: "18px", base: "16px" }}
                            fontWeight={"700"}
                            textAlign={"right"}
                            color={
                              toggleButton.status
                                ? toggleButton.mainColor
                                : "#3A3A3A"
                            }
                            marginBottom={0}
                          >
                            ${toggleButton.internationalPrice}/Min
                          </Text>
                        </Flex>
                      </Box>
                      <Stack>
                        <Box alignSelf={"end"}>
                          {isLoading ? (
                            <Spinner />
                          ) : (
                            <Switch
                              isChecked={toggleButton?.status}
                              onChange={() =>
                                handleButtonClick(
                                  toggleButton?.heading,
                                  toggleButton?.status
                                )
                              }
                              colorScheme="teal"
                              size="lg"
                            />
                          )}
  
                          {/* <Button
                            size={"sm"}
                            onClick={() =>
                              handleButtonClick(
                                toggleButton.heading,
                                toggleButton.status
                              )
                            }
                            border={
                              toggleButton.status ? "1px solid #15AD00" : "none"
                            }
                            color={toggleButton.status ? "#616161" : "#15AD00"}
                            bg={toggleButton.status ? "white" : "#B0FBA6"}
                          >
                            {toggleButton.status ? "Go Offline" : "Go Online"}
                          </Button> */}
                        </Box>
                      </Stack>
                    </Flex>
                  </VStack>
                  {/* <Callqueuetoggle astrologer={astrologer} /> */}
                </Flex>
                <Callqueuetoggle astrologer={astrologer} />
                </>
                );
              }
              else{
                return (
                <Flex
                  key={toggleButton.id}
                  h={{ base: "auto", md: "auto", lg: "144px" }}
                  w={{ base: "360px", md: "372px", lg: "365px" }}
                  bgColor={
                    toggleButton.status ? toggleButton.backgroundColor : "#C8C8C8"
                  }
                  borderRadius="12px"
                  justifyContent={"space-between"}
                  p={2}
                >
                  <VStack
                    w={{ base: "auto", md: "auto", lg: "auto" }}
                    // mt={"5px"}
                    // mb={"5px"}
                    // ml={"5px"}
                  >
                    <Flex
                      h={"100%"}
                      direction="column"
                      alignItems={{
                        base: "flex-start",
                        md: "flex-start",
                        lg: "flex-start",
                      }}
                      justifyContent="space-between"
                    >
                      <Box
                        mb={{ base: "auto", md: "auto", lg: "0" }}
                        display={"flex"}
                        // flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Image
                          src={
                            toggleButton.status
                              ? toggleButton.icon
                              : toggleButton.offIcon
                          }
                        />
                        <Heading
                          color={
                            toggleButton.status
                              ? toggleButton.mainColor
                              : "#3A3A3A"
                          }
                          size={"sm"}
                          marginBottom={0}
                        >
                          {toggleButton.heading}
                        </Heading>
                        {toggleButton.heading === "Live" &&
                          toggleButton.status && (
                            <>
                              <Button
                                ml="20px"
                                border={
                                  toggleButton.status
                                    ? "2px solid #2764FF"
                                    : "2px solid #5A5A5A"
                                }
                                borderRadius="4px"
                                color={
                                  toggleButton.status ? "#2764FF" : "#5A5A5A"
                                }
                                bg="transparent"
                                size="xs"
                                _hover={{
                                  bg: "transparent",
                                }}
                                isDisabled={!toggleButton.status}
                                onClick={() =>
                                  handleButtonClick(
                                    toggleButton.heading,
                                    toggleButton.status
                                  )
                                }
                              >
                                Re-Join
                              </Button>
                              {/* <Tooltip label="Search places" bg="red.600">
                              <Icon as={HiOutlineExclamationCircle}></Icon>
                            </Tooltip> */}
                              {/* <Tooltip label="Hey">
                              <Icon as={HiOutlineExclamationCircle} />
                            </Tooltip> */}
                              <Tooltip
                                hasArrow
                                label="You can enter the Live again from here"
                                bg="green.300"
                                color="white"
                                display={toggleButton.status ? "block" : "none"}
                              >
                                <WarningIcon ml={"8px"} color={"#5A5A5A"} />
                              </Tooltip>
                            </>
                          )}
                      </Box>
                      <Box>
                        <Flex
                          direction="column"
                          alignItems={{
                            base: "flex-start",
                            md: "flex-start",
                            lg: "flex-start",
                          }}
                          w={"max"}
                        >
                          <Text
                            size="sm"
                            color={toggleButton.status ? "#636363" : "#5A5A5A"}
                            mb={"0"}
                          >
                            Next Online Timing
                          </Text>
                          <Text
                            fontSize={{ base: "14px", md: "16px" }}
                            fontWeight={"700"}
                            color={
                              toggleButton.status
                                ? toggleButton.mainColor
                                : "#3A3A3A"
                            }
                            marginBottom={0}
                          >
                            {toggleButton.time}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </VStack>
  
                  <VStack
                    // w={{ base: "100%", md: "100%", lg: "50%" }}
                    display={"flex"}
                    alignItems={"end"}
                    // mr={"10px"}
                  >
                    <Flex
                      flexDirection={"column"}
                      justifyContent="space-around"
                      h={"100%"}
                    >
                      <Box>
                        <Flex flexDirection={"column"}>
                          <Text
                            fontSize={{ base: "11px", md: "14px" }}
                            color={toggleButton.status ? "#636363" : "#5A5A5A"}
                            marginBottom={0}
                            textAlign={"center"}
                          >
                            Price
                          </Text>
                          <Text
                            fontSize={{ md: "18px", base: "16px" }}
                            fontWeight={"700"}
                            textAlign={"right"}
                            color={
                              toggleButton.status
                                ? toggleButton.mainColor
                                : "#3A3A3A"
                            }
                            marginBottom={0}
                          >
                            ₹{toggleButton.price}/Min
                          </Text>
                          <Text
                            fontSize={{ md: "18px", base: "16px" }}
                            fontWeight={"700"}
                            textAlign={"right"}
                            color={
                              toggleButton.status
                                ? toggleButton.mainColor
                                : "#3A3A3A"
                            }
                            marginBottom={0}
                          >
                            ${toggleButton.internationalPrice}/Min
                          </Text>
                        </Flex>
                      </Box>
                      <Stack>
                        <Box alignSelf={"end"}>
                          {isLoading ? (
                            <Spinner />
                          ) : (
                            <Switch
                              isChecked={toggleButton?.status}
                              onChange={() =>
                                handleButtonClick(
                                  toggleButton?.heading,
                                  toggleButton?.status
                                )
                              }
                              colorScheme="teal"
                              size="lg"
                            />
                          )}
  
                          {/* <Button
                            size={"sm"}
                            onClick={() =>
                              handleButtonClick(
                                toggleButton.heading,
                                toggleButton.status
                              )
                            }
                            border={
                              toggleButton.status ? "1px solid #15AD00" : "none"
                            }
                            color={toggleButton.status ? "#616161" : "#15AD00"}
                            bg={toggleButton.status ? "white" : "#B0FBA6"}
                          >
                            {toggleButton.status ? "Go Offline" : "Go Online"}
                          </Button> */}
                        </Box>
                      </Stack>
                    </Flex>
                  </VStack>
                </Flex>);
              }
            })}
          <Box>
          {/* <Callqueuetoggle astrologer={astrologer} /> */}
          </Box>
        </Box>
        
        {/* <Callqueuetoggle/> */}
      </Container>
    </>
  );
};

export default Navigator;
