import React from "react";
// import {
//   // Box,
//   // SimpleGrid,
//   // VStack,
//   // Text,
//   // IconButton,
//   useBreakpointValue,
//   // useDisclosure,
//   // Switch,
//   // HStack,
// } from "@chakra-ui/react";
// import { PhoneIcon, 
//   // ChatIcon,
//    ViewIcon, LockIcon } from "@chakra-ui/icons";
// import axios from "axios";
// import { P_CHANGE_CALLS_AVAILABILITY } from "../../apiLinks";
import Dashboard from "./AstrologerProfileDashboard/Dashboard";
import { Route, Routes } from "react-router";
import Edit from "./AstrologerProfileDashboard/Edit";

const AstrologerProfile = (userDetails) => {
  //
  const astrologer = userDetails?.userDetails; 
  //
  // const cards = [
  //   {
  //     title: "Call",
  //     icon: <PhoneIcon />,
  //     indianPrice: astrologer?.guru?.fee,
  //     internationalPrice: astrologer?.guru?.internationalFee,
  //     isChecked: astrologer.guru.callAvailability === "offline" ? false : true,
  //   },
  //   // {
  //   //   title: "Chat",
  //   //   icon: <ChatIcon />,
  //   //   indianPrice: astrologer?.guru?.fee,
  //   //   internationalPrice: astrologer?.guru?.internationalFee,
  //   //   isChecked:: false,
  //   // },
  //   {
  //     title: "Live Streaming",
  //     icon: <ViewIcon />,
  //     indianPrice: astrologer?.guru?.live.public.national.callfee,
  //     internationalPrice: astrologer?.guru?.live.public.international.callfee,
  //     isChecked: astrologer.guru.liveStatus,
  //   },
  //   {
  //     title: "Private Video Call",
  //     icon: <LockIcon />,
  //     indianPrice: astrologer?.guru?.live.private.national.callfee,
  //     internationalPrice: astrologer?.guru?.live.private.international.callfee,
  //     isChecked: astrologer.guru.liveCallAvailability.private,
  //   },
  // ];
  // const columns = useBreakpointValue({ base: 2, md: 4 });
  //

  // const handleButtonClick = async (title, isChecked) => {
  //  
  //   // Call the respective API here
  //   const loginToken = localStorage.getItem("loginToken");
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${loginToken}`,
  //     },
  //   };


  //   if (title === "Call") {
  //     try {
  //       await axios.post(
  //         P_CHANGE_CALLS_AVAILABILITY(),
  //         {
  //           callAvailability: "online",
  //         },
  //         config
  //       );
  //       window.location.reload();
  //     } catch (error) {
  //      
  //     }
  //   } else if (title === "Chat") {
  //     // todo while working for chat
  //   } else if ("Live Streaming") {
  //     try {
  //       await axios.post(
  //         P_CHANGE_CALLS_AVAILABILITY(),
  //         {
  //           liveStatus: isChecked,
  //         },
  //         config
  //       );
  //       window.location.reload();
  //     } catch (error) {
  //      
  //     }
  //   } else if ("Private Video Call") {
  //     try {
  //       await axios.post(
  //         P_CHANGE_CALLS_AVAILABILITY(),
  //         {
  //           liveCallAvailability: {
  //             private: isChecked,
  //           },
  //         },
  //         config
  //       );
  //       window.location.reload();
  //     } catch (error) {
  //      
  //     }
  //   }
  // };
  return (
    <>    
    <Dashboard astrologer={astrologer}  /> 
       
    </>

  );
};

export default AstrologerProfile;
