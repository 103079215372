import { Container, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import Divider from "./ChatComponents/Divider";
import Footer from "./ChatComponents/Footer";
import Header from "./ChatComponents/Header";
import Messages from "./ChatComponents/Messages";
import BackgroundImg from "../../Blog/Asset/BackgroundImg.svg";

const Chat = () => {
  const [messages, setMessages] = useState([
    { from: "computer", text: "Hi, My Name is HoneyChat" },
    { from: "me", text: "Hey there" },
    { from: "me", text: "Myself Ferin Patel" },
    {
      from: "computer",
      text: "Nice to meet you. You can send me message and i'll reply you with same message.",
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");

  const handleSendMessage = () => {
    if (!inputMessage.trim().length) {
      return;
    }
    const data = inputMessage;

    setMessages((old) => [...old, { from: "me", text: data }]);
    setInputMessage("");

    setTimeout(() => {
      setMessages((old) => [...old, { from: "computer", text: data }]);
    }, 1000);
  };

  return (
    <Container maxW={"6xl"} px={0} bgImage={BackgroundImg}>
      <Flex w="100%" h="100vh" justify="center" align="center">
        <Flex w={["100%", "100%", "100%"]} h="100%" flexDir="column">
          <Header />
          {/* <Divider /> */}
          <Messages messages={messages} />
          {/* <Divider /> */}
          <Footer
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
          />
        </Flex>
      </Flex>
    </Container>
  );
};

export default Chat;
