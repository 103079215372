import React, { useEffect, useRef, useState } from "react";
import {
  Flex,
  Input,
  Button,
  IconButton,
  Icon,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  HStack,
  Box,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Heading,
  RadioGroup,
  Radio,
  color,
  Container,
  Textarea,
} from "@chakra-ui/react";
import "./customStyle.css";
import { AddIcon, ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import { IoSend } from "react-icons/io5";
import { BsFillMicFill } from "react-icons/bs";
import { MdCake } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { IoMdPhotos } from "react-icons/io";
// import { PiChatsFill } from "react-icons/pi";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { TbBowl } from "react-icons/tb";
import Remedy from "../../../../assets/Remedy.svg";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Footer = ({ inputMessage, setInputMessage, handleSendMessage }) => {
  let userDetails = useSelector((state) => state.loggedInUserDetails.data);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isIcon, setIsicon] = useState(false);

  const handleChange = () => {
    setIsicon(!isIcon);
    //   console.log("..................",isIcon);
  };

  // console.log("isMenuOpened", isMenuOpen);

  const menuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const btnRef = useRef();
  const mode = "audio";
  const role = "user";

  const [name, setName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [birthtime, setBirthtime] = useState("");
  const [gender, setGender] = useState("");
  const [birthplace, setBirthplace] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle the form submission and generate the birth chart here
    // For this example, we'll just display the collected data

    // console.log("Name:", name);
    // console.log("Birthdate:", birthdate);
    // console.log("Birthtime:", birthtime);
    // console.log("Gender:", gender);
    // console.log("Birthplace:", birthplace);

    // console.log("onClick", onClick);
  };

  // let userDetails = useSelector((state) => state.loggedInUserDetails.data);
  const [form, setForm] = useState(false);
  const handleForm = () => {
    setForm(!form);
  };

  // code for show gallery
  // const IoMdPhotos = () => {
  //   const [showGallery, setShowGallery] = useState(false);
  // };

  return (
    <>
      <Modal
        size={{ md: "md", base: "xs" }}
        isOpen={isOpen1}
        onClose={onClose1}
        isCentered
      >
        <ModalOverlay />


        <ModalContent>
          {/* <HStack justifyContent={"space-between"}></HStack> */}
          {/* <HStack justifyContent={"space-between"}></HStack> */}
          <ModalHeader
            textAlign={"center"}
            fontSize={{ base: "18px" }}
            fontWeight={"500"}
            pb={0}
          >
            Select BirthChart
          </ModalHeader>
          <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
          <ModalCloseButton />
          <Icon
            as={ArrowBackIcon}
            onClick={handleForm}
            position={"absolute"}
            left={3}
            top={4}
          />
          {form ? (
            <ModalBody>
              <Box maxW="md" mx="auto" p={4}>
                <form>
                  <FormControl mb={4}>
                    {/* <FormLabel>Name</FormLabel> */}
                    <Input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder=" Name"
                      textColor={"#767676"}
                      // border={"orange.300"}
                      // color="gray"
                      bg={"#F5F5F5"}
                      _focus={{ borderColor: "#FF4C00" }} // Border color when input is focused
                      _hover={{ borderColor: "#FF4C00" }} // Border color on hover
                      borderColor="#F5F5F5" // Default border color
                      borderRadius={"4px"}
                    />
                  </FormControl>

                  <FormControl mb={4}>
                    <FormLabel>Birthdate</FormLabel>
                    <InputGroup>
                      <Input
                        type="date"
                        value={birthdate}
                        onChange={(e) => setBirthdate(e.target.value)}
                        textColor={"#767676"}
                        bg={"#F5F5F5"}
                        _focus={{ borderColor: "#FF4C00" }} // Border color when input is focused
                        _hover={{ borderColor: "#FF4C00" }} // Border color on hover
                        borderColor="#F5F5F5" // Default border color
                        borderRadius={"4px"}
                        // placeholder="Birthdate"
                      />
                      {/* <InputRightElement pointerEvents="none">
                      <Icon as={FaCalendarAlt} color="#DD7323" />
                    </InputRightElement> */}
                    </InputGroup>
                  </FormControl>

                  <FormControl mb={4}>
                    <FormLabel>Birth Time</FormLabel>
                    <InputGroup>
                      <Input
                        type="time"
                        value={birthtime}
                        onChange={(e) => setBirthtime(e.target.value)}
                        textColor={"#767676"}
                        bg={"#F5F5F5"}
                        _focus={{ borderColor: "#FF4C00" }} // Border color when input is focused
                        _hover={{ borderColor: "#FF4C00" }} // Border color on hover
                        borderColor="#F5F5F5" // Default border color
                        borderRadius={"4px"}
                      />
                      {/* <InputRightElement pointerEvents="none">
                      <Icon as={FaClock} color="#DD7323" />
                    </InputRightElement> */}
                    </InputGroup>
                  </FormControl>

                  <FormControl mb={4}>
                    {/* <FormLabel>Gender</FormLabel> */}
                    <Select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      placeholder=" Gender"
                      textColor={"#767676"}
                      bg={"#F5F5F5"}
                      _focus={{ borderColor: "#FF4C00" }} // Border color when input is focused
                      _hover={{ borderColor: "#FF4C00" }} // Border color on hover
                      borderColor="#F5F5F5" // Default border color
                      borderRadius={"4px"}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Select>
                  </FormControl>

                  <FormControl mb={4}>
                    {/* <FormLabel>Birthplace</FormLabel> */}
                    <Input
                      type="text"
                      value={birthplace}
                      onChange={(e) => setBirthplace(e.target.value)}
                      placeholder=" Birthplace"
                      textColor={"#767676"}
                      bg={"#F5F5F5"}
                      _focus={{ borderColor: "#FF4C00" }} // Border color when input is focused
                      _hover={{ borderColor: "#FF4C00" }} // Border color on hover
                      borderColor="#F5F5F5" // Default border color
                      borderRadius={"4px"}
                    />
                  </FormControl>

                  {/* <Button type="submit" colorScheme="#FF4C00" >
          Send
        </Button> */}
                </form>
              </Box>
            </ModalBody>
          ) : (
            <ModalBody>
              <RadioGroup defaultValue="2">
                <Stack spacing={5} direction="column" id="radioIconRight">
                  <Radio colorScheme="orange" value="Cameron Williamson">
                    Cameron Williamson
                  </Radio>
                  <Radio colorScheme="orange" value="Bessie Cooper">
                    Bessie Cooper
                  </Radio>
                  <Radio colorScheme="orange" value="Kristin Watson">
                    Kristin Watson
                  </Radio>
                  <Radio colorScheme="orange" value="Guy Hawkins">
                    Guy Hawkins
                  </Radio>
                </Stack>
              </RadioGroup>
            </ModalBody>
          )}

          <Divider w={"90%"} mx={"auto"} my={0} color={"#E8E8E8"} />
          <ModalFooter justifyContent={"space-between"}>
            <IconButton
              bg={"#FF4C00"}
              aria-label="Add birthChart"
              size="md"
              icon={<AddIcon color={"white"} />}
              onClick={handleForm}
              display={form ? "none" : "inline-flex"}
            />
            <Button
              variant="solid"
              bg={"#FF4C00"}
              color={"white"}
              w={form ? "100%" : "90px"}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size={{ md: "md", base: "xs" }}
        isOpen={isOpen2}
        onClose={onClose2}
        isCentered
      >
        <ModalOverlay />
        <ModalContent height={"fit-content"} >
          <ModalHeader textAlign={"center"}>Select a Questions</ModalHeader>
          <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
          <ModalCloseButton />
          <Box maxHeight="400px" overflowY="auto">
            <ModalBody
              color={"#000"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={400}
              _hover={{ backgroundColor: "#f5f5f5", cursor: "pointer" }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
              nobis!
            </ModalBody>
            <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
            <ModalBody
              color={"#000"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={400}
              _hover={{ backgroundColor: "#f5f5f5", cursor: "pointer" }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
              nobis!
            </ModalBody>
            <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
            <ModalBody
              color={"#000"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={400}
              _hover={{ backgroundColor: "#f5f5f5", cursor: "pointer" }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
              nobis!
            </ModalBody>
            <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
            <ModalBody
              color={"#000"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={400}
              _hover={{ backgroundColor: "#f5f5f5", cursor: "pointer" }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
              nobis!
            </ModalBody>
            <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
            <ModalBody
              color={"#000"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={400}
              _hover={{ backgroundColor: "#f5f5f5", cursor: "pointer" }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
              nobis!
            </ModalBody>
            <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
            <ModalBody
              color={"#000"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={400}
              _hover={{ backgroundColor: "#f5f5f5", cursor: "pointer" }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
              nobis!
            </ModalBody>
            <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
            <ModalBody
              color={"#000"}
              fontSize={{ base: "14px", md: "18px" }}
              fontWeight={400}
              _hover={{ backgroundColor: "#f5f5f5", cursor: "pointer" }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
              nobis!
            </ModalBody>
            <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
          </Box>
        </ModalContent>
      </Modal>

      {/* Remedies code */}

      <Modal
        size={{ md: "md", base: "xs" }}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Remedies</ModalHeader>
          <Divider w={"90%"} mx={"auto"} mt={0} color={"#E8E8E8"} />
          <ModalCloseButton />
          <ModalBody>
            <Text>Suggest Remedies</Text>
            <Textarea placeholder='Here is a sample placeholder' />
          </ModalBody>
          <ModalFooter>
          <Button variant="ghost" textColor={"#FF4C00"} fontSize={{md:"18px",base:"14px"}} fontWeight={500} >Cancle</Button>
            <Button bg="#FF4C00" mr={2} onClick={onClose} textColor={"#fff"} p={{base:"6px 20px",md:"10px 36px"}} fontSize={{md:"18px",base:"14px"}} fontWeight={500}>
             Save
            </Button>
           
          </ModalFooter>
        </ModalContent>

        {/* birthchart form */}
      </Modal>

      <Flex
        mt="5"
        h={{ md: "136px", base: "64px" }}
        p={{ md: "22px", base: "12.5px" }}
        bg={"#F0F0F0"}
        alignItems={{ md: "flex-start", base: "center" }}
        justifyContent={"center"}
      >
        <Flex w="100%" alignItems={"center"}>
          <>
            <Menu direction="bottom">
              <MenuButton
                as={Button}
                _hover={{ bg: "transparent" }}
                p={{ md: 3, base: 2 }}
                onClick={handleChange}
              >
                <Icon
                  as={isMenuOpen ? CloseIcon : AddIcon}
                  // as={AddIcon}
                  color={"#FF4C00"}
                  boxSize={{ md: "30px", base: "14px" }}
                  // ml={{ md: "127px", base: "16px" }}
                  ref={btnRef}
                  onClick={() => {
                    onOpen();
                    menuOpen();
                    setIsMenuOpen(!isMenuOpen);
                    // handleChange();
                  }}
                  transform={isIcon ? "rotate(45deg)" : "rotate(0)"}
                  transition="transform 0.3s ease-out" // Adjust the transition duration and easing as needed
                  _hover={{ cursor: "pointer" }}
                />
              </MenuButton>
              <MenuList
                border={"none"}
                boxShadow={"none"}
                bg={"transparent"}
                position="absolute"
                bottom={{ md: 24, base: 8 }}
              >
                {role === "astro" ? (
                  <>
                    <MenuItem
                      bg={"transparent"}
                      borderRadius={"8px"}
                      _hover={{ bg: "#FFEEE7" }}
                      onClick={onOpen1}
                    >
                      <HStack spacing={{ md: "16px", base: "9px" }}>
                        <Box
                          p={{ md: "11px 13px", base: "5px 10px" }}
                          borderRadius={"full"}
                          bg={"#FF6E31"}
                          onClick={onOpen1}
                        >
                          <Icon
                            as={MdCake}
                            boxSize={{ md: "23px", base: "15px" }}
                            color={"white"}
                          />
                        </Box>
                        <Text
                          mb={0}
                          fontSize={{ md: "20px", base: "14px" }}
                          fontWeight={"500"}
                          color={"#636363"}
                        >
                          Birth Chart
                        </Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem bg={"transparent"} borderRadius={"8px"}>
                      <HStack spacing={{ md: "16px", base: "9px" }}>
                        <Box
                          p={{ md: "11px 13px", base: "5px 10px" }}
                          borderRadius={"full"}
                          bg={"#FF6E31"}
                        >
                          <Icon
                            as={IoMdPhotos}
                            boxSize={{ md: "23px", base: "15px" }}
                            color={"white"}
                          />
                        </Box>
                        <Text
                          mb={0}
                          fontSize={{ md: "20px", base: "14px" }}
                          fontWeight={"500"}
                          color={"#636363"}
                          onClick={() => setShowGallery(!showGallery)}
                        >
                          Photos
                        </Text>
                      </HStack>
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      bg={"transparent"}
                      borderRadius={"8px"}
                      _hover={{ bg: "#FFEEE7" }}
                      onClick={onOpen2}
                    >
                      <HStack spacing={{ md: "16px", base: "9px" }}>
                        <Box
                          p={{ md: "11px 13px", base: "5px 10px" }}
                          borderRadius={"full"}
                          bg={"#FF6E31"}
                        >
                          <Icon
                            as={HiChatBubbleLeftRight}
                            boxSize={{ md: "23px", base: "15px" }}
                            color={"white"}
                          />
                        </Box>
                        <Text
                          mb={0}
                          fontSize={{ md: "20px", base: "14px" }}
                          fontWeight={"500"}
                          color={"#636363"}
                        >
                          Questions
                        </Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem
                      bg={"transparent"}
                      borderRadius={"8px"}
                      _hover={{ bg: "#FFEEE7" }}
                    >
                      <HStack spacing={{ md: "16px", base: "9px" }}>
                        <Box
                          p={{ md: "11px 13px", base: "5px 10px" }}
                          borderRadius={"full"}
                          bg={"#FF6E31"}
                        >
                          <Icon
                            as={IoMdPhotos}
                            boxSize={{ md: "23px", base: "15px" }}
                            color={"white"}
                          />
                        </Box>
                        <label for="fileInput">
                          <Text
                            mb={0}
                            fontSize={{ md: "20px", base: "14px" }}
                            fontWeight={"500"}
                            color={"#636363"}
                            _hover={{ bg: "#FFEEE7" }}
                          >
                            Photos
                          </Text>
                        </label>

                        <Input
                          id="fileInput"
                          type="file"
                          alignItems={"center"}
                          display={"none"}
                        />
                      </HStack>
                    </MenuItem>
                    <MenuItem
                      bg={"transparent"}
                      borderRadius={"8px"}
                      _hover={{ bg: "#FFEEE7" }}
                      onClick={onOpen2}
                    >
                      <HStack spacing={{ md: "16px", base: "9px" }}>
                        <Box
                          p={{ md: "11px 13px", base: "9px 10px" }}
                          borderRadius={"full"}
                          bg={"#FF6E31"}
                        >
                          <Image
                            src={Remedy}
                            boxSize={{ md: "23px", base: "15px" }}
                            color={"white"}
                          />
                        </Box>
                        <Text
                          mb={0}
                          fontSize={{ md: "20px", base: "14px" }}
                          fontWeight={"500"}
                          color={"#636363"}
                        >
                          Remedies
                        </Text>
                      </HStack>
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </>
          <Input
            placeholder="Text Here..."
            border="none"
            borderRadius="31px"
            h={{ md: "52px", base: "39px" }}
            bg={"#fff"}
            mx={{ md: "31px", base: "13px" }}
            fontSize={{ md: "18px" }}
            focusBorderColor="#FF4C00"
            _focus={{
              border: "1px solid black",
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
          {/* <Button
        bg="black"
        color="white"
        borderRadius="none"
        _hover={{
          bg: "white",
          color: "black",
          border: "1px solid black",
        }}
        disabled={inputMessage.trim().length <= 0}
        onClick={handleSendMessage}
      >
        Send
      </Button> */}

          {mode === "audio" ? (
            <IconButton
              bg={"white"}
              borderRadius={"full"}
              // boxSize={{md: "28px"}}
              aria-label="send chat"
              icon={<BsFillMicFill color="#FF4C00" />}
              // disabled={inputMessage.trim().length <= 0}
              // onClick={handleSendMessage}
            />
          ) : (
            <IconButton
              bg={"white"}
              borderRadius={"full"}
              // boxSize={{md: "28px"}}
              aria-label="send chat"
              icon={<IoSend color="#FF4C00" />}
              disabled={inputMessage.trim().length <= 0}
              onClick={handleSendMessage}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Footer;
