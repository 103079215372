import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {  
  // G_GET_ALL_GURUS, G_GET_ALL_PAYMENTS, G_GET_ALL_RAZORPAY_PAYMENTS, 
  G_GET_ALL_STRIPE_PAYMENTS
  // , G_GET_ALL_USERS 
} from '../apiLinks';

const initialState = {
  data: [],
  status: 'idle',
  error: null
};

export const fetchAllStripeRecharges = createAsyncThunk('allStripeRechargesData/fetchAllStripeRecharges', async (pageNumber) => {
  const loginToken = localStorage.getItem("loginToken");
  const config = {
    headers: {
      Authorization: `Bearer ${loginToken}`,
    },
  };
  const response = await axios.get(G_GET_ALL_STRIPE_PAYMENTS(pageNumber),config);
 
  return response.data.invoices;
});

const getAllStripeRechargesSlice = createSlice({
  name: 'allGurusData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStripeRecharges.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllStripeRecharges.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchAllStripeRecharges.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default getAllStripeRechargesSlice.reducer;
