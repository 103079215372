import React, { useEffect, useState } from "react";
import Hero from "./Hero/Hero";
import News from "./News/News";
import Trust from "./Trust/Trust";
import RecommendedAstrologer from "./RecommendedAstrologer/RecommendedAstrologer";
import UserReview from "./UserReview/UserReview";
import { homepageViewEvent } from "../../facebookEvents/pixelEvents";
import { useDispatch, useSelector } from "react-redux";
import logo_gif from "../../assets/loading.gif";
import { Flex, HStack, Image } from "@chakra-ui/react";
import UserTestimonials from "./AstroCard/UserTestimonials";
import Artigyan from "./ArtiGyan/Artigyan";
import Unification from "./Unification/Unification";
import NewHero from "./NewHero/NewHero";
import FreeLive from "./FreeLive/FreeLive";
import homepageAstrologers, {
  fetchHomepageAstrologers,
} from "../../store/homepageAstrologers";
import axios from "axios";
import { G_ALL_LIVE_ASTROLOGERS } from "../../apiLinks";
import { Helmet } from "react-helmet";
import PrimaryLoading from "../Loaders/PrimaryLoading";

const HomePage = () => {
  useEffect(() => {
    homepageViewEvent();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHomepageAstrologers());
  }, []);

  const status = useSelector((state) => state.homepageAstrologers.status);
  const [astrologerLive, setAstrologerLive] = useState(false);
  const [liveGurus, setLiveGurus] = useState();

  const getLiveAstrologers = async () => {
    try {
      const response = await axios.get(G_ALL_LIVE_ASTROLOGERS);
      //
      if (response.data.guru.length !== 0) {
        setLiveGurus(response.data.guru);
        setAstrologerLive(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLiveAstrologers();
  }, []);
  return (
    <>
      {status === "succeeded" ? (
        <>
          <NewHero />
          {astrologerLive && <FreeLive />}
          <RecommendedAstrologer />
          <Hero />
          <Artigyan />
          <Unification />
          <UserReview />
          <UserTestimonials />
        </>
      ) : (
        <PrimaryLoading />
      )}
    </>
  );
};

export default HomePage;
