import {
  Box,
  Heading,
  Text,
  // Button,
  Flex,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import paymentSuccess from "../../../assets/checkmark.gif";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase";
import { useEffect } from "react";

const PaymentSuccess = ({ amount, response, remainingTime }) => {
  const isInternational = localStorage.getItem("isInternational") === "true";
  useEffect(() => {
    if (response && response.orderId && response.userAmount && response.gst) {
      logEvent(analytics, "purchase", {
        transaction_id: response.orderId,
        value: response.userAmount,
        amount: response.userAmount + response.gst,
        tax: response.gst,
        currency: isInternational ? "USD" : "INR",
      });
      var facebookEvent = {
        transaction_id: response.orderId,
        value: response.userAmount,
        amount: response.userAmount + response.gst,
        tax: response.gst,
        currency: isInternational ? "USD" : "INR",
      };
      window.fbq("init", "647781774079058");
      window.fbq("track", "Purchase", facebookEvent);
    }
  }, [response]);

  return (
    <Box
      bg="white"
      w={{ base: "90%", sm: "80%", md: "50%" }}
      mx="auto"
      my={10}
      p={8}
      borderRadius="lg"
      boxShadow="0 0 20px rgba(0, 0, 0, 0.2)"
      textAlign="center"
    >
      <Flex color="red.500" fontSize="5xl" mb={5} justifyContent="center">
        <motion.img src={paymentSuccess} alt="Your GIF" />
      </Flex>
      <Heading size="md" mb={5}>
        Payment Successful
      </Heading>

      <Text fontSize={{ md: "24px", base: "20px" }} fontWeight={700} mb={5}>
        {response.userAmount} /-
      </Text>

      {/* <Text fontSize={{ md: "18px", base: "14px" }} mb={5}>
        Transaction ID : &nbsp;{response.orderId}
      </Text> */}

      <Text mb={5}>
        <span
          style={{ fontWeight: "normal" }}
          fontSize={{ md: "18px", base: "14px" }}
        >
          Transaction ID :
        </span>{" "}
        <span
          style={{ fontWeight: "bold" }}
          fontSize={{ md: "18px", base: "14px" }}
        >
          {response.orderId}
        </span>
      </Text>

      <Text mb={5} color="green.500" fontWeight={600}>
        Your payment has been successfully processed.
      </Text>

      <Text mb={6}>
        You will be redirected to the wallet page in {remainingTime} seconds...
      </Text>
    </Box>
  );
};

export default PaymentSuccess;
