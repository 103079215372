import React from "react";
import { Box, Container, Heading, Text } from "@chakra-ui/react";
import icon1 from "../image/Group.svg";
import icon2 from "../image/Group (1).svg";
import icon3 from "../image/Group (2).svg";
import icon4 from "../image/Group 52384.svg";
import icon5 from "../image/Group (3).svg";
import icon6 from "../image/Group (4).svg";
import icon7 from "../image/Group (5).svg";
import icon8 from "../image/Group (6).svg";
import icon9 from "../image/Group (7).svg";
import icon10 from "../image/Group (8).svg";
import icon11 from "../image/Group (9).svg";
import icon12 from "../image/Group (10).svg";
import Horoscopecard from "./Horoscopecard";

export default function Horosscope() {
  let objOfZodiac = [
    {
      icon: icon1,
      name: "ARIES",
      date: "05-June-2023",
    },
    {
      icon: icon2,
      name: "TAURUS",
      date: "05-June-2023",
    },
    {
      icon: icon3,
      name: "GEMINI",
      date: "05-June-2023",
    },
    {
      icon: icon4,
      name: "CANCER",
      date: "05-June-2023",
    },
    {
      icon: icon5,
      name: "LEO",
      date: "05-June-2023",
    },
    {
      icon: icon6,
      name: "VIRGO",
      date: "05-June-2023",
    },
    {
      icon: icon7,
      name: "LIBRA",
      date: "05-June-2023",
    },
    {
      icon: icon8,
      name: "SAGITARIUS",
      date: "05-June-2023",
    },
    {
      icon: icon9,
      name: "CAPRICORN",
      date: "05-June-2023",
    },
    {
      icon: icon10,
      name: "AQUARIUS",
      date: "05-June-2023",
    },
    {
      icon: icon11,
      name: "PISCES",
      date: "05-June-2023",
    },
    {
      icon: icon12,
      name: "SCORPIO",
      date: "05-June-2023",
    },
  ];

  return (
    <>
      <Container
        style={{
          // margin: "70px auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
         maxW={"900px"}
         mb={"20px"}
      >
     
        <Heading mb={{base:"2px",sm:"-12px"}}>Check Your Horoscope</Heading>
        <Text
          bg={""}
          color={"#545454"}
          width={{base:"80%",sm:"100%",md:"100%",lg:"100%",xl:"100%"}}
          style={{ textAlign: "center", padding: "8px", margin: "8px" }}
          display={{base:"none",md:"block"}}
        >
          Love Experts: Discover experts who specialize in matters of the heart.
          Unlock the secrets of love, find compatibility, and receive
          personalized guidance for a fulfilling romantic journey.{" "}
        </Text>
        <Box
          gridTemplateColumns={{
            base: "repeat(3,1fr)",
            sm: "repeat(3,1fr)", // 480px
            md: "repeat(4,1fr)", // 768px
            lg: "repeat(6,1fr)", // 992px
            xl: "repeat(6,1fr)", // 1280px
          }}
          maxW={"1120px"}
          style={{ display: "grid", width: "90vw", gap: "10px 10px",alignItems:"center",justifyContent:"center" }}
        >
          {objOfZodiac.map((obj, index) => (
            <Horoscopecard
              icon={obj.icon}
              name={obj.name}
              date={obj.date}
              key={index}
            />
          ))}
        </Box>
        
        </Container>
    </>
  );
}
