import { Image } from "@chakra-ui/image";
import { Box, Container, Divider, Flex } from "@chakra-ui/layout";

import React, { useEffect, useMemo, useState } from "react";
import offerbannerInd from "../../assets/banners/offerbannerInd.png";
import free from "../../assets/banner1-100.jpeg";
import free1 from "../../assets/banners/free1.jpg";
import free2 from "../../assets/banner1-100.jpeg";
import free3 from "../../assets/consultAstrologerBanner.jpeg";
import Specialities from "./Specialities";
import Love from "./SpecialityPages/Love";
import Marriage from "./SpecialityPages/Marriage";
import Career from "./SpecialityPages/Career";
import Business from "./SpecialityPages/Business";
import Life from "./SpecialityPages/Life";
import Health from "./SpecialityPages/Health";
import Tarot from "./SpecialityPages/Tarot";
import All from "./SpecialityPages/All";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchConsultAstrologers } from "../../store/consultAstrologerSlice";
import { Spinner } from "@chakra-ui/spinner";
import logo_gif from "../../assets/loading.gif";
import ReactPaginate from "react-paginate";
import { ButtonGroup, Button } from "@chakra-ui/react";
import PrimaryLoading from "../Loaders/PrimaryLoading";
import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";
import data from "../Utils/data";
const ConsultAstrologer = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.allGurusData.status);
  const [container, setContainer] = useState(true);
  const [skills, setSkills] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (location !== "/doctor") {
      setContainer(false);
    }
  }, [location]);

  // const data = useSelector((state) => state.consultAstrologers.data);
  const total = useSelector((state) => state.consultAstrologers.total);
  const users = useSelector((state) => state.loggedInUserDetails.data);
  const loginToken = localStorage.getItem("loginToken");

  //
  //   ">>>>>>>>>>>>>>>>>>>>>>>",
  //   users?.data?.userDetails?.StripeInvoice.length !== 0
  // );
  //

  const handleOptionChange = (selectedOption) => {
    // Do something with the selected option received from the child component
    //
    setSkills(selectedOption);
  };

  //
  return (
    <>
      <Helmet>
        <title>Gurucool || Consult Astrologer</title>
        <meta
          name="description"
          content="Get personalized astrology consultations from expert astrologers at GuruCool. Gain valuable insights and guidance for all areas of your life. Book a session now!"
        />
      </Helmet>
      {/* {status === "loading" ? (
       <PrimaryLoading/>
      ) : ( */}
      <Container maxW={container ? "7xl" : "full"}>
        <Container maxW={"7xl"}>
          {/* {loginToken ? (
              <Box
                display={
                  users?.userDetails?.StripeInvoice.length !== 0 ||
                  users?.userDetails?.paymentsDetails.length !== 0
                    ? "none"
                    : "flex"
                }
              >
                <Image src={free} />
              </Box>
            ) : (
              <Box>
                <Image src={free} />
              </Box>
            )} */}
          {/* {users.length === 0 ||
            users?.userDetails?.StripeInvoice.length !== 0 ||
            users?.userDetails?.paymentsDetails.length !== 0 ? (
              <Box>
                <Image src={free} />
              </Box>
            ) : null} */}
          {/* <Specialities handleOptionChange={handleOptionChange} /> */}
        </Container>
        <All DocData={data} />
      </Container>
      {/* )} */}
    </>
  );
};

export default ConsultAstrologer;
