import { Box, Container, Heading, Text } from "@chakra-ui/react";
import React from "react";

export default function Horoscopedescription() {
  return (
    <>
      <Container 
        bg={""}
        marginTop={"50px"}
        marginBottom={"50px"}  maxW={"6xl"}>
    
        <Heading textAlign={"center"}>
          WHY SHOULD YOU CHECK HOROSCOPE DAILY?
        </Heading>
        <Box>
          <Text textAlign={"center"} marginTop={"10px"} p={"0px 16px"} marginBottom={"12px"}>
            Love Experts: Discover experts who specialize in matters of the
            heart. Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey.
          </Text>

          <Text textAlign={"center"} marginBottom={"12px"}p={"0px 16px"}>
            Love Experts: Discover experts who specialize in matters of the
            heart. Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey.
          </Text>
          <Text textAlign={"center"} marginBottom={"12px"}p={"0px 16px"}>
            Love Experts: Discover experts who specialize in matters of the
            heart. Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey.
          </Text>

          <Text textAlign={"center"} marginBottom={"12px"} p={"0px 16px"}>
            Love Experts: Discover experts who specialize in matters of the
            heart. Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey. Love
            Experts: Discover experts who specialize in matters of the heart.
            Unlock the secrets of love, find compatibility, and receive
            personalized guidance for a fulfilling romantic journey.
          </Text>
        </Box>
    
        </Container>
    </>
  );
}
