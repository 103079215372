import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Textarea,
  useToast,
  Icon,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Sidebar from "../Sidebar/Sidebar";
import { fetchAllGurus } from "../../../store/getAllGurus";
// import LoggedInUserDetails, {
//     logInUserDetailsAction,
//   } from "../../store/LoggedInUserDetails";

import { logInUserDetailsAction } from "../../../store/LoggedInUserDetails";
import { loginSystemAction } from "../../../store/LoginSystemSlice";
import { G_GET_USER_PROFILE } from "../../../apiLinks";
import axios from "axios";
import { FaStar } from "react-icons/fa";

const AddFeedback = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.allGurusData.data);
  const [selectedName, setSelectedName] = useState("");
  const [selectedAstroId, setSelectedAstroId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [feedback, setFeedback] = useState("");

  const [rating, setRating] = useState(0);

  const [selectedOptions, setSelectedOptions] = useState([]);
  //   const navigate = useNavigate();

  const handleRatingChange = (value) => {
    setRating(value);
    setSelectedOptions([]);
  };

  const handleOptionChange = (selected) => {
    setSelectedOptions(selected);
  };

  const toast = useToast();

  useEffect(() => {
    dispatch(fetchAllGurus({ pageNumber: 0, perPage: 200 }));
  }, [dispatch]);

  const handleSelectChange = (selectedOption) => {
    setSelectedName(selectedOption.label);
    setSelectedAstroId(selectedOption.value);
  };

  const options = data.map((guru) => ({
    value: guru.user._id,
    label: `${guru.user.firstName} ${guru.user.lastName}`,
  }));
 

  const loginToken = localStorage.getItem("loginToken");
  useEffect(() => {
    if (loginToken) {
      dispatch(loginSystemAction.setUserLoggedIn(true));
      axios
        .get(G_GET_USER_PROFILE, {
          headers: { Authorization: `Bearer ${loginToken}` },
        })
        .then((response) => {
          dispatch(logInUserDetailsAction.setData(response.data));
          setSelectedUserId(response.data.user._id);
        });
    }
  }, [loginToken]);

  const handleSubmit = async (e) => {
    // Make the Axios POST call to your backend or API
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://data.gurucool.life/api/v1/guru/addFeedback",
        {
          user: selectedUserId,
          astrologer: selectedAstroId,
          rating: rating,
          bad: rating <= 2 ? selectedOptions.join(", ") : "",
          good: rating > 2 ? selectedOptions.join(", ") : "",
          badFeedback: rating <= 2 ? feedback : "",
          goodFeedback: rating > 2 ? feedback : "",
        }
      );
      //

      // Show a success toast message
      toast({
        title: "Feedback submitted",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });

      // Reset the state variables
      setRating(0);
      setSelectedOptions([]);
      setFeedback("");

      // Check if the response status is 200 and redirect to the home page
      //   if (response.status === 200) {
      //     // const navigate = useNavigate();
      //     navigate("/");
      //   }
    } catch (error) {
      // Show an error toast message
      toast({
        title: "Error",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <Flex justifyContent={"flex-end"}>
        <Sidebar />
        <Box w={"80%"} p={5}>
          <Heading textAlign={"center"} mb={"24px"}>
            Add Feedback
          </Heading>
          <Box w={"50%"} m={"auto"}>
            <Select
              options={options}
              placeholder="Select Astrologer"
              value={
                selectedAstroId
                  ? { label: selectedName, value: selectedAstroId }
                  : null
              }
              onChange={handleSelectChange}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderColor: "orange",
                  "&:hover": { borderColor: "orange" },
                  boxShadow: "none",
                }),
              }}
            />

            <HStack
              spacing={{ md: "24px", base: "16px" }}
              mt={{ md: "36px", base: "12px" }}
              mb={{ md: "28px", base: "14px" }}
              justify={"center"}
            >
              {[1, 2, 3, 4, 5].map((value) => (
                <Icon
                  key={value}
                  as={FaStar}
                  boxSize={{ md: 8, base: 6 }}
                  cursor="pointer"
                  color={value <= rating ? "yellow.400" : "gray.300"}
                  onClick={() => handleRatingChange(value)}
                />
              ))}
            </HStack>
            {rating > 0 && (
              <Box mb={5}>
                <FormControl>
                  <FormLabel
                    fontSize={{ md: "18px", base: "12px" }}
                    fontWeight="500"
                  >
                    {rating <= 2 ? "What is Bad?" : "What is Good?"}
                  </FormLabel>
                  <CheckboxGroup
                    onChange={handleOptionChange}
                    value={selectedOptions}
                  >
                    {rating <= 2 ? (
                      <HStack
                        id="review-checkbox"
                        flexWrap="wrap"
                        gap={{ md: 2, base: 1 }}
                      >
                        <Checkbox
                          value="Bad Experience"
                          padding={{ md: "4px 12px", base: "2px 6px" }}
                          borderRadius={{ md: "22px", base: "11px" }}
                          border="1px"
                          // isChecked={selectedOptions.includes("option1")}
                          style={{
                            backgroundColor: selectedOptions.includes(
                              "Bad Experience"
                            )
                              ? "#DD7323"
                              : "transparent",
                            color: selectedOptions.includes("Bad Experience")
                              ? "#FEFEFE"
                              : "#000",
                          }}
                        >
                          Bad Experience
                        </Checkbox>

                        <Checkbox
                          value="Bad Interface"
                          padding={{ md: "4px 12px", base: "2px 6px" }}
                          borderRadius={{ md: "22px", base: "11px" }}
                          border="1px"
                          style={{
                            backgroundColor: selectedOptions.includes(
                              "Bad Interface"
                            )
                              ? "#DD7323"
                              : "transparent",
                            color: selectedOptions.includes("Bad Interface")
                              ? "#FEFEFE"
                              : "#000",
                          }}
                        >
                          Bad Interface
                        </Checkbox>
                        <Checkbox
                          value="Too Long"
                          padding={{ md: "4px 12px", base: "2px 6px" }}
                          borderRadius={{ md: "22px", base: "11px" }}
                          border="1px"
                          style={{
                            backgroundColor: selectedOptions.includes(
                              "Too Long"
                            )
                              ? "#DD7323"
                              : "transparent",
                            color: selectedOptions.includes("Too Long")
                              ? "#FEFEFE"
                              : "#000",
                          }}
                        >
                          Too Long
                        </Checkbox>
                      </HStack>
                    ) : (
                      <>
                        <HStack
                          id="review-checkbox"
                          flexWrap="wrap"
                          gap={{ md: 2, base: 1 }}
                        >
                          <Checkbox
                            value="Good Prediction"
                            padding={{ md: "4px 12px", base: "2px 6px" }}
                            borderRadius={{ md: "22px", base: "11px" }}
                            border="1px"
                            style={{
                              backgroundColor: selectedOptions.includes(
                                "Good Prediction"
                              )
                                ? "#DD7323"
                                : "transparent",
                              color: selectedOptions.includes("Good Prediction")
                                ? "#FEFEFE"
                                : "#000",
                            }}
                          >
                            Good Prediction
                          </Checkbox>
                          <Checkbox
                            value="Good Astrologer"
                            padding={{ md: "4px 12px", base: "2px 6px" }}
                            borderRadius={{ md: "22px", base: "11px" }}
                            border="1px"
                            style={{
                              backgroundColor: selectedOptions.includes(
                                "Good Astrologer"
                              )
                                ? "#DD7323"
                                : "transparent",
                              color: selectedOptions.includes("Good Astrologer")
                                ? "#FEFEFE"
                                : "#000",
                            }}
                          >
                            Good Astrologer
                          </Checkbox>
                        </HStack>
                      </>
                    )}
                  </CheckboxGroup>
                </FormControl>
              </Box>
            )}

            <FormControl mt={10} mb={3}>
              <FormLabel fontSize={{ md: "18px", base: "12px" }}>
                Write Feedback
              </FormLabel>
              <Textarea
                className="feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                bg="gray.300" // sets the background color to gray.100
                placeholder="Share your thoughts/expereience with other customers. "
                focusBorderColor="orange.500"
              />
            </FormControl>
            <Box display={"flex"} justifyContent="center">
              <Button
                onClick={(e) => handleSubmit(e)}
                colorScheme="orange"
                w={"60%"}
                m={"auto"}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default AddFeedback;
