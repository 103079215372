import {
  Box,
  Button,
  Container,
  FormControl,
  Heading,
  Input,
  Modal,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import Login from "../../Login&Signup/Login";
import { useSelector } from "react-redux";

export default function Formpersonalized() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loggedIn = useSelector((state) => state.logIn.userLoggedIn);
  return (
    <>
      
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "50px",
          width: "100%",
          backgroundColor: "#FFE9DF",
        }}
      >
        <Heading
          style={{ margin: "auto", textAlign: "center", padding: "15px" }}
        >
          Get Personalized Horoscope
        </Heading>
        {/* <Container></Container> */}
        <Box
          width={{base:"100vw",sm:"85vw",md:"85vw",lg:"85vw",xl:"85vw"}}
          maxW={"1100px"}
          bg={""}
          flexDirection={{ base: "column", sm: "column", md: "row", lg: "row" }}
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "15px",
            paddingBottom: "16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "4px auto", sm: "10px", md: "14px", lg: "14px" }}
            onClick={onOpen}
          >
            <Input type="text" placeholder="Name" backgroundColor={"white"} />
          </FormControl>
          <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "4px auto", sm: "10px", md: "14px", lg: "14px" }}
            onClick={onOpen}
          >
            <Input
              type="text"
              placeholder="Birth Place"
              backgroundColor={"white"}
            />
          </FormControl>
          <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "4px auto", sm: "10px", md: "14px", lg: "14px" }}
            onClick={onOpen}
          >
            <Select placeholder="Gender" backgroundColor={"white"}>
              <option>Male</option>
              <option>Female</option>
            </Select>
          </FormControl>
          <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "4px auto", sm: "10px", md: "14px", lg: "14px" }}
            onClick={onOpen}
          >
            <Input
              placeholder="Birth Date"
              type="date"
              backgroundColor={"white"}
            />
          </FormControl>
          {/* <FormControl
            width={{ base: "90vw", sm: "80vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "7px auto", sm: "10px", md: "14px", lg: "14px" }}
            onClick={onOpen}
          >
            <Input type="time" placeholder="Time" backgroundColor={"white"} />
          </FormControl> */}
          <FormControl
            width={{ base: "20vw", sm: "20vw", md: "20vw", lg: "20vw",xl:"15vw" }}
            margin={{ base: "9px", sm: "10px", md: "14px", lg: "14px" }}
            onClick={onOpen}
          >
            <Button
              width={{ base: "20vw", sm: "20vw", md: "20vw", lg: "20vw",xl:"15vw" }}
              type="submit"
              backgroundColor={"#FF4C00"}
              color={"white"}
            >
              Submit
            </Button>
          </FormControl>
        </Box>
      </Box>
      <Modal
        isCentered
        size={{ base: "xs", md: "4xl" }}
        isOpen={isOpen} onClose={onClose}
      >
        <Login />
      </Modal>
    </>
  );
}
