import React, { useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Image,
  Grid,
  GridItem,
  useColorModeValue,
  useDisclosure,
  Modal,
  Icon,
  Flex,
} from "@chakra-ui/react";
import gift1 from "../../../assets/gifts/Gift1.png";
import gift2 from "../../../assets/gifts/Gift2.png";
import gift3 from "../../../assets/gifts/Gift3.png";
import gift4 from "../../../assets/gifts/Gift4.png";
import gift5 from "../../../assets/gifts/Gift5.png";
import gift6 from "../../../assets/gifts/Gift6.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { P_LIVE_SEND_GIFTS } from "../../../apiLinks";
// import { useParams } from "react-router-dom";
import Login from "../../Login&Signup/Login";
import { IoAlertCircle } from "react-icons/io5";
import { fetchLoggedInUserDetails } from "../../../store/LoggedInUserDetails";

import useGetSessionId from "../../../customHooks/useGetSessionId";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseEvents/firebase";

const loginToken = localStorage.getItem("loginToken");
const GiftGrid = ({
  gifts,
  walletBalance,
  onRecharge,
  onSend,
  onSelect,
  alertBalance,
  onClose1,
}) => {
  const [selectedGiftIndex, setSelectedGiftIndex] = useState(null);
  const columnBackgroundColor = useColorModeValue("transperant");
  const textColor = useColorModeValue("white");
  const selectedColumnBackgroundColor = useColorModeValue(
    "orange.100",
    "teal.700"
  );
  const selectedTextColor = useColorModeValue("gray.700", "teal.700");

  const handleGiftClick = (index) => {
    setSelectedGiftIndex(index);
    onSelect(gifts[index].price);
  };

  return (
    <VStack spacing={6}>
      <Text color={"white"}>
        Attention! THis gifts are voluntary and do not gurantee a consultation
        from astrologers
      </Text>
      <Flex justify={"center"} align={"center"} width={"100%"}>
        {alertBalance ? (
          <Icon as={IoAlertCircle} boxSize={6} color={"red"} />
        ) : null}

        <Text fontSize={"14px"} fontWeight={"500"} color={"red"} ml={2}>
          {alertBalance}
        </Text>
      </Flex>
      <Grid templateColumns="repeat(3, 2fr)" gap={2}>
        {gifts.map((gift, index) => (
          <GridItem
            key={index}
            onClick={() => handleGiftClick(index)}
            backgroundColor={
              index === selectedGiftIndex
                ? selectedColumnBackgroundColor
                : columnBackgroundColor
            }
            borderRadius="md"
            // borderWidth="1px"
            py={1}
            px={2}
            _hover={{ cursor: "pointer" }}
          >
            <VStack spacing={1} alignItems="center">
              <Box boxSize={"50px"}>
                <Image src={gift.giftImg} alt={gift.name} boxSize="100%" />
              </Box>
              <Text
                fontSize={"14px"}
                fontWeight="bold"
                color={
                  index === selectedGiftIndex ? selectedTextColor : textColor
                }
              >
                {gift.name}
              </Text>
              <Text
                fontSize={"14px"}
                color={
                  index === selectedGiftIndex ? selectedTextColor : textColor
                }
              >
                {gift.currency}
                {gift.price}
              </Text>
            </VStack>
          </GridItem>
        ))}
      </Grid>
      <HStack w="100%" justifyContent="space-between">
        <Button onClick={onRecharge}>Recharge</Button>
        <Text color={"white"}>Wallet Balance: {walletBalance}</Text>
        <Button
          onClick={() => {
            onSend();
          }}
        >
          Send
        </Button>
      </HStack>
    </VStack>
  );
};

const Gifts = (props) => {
  const astroDetails = props.astroDetail;
  // const sessionId = props.sessionId;
  // const sessionId = useGetSessionId(astroDetails?.roomId);

  const dispatch = useDispatch();
  const session = useGetSessionId();
  const [giftValue, setGiftValue] = useState();
  const [alertBalance, setAlertBalance] = useState();
  // const urlParams = new URLSearchParams(window.location.search);
  // const astrologerId = urlParams.get("id");
  const isInternational = localStorage.getItem("isInternational") === "true";
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );
  const walletBalance = isInternational
    ? parseFloat(loggedInUserDetails?.userDetails?.internationalWallet).toFixed(
        2
      )
    : Math.round(loggedInUserDetails?.userDetails?.wallet);

  //
  const indianGifts = [
    { id: 1, name: "Diya", giftImg: gift1, currency: "₹", price: 7 },
    { id: 2, name: "Om", giftImg: gift2, currency: "₹", price: 18 },
    { id: 3, name: "Hamsa", giftImg: gift3, currency: "₹", price: 54 },
    { id: 4, name: "GaneshJi", giftImg: gift4, currency: "₹", price: 101 },
    { id: 5, name: "Shiva Lingam", giftImg: gift5, currency: "₹", price: 360 },
    { id: 6, name: "Bramhand", giftImg: gift6, currency: "₹", price: 1008 },
  ];
  // international prices with offer
  const internationalGifts = [
    { id: 1, name: "Diya", giftImg: gift1, currency: "$", price: 0.25 },
    { id: 2, name: "Om", giftImg: gift2, currency: "$", price: 1 },
    { id: 3, name: "Hamsa", giftImg: gift3, currency: "$", price: 3 },
    { id: 4, name: "GaneshJi", giftImg: gift4, currency: "$", price: 5 },
    { id: 5, name: "Shiva Lingam", giftImg: gift5, currency: "$", price: 10 },
    { id: 6, name: "Bramhand", giftImg: gift6, currency: "$", price: 30 },
  ];
  const gifts = isInternational ? internationalGifts : indianGifts;

  const loginToken = localStorage.getItem("loginToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRecharge = () => {
    window.location.href = "/wallet";
  };

  const handleSend = async () => {
    onClose();
    const loginToken = localStorage.getItem("loginToken");
    const astrologer = astroDetails?.user.guru;
    if (!loginToken) {
      onOpen();
      return;
    }

    const walletBalance = isInternational
      ? loggedInUserDetails?.userDetails?.internationalWallet
      : loggedInUserDetails?.userDetails?.wallet;

    if (giftValue > walletBalance) {
      setAlertBalance("Your Wallet Balance is Low. Please Recharge");
      setTimeout(() => {
        setAlertBalance("");
      }, 3000);
      return;
    }
    try {
      const response = await axios.post(
        P_LIVE_SEND_GIFTS,
        {
          giftValue,
          astrologer,
          liveSessionId: await fetchSession(astroDetails?.roomId),
        },
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );

      props.onClose1();
      if (response.data.success) {
        logEvent(analytics, "Live_Gift_Count", {
          currency: isInternational ? "$" : "₹",
          price: giftValue,
        });
        dispatch(fetchLoggedInUserDetails(loginToken));
      }
    } catch (error) {
      console.error(
        "Error sending gift:",
        error.response ? error.response.data : error
      );
    }
  };

  //

  const handleGiftSelection = (selectedGift) => {
    setGiftValue(selectedGift);
  };

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "xs", md: "4xl" }}
      >
        <Login />
      </Modal>
      <Box>
        <GiftGrid
          gifts={gifts}
          walletBalance={walletBalance}
          onRecharge={handleRecharge}
          onSend={handleSend}
          onSelect={handleGiftSelection}
          onClose1={props.onClose1}
          alertBalance={alertBalance}
        />
      </Box>
    </>
  );
};

export default Gifts;

const fetchSession = async (roomId) => {
  const managementToken = await fetchManagementToken();
  const url = `https://api.100ms.live/v2/sessions?room_id=${roomId}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${managementToken}`,
    },
  });
  if (response.status === 200 && response.data.data[0]?.active) {
    return response.data.data[0].id;
  }
};

const fetchManagementToken = async () => {
  try {
    const response = await axios.get(
      "https://data.gurucool.life/api/v1/live/getManagementToken"
    );
    if (response.status === 200) {
      return response.data.token;
    }
  } catch (err) {}
};
