import React from "react";
import { Flex, Box, 
  // Text, 
  Link, Icon, Image
  // , Stack
 } from "@chakra-ui/react";
// import {
//   FiHome,
//   FiSettings,
//   FiLogOut,
//   FiChevronDown,
//   FiChevronUp,
// } from "react-icons/fi";
import { MdReviews } from "react-icons/md";
import {
  RiCustomerService2Fill,
  RiUserAddFill,
  RiUserFill,
  RiUser3Line,
} from "react-icons/ri";
import { SiRazorpay } from "react-icons/si";
import { FaStripe } from "react-icons/fa";
import {
  Link as ReactRouterLink,
  NavLink,
  // useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clickHandlersAction } from "../../../store/clickHandlersSlice";
import logo from "../../../../src/assets/logo.png";

const Sidebar = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  //   const [activeLink, setActiveLink] = React.useState(null);
  const activeLink = useSelector((state) => state.clickHandlers.activeLink);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (link) => {
    dispatch(clickHandlersAction.setActiveLink(link));
    // setActiveLink(link);
  };

  const options = [
    { label: "Dashboard", path: "/admin" },
    { label: "Settings", sett: "/admin/settings" },
    { label: "Settings", profile: "/admin/settings/profile" },
    { label: "AddAstrologer", profile: "/admin/settings/addAstrologer" },
    { label: "ShowAstrologer", profile: "/admin/settings/showAstrologers" },
    { label: "GetConsultation", profile: "/admin/settings/GetConsultation" },
    { label: "ShowUsers", profile: "/admin/settings/showUsers" },
    {
      label: "ShowRazorpayPayments",
      profile: "/admin/settings/showRazorpayPayments",
    },
    {
      label: "DisableAstrologer",
      profile: "/admin/settings/disableAstrologers",
    },
    { label: "Help", path: "/help" },
  ];

  // const userDetails = useSelector((state) => state.loggedInUserDetails);
  //
  // const

  return (
    <>
      <Flex
        bg="gray.700"
        h="100vh"
        w="20%"
        px="4"
        py="5"
        overflowY="auto"
        pos="fixed"
        left="0"
        top="0"
        zIndex="999"
        flexDir="column"
      >
        {/* Logo */}
        <Box mb="6">
          <NavLink to="/" fontSize="2xl" fontWeight="bold" color="white">
            <Image src={logo} alt="Logo"></Image>
          </NavLink>
        </Box>

        {/* Links */}
        <Box>
          {/* <Link
            display="flex"
            alignItems="center"
            mb="2"
            color={activeLink === "home" ? "blue.300" : "white"}
            _hover={{ textDecoration: "none", color: "blue.300" }}
            onClick={() => handleLinkClick("home")}
          >
            <Icon as={FiHome} mr="2" />
            Home
          </Link> */}

          <ReactRouterLink to="/admin/addastrologer">
            <Link
              // to="/admin/settings/profile"
              display="flex"
              alignItems="center"
              mb="2"
              color={activeLink === "addastrologer" ? "blue.300" : "white"}
              _hover={{ textDecoration: "none", color: "blue.300" }}
              onClick={() => handleLinkClick("addastrologer")}
            >
              <Icon as={RiUserAddFill} mr="2" />
              Add Astrologer
            </Link>
          </ReactRouterLink>
          <ReactRouterLink to="/admin/showAstrologers">
            <Link
              display="flex"
              alignItems="center"
              mb="2"
              color={activeLink === "showAstrologers" ? "blue.300" : "white"}
              _hover={{ textDecoration: "none", color: "blue.300" }}
              onClick={() => handleLinkClick("showAstrologers")}
            >
              <Icon as={RiUserFill} mr="2" />
              Show Astrologers
            </Link>
          </ReactRouterLink>
          <ReactRouterLink to="/admin/getConsultations">
            <Link
              display="flex"
              alignItems="center"
              mb="2"
              color={activeLink === "GetConsultation" ? "blue.300" : "white"}
              _hover={{ textDecoration: "none", color: "blue.300" }}
              onClick={() => handleLinkClick("GetConsultation")}
            >
              <Icon as={RiCustomerService2Fill} mr="2" />
              Show Consultation
            </Link>
          </ReactRouterLink>
          {/* <ReactRouterLink to="/admin/addBalance">
            <Link
              display="flex"
              alignItems="center"
              mb="2"
              color={activeLink === "AddBalance" ? "blue.300" : "white"}
              _hover={{ textDecoration: "none", color: "blue.300" }}
              onClick={() => handleLinkClick("AddBalance")}
            >
              <Icon as={FiHome} mr="2" />
              Get Consultation
            </Link>
          </ReactRouterLink> */}
          <ReactRouterLink to="/admin/showUsers">
            <Link
              display="flex"
              alignItems="center"
              mb="2"
              color={activeLink === "showUsers" ? "blue.300" : "white"}
              _hover={{ textDecoration: "none", color: "blue.300" }}
              onClick={() => handleLinkClick("showUsers")}
            >
              <Icon as={RiUser3Line} mr="2" />
              Show Users
            </Link>
          </ReactRouterLink>
          <ReactRouterLink to="/admin/showRazorpayPayments">
            <Link
              display="flex"
              alignItems="center"
              mb="2"
              color={
                activeLink === "showRazorpayPayments" ? "blue.300" : "white"
              }
              _hover={{ textDecoration: "none", color: "blue.300" }}
              onClick={() => handleLinkClick("showRazorpayPayments")}
            >
              <Icon as={SiRazorpay} mr="2" />
              Show Razorpay Payments
            </Link>
          </ReactRouterLink>

          <ReactRouterLink to="/admin/showStripePayments">
            <Link
              display="flex"
              alignItems="center"
              mb="2"
              color={activeLink === "showStripePayments" ? "blue.300" : "white"}
              _hover={{ textDecoration: "none", color: "blue.300" }}
              onClick={() => handleLinkClick("showStripePayments")}
            >
              <Icon as={FaStripe} mr="2" />
              Show Stripe Payments
            </Link>
          </ReactRouterLink>

          {/* Add Feedback Page Link Here */}
          <ReactRouterLink to="/admin/addFeedback">
            <Link
              display="flex"
              alignItems="center"
              mb="2"
              color={activeLink === "addFeedback" ? "blue.300" : "white"}
              _hover={{ textDecoration: "none", color: "blue.300" }}
              onClick={() => handleLinkClick("addFeedback")}
            >
              <Icon as={MdReviews} mr="2" />
              Add Feddback
            </Link>
          </ReactRouterLink>
          {/* <ReactRouterLink to="/admin/disableAstrologers">
          <Link
            display="flex"
            alignItems="center"
            mb="2"
            color={activeLink === "disableAstrologers" ? "blue.300" : "white"}
            _hover={{ textDecoration: "none", color: "blue.300" }}
            onClick={() => handleLinkClick("disableAstrologers")}
          >
            <Icon as={FiHome} mr="2" />
            Disable Astrologers
          </Link>
        </ReactRouterLink> */}

          {/* Dropdown */}
          {/* <Box>
            <Link
              to
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb="2"
              color={activeLink === "settings" ? "blue.300" : "white"}
              onClick={toggleSidebar}
              _hover={{ textDecoration: "none", color: "blue.300" }}
            >
              <Box>
                <Icon as={FiSettings} mr="2" />
                Settings
              </Box>
              <Icon as={isOpen ? FiChevronUp : FiChevronDown} />
            </Link>
            <Box
              display={isOpen ? "block" : "none"}
              pl="4"
              bg="gray.600"
              py="2"
            >
              <ReactRouterLink to="/admin/settings/profile">
                <Link
                  to="/admin/settings/profile"
                  display="block"
                  mb="2"
                  color={activeLink === "profile" ? "blue.300" : "white"}
                  _hover={{ textDecoration: "none", color: "blue.300" }}
                  onClick={() => handleLinkClick("profile")}
                >
                  Profile
                </Link>
              </ReactRouterLink>

              <Link
                display="block"
                mb="2"
                color={activeLink === "account" ? "blue.300" : "white"}
                _hover={{ textDecoration: "none", color: "blue.300" }}
                onClick={() => handleLinkClick("account")}
              >
                Account
              </Link>
            </Box>
          </Box> */}

          {/* <Link
            display="flex"
            alignItems="center"
            mb="2"
            color={activeLink === "logout" ? "blue.300" : "white"}
            _hover={{ textDecoration: "none", color: "blue.300" }}
            onClick={() => handleLinkClick("logout")}
          >
            <Icon as={FiLogOut} mr="2" />
            Logout
          </Link> */}
        </Box>
      </Flex>
    </>
  );
};

export default Sidebar;
