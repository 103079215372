import { useEffect, useState } from "react";
import "./Feedback.css";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Checkbox,
  CheckboxGroup,
  Textarea,
  useToast,
  HStack,
  Icon,
  Heading,
} from "@chakra-ui/react";
import axios from "axios";

import { FaStar } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {firebaseApp,analytics,logEvent} from '../../../firebaseEvents/firebase';

const Feedback = ({ purchaseId, userId, astroId }) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
//
//
//
  const handleRatingChange = (value) => {
    setRating(value);
    setSelectedOptions([]);
  };

  const handleOptionChange = (selected) => {
    setSelectedOptions(selected);
  };

  //   const handleSubmit = () => {
  //     // Here you can submit the rating and feedback data to your backend or API
  //     // For demonstration purposes, we're just showing a toast message
  //     const message = `Rating: ${rating}\nFeedback: ${feedback}\nSelected options: ${selectedOptions.join(", ")}`;
  //     toast({
  //       title: "Feedback submitted",
  //       description: message,
  //       status: "success",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   };

  const astroDetails = useSelector(
    (state) => state.selectedAstrologerDetails.astroDetails
  );

  const loggedInUserDetails = useSelector((state) => state.loggedInUserDetails);

  const handleSubmit = async (e) => {
    // Make the Axios POST call to your backend or API
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://data.gurucool.life/api/v1/guru/addFeedback",
        {
          user: userId,
          astrologer: astroId,
          rating: rating,
          bad: rating <= 2 ? selectedOptions.join(", ") : "",
          good: rating > 2 ? selectedOptions.join(", ") : "",
          badFeedback: rating <= 2 ? feedback : "",
          goodFeedback: rating > 2 ? feedback : "",
          purchaseId: purchaseId,
        }
      );
      //

      // Show a success toast message
      toast({
        title: "Feedback submitted",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });

      // Reset the state variables
      setRating(0);
      setSelectedOptions([]);
      setFeedback("");

      // Check if the response status is 200 and redirect to the home page
      if (response.status === 200) {
        // const navigate = useNavigate();
        navigate("/");
      }
    } catch (error) {
      // Show an error toast message
      toast({
        title: "Error",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };
  useEffect(() => {
    logEvent(analytics,'call_success');
  }, []);

  //
  //  

  return (
    <Box border={"1px solid #EDEDED"} padding={{ md: "10px", base: "6px" }}>
      <Heading
        textAlign={"center"}
        fontSize={{ md: "20px", base: "14px" }}
        fontWeight="500"
        paddingBottom={{ md: 2, base: 1 }}
        borderBottom={"1px solid lightgray"}
      >
        Feedback
      </Heading>
      <HStack
        spacing={{ md: "24px", base: "16px" }}
        mt={{ md: "36px", base: "12px" }}
        mb={{ md: "28px", base: "14px" }}
        justify={"center"}
      >
        {[1, 2, 3, 4, 5].map((value) => (
          <Icon
            key={value}
            as={FaStar}
            boxSize={{ md: 8, base: 6 }}
            cursor="pointer"
            color={value <= rating ? "yellow.400" : "gray.300"}
            onClick={() => handleRatingChange(value)}
          />
        ))}
      </HStack>
      {rating > 0 && (
        <Box mb={5}>
          <FormControl>
            <FormLabel fontSize={{ md: "18px", base: "12px" }} fontWeight="500">
              {rating <= 2 ? "What is Bad?" : "What is Good?"}
            </FormLabel>
            <CheckboxGroup
              onChange={handleOptionChange}
              value={selectedOptions}
            >
              {rating <= 2 ? (
                <HStack
                  id="review-checkbox"
                  flexWrap="wrap"
                  gap={{ md: 2, base: 1 }}
                >
                  <Checkbox
                    value="Bad Experience"
                    padding={{ md: "4px 12px", base: "2px 6px" }}
                    borderRadius={{ md: "22px", base: "11px" }}
                    border="1px"
                    // isChecked={selectedOptions.includes("option1")}
                    style={{
                      backgroundColor: selectedOptions.includes(
                        "Bad Experience"
                      )
                        ? "#FF5E1A"
                        : "transparent",
                      color: selectedOptions.includes("Bad Experience")
                        ? "#FEFEFE"
                        : "#000",
                    }}
                  >
                    Bad Experience
                  </Checkbox>

                  <Checkbox
                    value="Bad Interface"
                    padding={{ md: "4px 12px", base: "2px 6px" }}
                    borderRadius={{ md: "22px", base: "11px" }}
                    border="1px"
                    style={{
                      backgroundColor: selectedOptions.includes("Bad Interface")
                        ? "#FF5E1A"
                        : "transparent",
                      color: selectedOptions.includes("Bad Interface")
                        ? "#FEFEFE"
                        : "#000",
                    }}
                  >
                    Bad Interface
                  </Checkbox>
                  <Checkbox
                    value="Too Long"
                    padding={{ md: "4px 12px", base: "2px 6px" }}
                    borderRadius={{ md: "22px", base: "11px" }}
                    border="1px"
                    style={{
                      backgroundColor: selectedOptions.includes("Too Long")
                        ? "#FF5E1A"
                        : "transparent",
                      color: selectedOptions.includes("Too Long")
                        ? "#FEFEFE"
                        : "#000",
                    }}
                  >
                    Too Long
                  </Checkbox>
                </HStack>
              ) : (
                <>
                  <HStack
                    id="review-checkbox"
                    flexWrap="wrap"
                    gap={{ md: 2, base: 1 }}
                  >
                    <Checkbox
                      value="Good Prediction"
                      padding={{ md: "4px 12px", base: "2px 6px" }}
                      borderRadius={{ md: "22px", base: "11px" }}
                      border="1px"
                      style={{
                        backgroundColor: selectedOptions.includes(
                          "Good Prediction"
                        )
                          ? "#FF5E1A"
                          : "transparent",
                        color: selectedOptions.includes("Good Prediction")
                          ? "#FEFEFE"
                          : "#000",
                      }}
                    >
                      Good Prediction
                    </Checkbox>
                    <Checkbox
                      value="Good Astrologer"
                      padding={{ md: "4px 12px", base: "2px 6px" }}
                      borderRadius={{ md: "22px", base: "11px" }}
                      border="1px"
                      style={{
                        backgroundColor: selectedOptions.includes(
                          "Good Astrologer"
                        )
                          ? "#FF5E1A"
                          : "transparent",
                        color: selectedOptions.includes("Good Astrologer")
                          ? "#FEFEFE"
                          : "#000",
                      }}
                    >
                      Good Astrologer
                    </Checkbox>
                  </HStack>
                </>
              )}
            </CheckboxGroup>
          </FormControl>
        </Box>
      )}
      <FormControl>
        <FormLabel fontSize={{ md: "18px", base: "12px" }}>
          Write Feedback
        </FormLabel>
        <Textarea
          className="feedback"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          bg="gray.300" // sets the background color to gray.100
          placeholder="Share your thoughts/expereience with other customers. "
          focusBorderColor="orange.500"
        />
      </FormControl>
      <Box mt={4} display="flex" gap={2}>
        <Button
          variant={"outline"}
          onClick={() => navigate("/")}
          colorScheme="orange"
          w={"100%"}
        >
          Skip For Now
        </Button>
        <Button
          onClick={(e) => handleSubmit(e)}
          colorScheme="orange"
          w={"100%"}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Feedback;
