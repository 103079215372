import React, { useEffect } from "react";
import {
  Container,
  Box,
  Heading,
  Text,
  // , Link
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <title>Gurucool || About Us</title>
        <meta
          name="description"
          content="Discover the trusted astrology platform, GuruCool. We provide authentic astrological services, personalized readings, and valuable guidance. We privide you with a fist free consulation."
        />
      </Helmet>
      <Container maxW={"6xl"}>
        <Heading
          as="h2"
          fontSize={{ md: "36px", base: "24px" }}
          fontWeight={700}
          m={{ md: "64px 0 35px 0", base: "30px 0" }}
          textAlign="center"
        >
          {/* About Nohata and Shiv Private Limited */}
          About Gurucool
        </Heading>
        <Box mb={{ md: "16px", base: "14px" }} textAlign="justify">
          <Text>
            {/* Nohata and Shiv Private Limited */}
            Welcome to Gurucool for doctors, your premier destination for online
            doctor consultations. Our platform is dedicated to bringing you
            convenient and accessible healthcare, connecting you with a diverse
            range of experienced and qualified medical professionals. Whether
            you're seeking medical advice, a second opinion, or a prescription
            refill, we've got you covered.
          </Text>
          <Text>
            At Gurucool, we understand that your health and well-being are of
            utmost importance. That's why we've curated a comprehensive
            selection of renowned doctors from various specialties, all
            available for virtual consultations. Our team is committed to
            revolutionizing the way you access healthcare by providing a
            seamless and secure online platform that bridges the gap between
            patients and doctors.
          </Text>
          <Heading as="h2">What We Do </Heading>
          <Text>
            {" "}
            We offer a platform that allows users to connect with doctors
            specializing in various medical fields such as general medicine,
            dermatology, gynecology, and more. Our selection of doctors is
            meticulous, involving reviews and verification to ensure our users
            receive the highest quality service. Users can engage with their
            preferred doctor through calls or chats to obtain answers to their
            queries and receive tailored guidance.
          </Text>

          <Heading as="h2">How It Works</Heading>
          <Text>
            <ol>
              <li>
                Browse Profiles: Explore the profiles of our listed doctors to
                find the one that best matches your needs and preferences.
              </li>
              <li>
                Book an Appointment: Choose a suitable time slot and schedule
                your online consultation. You can provide a brief overview of
                your health concerns to help the doctor prepare for your
                session.
              </li>
              <li>
                Virtual Consultation: Log in to your account at the appointed
                time and connect with your chosen doctor through our secure
                video conferencing platform.
              </li>
              <li>
                Receive Expert Advice: During the consultation, the doctor will
                attentively listen to your concerns, ask relevant questions, and
                provide personalized medical advice, treatment plans, and
                prescriptions, if necessary.
              </li>
              <li>
                Follow-Up and Support: If needed, you can schedule follow-up
                appointments or message your doctor directly through our
                platform for any further questions or updates.
              </li>
            </ol>
          </Text>
          <Heading as="h2"> Why Choose Us </Heading>
          <Text>
            <ol>
              <li>
                Expertise at Your Fingertips: Our platform hosts a network of
                carefully vetted doctors who are leaders in their respective
                fields. From general practitioners to specialists across a wide
                spectrum of medical disciplines, you can be assured that you're
                receiving advice from professionals with years of experience.
              </li>
              <li>
                Convenience Redefined: Skip the hassle of waiting rooms and long
                commutes. With [Website Name], you can consult with a doctor
                from the comfort of your own home, saving you time and energy.
                Our user-friendly interface ensures a smooth and intuitive
                experience, even for those who may be less tech-savvy.
              </li>
              <li>
                Privacy and Security: We understand the importance of
                maintaining the confidentiality of your medical information. Our
                platform employs state-of-the-art security measures to protect
                your data and ensure that your online consultations are secure
                and private.
              </li>
              <li>
                Flexible Scheduling: Life can be hectic, and we get that. Our
                platform offers flexible scheduling options, allowing you to
                book appointments at a time that suits your busy lifestyle.{" "}
              </li>
              <li>
                Cost-Effective Healthcare: Online consultations can often be
                more affordable than in-person visits. We aim to provide
                high-quality healthcare that is accessible to a wide range of
                individuals, regardless of their budget. 6. Global Reach:
                [Website Name] breaks down geographical barriers, connecting you
                with doctors from around the world. This means you have access
                to a diverse pool of medical expertise and opinions.
              </li>
            </ol>
          </Text>
          {/* <Text>
            Join our platform today and get connected with some of the best
            astrologers in the industry. We are committed to providing our users
            with the best possible astrological guidance and helping them lead
            happier, more fulfilling lives. Rewrite this better
          </Text> */}
          <Text>
            Address: 52, Padmawati colony-B, near mansarovar metro station,
            Jaipur, Rajasthan, 302019
          </Text>
          {/* <Text>Company Name: Nohata and Shiv Private Limited</Text> */}
          {/* <Text>Phone: </Text> */}
        </Box>
      </Container>
    </>
  );
};

export default AboutUsPage;
