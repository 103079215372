import {
  Box,
  Container,
  Flex,
  HStack,
  // Icon,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
// import { GiBeveledStar } from "react-icons/gi";
import Sun from "../../../assets/unification/Sun.webp";
import Chakra from "../../../assets/unification/Chakra.webp";
import Love from "../../../assets/unification/Love.png";
import Marriage from "../../../assets/unification/Marriage.png";
import Job from "../../../assets/unification/Job.png";
import Money from "../../../assets/unification/Money.png";
import Education from "../../../assets/unification/Education.png";
import Health from "../../../assets/unification/Health.png";
import Orbit from "../../../assets/unification/Orbit.png";
import Birth from "../../../assets/unification/Birth.png";
import Family from "../../../assets/unification/Family.png";
import NumberBlock from "../../../assets/unification/NumberBlock.png";
import { motion } from "framer-motion";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Unification = () => {
  const solutionLeft = [
    {
      id: 1,
      solution: "Love Related Problem",
      image: Love,
    },
    {
      id: 2,
      solution: "Marriage Problem",
      image: Marriage,
    },
    {
      id: 3,
      solution: "Job/Business Problem",
      image: Job,
    },
    {
      id: 4,
      solution: "Money Problem",
      image: Money,
    },
    {
      id: 5,
      solution: "Education Problem",
      image: Education,
    },
  ];

  const solutionRight = [
    {
      id: 1,
      solution: "Health Problem",
      image: Health,
    },
    {
      id: 1,
      solution: "Grah Shanti Problem",
      image: Orbit,
    },
    {
      id: 1,
      solution: "Child Birth Problem",
      image: Birth,
    },
    {
      id: 1,
      solution: "Husband Wife Problem",
      image: Family,
    },
    {
      id: 1,
      solution: "Lucky Number Problem",
      image: NumberBlock,
    },
  ];

  const options = useMemo(
    () => ({
      nav: true,
      dots: false,
      margin: 4,
      // center: true,
      // autoplay: true,
      // autoplayTimeout: 3000,
      // autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2.5,
          // margin: 8,
          nav: false,
          loop: true,
        },
        600: {
          items: 4,
          loop: true,
          nav: false,
          margin: 10,
          autoplay: true,
          autoplayTimeout: 3000,
          autoplayHoverPause: true,
        },
        1000: {
          items: 1,
        },
      },
    }),
    []
  );

  // useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);



  return (
    <>
      <Container
        maxW={"6xl"}
        my={{ md: "30px", base: "15px" }}
        id="unification"
      >
        <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ md: "56px", base: "16px" }}
        >
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
          <Text
            fontSize={{ base: "18px", md: "32px" }}
            fontWeight={"700"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
          >
            Many Problem One Solution
          </Text>
          {/* <Icon as={GiBeveledStar} boxSize={"29px"} color="#D97122" /> */}
        </Box>

        <HStack justify={{ lg: "space-between", md: "center", base: "center" }}>
          <VStack
            spacing={{ md: "24px" }}
            display={{ lg: "flex", md: "none", base: "none" }}
            
          >
            {solutionLeft.map((curr, index) => {
              return (
                <Flex
                  borderRadius={"8px"}
                  border={"1px solid #A95210"}
                  w={{ md: "100%" }}
                  h={{ md: "57px" }}
                  // data-aos="fade-up"
                >
                  <Box
                    as="div"
                    boxSize={{ lg: "56px" }}
                    // w={{md: "56px"}}
                    // h={{md: "56px"}}
                    bg={"#A95210"}
                    borderRadius={"8px 0 0 8px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mr={{ lg: "17px", md: "15px" }}
                  >
                    <Image borderRadius={"7px"} src={curr.image} alt="Astro" />
                  </Box>
                  <Flex
                    py={{ lg: "10px" }}
                    align={"center"}
                    pr={{ md: "17px" }}
                    w={{ md: "116px" }}
                  >
                    <Text
                      fontSize={{ md: "14px" }}
                      fontWeight={"500"}
                      color={"#6B6B6B"}
                      align={"center"}
                    >
                      {curr.solution}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </VStack>

          <Stack position={"relative"} >
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ type: "tween", repeat: Infinity, duration: 9 }}
            >
              <Box boxSize={{ md: "472px", base: "268px" }}>
                <Image
                  src={Chakra}
                  alt="Chakra"
                  boxSize={"100%"}
                  objectFit={"cover"}
                />
              </Box>
            </motion.div>

            <Box
              boxSize={{ md: "176px", base: "88px" }}
              position={"absolute"}
              top={"48%"}
              left={"50%"}
              transform={"translate(-50%, -50%)"}
            >
              <Image src={Sun} alt="Sun" boxSize={"100%"} objectFit={"cover"} />
            </Box>
          </Stack>

          <VStack
            spacing={{ md: "24px" }}
            display={{ lg: "flex", md: "none", base: "none" }}
          >
            {solutionRight.map((curr, index) => {
              return (
                <Flex
                  borderRadius={"8px"}
                  border={"1px solid #A95210"}
                  w={{ md: "100%" }}
                  h={{ md: "57px" }}
                  justifyContent={"space-between"}
                  // data-aos="fade-up"
                >
                  <Flex
                    py={{ lg: "10px" }}
                    align={"center"}
                    pl={{ md: "17px" }}
                    w={{ md: "116px" }}
                  >
                    <Text
                      fontSize={{ md: "14px" }}
                      fontWeight={"500"}
                      color={"#6B6B6B"}
                      align={"center"}
                    >
                      {curr.solution}
                    </Text>
                  </Flex>
                  <Box
                    as="div"
                    boxSize={{ lg: "56px" }}
                    // w={{md: "56px"}}
                    // h={{md: "56px"}}
                    bg={"#A95210"}
                    borderRadius={"0 8px 8px 0"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    ml={{ lg: "17px", md: "15px" }}
                    alignSelf={"flex-end"}
                  >
                    <Image
                      //   boxSize={"70%"}
                      borderRadius={"7px"}
                      src={curr.image}
                      alt="Astro"
                    />
                  </Box>
                </Flex>
              );
            })}
          </VStack>
        </HStack>

        <Stack
          direction={"column"}
          display={{ lg: "none", md: "flex", base: "flex" }}
          mt={"18px"}
          overflowX={"scroll"}
          sx={{
            // Hide scrollbar in webkit-based browsers
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <HStack>
            {solutionRight.map((curr, index) => {
              return (
                <Flex
                  borderRadius={"8px"}
                  border={"1px solid #A95210"}
                  w={{ md: "100%", base: "136px" }}
                  h={{ md: "48px", base: "40px" }}
                >
                  <Box
                    as="div"
                    boxSize={{ lg: "56px", md: "48px", base: "39px" }}
                    // w={{md: "56px"}}
                    // h={{md: "56px"}}
                    bg={"#A95210"}
                    borderRadius={"8px 0 0 8px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mr={{ lg: "17px", md: "15px", base: "7px" }}
                  >
                    <Image
                      boxSize={"65%"}
                      // h={"65%"}
                      borderRadius={"7px"}
                      src={curr.image}
                      alt="Astro"
                    />
                  </Box>
                  <Flex
                    py={{ lg: "10px", md: "8px", base: "5px" }}
                    align={"center"}
                    pr={{ md: "17px" }}
                    w={{ md: "116px", base: "79px" }}
                  >
                    <Text
                      fontSize={{ lg: "14px", md: "12px", base: "10px" }}
                      fontWeight={"500"}
                      color={"#6B6B6B"}
                      align={"center"}
                    >
                      {curr.solution}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </HStack>

          <HStack>
            {solutionLeft.map((curr, index) => {
              return (
                <Flex
                  borderRadius={"8px"}
                  border={"1px solid #A95210"}
                  w={{ md: "100%", base: "136px" }}
                  h={{ lg: "57px", md: "48px", base: "40px" }}
                >
                  <Box
                    as="div"
                    boxSize={{ lg: "56px", md: "48px", base: "39px" }}
                    // w={{md: "56px"}}
                    // h={{md: "56px"}}
                    bg={"#A95210"}
                    borderRadius={"8px 0 0 8px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mr={{ lg: "17px", md: "15px", base: "7px" }}
                  >
                    <Image
                      boxSize={"65%"}
                      // h={"65%"}
                      borderRadius={"7px"}
                      src={curr.image}
                      alt="Astro"
                    />
                  </Box>
                  <Flex
                    py={{ lg: "10px", md: "8px", base: "5px" }}
                    align={"center"}
                    pr={{ md: "17px" }}
                    w={{ md: "116px", base: "79px" }}
                  >
                    <Text
                      fontSize={{ lg: "14px", md: "12px", base: "10px" }}
                      fontWeight={"500"}
                      color={"#6B6B6B"}
                      align={"center"}
                    >
                      {curr.solution}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </HStack>
        </Stack>
      </Container>
    </>
  );
};

export default Unification;
