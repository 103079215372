import { Box, Container, HStack, Image, Text } from '@chakra-ui/react'
import React from 'react';
import Facebook from "../../Assests/Supportasset/Facebook.svg";
import { Flex, } from '@chakra-ui/react';
import Instagram from "../../Assests/Supportasset/Instagram.svg";
import Exotel from "../../Assests/Supportasset/Exotel.svg";
import Google from "../../Assests/Supportasset/Google.svg";
// import Quora from "../../Assests/Supportasset/Quora.svg";
import Youtube from "../../Assests/Supportasset/Youtube.svg";
import Clevertap from "../../Assests/Supportasset/Clevertap.svg";
import Quora from "../../Assests/Supportasset/Quora.svg";

const SupportMedia = () => {

  return (
    <>
      <Container maxW={"full"} px={0} bg={"#F9F9F9"} mt={{ md: "60px" }} display={{ md: "block" ,base:""}}>
        <Container maxW={"6xl"}>
          <Flex
            flexDirection={{ md: "row", base: "column" }}
            justifyContent={{ md: "space-around", base: "center" }}
            alignItems={"center"}
            py={{ md: "60px", base: "24px" }}
            w={"100%"}
          >

            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={{ base: "center", md: "center" }}
              mt={{ base: "24px", md: "unset" }}
            >
              <Text
                mb={0}
                fontSize={{ md: "38px", base: "22px" }}
                fontWeight={"700"}
                textAlign={"center"}
              >
                SUPPORT MEDIA
              </Text>
              {/* <Text
                  mt={{ md: "26px", base: "12px" }}
                  mb={0}
                  fontSize={{ md: "16px", base: "12px" }}
                  fontWeight={"400"}
                  color={"#232323"}
                  w={{ md: "615px", base: "326px" }}
                  textAlign={{md: "left", base: "center"}}
                >
                 
                </Text> */}

              <Box mt={{ md: "50px", base: "24px" }} w={"100%"} display={"flex"} flexWrap={"wrap"} justifyContent={"center"} gap={{md:"58px" ,base:"24px"}} >

                {/* <HStack justifyContent={"space-between"} w={"100%"}> */}
                  <Box w={{md:"123px",base: "69px"}} h={{md:"25px",base:"13px"}}>
                    <Image src={Facebook} alt="constumer-support" mx={"auto"} w={"100%"} h={"100%"}  paddingStart={"16px"} />
                  </Box>
                  <Box w={{md:"117.485px",base: "47px"}} h={{md:"39.691px",base:"16px"}}>
                    <Image src={Google} alt="PrivateNetworkt"  mx={"auto"} w={"100%"} h={"100%"}/>
                  </Box>


                  <Box w={{md:"115.104px",base: "56px"}} h={{md:"39.691px",base:"18px"}} >
                    <Image src={Instagram} alt="PrivateNetworkt" mx={"auto"} w={"100%"} h={"100%"} />
                  </Box>
                  <Box w={{md:"93.374px",base: "39px"}} h={{base:"16px",md:"36.364px"}}>
                    <Image src={Quora} alt="PrivateNetwork"  mx={"auto"} w={"100%"} h={"100%"}/>
                  </Box>


                  <Box w={{base: "44px",md:"80.926px"}} h={{base:"20px",md:"37.121px"}}>
                    <Image src={Exotel} alt="constumer-support"  mx={"auto"} w={"100%"} h={"100%"}/>
                  </Box>
                  <Box w={{base: "52px",md:"100.815px"}} h={{base:"20px",md:"36.516px"}}>
                    <Image src={Youtube} alt="PrivateNetworkt" mx={"auto"} w={"100%"} h={"100%"}/>
                  </Box>
                  <Box w={{base: "51px",md:" 94.301px"}} h={{base:"19px",md:"34.967px"}}>
                    <Image src={Clevertap} alt="PrivateNetworkt"  mx={"auto"} w={"100%"} h={"100%"}/>
                  </Box>
                {/* </HStack> */}
              </Box>
            </Box>
          </Flex>
        </Container>
      </Container>
    </>
  );
};



export default SupportMedia