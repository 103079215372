import React from "react";
import { Container, Box, Image, Flex, Text, Center } from "@chakra-ui/react";

const Offer = () => {
  return (
    <Container maxW={"6xl"}>
      <Box
        display={{ md: "none", base: "flex" }}
        h={"64px"}
        backgroundImage={
          "radial-gradient(48.77% 80.11% at 48.63% 50.00%, #7A2400 0%, #4B1600 94.79%)"
        }
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        borderRadius={"4px"}
      >
        <Text fontSize={"11px"} fontWeight={"500"} color={"#FF5710"} mb={0}>
          Recharge Offer
        </Text>
        <Text fontSize={"13px"} fontWeight={"600"} color={"#FFF"} mb={0} mt={"7px"}>
        Get 20% OFF in Next Recharge
        </Text>
      </Box>
    </Container>
  );
};

export default Offer;
