import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  // , useParams
} from "react-router-dom";
import {
  Container,
  Box,
  Heading,
  // Image,
  Text,
  Avatar,
  Center,
  Button,
  Spinner,
} from "@chakra-ui/react";

// import userPic from "../../../assets/_user.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { callConsultationEndedDetailsAction } from "../../../store/callConsultationEndedDetailsSlice";
import { useLocation } from "react-router-dom";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase";
import QueueConsultationStarted from "../QueueConsultationStarted/QueueConsultationStarted";
import { fetchHomepageAstrologers } from "../../../store/homepageAstrologers";
import { G_GET_SINGLE_ASTROLOGER_BY_TOKEN } from "../../../apiLinks";

const CallConsultationStarted = () => {
  const host = localStorage.getItem("host");
  const token = localStorage.getItem("guruToken");
  const [astroDetails, setAstrodetails] = useState(useSelector(
    (state) => state.selectedAstrologerDetails.astroDetails
  ));
  // const astroDetails = useSelector(
  //   (state) => state.selectedAstrologerDetails.astroDetails
  // );
  // console.log(".......",astroDetails)
  const dispatch = useDispatch();
  const minTime = useSelector(
    (state) => state.startCallBirthDetails.minCallDuration
  );

  const [CallMsg, setCallMsg] = useState(
    "Connecting to astrologer wait for a few seconds. In case of any internet issue, the first 30 second wouldn't be charged"
  );
  const [callStatus, setCallStatus] = useState("");
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  const purchaseId = localStorage.getItem("purchaseId");
  // useParams

  function fetchCallStartedData() {
    axios
      .get(
        "https://data.gurucool.life/api/v1/consultations/consultationReceipt",
        {
          params: {
            purchaseId,
          },
        }
      )
      .then(async function (response) {
        // console.log("Call Status");
        // console.log(response);
        setCallStatus(response.data.purchase.callStatus);

        axios.get(G_GET_SINGLE_ASTROLOGER_BY_TOKEN(token)).then((response) => {
          console.log("response",response);
          setAstrodetails(response.data.guru);
        });

        if (response.data.purchase.callStatus === "completed") {
          logEvent(analytics, "Call_Success_For", {
            item_name:
              astroDetails?.user?.firstName +
              " " +
              astroDetails?.user?.lastName,
          });
          dispatch(
            callConsultationEndedDetailsAction.setPurchaseId(purchaseId)
          );
          dispatch(
            callConsultationEndedDetailsAction.setCallDuration(
              response.data.purchase.timeDuration
            )
          );
          dispatch(
            callConsultationEndedDetailsAction.setAmount(
              response.data.purchase.amount
            )
          );
          localStorage.removeItem("purchaseId");
          navigate("/callconsultationdetails");
        } else if (response.data.purchase.callStatus === "started") {
          setCallMsg("Your Call has been Started");
          logEvent(analytics, "call_initiated");
          logEvent(analytics, "Call_Init_for", {
            item_name:
              astroDetails?.user?.firstName +
              " " +
              astroDetails?.user?.lastName,
          });
          // windows.location.reload()
        } else if (response.data.purchase.callStatus === "failed") {
          setCallMsg(
            "Your Call connecting has been failed please restart the process"
          );
          logEvent(analytics, "call_fail");
          logEvent(analytics, "Call_Failure_for", {
            item_name:
              astroDetails?.user?.firstName +
              " " +
              astroDetails?.user?.lastName,
          });
        }
      })
      .catch(function (error) {});
  }
  // setInterval(fetchCallStartedData, 20000);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(fetchCallStartedData, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (location.pathname !== "/callconsultationstarted") {
      clearInterval(intervalRef.current);
    }
  }, [location]);

  //
  const handleNavigate = () => {
    navigate("/");
  };

  const [remainingTime, setRemainingTime] = useState(60);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(interval);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // useEffect(()=>{},[])
  // console.log("Astrodetails",astroDetails);
  return (
    <>
      {astroDetails ? (
        // astroDetails?.callQueue?.[0] === localStorage.getItem("purchaseId") ||
        callStatus === "queued" ? (
          <QueueConsultationStarted astroDetails={astroDetails} />
        ):(
          <Container
            bg="#EDEDED"
            maxW="6xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            // height="100vh"
            padding="3%"
          >
            <Box
              bg="#FCFCFC"
              width={{ md: "fit-content", base: "265px" }}
              height={{ md: "fit-content", base: "auto" }}
              borderRadius={{ md: "12px", base: "9px" }}
              mx="auto"
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              p={8}
            >
              <Box>
                <Heading
                  fontSize={{ md: "20px", base: "16px" }}
                  fontWeight={500}
                  textAlign="center"
                  mt={{ md: "26px", base: "20px" }}
                  mb={{ md: "22px", base: "18px" }}
                >
                  {/* Consultation Started with {astroDetails.user.firstName} {astroDetails.user.lastName} */}
                  {/* Consultation Started... */}
                  {callStatus === "busy" ||
                  callStatus === "failed" ||
                  callStatus === "no-answer"
                    ? "Consultation failed"
                    : "Consultation Started..."}
                </Heading>

                <Center>
                  <Avatar
                    // mx={"auto"}
                    width="100px"
                    height="100px"
                    src={astroDetails.user.avatar.url}
                    alt="user"
                    // maxW={{ base: "25%", md: "100%" }}
                  />
                </Center>
                <Heading
                  fontSize={{ md: "20px", base: "16px" }}
                  fontWeight={500}
                  textAlign="center"
                  mt={{ md: "26px", base: "20px" }}
                  mb={{ md: "12px", base: "8px" }}
                  color={
                    callStatus === "busy" ||
                    callStatus === "failed" ||
                    callStatus === "no-answer"
                      ? "red.400"
                      : "#15AD00"
                  }
                >
                  {/* Your consultation ends in */}
                  {callStatus === "busy" ||
                  callStatus === "failed" ||
                  callStatus === "no-answer"
                    ? "Connecting to failed"
                    : "Your Consultation is Starting"}
                </Heading>
              </Box>
              <Box>
                {callStatus === "busy" ||
                callStatus === "failed" ||
                callStatus === "no-answer" ? (
                  <Button
                    variant={"solid"}
                    colorScheme="orange"
                    w={"100%"}
                    onClick={handleNavigate}
                  >
                    Go Back To Home Page
                  </Button>
                ) : (
                  <>
                    <Text
                      fontSize={{ md: "12px", base: "10px" }}
                      fontWeight={600}
                      textAlign="center"
                      mb={{ md: "15px", base: "11px" }}
                      color="#797979"
                    >
                      {/* {CallMsg} */}
                      Connecting you to an astrologer,{" "}
                      <Text color=" #FF6644">
                        First 60 seconds won't charge
                      </Text>
                    </Text>
                    <Text textAlign="center" color="#15AD00">
                      {formatTime(remainingTime)}{" "}
                      <span style={{ textAlign: "center", color: "#797979" }}>
                        Time Remaining
                      </span>
                    </Text>
                  </>
                )}
              </Box>
              {/* <Box width={{ md: "418px", base: "200px" }} mx="auto">
              <Text
                fontSize={{ md: "12px", base: "9px" }}
                fontWeight={400}
                mb={{ md: "15px", base: "11px" }}
                color="#FF6644"
                textAlign="center"
              ></Text>
            </Box> */}
            </Box>
          </Container>
        ) 
      ) :<Box h={"100vh"} w={"100vw"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Spinner size='xl' />
        </Box>}
    </>
  );
};

export default CallConsultationStarted;
