import React from "react";
import logo_gif from "../../assets/loadingGifs/gurucoolLoading.webp";
// import logo_gif from "../../assets/loading.gif";
import { Flex, Image } from "@chakra-ui/react";
import { Spinner } from "react-bootstrap";

const PrimaryLoading = () => {
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={{ base: "100svh",md:"fit-content" }}
    >
      {/* <Spinner/> */}
      <Image src={logo_gif} alt="loading" />
    </Flex>
  );
};

export default PrimaryLoading;
