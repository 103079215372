import { SearchIcon } from "@chakra-ui/icons";
import {
  Container,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import axios from "axios";
// import { logEvent } from "firebase/analytics";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase";
import React, { useEffect, useState } from "react";

const SearchBarTop = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(null); // Initialize with null instead of []
  console.log(filteredData);
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (searchTerm) {
        handleSearch();
      }
    }, 500); // Adjust the delay time according to your needs

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    axios
      .get("https://data.gurucool.life/api/v1/guru/homePageSearchBar", {
        params: {
          Name: searchTerm,
        },
      })
      .then((response) => {
        setFilteredData(response.data.guru);
      })
      .catch((error) => {
        console.error(error);
        setFilteredData([]); // Set an empty array in case of error
      });
  };

  return (
    <Container
      p={3}
      position="relative"
      display={{ base: "block", sm: "block", md: "none" }}
    >
      <InputGroup>
        <InputLeftElement
          className="InputLeft"
          pointerEvents="none"
          children={<SearchIcon className="SearchIcon" color="gray.300" />}
          size="xs"
          mt={{ md: "6px" }}
        />
        <Input
          bg="#fff"
          className="Input"
          variant="outline"
          size="xs"
          placeholder="Search"
          height={{ base: "2.4rem", md: "48px" }}
          borderRadius="0.5rem"
          display={"flex"}
          alignItems={"center"}
          type="text"
          onChange={(e) => {
            handleInputChange(e);
          }}
          value={searchTerm}
          focusBorderColor="orange"
          onClick={logEvent(analytics, "search_click")}
        />
      </InputGroup>

      {searchTerm.length !== 0 && filteredData !== null && (
        <div
          className="dataResult"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            background: "#F3F3F3",
            border: "1px solid #ddd",
            borderRadius: "0.5rem",
            maxHeight: "200px",
            overflowY: "auto",
            padding: "0.5rem",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: "1000",
          }}
        >
          {filteredData !== null &&
            filteredData.map((item, index) => (
              <a
                className="dataItem"
                style={{ display: "block", height: "40px" }}
                href={`/astrologer/${item?.userName}`}
                key={index}
              >
                <p>
                  {item?.firstName} {item?.lastName}
                </p>
              </a>
            ))}
        </div>
      )}
    </Container>
  );
};

export default SearchBarTop;
