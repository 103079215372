import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { fetchHomepageAstrologers } from "../../../../store/homepageAstrologers";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
// import { BsFillLockFill } from "react-icons/bs";
import LockIcon from "../../Assests/Supportasset/LockIcon.svg";
import { FaLock } from "react-icons/fa";
import data from "../../../Utils/data";

const Astro = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.homepageAstrologers.data);
  const status = useSelector((state) => state.homepageAstrologers.status);
  const error = useSelector((state) => state.homepageAstrologers.error);
  // console.log("0", data);
  const dataLength = data.length;

  const astroData = [
    {
      id: 1,
      name: "Astrologer Name",
      skill: "Vedic Astrologey",
      language: "English, Hindi, Marathi",
    },
    {
      id: 2,
      name: "Astrologer Name",
      skill: "Vedic Astrologey",
      language: "English, Hindi, Marathi",
    },
    {
      id: 3,
      name: "Astrologer Name",
      skill: "Vedic Astrologey",
      language: "English, Hindi, Marathi",
    },
    {
      id: 5,
      name: "Astrologer Name",
      skill: "Vedic Astrologey",
      language: "English, Hindi, Marathi",
    },
    // {
    //   id: 5,
    //   name: "Astrologer Name",
    //   skill: "Vedic Astrologey",
    //   language: "English, Hindi, Marathi",
    // },
  ];

  // const skills = [
  //   {
  //       id: 1,
  //       skill: "Love",
  //   },
  //   {
  //       id: 2,
  //       skill: "Marriage",
  //   },
  //   {
  //       id: 3,
  //       skill: "Life",
  //   },
  //   {
  //       id: 4,
  //       skill: "Career",
  //   },
  // ];
  // console.log("data", data);
  const options = useMemo(
    () => ({
      nav: true,
      dots: false,
      margin: 20,
      // center: true,
      // autoplay: true,
      // autoplayTimeout: 3000,
      // autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
          margin: 8,
          //   nav: false,
          loop: true,
        },
        600: {
          items: 1,
          loop: true,
        },
        1000: {
          items: 4,
        },
      },
    }),
    []
  );
  useEffect(() => {
    dispatch(fetchHomepageAstrologers());
  }, [dispatch]);

  // const avatarStyle = {
  //   filter: isMostTrusted ? "blur(10px)" : "blur(0px)",
  //   transition: "filter 0.3s ease",
  // };
  return (
    <Container maxW={"6xl"} px={0} mt={{ base: "41px" }}>
      <Heading
        fontSize={{ md: "44px", base: "20px" }}
        fontWeight={"700"}
        color={"#373737"}
        textAlign={"center"}
        pt={{ md: "60px" }}
        mb={{ md: "32px", base: "17px" }}
      >
        GURUCOOL DOCTORS
      </Heading>
      <Flex
        flexWrap="wrap" // Enable wrapping of boxes
        justifyContent={{ md: "center", base: "center" }} // Space boxes evenly
        gap={{ md: "20px", base: "10px" }} // Gap between boxes
        mt={{ md: "32px" }}
        alignItems={"center"}
      >
        {data.map((curr, index) => {
          // let guruToken = curr.user.guru;
          return (
            <NavLink to={`/doctor/${curr?.id}`}>
              <VStack
                w={{ md: "264px", base: "158px" }}
                px={{ md: "44px", base: "16px" }}
                pt={{ md: "16px", base: "10px" }}
                pb={{ md: "29px", base: "8px" }}
                borderRadius={{ md: "17px", base: "8.127px" }}
                boxShadow={"0px 1px 4px 0px rgba(0, 0, 0, 0.25)"}
                mb={{ md: 4 }}
                // isDisabled={curr?.mostTrusted}
              >
                <WrapItem
                  position={"relative"}
                  // isDisabled={curr?.mostTrusted}
                >
                  <Avatar
                    name="Dan Abrahmov"
                    src={curr?.imageURL}
                    boxSize={{ md: "144px", base: "69px" }}
                    // isDisabled={curr?.mostTrusted}
                    // style={{
                    //   filter: curr?.mostTrusted ? "blur(3px)" : "blur(0px)",
                    // }}
                  />
                  {/* <Box
                    position="absolute"
                    left="50%"
                    top="50%"
                    transform="translate(-50%, -50%)"
                    // display={curr?.mostTrusted ? "block" : "none"}
                    // isDisabled={curr?.mostTrusted}

                  >
                    <Image isDisabled={curr?.mostTrusted} src={LockIcon} alt="LockIcon" boxSize={"120px"} color={"white"}/>
                  </Box> */}
                </WrapItem>

                <Flex
                  h={{ md: "72px" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  // isDisabled={curr?.mostTrusted}
                >
                  <Text
                    fontSize={{ md: "24px", base: "12px" }}
                    fontWeight={"500"}
                    color={"#111"}
                    textAlign={"center"}
                    mb={0}
                    isDisabled={curr?.mostTrusted}
                  >
                    {curr?.firstName} {curr?.lastName}
                  </Text>
                </Flex>

                {/* <Text
                  // key={skill}
                  fontSize={{ md: "16px", base: "10px" }}
                  fontWeight={"500"}
                  color={"#727272"}
                  my={{ md: "12px", base: "5px" }}
                  isDisabled={curr?.mostTrusted}
                >
                  {curr?.skills[0]}
                </Text> */}
                <Text
                  // key={skill}
                  fontSize={{ md: "16px", base: "10px" }}
                  fontWeight={"500"}
                  color={"#727272"}
                  my={{ md: "12px", base: "5px" }}
                  // isDisabled={curr?.mostTrusted}
                >
                  {`Experience - ${curr?.exp} Year`}
                </Text>

                {/* <Text
                  fontSize={{ md: "16px", base: "10px" }}
                  fontWeight={"500"}
                  color={"#111"}
                  isDisabled={curr?.mostTrusted}
                >
                  {curr?.languages?.map((item) => (
                    <span>{item} </span>
                  ))}
                </Text> */}
                <NavLink to={`/doctor/${curr?.id}`}>
                  <Button
                    variant={"solid"}
                    mt={{ md: "25px" }}
                    colorScheme="orange"
                    borderRadius={"full"}
                    px={{ md: "20px", base: "9.6px" }}
                    py={{ md: "8px", base: "4.8px" }}
                    fontSize={{ base: "12px" }}
                    fontWeight={"500"}
                    w={{ md: "144px", base: "99.31px" }}
                    h={{ md: "25px", base: "27.583px" }}
                    // isDisabled={curr?.mostTrusted}
                    // leftIcon={curr?.mostTrusted ? <FaLock /> : ""}
                  >
                    Consult Now
                  </Button>
                </NavLink>
              </VStack>
            </NavLink>
          );
        })}
        {/* </OwlCarousel> */}
      </Flex>
      <Flex
        w={"100%"}
        justifyContent={"center"}
        display={dataLength > 8 ? "flex" : "none"}
      >
        {/* <Button
          rightIcon={<ChevronDownIcon />}
          colorScheme="orange"
          variant="solid"
          mx={"auto"}
        >
          Settings
        </Button> */}
      </Flex>
    </Container>
  );
};

export default Astro;
