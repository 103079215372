import React from "react";
import Header from "./components/Hero/Header";
import theme from "./theme";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import Consultation from "./components/Consultation/Consultation";
import AstroCarousel from "./components/AstroCarousel/AstroCarousel";
import ExpertAstro from "./components/ExpertAstro/ExpertAstro";
import HelpProblems from "./components/HelpProblems/HelpProblems";
import UserSay from "./components/UsersSaying/UserSay";

function App() {
  const GlobalStyle = () => (
    <Global
      styles={css`
        @import url("https://fonts.googleapis.com/css2?family=Fondamento&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
      `}
    />
  );
  return (
    <ChakraProvider theme={theme}>
      <GlobalStyle />
      <div className="App">
        <Header />
        <Consultation />
        <AstroCarousel />
        <ExpertAstro />
        <HelpProblems />
        <UserSay />
      </div>
    </ChakraProvider>
  );
}

export default App;
