import {
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  Icon,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import Group from '../AstrologerProfileDashboard/ProfileAssets/Group1.png'
import { GiRemedy } from "react-icons/gi";
// react toastify for Notification
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function convertIst(date) {
  const utcDate = new Date(date);
  const istDate = new Date(
    utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );
  return istDate.toLocaleString();
}

const Consultation = ({ astrologer }) => {
  const filterConsultationType = (type) => {
    return astrologer?.guru?.consultations.filter(
      (item) => item.consultationType === type
    );
  };

  //

  const loginToken = localStorage.getItem("loginToken");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remedies, setRemedies] = useState("");
  const [purchaseId, setPurchaseId] = useState("");
  const [remediesSubmitted, setRemediesSubmitted] = useState("");
  //
  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    setRemedies(inputValue);
  };
  useEffect(() => {
    const previousConsultation =
      astrologer?.guru?.consultations[0]?.consultationType;
    // const publicLiveCall = true;
    const remediesSubmitted = astrologer?.guru?.consultations[0]?.Remedies;

    if (
      (previousConsultation === "call" ||
        previousConsultation === "public_live_Call") &&
      !remediesSubmitted
    ) {
      setIsModalOpen(true);
      setPurchaseId(astrologer.guru.consultations[0]._id);
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  //
  //

  // Handle Post Reqest for the Remedies.
  const handleRemedies = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://data.gurucool.life/api/v1/guru/addRemedies",
        {
          text: remedies,
          purchaseId: purchaseId,
        },
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );

      if (response.data.success) {
        setIsModalOpen(false);
        setRemediesSubmitted(response.data.purchase.Remedies);
        toast.success("Remedies added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          onClose: () => {
            setTimeout(() => {
              window.location.reload(); // Reload the page
              const buttonElement = document.getElementById(
                "recentlyClickedButton"
              );
              if (buttonElement) {
                buttonElement.scrollIntoView({ behavior: "smooth" }); // Scroll to the recently clicked button
              }
            }, 2000); // Delay before reloading and scrolling (2 seconds)
          },
        });
      } // Assuming you want to log the response data
    } catch (error) {
      console.error(error);
    }
  };

  const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <Container maxW={"6xl"}>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size={{ base: "xs", md: "sm" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Remedies</ModalHeader>
          <ModalCloseButton
            _hover={{ color: "orange.500", background: "orange.100" }}
          />
          <ModalBody>
            <Textarea
              placeholder="Enter Remedies Here..."
              focusBorderColor="orange.500"
              value={remedies}
              onChange={handleInputChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="orange" onClick={(e) => handleRemedies(e)}>
              Send
            </Button>
            <Button
              variant="ghost"
              colorScheme={"orange"}
              ml={3}
              onClick={closeModal}
            >
              Not Now
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <Box textAlign="center" pt={8}>
        <Button onClick={openModal}>Open Modal</Button>
      </Box> */}
      <Flex
        h={"auto"}
        pb={"50px"}
        bg="#FEF1E6"
        borderRadius="12px"
        // w="81%"
        mt="32px"
        flexDirection="column"
      >
        <Heading size={"lg"} textAlign={"center"} mt={{ base: 2, md: 4 }}>
          Consultation History
        </Heading>
        <Tabs>
          <TabList
            gap="16px"
            justifyContent="center"
            mt="24px"
            borderBottom={"none"}
          >
            <Tab
              _selected={{ color: "white", bg: "#B5570F" }}
              bg="#FFE1CA"
              color="#767676"
              border="none"
              h="45px"
              w="119px"
              borderRadius="2px"
            >
              All
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "#B5570F" }}
              bg="#FFE1CA"
              color="#767676"
              border="none"
              h="45px"
              w="119px"
              borderRadius="2px"
            >
              Call
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "#B5570F" }}
              bg="#FFE1CA"
              color="#767676"
              border="none"
              h="45px"
              w="119px"
              borderRadius="2px"
            >
              Pvt. Call
            </Tab>
            <Tab
              _selected={{ color: "white", bg: "#B5570F" }}
              bg="#FFE1CA"
              color="#767676"
              border="none"
              h="45px"
              w="119px"
              borderRadius="2px"
            >
              Gift
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex
                overflowX={"scroll"}
                sx={{ "::-webkit-scrollbar": { display: "none" } }}
              >
                <Table size={"auto"} variant="simple" mt="24px" mx="auto">
                  <Thead bgColor="#EBEBEB">
                    <Tr>
                      <Th
                        display={{ base: "none", md: "block" }}
                        fontSize={{ base: "14px", md: "17px" }}
                        h="46px"
                      >
                        Id
                      </Th>
                      <Th fontSize={{ base: "14px", md: "17px" }} h="46px">
                        Date & Time
                      </Th>
                      <Th fontSize={{ base: "14px", md: "17px" }} h="46px">
                        Status
                      </Th>
                      <Th fontSize={{ base: "14px", md: "17px" }} h="46px">
                        Duration
                      </Th>
                      <Th fontSize={{ base: "14px", md: "17px" }} h="46px">
                        Earning
                      </Th>
                      <Th fontSize={{ base: "14px", md: "17px" }} h="46px">
                        Give Remedies
                      </Th>
                      {/* <Th h="46px">Details</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody bgColor="#FFFFFF">
                    {/* {data.map((item, index) => ( */}
                    {/* {console.log(astrologer.guru.consultations)} */}
                    {astrologer?.guru?.consultations?.map((item, index) => (
                      <Tr
                        key={index}
                        // pt={'11px'}
                        // bg={item.callStatus==="success" ? "green.200" : "gray.200"}
                      >
                        <Td
                          display={{ base: "none", md: "table-cell" }}
                          color="#676767"
                          h="32px"
                          pt="11px"
                          fontSize={{ base: "14px", md: "17px" }}
                        >
                          {item._id}
                        </Td>
                        <Td
                          color="#676767"
                          h="32px"
                          pt="11px"
                          fontSize={{ base: "14px", md: "17px" }}
                        >
                          {convertIst(item.createdAt)}
                        </Td>

                        <Td
                          color="#676767"
                          h="32px"
                          pt="11px"
                          fontSize={{ base: "14px", md: "17px" }}
                        >
                          {item.callStatus}
                        </Td>
                        <Td
                          color="#676767"
                          h="32px"
                          pt="11px"
                          fontSize={{ base: "14px", md: "17px" }}
                        >
                          {/* {item.timeDuration} */}
                          {item.timeDuration
                            ? parseFloat(item.timeDuration).toFixed(2)
                            : "-"}
                        </Td>
                        <Td
                          color="#676767"
                          h="32px"
                          pt="11px"
                          fontSize={{ base: "14px", md: "17px" }}
                        >
                          {/* {item.amount} */}₹{" "}
                          {parseFloat(item.astrologersEarnings).toFixed(2)}
                        </Td>
                        {item.Remedies ? (
                          <Td
                            color="#676767"
                            h="32px"
                            pt="11px"
                            fontSize={{ base: "14px", md: "17px" }}
                            // onClick={() => {
                            //   openModal();
                            //   setPurchaseId(item._id);
                            // }}
                            // cursor={"pointer"}
                          >
                            <Text
                              textAlign={"center"}
                              fontSize={{ base: "12px", md: "18px" }}
                              fontWeight={"500"}
                              color={"orange.500"}
                            >
                              Submitted
                            </Text>
                          </Td>
                        ) : (
                          <Td
                            color="#676767"
                            h="32px"
                            pt="11px"
                            fontSize={{ base: "14px", md: "17px" }}
                            onClick={() => {
                              openModal();
                              setPurchaseId(item._id);
                            }}
                            cursor={"pointer"}
                          >
                            {item.consultationType === "Live_gifts" ? (
                              <Text textAlign={"center"}>---</Text>
                            ) : (
                              <Button variant={"ghost"} colorScheme="orange">
                                <Icon
                                  as={GiRemedy}
                                  boxSize={4}
                                  color={"orange.500"}
                                  mr={{ base: 1, md: 2 }}
                                />
                                Give Remedy
                              </Button>
                            )}
                          </Td>
                        )}
                        {/* <Td color="#676767" h="32px" pt="3px">
                        <img src={item.Details} alt="Details" />
                      </Td> */}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                overflowX={"scroll"}
                sx={{ "::-webkit-scrollbar": { display: "none" } }}
              >
                <Table size={"auto"} variant="simple" mt="24px" mx="auto">
                  <Thead bgColor="#EBEBEB">
                    <Tr>
                      <Th h="46px">Id</Th>
                      <Th h="46px">Date & Time</Th>
                      <Th h="46px">Status</Th>
                      <Th h="46px">Duration</Th>
                      <Th h="46px">Earning</Th>
                      <Th h="46px">Give Remedies</Th>
                      {/* <Th h="46px">Details</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody bgColor="#FFFFFF">
                    {/* {astrologer?.guru?.consultations.map((item, index) => ( */}
                    {filterConsultationType("Call").map((item, index) => (
                      <Tr
                        key={index}
                        //  bg={item.callStatus==="success" ? "green.200" : "gray.200"}
                      >
                        <Td color="#676767" h="32px" pt="11px">
                          {item._id}
                        </Td>
                        <Td color="#676767" h="32px" pt="11px">
                          {convertIst(item.createdAt)}
                        </Td>
                        <Td color="#676767" h="32px" pt="11px">
                          {item.callStatus}
                        </Td>
                        <Td color="#676767" h="32px" pt="11px">
                          {item.timeDuration
                            ? parseFloat(item.timeDuration).toFixed(2)
                            : "-"}
                        </Td>
                        <Td color="#676767" h="32px" pt="11px">
                          ₹ {parseFloat(item.astrologersEarnings).toFixed(2)}
                        </Td>
                        {item.Remedies || remediesSubmitted ? (
                          <Td
                            color="#676767"
                            h="32px"
                            pt="11px"
                            fontSize={{ base: "14px", md: "17px" }}
                            // onClick={() => {
                            //   openModal();
                            //   setPurchaseId(item._id);
                            // }}
                            // cursor={"pointer"}
                          >
                            <Text
                              textAlign={"center"}
                              fontSize={{ base: "12px", md: "18px" }}
                              fontWeight={"500"}
                              color={"orange.500"}
                            >
                              Submitted
                            </Text>
                          </Td>
                        ) : (
                          <Td
                            color="#676767"
                            h="32px"
                            pt="11px"
                            fontSize={{ base: "14px", md: "17px" }}
                            onClick={() => {
                              openModal();
                              setPurchaseId(item._id);
                            }}
                            cursor={"pointer"}
                          >
                            {item.consultationType === "Live_gifts" ? (
                              <Text textAlign={"center"}>---</Text>
                            ) : (
                              <Button
                                variant={"ghost"}
                                colorScheme="orange"
                                id="recentlyClickedButton"
                              >
                                <Icon
                                  as={GiRemedy}
                                  boxSize={4}
                                  color={"orange.500"}
                                  mr={{ base: 1, md: 2 }}
                                />
                                Give Remedy
                              </Button>
                            )}
                          </Td>
                        )}
                        {/* <Td color="#676767" h="32px" pt="3px">
                        <img src={item.Details} alt="Details" />
                      </Td> */}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                overflowX={"scroll"}
                sx={{ "::-webkit-scrollbar": { display: "none" } }}
              >
                <Table size={"auto"} variant="simple" mt="24px" mx="auto">
                  <Thead bgColor="#EBEBEB">
                    <Tr>
                      <Th h="46px">Id</Th>
                      <Th h="46px">Date & Time</Th>
                      <Th h="46px">Status</Th>
                      <Th h="46px">Duration</Th>
                      <Th h="46px">Earning</Th>
                      {/* <Th h="46px">Details</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody bgColor="#FFFFFF">
                    {/* {data.map((item, index) => ( */}
                    {filterConsultationType("Private Call").map(
                      (item, index) => (
                        <Tr key={index}>
                          <Td color="#676767" h="32px" pt="11px">
                            {item._id}
                          </Td>
                          <Td color="#676767" h="32px" pt="11px">
                            {convertIst(item.createdAt)}
                          </Td>
                          <Td color="#676767" h="32px" pt="11px">
                            {item.callStatus}
                          </Td>
                          <Td color="#676767" h="32px" pt="11px">
                            {item.timeDuration
                              ? parseFloat(item.timeDuration).toFixed(2)
                              : "-"}
                          </Td>
                          <Td color="#676767" h="32px" pt="3px">
                            ₹ {parseFloat(item.astrologersEarnings).toFixed(2)}
                          </Td>
                          {/* <Td color="#676767" h="32px" pt="3px">
                        <img src={item.Details} alt="Details" />
                      </Td> */}
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                overflowX={"scroll"}
                sx={{ "::-webkit-scrollbar": { display: "none" } }}
              >
                <Table size={"auto"} variant="simple" mt="24px" mx="auto">
                  <Thead bgColor="#EBEBEB">
                    <Tr>
                      <Th h="46px">Id</Th>
                      <Th h="46px">Date & Time</Th>
                      <Th h="46px">Status</Th>
                      <Th h="46px">Duration</Th>
                      <Th h="46px">Earning</Th>
                      {/* <Th h="46px">Details</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody bgColor="#FFFFFF">
                    {/* {data.map((item, index) => ( */}
                    {/* {console.log(filterConsultationType)} */}
                    {filterConsultationType("Live_gifts").map((item, index) => (
                      <Tr key={index}>
                        <Td color="#676767" h="32px" pt="11px">
                          {item._id}
                        </Td>
                        <Td color="#676767" h="32px" pt="11px">
                          {convertIst(item.createdAt)}
                        </Td>
                        <Td color="#676767" h="32px" pt="11px">
                          {item.callStatus}
                        </Td>
                        <Td color="#676767" h="32px" pt="11px">
                          {item.timeDuration
                            ? parseFloat(item.timeDuration).toFixed(2)
                            : "-"}
                        </Td>
                        <Td color="#676767" h="32px" pt="3px">
                          ₹ {parseFloat(item.astrologersEarnings).toFixed(2)}
                        </Td>
                        {/* <Td color="#676767" h="32px" pt="3px">
                        <img src={item.Details} alt="Details" />
                      </Td> */}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <ToastContainer />
    </Container>
  );
};

export default Consultation;
