// import logo from './logo.svg';
import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import Header from './Component/Header/Header';
import Hero from './Component/Hero/Hero';
import Skill from './Component/Skills/Skill';
import Astro from './Component/Astro expert/astro';
import Tarot from './Component/Tarot/Tarot';
import Vastu from './Component/Vastu/Vastu';
import Palmistry from './Component/Palmistry/Palmistry';
import Footer from './Component/Footer/Footer';
import { Route, Routes } from "react-router";
import Gurucool from './Component/whygurucool/gurucool';
import SupportMedia from './Component/SupportMedia/SupportMedia';
import NewBanner from './Component/NewBanner/NewBanner';
import SearchBarTop from '../Newhomepage/SearchBarTop/SearchBarTop';
function App() {
  return (
    <ChakraProvider>
      {/* <Header /> */}
      <SearchBarTop/>
      <Hero />
      {/* <Skill /> */}
       <NewBanner/>
      <Astro/>
      {/* <Tarot/> */}
       {/* <Vastu/> */}
       {/* <Palmistry/> */}
       <Gurucool/>
       <SupportMedia/>
       {/* <Footer/> */}
       <Routes/>
    </ChakraProvider>
    

  );
}

export default App;
