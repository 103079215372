// BASE URL
// export const BASE_URL = "https://data.gurucool.life/api/v1/";
export const BASE_URL = "https://data.gurucool.life/api/v1/";
export const V2_BASE_URL = "https://data.gurucool.life/api/v2/";

// LOGIN URLS
const host = localStorage.getItem("host")

export const P_VERIFY_LOGIN_OTP =
  host === "myguruji" ? `${V2_BASE_URL}user/verifyOTP` : `${BASE_URL}user/verifyOTP`;
export const P_SEND_LOGIN_OTP =
  host === "myguruji" ? `${V2_BASE_URL}user/sendOTP` : `${BASE_URL}user/sendOTP`;

export const G_GET_ALL_ASTROLOGERS =
  BASE_URL + "guru/getGuruByFilter?page=${1}&perPage=${200}";
export const G_GET_ALL_GURUS = (pageNumber, perPage, uid) =>
  BASE_URL +
  `guru/guru?page=${pageNumber}&perPage=${perPage}${uid ? `&gid=${uid}` : ""}`;
export const G_GET_ALL_CONSULT_ASTROLOGERS = (pageNumber, perPage, uid) =>
  BASE_URL + `guru/astrologersDetails?page=${pageNumber}&perPage=${perPage}`;
export const G_GET_SINGLE_GURU = (guruToken) =>
  BASE_URL + `guru/getSingleGuru?guruId=${guruToken}`;
export const G_GET_ALL_USERS = (pageNumber, perPage, uid) =>
  BASE_URL +
  `admin/getUsers?page=${pageNumber}&perPage=${perPage}${
    uid ? `&uid=${uid}` : ""
  }`;
export const G_GET_ALL_RAZORPAY_PAYMENTS = (pageNumber) =>
  BASE_URL + `admin/getPaymentsRazorpay?page=${pageNumber}&perPage=20`;

export const G_GET_ALL_STRIPE_PAYMENTS = (pageNumber) =>
  BASE_URL + `admin/fetchStripePaymentInvoices?page=${pageNumber}&perPage=20`;

export const G_GET_USER_PROFILE = BASE_URL + "user/getProfile";

export const P_PUT_USER_DETAILS = (firstName, lastName, gender, email) =>
  `${BASE_URL}user/editProfile?firstName=${firstName}&lastName=${lastName}&gender=${gender}&email=${email}`;

export const P_PUT_MAKE_USER_ADMIN = BASE_URL + `admin/superAdminAccessControl`;

export const G_GET_SINGLE_GURUS = (token) =>
  BASE_URL + `guru/getSingleGuru?guruId=${token}`;

// PAYMENT URLS
export const P_STRIPE_CHECKOUT = (
  selectedPrice,
  currency,
  customerId,
  userId,
  gst,
  offerPrice,
  couponCode,
  couponType
) =>
host === "myguruji" ? V2_BASE_URL : BASE_URL +
  `payments/create-checkout-session?price=${selectedPrice}&currency=${currency}&customer=${customerId}&userid=${userId}&gst=${gst}&offerPrice=${offerPrice}&couponCode=${couponCode}&couponType=${couponType}`;
export const P_STRIPE_CHECKOUT_INTERNATIONAL = (
  selectedPrice,
  currency,
  customerId,
  userId,
  offerPrice,
  couponCode,
  couponType
) =>
  BASE_URL +
  `payments/create-checkout-session?price=${selectedPrice}&currency=${currency}&customer=${customerId}&userid=${userId}&gst=0&offerPrice=${offerPrice}&couponCode=${couponCode}&couponType=${couponType}`;

export const G_RAZORPAY_CHECKOUT_URL = `${BASE_URL}payments/checkout`;
export const G_RAZORPAY_CHECKOUT_URL_V2 = `${V2_BASE_URL}payments/checkout`;
export const G_RAZORPAY_CALLBACK_URL = `${BASE_URL}payments/payment-verification`;
export const G_RAZORPAY_CALLBACK_URL_V2 = `${V2_BASE_URL}payments/payment-verification`
export const G_RAZORPAY_PAYMENT_KEY_URL = `${BASE_URL}payments/key`;
export const G_RAZORPAY_PAYMENT_KEY_URL_V2 =`${V2_BASE_URL}payments/key`;
export const G_GET_PAYMENT_INFO_URL = (paymentDetailsId) =>
  `${BASE_URL}payments/get_payment_info?paymentID=${paymentDetailsId}`;
  export const G_GET_PAYMENT_INFO_URL_V2 = (paymentDetailsId) =>
  `${ V2_BASE_URL}payments/get_payment_info?paymentID=${paymentDetailsId}`;

// ADMIN URLS

export const P_ADD_ASTROLOGER = BASE_URL + "guru/register";
export const P_EDIT_ASTROLOGERS_DETAILS = (astrologerId) =>
  BASE_URL + `guru/edit_guru_details?guruId=${astrologerId}`;
export const GET_CONSULTATION = (pageNumber, perPage, uid, gid) =>
  BASE_URL +
  `admin/getConsultations?page=${pageNumber}&perPage=${perPage}${
    uid ? `&uid=${uid}` : ""
  }${gid ? `&gid=${gid}` : ""}`;

export const ADD_ASTROLOGER_IMAGES_UPLOAD = (guruID) =>
  `${BASE_URL}guru/upload_guru_backgrounds?guruId=${guruID}`;

export const G_GET_SINGLE_ASTROLOGER_BY_TOKEN = (guruID) =>
  `${BASE_URL}guru/getSingleGuru?guruId=${guruID}`;

export const G_GET_SINGLE_ASTROLOGER = (userName) =>
  `${BASE_URL}guru/getSingleGuru?userName=${userName}`;

//Get Single Guru By UserName
export const G_GET_SINGLE_ASTROLOGER_BY_USERNAME = (userName) =>
  `${BASE_URL}guru/getSingleGuru?userName=${userName}`;

export const P_EDIT_ASTROLOGER_DETAILS = (guruID) =>
  `${BASE_URL}guru/edit_guru_details?guruId=${guruID}`;

export const P_ADD_BALANCE = (userId, amount) =>
  `${BASE_URL}admin/superAdminRecharge?id=${userId}&amount=${amount}`;

export const P_ADD_WALLET_BALANCE = (userId, amount, isInternational) =>
  `${BASE_URL}admin/superAdminRecharge?id=${userId}&amount=${amount}&international=${isInternational}`;

// ASTROLOGER URLS
// astrologer url to change the call status
export const P_CHANGE_CALLS_AVAILABILITY = `${BASE_URL}guru/liveAvailability`;
// live
export const P_LIVE_SEND_GIFTS = `${BASE_URL}live/gifts`;
export const G_LIVE_GET_CURRENT_GIFT = `${BASE_URL}live/getGift`;
export const G_ALL_LIVE_ASTROLOGERS = `${BASE_URL}guru/getAllLiveGuru`;
export const P_LIVE_SAVE_PEER_ID = `${BASE_URL}live/save_peer_id`;
export const P_LIVE_REQUEST_START_CALL = `${BASE_URL}live/callTrigger`;
export const P_LIVE_GET_LIVE_TRIGGER = `${BASE_URL}live/getCallTrigger`;
export const P_LIVE_START_CALL = `${BASE_URL}live/start_public_call`;
export const P_LIVE_END_CALL = `${BASE_URL}live/end_public_call`;
export const P_LIVE_GET_CALL_TICKET_ID = (triggerId) =>
  `${BASE_URL}live/getcallTicketId?triggerId=${triggerId}`;
// live

// follow unfollow astrologer
export const P_FOLLOW_GURU = `${BASE_URL}user/followGuru`;
export const P_UNFOLLOW_GURU = `${BASE_URL}user/unfollowGuru`;

// version 2
export const V2_P_SEND_LOGIN_OTP = V2_BASE_URL + "user/sendOTP";

export const V2_P_STRIPE_CHECKOUT = (
  selectedPrice,
  currency,
  customerId,
  userId
) =>
  BASE_URL +
  `payments/create-checkout-session?price=${selectedPrice}&currency=${currency}&customer=${customerId}&userid=${userId}`;

export const V2_G_RAZORPAY_CHECKOUT_URL = BASE_URL + "payments/checkout";
