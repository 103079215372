import React from "react";
import { useLocation } from "react-router-dom";
import free1 from "../../../assets/banners/free1.jpg";
import { Box, Image } from "@chakra-ui/react";
import totalSpend from "../../../assets/banners/totalSpendDesktop.webp";
import NUJBanner from "../../../assets/NUJBannerWeb.png";
export const CommonBanners = ({ users }) => {
  const logintoken = localStorage.getItem("loginToken");
  const host = localStorage.getItem("host");
  return (
    <>
      {!logintoken && (
        <Box onClick={() => (window.location.href = "/wallet")}>
          <Image src={host === "myguruji" ? NUJBanner : free1}s alt="love-banner" />
        </Box>
      )}
      {logintoken &&
        users?.userDetails?.StripeInvoice.length === 0 &&
        users?.userDetails?.paymentsDetails.length === 0 && (
          <Box onClick={() => (window.location.href = "/wallet")}>
            <Image
              src={host === "myguruji" ? NUJBanner : free1}
              alt="love-banner"
            />
          </Box>
        )}
      {logintoken && users?.userDetails?.totalSpending > 200 && (
        <Box onClick={() => (window.location.href = "/wallet")}>
          <Image width={"100%"} src={totalSpend} alt="love-banner" />
        </Box>
      )}
    </>
  );
};
