import React from 'react'
import CardGrid from './CardGrid/CardGrid'
const AllAstrologers = () => {
  return (
    <>
        <CardGrid />
    </>
  )
}

export default AllAstrologers