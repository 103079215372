import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    language: [""],
    skill: '',
    price: '',
  },
  reducers: {
    setLanguageFilter: (state, action) => {
      state.language = action.payload;
    },
    setSkillFilter: (state, action) => {
      state.skill = action.payload;
    },
    setPriceFilter: (state, action) => {
      state.price = action.payload;
    },
  },
});

export const filterAction = filterSlice.actions;
export default filterSlice.reducer;