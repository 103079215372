import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    fondamento: "'Fondamento', sans-serif",
    roboto: "'Roboto, sans-serif",
    poppins: "'Poppins', sans-serif",
    sansation: "'Sansation', sans-serif",
    righteous: "'Righteous', sans-serif",
    ultra : "'Ultra', serif"
  },
});

export default theme;
