import {
  Flex,
  Box,
  Container,
  Text,
  Image,
  Button,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Modal,
  Divider,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Textarea,
  ModalFooter,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import Avatar from "../../../assets/profPic.png";
import axios from "axios";
import { toast } from "react-toastify";

function UsersConsultationCard({ consultationData }) {
  const [getRemedies, setGetRemedies] = useState("");
  const [shownRemedy, setShownRemedy] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remedies, setRemedies] = useState("");
  const [purchaseId, setPurchaseId] = useState("");
  const [remediesSubmitted, setRemediesSubmitted] = useState("");
  //   const [birthData, setBirthData] = useState("");
  // const [data, setData] = useState();

  //

  const handleInputRemedies = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://data.gurucool.life/api/v1/guru/addRemedies",
        {
          text: remedies,
          purchaseId: purchaseId,
        },
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );

      if (response.data.success) {
        setIsModalOpen(false);
        setRemediesSubmitted(response.data.purchase.Remedies);
        toast.success("Remedies added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          onClose: () => {
            setTimeout(() => {
              window.location.reload(); // Reload the page
              const buttonElement = document.getElementById(
                "recentlyClickedButton"
              );
              if (buttonElement) {
                buttonElement.scrollIntoView({ behavior: "smooth" }); // Scroll to the recently clicked button
              }
            }, 2000); // Delay before reloading and scrolling (2 seconds)
          },
        });
      } // Assuming you want to log the response data
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const loginToken = localStorage.getItem("loginToken");

  //       const response = await axios.get(
  //         `https://data.gurucool.life/api/v1/guru/getThreeConsultation?guruId=${consultationData?.guru}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${loginToken}`,
  //           },
  //         }
  //       );

  //       setData(response?.data?.consultations);
  //       // Handle the response data
  //     } catch (error) {
  //       // Handle the error
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  //   const fetchBirthDetails = async (uid) => {
  //     try {
  //       const response = await axios.get(
  //         `https://data.gurucool.life/api/v1/guru/getBirthDetailsForAstroDashboard?uid=${uid}`
  //         // {
  //         //   headers: {
  //         //     Authorization: `Bearer ${loginToken}`,
  //         //   },
  //         // }
  //       );

  //       // Save the response data in the state
  //       await setBirthData(response?.data?.BirthChart[0]);
  //       //
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //
  const handleRemedies = (remedy) => {
    setGetRemedies(remedy);
    setShownRemedy(true);
  };

  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();

  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();

  // Assuming your MongoDB date is stored in the variable 'mongoDate'
  const mongoDate = new Date(consultationData.updatedAt);

  // Format the time in 24-hour format (HH:mm)
  const time = mongoDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  // Format the date as DD/MM/YYYY
  const date = mongoDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long", // Use "long" instead of "2-digit" to get the full month name
    year: "numeric",
  });

  // Combine the formatted time and date
  const formattedDateTime = `${date} ${time}`;

  const roundedAmount = Number(consultationData[0]?.amount.toFixed(2));
  //
  //
  //
  //
  //
  const loginToken = localStorage.getItem("loginToken");

  //
  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    setRemedies(inputValue);
  };
  useEffect(() => {
    const previousConsultation = consultationData?.consultationType;
    // const publicLiveCall = true;
    const remediesSubmitted = consultationData?.Remedies;

    if (
      (previousConsultation === "call" ||
        previousConsultation === "public_live_Call") &&
      !remediesSubmitted
    ) {
      setIsModalOpen(true);
      setPurchaseId(consultationData?._id);
    }
  }, []);

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  //
  //
  //

  console.log("consultationData?.consult", consultationData?.consult);
  //
  //
  //     "consultationData?.consult[0]",
  //     consultationData?.consult[0]?.amount
  //   );
  return (
    <>
      <Modal
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        size={{ base: "xs", md: "sm" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Your Remedies</ModalHeader>
          <ModalCloseButton
            _hover={{ color: "orange.500", background: "orange.100" }}
          />
          <Divider mt={0} w={"90%"} color={"gray.300"} mx={"auto"} />
          <ModalBody>
            <Text>{getRemedies ? getRemedies : "No Remedies Present"}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenModal2}
        onClose={onCloseModal2}
        size={{ base: "xs", md: "sm" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Remedies</ModalHeader>
          <ModalCloseButton
            _hover={{ color: "orange.500", background: "orange.100" }}
          />
          <ModalBody>
            <Textarea
              placeholder="Enter Remedies Here..."
              focusBorderColor="orange.500"
              value={remedies}
              onChange={handleInputChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              onClick={(e) => handleInputRemedies(e)}
            >
              Send
            </Button>
            <Button
              variant="ghost"
              colorScheme={"orange"}
              ml={3}
              onClick={onCloseModal2}
            >
              Not Now
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Container
        p="10px"
        // bg="#FAFAFA"
        maxW={"1059px"}
        // h="180px"
        borderRadius={"10px"}
        alignItems={"center"}
        boxSizing="border-box"
        display={{ md: "block", base: "none", sm: "none" }}
        m="20px"
      >
        <Flex w="1059px" h="145px" gap="50px">
          <Box
            w="300px"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="18px"
          >
            <Text
              color={
                consultationData?.callStatus === "completed" ||
                consultationData?.callStatus === "success"
                  ? "#38CC24"
                  : "red"
              }
              fontSize={"19px"}
              fontWeight={"400"}
              lineHeight={"100%"}
              m="0"
            >
              {consultationData?.callStatus === "completed" ||
              consultationData?.callStatus === "success"
                ? "Completed"
                : "Failed"}
            </Text>
            <Flex
              w="360px"
              gap="23px"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Flex gap="12px" alignItems={"center"}>
                {/* <Image
                  h="50px"
                  w="50px"
                  borderRadius={"50%"}
                  src={
                    consultationData?.user?.avatar?.url
                      ? consultationData?.user?.avatar?.url
                      : Avatar
                  }
                /> */}

                {consultationData?.user?.avatar?.url ? (
                  <Image
                    h="50px"
                    w="50px"
                    borderRadius={"50%"}
                    src={consultationData?.user?.avatar?.url}
                  />
                ) : (
                  <Avatar
                    name={`${consultationData?.user?.firstName} ${consultationData?.user?.lastName}`}
                    size={"md"}
                  ></Avatar>
                )}
                <Text
                  m="0"
                  fontSize={"20px"}
                  color={"#474747"}
                  fontWeight={"500"}
                >
                  {`${consultationData?.user?.firstName} ${consultationData?.user?.lastName}`}
                </Text>
              </Flex>
            </Flex>
            {/* <Button border={"1px solid #474747"} bg="#FFF">
                Remedies
              </Button> */}
            {/* 
                <Button
                  variant={
                    consultationData.callStatus === "success" &&
                    consultationData.Remedies
                      ? "solid"
                      : "outline"
                  }
                  // color={shownRemedy ? "orange" : "#474747"}
                  color={consultationData.callStatus === "success" &&
                  consultationData.Remedies
                    ? "#FFF"
                    : "black"}
                  // border={shownRemedy ? "1px solid orange" : "1px solid black"}
                  fontSize={{ md: "14px" }}
                  display={{
                    base: "none",
                    md: "inline-flex",
                  }}
                  colorScheme={
                    consultationData.callStatus === "success" &&
                    consultationData.Remedies
                      ? "orange"
                      : "black"
                  }
                  onClick={() => {
                    handleRemedies(consultationData.Remedies);
                    onOpenModal1();
                  }}
                >
                  Remedies
                </Button> */}
          </Box>
          <Box w="350px" fontSize={"16px"} fontWeight={"400"} color="#707070">
            <Flex justifyContent={"space-between"}>
              <Text mb="6px">Time</Text>
              <Text mb="6px">{formattedDateTime}</Text>
            </Flex>
            <Flex justifyContent={"space-between"}>
              <Text mb="6px">Duration</Text>
              <Text mb="6px">
                {consultationData?.timeDuration
                  ? consultationData?.timeDuration.toFixed(2)
                  : "0"}{" "}
                Min
              </Text>
            </Flex>
            <Flex justifyContent={"space-between"}>
              <Text mb="6px">Consultation Type</Text>
              <Text mb="6px">{consultationData?.consultationType}</Text>
            </Flex>
            <Flex justifyContent={"space-between"} mb="10px">
              <Text mb="6px">Earning</Text>
              <Text mb="6px" fontWeight="600" color={consultationData?.astrologersEarnings
                    ? "#118C00"
                    : "#222"}>
              ₹{" "}
                {consultationData?.amount
                  ? consultationData?.astrologersEarnings.toFixed(2)
                  : "0"}
              </Text>
            </Flex>
            <Flex
              fontSize={"17px"}
              color="#222"
              fontWeight={"500"}
              justifyContent={"space-between"}
            >
              <Text mb="0px">Money Spent</Text>
              <Text mb="0px">
                ₹{" "}
                {/* {consultationData[0]?.amount
                  ? Number(consultationData[0]?.amount.toFixed(2))
                  : "0"} */}
                {consultationData?.amount
                  ? consultationData?.amount.toFixed(2)
                  : "0"}
              </Text>
            </Flex>
          </Box>
          <Box w="228px">
            <Text fontSize={"14px"} mb="27px">
              ID：{consultationData?._id}
            </Text>
            <Button
              // bg="#FFF"
              // border={"1px solid #474747"}
              mb="11px"
              w="188px"
              h="36px"
              mt="20px"
              isDisabled={consultationData.RecordingUrl ? false : true}
              variant={consultationData?.RecordingUrl ? "solid" : "outline"}
              colorScheme={consultationData?.RecordingUrl ? "orange" : "black"}
              color={consultationData?.RecordingUrl ? "#FFF" : "black"}
              onClick={() => {
                if (consultationData?.RecordingUrl) {
                  window.open(consultationData?.RecordingUrl, "_blank");
                }
              }}
            >
              {consultationData?.callStatus === "success" &&
              consultationData?.RecordingUrl
                ? "Recording"
                : "No Recording"}
            </Button>
            {/* <Button bg="#FFF" border={"1px solid #474747"} w="188px" h="36px">
                  Share Feedback
                </Button> */}

            <Button
              variant={
                consultationData?.callStatus === "completed" ||
                (consultationData?.callStatus === "success" &&
                  consultationData?.Remedies)
                  ? "solid"
                  : "outline"
              }
              // color={shownRemedy ? "orange" : "#474747"}
              color={
                consultationData?.callStatus === "completed" ||
                (consultationData?.callStatus === "success" &&
                  consultationData?.Remedies)
                  ? "#FFF"
                  : "black"
              }
              // border={shownRemedy ? "1px solid orange" : "1px solid black"}
              fontSize={{ md: "14px" }}
              display={{
                base: "none",
                md: "inline-flex",
              }}
              colorScheme={
                consultationData?.callStatus === "completed" ||
                (consultationData?.callStatus === "success" &&
                  consultationData?.Remedies)
                  ? "orange"
                  : "black"
              }
              w="188px"
              h="36px"
              onClick={() => {
                if (
                  consultationData?.callStatus === "completed" ||
                  (consultationData?.callStatus === "success" &&
                    !consultationData?.Remedies)
                ) {
                  // Open the different modal
                  onOpenModal2();
                  // openModal();
                  setPurchaseId(consultationData?._id);
                } else {
                  handleRemedies(consultationData?.Remedies);
                  onOpenModal1();
                }
              }}
              isDisabled={
                consultationData?.callStatus === "completed" ||
                consultationData?.callStatus === "success"
                  ? false
                  : true
              }
            >
              {consultationData?.callStatus === "success" &&
              consultationData?.Remedies
                ? "Remedies"
                : "Give Remedies"}
            </Button>
          </Box>
        </Flex>
      </Container>

      {/* FOR SMALL SCREEN SIZES */}
      <Container
        bg="#FFF"
        p="10px"
        mb="20px"
        maxW={"1059px"}
        // h="350px"
        boxSizing="border-box"
        display={{ md: "none", base: "block", sm: "block" }}
      >
        <Flex>
          <Box>
            <Flex justifyContent={"space-between"} gap="20px">
              <Text
                color={
                  consultationData?.callStatus === "completed" ||
                  consultationData?.callStatus === "success"
                    ? "#38CC24"
                    : "red"
                }
                fontSize={"18px"}
                fontWeight={"400"}
                lineHeight={"100%"}
                m="0"
                textAlign="start"
                w={{ sm: "88px", base: "88px" }}
                minWidth={"90px"}
              >
                {consultationData?.callStatus === "completed" ||
                consultationData?.callStatus === "success"
                  ? "Completed"
                  : "Failed"}
              </Text>
              <Text
                fontSize={"12px"}
                mb="27px"
                w={{ sm: "180px", base: "180px" }}
              >
                ID：{consultationData._id}
              </Text>
            </Flex>

            <Flex gap="70px" alignItems={"center"}>
              <Flex
                gap="12px"
                //   justifyContent={"center"}
                alignItems={"center"}
              >
                {/* <Image
                  src={
                    consultationData?.user?.avatar?.url
                      ? consultationData?.user?.avatar?.url
                      : Avatar
                  }
                  w="33px"
                  h="33px"
                /> */}
                {consultationData?.user?.avatar?.url ? (
                  <Image
                    h="50px"
                    w="50px"
                    borderRadius={"50%"}
                    src={consultationData?.user?.avatar?.url}
                  />
                ) : (
                  <Avatar
                    name={`${consultationData?.user?.firstName} ${consultationData?.user?.lastName}`}
                    size={"md"}
                  ></Avatar>
                )}

                <Text
                  m="0"
                  fontSize={"15px"}
                  color={"#474747"}
                  fontWeight={"500"}
                >
                  {`${consultationData?.user?.firstName} ${consultationData?.user?.lastName}`}
                </Text>
              </Flex>
              {/* <Button
                  // ml="110px"
                  border={"1px solid #474747"}
                  h="28px"
                  w="90px"
                  fontSize={"12px"}
                  bg="#FFF"
                >
                  Birth Chart
                </Button> */}
            </Flex>

            <Box mt="16px" fontSize={"14px"} fontWeight={"400"} color="#707070">
              <Flex justifyContent={"space-between"}>
                <Text mb="6px">Time</Text>
                <Text mb="6px">{formattedDateTime}</Text>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Text mb="6px">Duration</Text>
                <Text mb="6px">
                  {consultationData?.timeDuration
                    ? consultationData?.timeDuration.toFixed(2)
                    : "0"}{" "}
                  Min{" "}
                </Text>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Text mb="6px">Consultation Type</Text>
                <Text mb="6px">{consultationData.consultationType}</Text>
              </Flex>
              <Flex justifyContent={"space-between"} mb="12px">
                <Text mb="6px">Earning</Text>
                <Text mb="6px" color={consultationData?.astrologersEarnings
                    ? "#118C00"
                    : "#222"}>
                ₹{" "}
                  {consultationData?.amount
                    ? consultationData?.astrologersEarnings.toFixed(2)
                    : "0"}
                </Text>
              </Flex>
              <Flex
                fontSize={"15px"}
                color="#222"
                fontWeight={"500"}
                justifyContent={"space-between"}
              >
                <Text mb="0px">Money Spent</Text>
                <Text mb="0px">
                  ₹{" "}
                  {consultationData?.amount
                    ? consultationData?.amount.toFixed(2)
                    : "0"}
                </Text>
              </Flex>
            </Box>

            <Flex justifyContent={"space-between"} mt="16px">
              <Button
                fontSize={"14px"}
                // bg="#FFF"
                // border={"1px solid #474747"}
                mb="11px"
                w="115px"
                h="32px"
                // mb="11px"
                // w="188px"
                // h="36px"
                isDisabled={consultationData.RecordingUrl ? false : true}
                variant={consultationData.RecordingUrl ? "solid" : "outline"}
                colorScheme={consultationData.RecordingUrl ? "orange" : "black"}
                color={consultationData.RecordingUrl ? "#FFF" : "black"}
                onClick={() => {
                  if (consultationData.RecordingUrl) {
                    window.open(consultationData.RecordingUrl, "_blank");
                  }
                }}
              >
                {consultationData?.callStatus === "completed" ||
                (consultationData?.callStatus === "success" &&
                  consultationData.RecordingUrl)
                  ? "Recording"
                  : "No Recording"}
              </Button>
              <Button
                variant={
                  consultationData?.callStatus === "completed" ||
                  (consultationData?.callStatus === "success" &&
                    consultationData.Remedies)
                    ? "solid"
                    : "outline"
                }
                // color={shownRemedy ? "orange" : "#474747"}
                color={
                  consultationData?.callStatus === "completed" ||
                  (consultationData?.callStatus === "success" &&
                    consultationData.Remedies)
                    ? "#FFF"
                    : "black"
                }
                // border={shownRemedy ? "1px solid orange" : "1px solid black"}
                fontSize={"14px"}
                colorScheme={
                  consultationData?.callStatus === "completed" ||
                  (consultationData?.callStatus === "success" &&
                    consultationData.Remedies)
                    ? "orange"
                    : "black"
                }
                w="115px"
                h="32px"
                onClick={() => {
                  if (
                    consultationData?.callStatus === "completed" ||
                    (consultationData?.callStatus === "success" &&
                      !consultationData.Remedies)
                  ) {
                    // Open the different modal
                    onOpenModal2();
                    // openModal();
                    setPurchaseId(consultationData._id);
                  } else {
                    handleRemedies(consultationData.Remedies);
                    onOpenModal1();
                  }
                }}
                isDisabled={
                  consultationData?.callStatus === "completed" ||
                  consultationData?.callStatus === "success"
                    ? false
                    : true
                }
              >
                {consultationData?.callStatus === "completed" ||
                (consultationData?.callStatus === "success" &&
                  consultationData.Remedies)
                  ? "Remedies"
                  : "Give Remedies"}
              </Button>
            </Flex>

            {/* <Button
                fontSize={"14px"}
                bg="#FFF"
                border={"1px solid #474747"}
                mb="11px"
                w="251px"
                h="38px"
                mt="20px"
              >
                History
              </Button> */}
          </Box>
        </Flex>
      </Container>
    </>
  );
}

export default UsersConsultationCard;
