import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { G_GET_ALL_CONSULT_ASTROLOGERS } from "../apiLinks";

const initialState = {
  data: [],
  total: 0,
  status: "idle",
  error: null,
  currentPage: 0,
  totalPages: 0,
};

export const fetchConsultAstrologers = createAsyncThunk(
  "consultAstrologerSlice/fetchConsultAstrologers",
  async ({ pageNumber, perPage }) => {
    const response = await axios.get(
      G_GET_ALL_CONSULT_ASTROLOGERS(pageNumber, perPage)
    );
    // console.log(response);
    return response.data.guru;
  }
);

const consultAstrologerSlice = createSlice({
  name: "allConsultAstrologersData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultAstrologers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchConsultAstrologers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.docs;
        state.currentPage = action.payload.page;
        state.totalPages = action.payload.pages;
        state.total = action.payload.total;
      })
      .addCase(fetchConsultAstrologers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default consultAstrologerSlice.reducer;
