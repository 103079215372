import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { G_GET_SINGLE_GURU } from "../apiLinks";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchSelectedAstrologerData = createAsyncThunk(
  "selectedAstrologer/fetchSelectedAstrologerData",
  async () => {
    const token= localStorage.getItem("guruToken");
    const response = await axios.get(
      G_GET_SINGLE_GURU(token)
    );
    console.log(response.data);
    return response.data;
  }
);

const selectedAstrologerSlice = createSlice({
  name: "selectedastrologers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectedAstrologerData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSelectedAstrologerData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchSelectedAstrologerData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default selectedAstrologerSlice.reducer;
