import {
  // Box,
  // Flex,
  // Spacer,
  // Text,
  // Button,
  // IconButton,
  useDisclosure,
  // Tabs,
  // TabList,
  // Tab,
  // TabPanels,
  // TabPanel,
  // Container,
  // HStack,
  // Stack,
  
} from "@chakra-ui/react";
// import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import Sidebar from "./Sidebar/Sidebar";
// import Profile from "./Profile/AdminProfile";
// import NoAccess from "../../pages/Noaccess";

function Admin() {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
    <Sidebar/>
    {/* <Profile path="/profile"/> */}
    {/* <NoAccess/> */}
  </>
  );
}

export default Admin;
