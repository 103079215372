import React, { useState, useEffect } from "react";
import {
  Heading,
  Avatar,
  Box,
  Container,
  Text,
  Stack,
  Button,
  // Link,
  Image,
  useDisclosure,
  Modal,
  // Flex,
} from "@chakra-ui/react";
// import CardGrid from "../CardGrid/CardGrid";

// import Chat_icon from "../../../assets/chat-blue.svg";
import Chat_icon_gray from "../../../assets/chat-gray.svg";
import Call_icon from "../../../assets/Call_icon.png";
import CallBusy from "../../../assets/callBusy.png";
import CallOffline from "../../../assets/callOffline.png";
import Star from "../../../assets/Star.png";
import ChatFlowModal from "../../ChatFlow/ChatFlowModal";
import CallFlowModal from "../../CallFlow/CallFlowModal";
import Login from "../../Login&Signup/Login";

import { useDispatch
  //  useSelector
   } from "react-redux";
// import { fetchSelectedAstrologerData } from "../../../store/selectedAstrologerSlice";
import { fetchData } from "../../../store/TarotSlice";
// import { Center, Spinner } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";

const Card = ({ info }) => {
  const token = localStorage.getItem("guruToken");
  //
  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${token}`
        )
        .then((response) => {
          //
          dispatch(
            selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
          );
        });
    }
    // eslint-disable-next-line
  }, [token]);

  const dispatch = useDispatch();
  // const data = useSelector((state) => state.data.data);
  // const status = useSelector((state) => state.data.status);
  // const error = useSelector((state) => state.data.error);
 

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  // const options = {
  //   // loop: true,
  //   // controlsClass: 'owl-controls',
  //   // center: true,
  //   nav: true,
  //   dots: false,
  //   margin: 8,
  //   // dotsEach: false,
  //   responsive: {
  //     0: {
  //       items: 2.1,
  //       margin: 8,
  //       nav: false,
  //       loop: true,
  //     },
  //     600: {
  //       items: 3.2,
  //       loop: true,
  //     },
  //     1000: {
  //       items: 5,
  //     },
  //   },
  // };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [chatBtnClicked, setChatBtnClicked] = useState(false);
  const [callBtnClicked, setCallBtnClicked] = useState(false);

  // const chatClicked = () => {
  //   setChatBtnClicked(true);
  //   onOpen();
  // };

  const callClicked = () => {
    setCallBtnClicked(true);
    onOpen();
  };

  const loginToken = localStorage.getItem("loginToken");
  // const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Container
        maxW={"6xl"}
        mt={{ base: "24px", md: "32px" }}
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        p={{ base: "0 6px", md: "auto" }}
      >
        {!loginToken ? (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "md" }}
          >
            <Login />
          </Modal>
        ) : (
          <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "xs", md: "md" }}
          >
            {chatBtnClicked && <ChatFlowModal />}
            {callBtnClicked && <CallFlowModal />}
          </Modal>
        )}

        <Stack id="topAstrologer" direction="column">
          {/* <OwlCarousel className="owl-theme" loop {...options}> */}
          {/* {info.map((info, index) => {
              return ( */}
          <Box
            w={{ base: "175px", md: "255px" }}
            // w={"full"}
            boxShadow={"xl"}
            rounded={"lg"}
            p={{ base: 3, md: 6 }}
            textAlign={"center"}
            border="2px solid #f6caa9"
            _hover={{
              border: "2px solid #FD9D54",
            }}
          >
            <Avatar
              size={{ base: "xl", md: "2xl" }}
              src={info.user.avatar.url}
              alt={"Avatar Alt"}
              mb={4}
              pos={"relative"}
              _after={{
                content: '""',
                w: 4,
                h: 4,
                bg: "green.300",
                border: "2px solid white",
                rounded: "full",
                pos: "absolute",
                bottom: 0,
                right: 3,
              }}
            />

            <NavLink
              to={`/astrologer/${info.user.firstName}${info.user.lastName}`}
              onClick={
                () => localStorage.setItem("guruToken", info.user.guru)
                // dispatch(fetchSelectedAstrologerData())
              }
            >
              <Heading
                fontSize={{ base: "12px", md: "18px" }}
                fontFamily={"body"}
                textAlign={"left"}
                mb={"4px"}
              >
                {info.user.firstName} {info.user.lastName}
              </Heading>
            </NavLink>

            <Box display="flex" alignItems={"center"} mb={"4px"}>
              <Image
                className="star"
                src={Star}
                alt="Star"
                mr={{ base: "8px", md: "12px" }}
                maxW={{ base: "10%", md: "70%" }}
              />
              <Text
                fontWeight={{ base: 500, md: 600 }}
                fontSize={{ base: "11px", md: "14px" }}
                color={"#090909"}
              >
                {info.rating} | {info.experience}{" "}
                <span style={{ color: "#727272" }}>Years</span>
              </Text>
            </Box>

            <Box display={"flex"} justifyContent={"space-around"} mb={"6px"}>
              <Text
                fontSize={{ base: "12px", md: "16px" }}
                minH={{ base: "36px", md: "48px" }}
                display={"flex"}
                alignItems={"center"}
                textAlign={"left"}
              >
                {info.specialization.map((curr) => {
                  return `${curr}, `;
                })}
              </Text>
              {/* <Text fontSize={{ base: "10px", md: "14px" }}>
                {info.skills[1]},
              </Text>
              <Text fontSize={{ base: "10px", md: "14px" }}>
                {info.skills[2]},
              </Text> */}
            </Box>
            <Box>
              <Text
                textAlign={"left"}
                fontSize={{ base: "16px", md: "25px" }}
                fontWeight={{ base: "700" }}
                color={"#525252"}
                display={"flex"}
                alignItems={"baseline"}
              >
                ₹{info.fee}/
                <Text
                  fontSize={{ base: "10px", md: "16px" }}
                  fontWeight={"700"}
                  color={"#525252"}
                >
                  min
                </Text>
              </Text>
            </Box>

            <Stack mt={4} direction={{ base: "row", md: "column" }} spacing={4}>
              {info.callAvailability === "offline" && (
                <Button
                  flex={1}
                  fontSize={{ base: "10px", md: "16px" }}
                  fontWeight={"700"}
                  color={"#515151"}
                  borderRadius={{ base: "4px", md: "6px" }}
                  py={{ base: "4px", md: "8px" }}
                  h={{ base: "30px", md: "auto" }}
                  bg={"#BEBEBE"}
                  _hover={{
                    cursor: "no-drop",
                  }}
                  _focus={{
                    bg: "#BEBEBE",
                  }}
                >
                  <Image
                    src={CallOffline}
                    alt="call_offline"
                    maxW={{ base: "50%", md: "70%" }}
                    mr={{ base: "8px", md: "16px" }}
                  />
                  Offline
                </Button>
              )}
              {info.callAvailability === "online" && (
                <>
                  <Button
                    flex={1}
                    fontSize={{ base: "10px", md: "16px" }}
                    fontWeight={"700"}
                    color={"#15AD00"}
                    borderRadius={{ base: "4px", md: "6px" }}
                    py={{ base: "4px", md: "8px" }}
                    h={{ base: "30px", md: "auto" }}
                    bg={"#C6F9BF"}
                    _hover={{
                      bg: "#99da91",
                    }}
                    _focus={{
                      bg: "#C6F9BF",
                    }}
                    onClick={() => {
                      callClicked();
                      localStorage.setItem("guruToken", info.user.guru);
                    }}
                  >
                    <Image
                      src={Call_icon}
                      alt="call_icon"
                      maxW={{ base: "50%", md: "70%" }}
                      mr={{ base: "8px", md: "16px" }}
                    />
                    Call
                  </Button>
                </>
              )}

              {info.callAvailability === "busy" && (
                <Button
                  flex={1}
                  fontSize={{ base: "10px", md: "16px" }}
                  fontWeight={"700"}
                  color={"#FF3C3C"}
                  borderRadius={{ base: "4px", md: "6px" }}
                  py={{ base: "4px", md: "8px" }}
                  h={{ base: "30px", md: "auto" }}
                  bg={"#FFC1C1"}
                  _hover={{
                    cursor: "no-drop",
                  }}
                  _focus={{
                    bg: "#FFC1C1",
                  }}
                >
                  <Image
                    src={CallBusy}
                    alt="call_busy"
                    maxW={{ base: "50%", md: "70%" }}
                    mr={{ base: "8px", md: "16px" }}
                  />
                  Busy
                </Button>
              )}

              {/* <Button
                flex={1}
                fontSize={{ base: "10px", md: "16px" }}
                fontWeight={"700"}
                color={"#15AD00"}
                borderRadius={{ base: "4px", md: "6px" }}
                py={{ base: "4px", md: "8px" }}
                bg={"#C6F9BF"}
                _hover={{
                  bg: "#99da91",
                }}
                _focus={{
                  bg: "#C6F9BF",
                }}
                //   onClick={onOpen}
                onClick={() => {
                  callClicked();
                  localStorage.setItem("guruToken", info.user.guru);
                }}
              >
                <Image
                  src={Call_icon}
                  alt="call_icon"
                  maxW={{ base: "50%", md: "70%" }}
                  mr={{ base: "8px", md: "16px" }}
                  objectFit="cover"
                />
                Call
              </Button> */}

              <Button
                // onMouseOver={() => setIsHovered(true)}
                // onMouseOut={() => setIsHovered(false)}
                flex={1}
                fontSize={{ base: "10px", md: "16px" }}
                fontWeight={"700"}
                color={"#747474"}
                borderRadius={{ base: "4px", md: "6px" }}
                py={{ base: "4px", md: "8px" }}
                h={{ base: "30px", md: "auto" }}
                bg={"#D1D1D1"}
                style={{ cursor: "no-drop" }}
                // _hover={{
                //   bg: "#D1D1D1",
                //   color: "#747474",
                // }}
                // _focus={{
                //   bg: "#5bc3d1",
                // }}
                // onClick={() => {
                //   chatClicked();
                //   // onOpen();
                // }}
              >
                <Image
                  // filter={isHovered ? "grayscale(100%)" : ""}
                  src={Chat_icon_gray}
                  alt="Chat_icon"
                  maxW={{ base: "50%", md: "70%" }}
                  mr={{ base: "8px", md: "16px" }}
                />
                Chat
              </Button>
            </Stack>
          </Box>
          {/* );
            })} */}
          {/* </OwlCarousel> */}
        </Stack>
      </Container>
    </>
  );
};

export default Card;
