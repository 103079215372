import call from "./call.svg";
import live from "./live.svg";
import busy from "./busy.svg";
import offline from "./offline.svg";
import busylive from "./busylive.svg";
import React, { useState } from "react";
import "./FloatButton.css";
import {
  Flex,
  Stack,
  Box,
  Text,
  HStack,
  VStack,
  Button,
  Image,
  Container,
  useDisclosure,
  Modal,
} from "@chakra-ui/react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CallFlowModal from "../CallFlow/CallFlowModal";
import Login from "../Login&Signup/Login";
import { useSearchParams } from "react-router-dom";
import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";

const FloatingButton = (props) => {
  const [searchParams] = useSearchParams();
  const guruToken = searchParams.get("id");
  const host = localStorage.getItem("host");
  console.log(guruToken);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const astroDetails = props?.astroDetails;
  console.log(astroDetails);
  const [callBtnClicked, setCallBtnClicked] = useState(false);
  const loginToken = localStorage.getItem("loginToken");

  const callClickedHandler = () => {
    localStorage.setItem("guruToken", astroDetails.user.guru);
    axios
      .get(
        `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${astroDetails?.user?.guru}`
      )
      .then((response) => {
        //
        dispatch(
          selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
        );
      });
    setCallBtnClicked(true);
    onOpen();
  };

  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );

  console.log("loggedInUserDetails Floating>>>>>>>>>", loggedInUserDetails);

  const cutFee = `₹ ${astroDetails?.cutfee}/min`;
  console.log(cutFee);
  return (
    <>
      {!loginToken ? (
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          size={{ base: "xs", md: "4xl" }}
        >
          <Login />
        </Modal>
      ) : (
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          size={{ base: "xs", md: "md" }}
        >
          {callBtnClicked && <CallFlowModal />}
        </Modal>
      )}
      <Box
        className="floating-button"
        //   border={"2px solid gray"}
        h={"90px"}
        w={"full"}
        display={{ base: "flex", md: "none" }}
        alignItems={"center"}
        justifyContent={"center"}
        position={"fixed"}
        bottom={0}
      >
        <Flex h={"80%"} w={"95%"} bgColor={"white"} gap={"16px"}>
          <Button
            onClick={() => {
              if (
                astroDetails.callAvailability === "online" ||
                astroDetails.callqueue.length > 0
              ) {
                callClickedHandler();
              }
            }}
            // as="button"
            w={"80%"}
            h={"75px"}
            bgColor={
              !loginToken
                ? "#3CCA3A" // Button color when user is not logged in
                : astroDetails.callAvailability === "busy"
                ? "#E6E6E6" // Button color when astroDetails.callAvailability is "busy"
                : astroDetails.callAvailability === "offline"
                ? "#E6E6E6" // Button color when astroDetails.callAvailability is "offline"
                : "#FF4C00" // Default button color when astroDetails.callAvailability is "online"
            }
            display={"flex"}
            borderRadius={"6px"}
            alignItems={"center"}
            isDisabled={
              !loggedInUserDetails?.userDetails?.normalUserJourney2 &&
              astroDetails?.mostTrusted
            }
          >
            <Box className="first" w={"65%"} display={"flex"} gap={"10px"}>
              <Box
                w={"22%"}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Image
                  src={
                    !loginToken
                      ? call // Button color when user is not logged in
                      : astroDetails.callAvailability === "busy"
                      ? busy // Button color when astroDetails.callAvailability is "busy"
                      : astroDetails.callAvailability === "offline"
                      ? offline // Button color when astroDetails.callAvailability is "offline"
                      : call // Default button color when astroDetails.callAvailability is "online"
                  }
                  alt="call"
                  w={"28px"}
                />
              </Box>
              <Box w={"78%"} display={"flex"} alignItems={"center"}>
                <Text
                  as="div"
                  color={
                    !loginToken
                      ? "white" // Text color when user is not logged in
                      : astroDetails.callAvailability === "busy"
                      ? "#FF4141" // Text color when astroDetails.callAvailability is "busy"
                      : astroDetails.callAvailability === "offline"
                      ? "gray" // Text color when astroDetails.callAvailability is "offline"
                      : "white" // Default text color when astroDetails.callAvailability is "online"
                  }
                  fontWeight={"500"}
                  fontSize={"23px"}
                >
                  {!loginToken
                    ? "Free Call" // Text when user is not logged in
                    : astroDetails.callAvailability === "busy"
                    ? "Busy" // Text when astroDetails.callAvailability is "busy"
                    : astroDetails.callAvailability === "offline"
                    ? "Offline" // Text when astroDetails.callAvailability is "offline"
                    : "Call Now"}{" "}
                  {/* Default text when astroDetails.callAvailability is "online" */}
                </Text>
              </Box>
            </Box>
            <Box
              flexDirection={"column"}
              className="sec"
              w={"35%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                as="div"
                color={
                  !loginToken
                    ? "white" // Text color when user is not logged in
                    : astroDetails.callAvailability === "busy"
                    ? "#FF4141" // Text color when astroDetails.callAvailability is "busy"
                    : astroDetails.callAvailability === "offline"
                    ? " #808080" // Text color when astroDetails.callAvailability is "offline"
                    : "white" // Default text color when astroDetails.callAvailability is "online"
                }
                fontWeight={"500"}
                fontSize={"17px"}
              >
                {loggedInUserDetails?.userDetails?.website[0] ===
                  "gurucool.co" &&
                host === "myguruji" &&
                loggedInUserDetails?.userDetails?.consultationCount === 0 &&
                !loggedInUserDetails?.userDetails?.normalUserJourney2
                  ? "Free"
                  : `₹ ${astroDetails?.fee}/min`}
              </Text>
              <Text
                as="div"
                color={
                  !loginToken
                    ? "white" // Text color when user is not logged in
                    : astroDetails.callAvailability === "busy"
                    ? "#FF4141" // Text color when astroDetails.callAvailability is "busy"
                    : astroDetails.callAvailability === "offline"
                    ? " #808080" // Text color when astroDetails.callAvailability is "offline"
                    : "white" // Default text color when astroDetails.callAvailability is "online"
                }
                fontWeight={"500"}
                fontSize={"11px"}
                textDecorationLine={
                  loggedInUserDetails?.userDetails?.website[0] ===
                    "gurucool.co" &&
                  host === "myguruji" &&
                  !loggedInUserDetails?.userDetails?.normalUserJourney2 &&
                  loggedInUserDetails?.userDetails?.consultationCount === 0
                    ? "none"
                    : "line-through"
                }
                // display={astroDetails?.cutfee === "" ? "block" : "none"}
              >
                {loggedInUserDetails?.userDetails?.website[0] ===
                  "gurucool.co" &&
                host === "myguruji" &&
                loggedInUserDetails?.userDetails?.consultationCount === 0 &&
                !loggedInUserDetails?.userDetails?.normalUserJourney2
                  ? `${loggedInUserDetails?.userDetails?.timeWallet} Min`
                  : cutFee}
              </Text>
            </Box>
          </Button>
          <Button
            // as="button"
            boxShadow={"0px 3px 4px 0px rgba(0, 0, 0, 0.20)"}
            w={"20%"}
            h="75px"
            borderRadius={"6px"}
            bgColor={astroDetails?.liveStatus ? "#FF4C00" : "#E6E6E6"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={() => {
              if (astroDetails?.liveStatus === true) {
                window.location.href = `/live/${astroDetails?.user?.firstName}${astroDetails?.user?.lastName}?id=${astroDetails?.user?.guru}`;
              }
            }}
            isDisabled={
              !loggedInUserDetails?.userDetails?.normalUserJourney2 &&
              astroDetails?.mostTrusted
            }git 
          >
            <Image
              src={astroDetails?.liveStatus ? live : busylive}
              alt="live"
              w={"35px"}
            />
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default FloatingButton;
