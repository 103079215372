const doctors = [
  {
    id: "1",
    firstName: "Dr Parul",
    lastName: "Anand",
    exp: "10",
    imageURL: "https://www.info.guruji.life/images/dr-parul.png",
    followers: "8.3",
    served: "7.4",
    heading:
      "Ayurveda physician, Gynaecologist, Cosmetologist and lifestyle expert",
    description:
      "BAMS, MD Ayurveda with clinical experience of almost 10 years. She has done her specialization from prestigious Ayurveda Institutes of Govt of India. She has been Assistant Professor in Ayurvedic Medical College and has worked as Research Fellow with Govt of India. She has many research publications and is Editor-in-chief of reputed international journals of Ayurveda. She is clinically expert in the fields of Ayurveda Cosmetology , gynecology, child health care, communicable diseases, lifestyle diseases, Ayurveda prakriti determination, Ayurveda lifestyle modifications to present day needs and much more.",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Joint/ Bone health",
      "Digestion related problems",
    ],
  },
  {
    id: "2",
    firstName: "Dr Bhavana",
    lastName: "",
    exp: "7",
    imageURL: "https://www.info.guruji.life/images/dr-bhavna.png",
    followers: "3.7",
    served: "2.4",
    heading: "Panchakarma MD",
    description:
      "Dr Bhavna is an ayurveda physician and has experience of 7 years in these field.She pursued her BAMS degree from National Institute of Ayurveda Jaipur Rajasthan. Bhavna completed postgraduate in panchakarma specialist from National Institute of Ayurveda Jaipur Rajasthan. She is silver medalist in Rajasthan Ayurveda university in BAMS degree. She has been an active participant in International ayurveda conferences, international seminar, national seminar,workshops and national webinar and very passionate about the latest reaserch in field of Ayurveda which inspire her to serve the society with the ancient science of Ayurveda with advanced techniques.",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Joint/ Bone health",
      "Digestion related problems",
      "Thyroid dysfunction",
      "Diabetes",
      "Weight loss/ Obesity",
      "Piles/ Fistula",
    ],
  },
  {
    id: "3",
    firstName: "Dr Sheetal",
    lastName: "Korade",
    exp: "7",
    imageURL: "https://www.info.guruji.life/images/dr-shital-karode.png",
    followers: "8.3",
    served: "6.4",
    heading: "",
    description:
      "Dr. Sheetal Korade (BAMS), founder and consultant at Atulya Ayurveda Panchakarma & Wellness Centre. She is a young , vivacious and experienced health professional who has been working in the field of Ayurveda , panchakarma ,Beauty , nutrition & Wellness for last 7 years. After working with renowned organisations as Ayurveda Consultant , Teacher and tTrainer in Goa, Bangalore and and Mumbai she started ATULEYA AYURVEDA (Panchakarma & Wellness Centre) in Thane (india) with the aim of propagating traditional Ayurveda with modern touch to todays's world. She believes Ayurveda is not just an ancient system. Its a science of the 21st century and beyond . It has got a great outlook which comprises all time to come.",
    specialization: [
      "Panchkarma",
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Joint/ Bone health",
      "Digestion related problems",
      "Thyroid dysfunction",
      "Diabetes",
      "Weight loss/ Obesity",
    ],
  },
  {
    id: "4",
    firstName: "Dr Kanchan",
    lastName: "Sharma",
    exp: "4",
    imageURL: "https://www.info.guruji.life/images/dr-kanchan-sharma.png",
    followers: "3.3",
    served: "2.4",
    heading: "General physician",
    description:
      "Vaidya Kanchan Sharma (B.A.M.S). Worked and Trained intensely in Ayurveda. ( Emergency management, Life Style Management, Mantra Chikitsa, Panchakarma, Yoga ). Worked in ICU and treated the patients with ayurveda. Trained traditional Pulse Expert. Also had the exposure of Heart and Cancer patients in ayurveda (1year). Educating people about ayurveda. Worked in Karnataka, Gujarat and Himachal Pradesh. Born and brought up in Himachal ( essence of nature )",
    specialization: [
      "Panchkarma",
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Joint/ Bone health",
      "Digestion related problems",
      "Thyroid dysfunction",
      "Diabetes",
      "Weight loss/ Obesity",
    ],
  },
  {
    id: "5",
    firstName: "Dr Shaveta",
    lastName: "Jasra",
    exp: "6",
    imageURL: "https://www.info.guruji.life/images/dr-shaveta.png",
    followers: "6.8",
    served: "5.4",
    heading: "Lifestyle guide, Nutritionist, Mental health Related",
    description:
      "Dr.Shaveta Jasra is Ayurveda consultant, lifestyle expert and founder of Evolve Get Inspired (lifestyle alignment). She has done diploma course in holistic therapies from Kochi, Kerala . She is dynamic, mind body enthusiast and holistic healer who has various experiences in the health field of emergency dptt., lifestyle improvement, corporate industry, celebrity nutrition, yoga, meditation, reiki and self care. Her mission is to spread disease free living by making healthy improvements into the lifestyle with achievable goals . She believes if the why is clear, the how becomes clear.",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Joint/ Bone health",
      "Digestion related problems",
      "Thyroid dysfunction",
      "Diabetes",
      "Weight loss/ Obesity",
    ],
  },
  {
    id: "6",
    firstName: "Dr Priyanka",
    lastName: "Sonawane",
    exp: "7",
    imageURL: "https://www.info.guruji.life/images/dr-priyanka.png",
    followers: "6.8",
    served: "5.4",
    heading: "Hair and Skin Expert",
    description:
      "Graduated from the Maharashtra University of Health Science Nashik 2015 . Dr Priyanka did her BAMS from Dhule and since has an experience of 7 year .She is also specialised in Panchakarma from Dhanvantari Ayurved Shikshan Sansthan Dhule under the guidance of renowned Ayurvedacharya K. P.Joshi Sir.She is a strong believer of Ayurveda and believes every illness can cure by Ayurveda by knowing a root cause and by doing some lifestyle changes.Her patient refer her as an avid and patient listener and an expert advisor.She is member of Ayurved Vyaspeeth",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Joint/ Bone health",
      "Digestion related problems",
      "Thyroid dysfunction",
      "Diabetes",
      "Weight loss/ Obesity",
    ],
  },
  {
    id: "7",
    firstName: "Dr Kirti",
    lastName: "Sachdeva",
    exp: "10",
    imageURL: "https://www.info.guruji.life/images/dr-kirti.png",
    followers: "6.8",
    served: "5.4",
    heading:
      "Panchkarma & life style disorders specialist, Health and Wellness coach",
    description:
      "Dr.Kirti Sachdeva (B.A.M.S ,M.D in Ayurveda) D.A.T,N.D.D.Y . Associate professor & consultant in department of panchkarma of Ayurvedic medical College She is an ayurvedic expert who is confidently treating life style disorders like obesity, diabetes, thyroid dysfunction,PCOS, digestive problems and respiratory problems. She strongly believe that we are,what we eat and do .Food is our life,and due to unhealthy eating habbits and sedentary lifestyle ,we all got these type of life style disorders",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Joint/ Bone health",
      "Digestion related problems",
      "Thyroid dysfunction",
      "Diabetes",
      "Weight loss/ Obesity",
    ],
  },
  {
    id: "8",
    firstName: "Dr Roshani",
    lastName: "Verma",
    exp: "10",
    imageURL: "https://www.info.guruji.life/images/dr-roshani.png",
    followers: "12.3",
    served: "11.4",
    heading: "Lifestyle Expert, Yoga & Meditation Specialist",
    description:
      "Dr. Roshani Verma, M.D. Swasthavritta & Yoga, 10 Years of Clinical Practice. Well known International Researcher and Ayurveda Physician Graduation and Post graduation from Govt. Ayurveda College and Hospital, M.P., India. Editorial Board Member in more than 10 International Journals. More than 100 Lectures/Presentations and CMEs Specialized in Ayurveda, Yoga and a Lifestyle Expert Treating patients through Ayurveda Medicine, Clinical Yoga, Diet and Nutrition and Naturopathy. Many National and International Award as- 1. Ayur Women National Award 2. Corona Warrior Award 3. Guinness book of world record holder Youtube channel for public awareness- Light on Ayurveda By Dr. Roshani Verma",
    specialization: [
      "Lifestyle Expert",
      "Diet and Nutrition",
      "Naturopathy",
      "Preventive Panchkarma",
      "Thyroid dysfunction",
      "Diabetes",
      "Ayurveda & Yoga",
      "Weight loss/ Obesity",
      "Digestion related problems",
    ],
  },
  {
    id: "9",
    firstName: "Dr Himanshu",
    lastName: "Verma",
    exp: "7",
    imageURL: "https://www.info.guruji.life/images/dr-himanshu-verma.png",
    followers: "5.3",
    served: "3.4",
    heading: "Ayurveda physician, Joint and Bone Health, Skin",
    description:
      "Hello everyone.. I am doctor Himanshu Verma I am ayurvedic doctor I have experience of more than 5 years in ayurvedic practice . I am here to treat your all type of disease and I am specialized in skin disease, hair fall , piles , joint care and all type of sexual disease .We care we heal we cure your overall health..",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Hair problems",
      "Joint/ Bone health",
      "Piles/ Fistula",
      "Digestion related problems",
    ],
  },
  {
    id: "10",
    firstName: "Dr Indu",
    lastName: "Sharma",
    exp: "10",
    imageURL: "https://www.info.guruji.life/images/dr-indu.png",
    followers: "8.3",
    served: "7.4",
    heading: "",
    description:
      "Assistant professor and consultant in Mjf ayurvedic hospital jaipur My qualifications - MS, BAMS from national institute of ayurveda jaipur Work experience - total having 10 yr experience in Ayurvedic medicine n research. Conducted more than 40 medical camps. Skills- knowledge of Kerala ayurveda panchakarma. I'm treating all allergies problems ,female related problems, eye and Ent problems, skin problems .",
    specialization: [
      "Panchkarma",
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Digestion related problems",
    ],
  },
  {
    id: "11",
    firstName: "Dr Jyoti",
    lastName: "Kumar",
    exp: "10",
    imageURL: "https://www.info.guruji.life/images/dr-jyoti.png",
    followers: "8.7",
    served: "6.4",
    heading: "",
    description:
      "Ayurvedic Specialist with more than 10 years of Global Experience. Key area is weight management, Patient Counselling, wellness, Breast Cancer, immunity building & healthy life style. Sexual Problems & infertility Management. Have solved more than 100 Infertility cases successfully. Helped more than 200 families to stay health & happy married life.",
    specialization: [
      "Weight Management",
      "Patient Counselling",
      "Breast Cancer",
      "Immunity Building",
      "Sexual Problems",
      "Infertility Management",
    ],
  },
  {
    id: "12",
    firstName: "Dr Sanjeev",
    lastName: "Chander",
    exp: "10",
    imageURL: "https://www.info.guruji.life/images/dr-sanjeev.png",
    followers: "6.8",
    served: "7.4",
    heading: "",
    description:
      "Ayurved bahut hi purani chikitsa hai. Ayurveda Shabd do shabdon se milkar Bana hai ayur aur Veda ayur ka Arth hai jivan aur Veda ka Arth hai Gyan vigyan isliye Ayurveda ka Arth hai jivan ka gyan vigyan aur jivan jeene ki Kala Isliye aap bhi ayurved Apnaye aur nirogi jivan payein.",
    specialization: [
      "Panchkarma",
      "Skin problems",
      "Hair problems",
      "Thyroid dysfunction",
      "Joint/ Bone health",
      "Weight loss/ Obesity",
      "Digestion related problems",
    ],
  },
  {
    id: "13",
    firstName: "Dr Pankaj",
    lastName: "",
    exp: "11",
    imageURL: "https://www.info.guruji.life/images/dr-pankaj.png",
    followers: "",
    served: "",
    heading: "Panchkarma specialist, Master in skin disease",
    description:
      "Doctor in of Medicine(MD) Dravyaguna department. Institute : National Institute Of Ayurveda, Jaipur ,Rajasthan. University : Dr Sarvepalli Radhakrishnan Rajasthan Ayurved University. Bachelor Of Ayurvedic Medicine And Surgery (B.A.M.S). Diploma in Computer Science (DCS). Total having 3 year research experience in ayurvedic medicine. One month training in Arya Vaidya Sala Kottakal, Kerala. Conducted more than 40 medical camps. Working as senior consultant in Dr B. L. Ayurveda and Panchkarma since Nov 2018 to till date",
    specialization: [
      "Panchkarma",
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Thyroid dysfunction",
      "Diabetes",
      "Joint/ Bone health",
      "Weight loss/ Obesity",
      "Digestion related problems",
    ],
  },
  {
    id: "14",
    firstName: "Dr Guneet",
    lastName: "Taneja",
    exp: "14",
    imageURL: "https://www.info.guruji.life/images/dr-guneet.png",
    followers: "12.3",
    served: "11.4",
    heading: "",
    description:
      "Graduated from the land of Gods, Uttarakhand, Dr Guneet did her BAMS from Dehradun and since has an experience of 14 years as a feather in her cap. She is an ardent ayurvedic practitioner and believes in rectifying the illnesses by lifestyle and habit rectification rather than excess medicine dependency. She is also a postgraduate certified Panchkarma specialist from National Research Institute of Panchakarma, Kerala. Her patients refer her as an avid and patient listener and an expert advisor.",
    specialization: [
      "Panchkarma",
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Thyroid dysfunction",
      "Diabetes",
      "Joint/ Bone health",
      "Weight loss/ Obesity",
      "Digestion related problems",
    ],
  },
  {
    id: "15",
    firstName: "Dr Monika",
    lastName: "Sharma",
    exp: "6",
    imageURL: "https://www.info.guruji.life/images/dr-monika.png",
    followers: "3.7",
    served: "2.4",
    heading: "",
    description:
      "An Ayurveda consultant skilled in Holistic Health, clinical approach and Ayurveda Treatment with an Aim to bring health herbally and naturally.",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Thyroid dysfunction",
      "Diabetes",
      "Joint/ Bone health",
      "Weight loss/ Obesity",
      "Piles/ Fistula",
      "Digestion related problems",
      "Agni karma",
      "Panchkarma",
    ],
  },
  {
    id: "16",
    firstName: "Dr Gitika",
    lastName: "Diwan",
    exp: "7",
    imageURL: "https://www.info.guruji.life/images/dr-gitika.png",
    followers: "3.7",
    served: "5.4",
    heading: "",
    description:
      "I’m a 4th generation Ayurveda physician and I have 7 years of work experience. I’m the consultant Ayurveda physician at Janam health clinic, Ahmedgarh and my camp named #sheforherbyDrGitikaDiwan has covered more than 50 school and made more than 15000 people aware about hand hygiene, breast cancer, menstrual hygiene, blood group awareness and mental health awareness..",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Thyroid dysfunction",
      "Diabetes",
      "Piles/ Fistula",
      "Digestion related problems",
    ],
  },
  {
    id: "17",
    firstName: "Dr Anupama",
    lastName: "Sharma",
    exp: "20",
    imageURL: "https://www.info.guruji.life/images/dr-anupama.png",
    followers: "9.8",
    served: "8.5",
    heading: "Infertility & Gynaecological disorders, Panchkarma Specialist",
    description:
      "Divy Ayurveda (Panchkarma and Infertility treatment centre) is an Ayurvedic clinic where patients are treated with herbal medicines as well as Panchkarma therapies which work as natural remedies to get rid of all problems. Ayurveda, which goes back to prehistoric times is not just a branch of medicine but a way of life. Therefore Dr. Anupama Sharma, who holds a degree in BAMS from the University of Delhi and diploma in naturopathy and Yoga from Delhi, believes in providing the most authentic Ayurvedic treatments. She also treats all kinds of infertility patients with a fairly good success rate ranging from tubal blockes to hormonal imbalance, PCOD, and unexplained infertility through various Panchkarma as well as Panchkarma therapies which work as natural remedies to get rid of all problems. Ayurveda, which goes back to prehistoric times is not just a branch of medicine but a way of life.",
    specialization: [
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Thyroid dysfunction",
      "Diabetes",
      "Joint/ Bone health",
      "Weight loss/ Obesity",
      "Digestion related problems",
    ],
  },
  {
    id: "18",
    firstName: "Dr Anu",
    lastName: "Jaiswal",
    exp: "21",
    imageURL: "https://www.info.guruji.life/images/dr-anu.png",
    followers: "12.3",
    served: "11.4",
    heading: "Panchkarma specialist, Ayurveda consultant",
    description:
      "Dr. Anu Jaiswal is a practising Ayurvedic Physician with an experience of 21years. She graduated from the Govt. Rishikul ayurvedic College & Hospital Haridwar, Uttarakhand, India.She is ayurvedacharya and panchkarma expert and well versed in the use of shock-wave treatment, PEMF , I-lipo, and has diligently worked in combining Ayurveda with technology for over 21 years, for timeless and more effective therapies. She started the VEDIC SUTRA WELLNESS CENTER in 2017 and has since been providing innovative Ayurveda Therapies for curing Body Ailments and disorders of mind.",
    specialization: [
      "Panchkarma",
      "Skin problems",
      "Hair problems",
      "Gynecological problems (PCOD)",
      "Thyroid dysfunction",
      "Diabetes",
      "Joint/ Bone health",
      "Weight loss/ Obesity",
      "Digestion related problems",
    ],
  },
];

export default doctors;
