import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import HornSound from "../../../assets/horksound.svg";
import {
  firebaseApp,
  analytics,
  logEvent,
} from "../../../firebaseEvents/firebase"

const SearchBottomBanner = () => {
  return (
    <Container
      display={"flex"}
      justifyContent="center"
      bg="white"
      py={{ base: "0px", md: "24px" }}
      maxW={"full"}
      h={{ base: "160px", md: "368px" }}
      px={{ md: "0" }}
      p={{ base: "10px" }}
      pt={{ base: "5px" }}
      mb={{base:"5px", md:"20px"}}
    >
      <Box
        bg="linear-gradient(145deg, #FF964F 0%, #FFF 100%)"
        borderRadius="0.5rem"
        h={{ base: "132px", md: "320px" }}
        mt={{ base: "6px" , md : "0px" }}
        display={"flex"}
        alignItems={"center"}
        w={{ base: "96%" }}
        px={{md:"4"}}
        border={'none !important'}
        maxW={{md:"6xl"}}
       
      >
        <Flex gap={{ base: "auto", md: "3.5rem" }}>
          <Box
            p={{ base: "12px" }}
            w={{ base: "auto", md: "305px" }}
            h={{ md: "272px" }}
            ml={{ base: "auto", md: "2rem" }}
          >
            {" "}
            {/* Corrected the typo in the padding attribute */}
            <Image
              src={HornSound}
              w={{ base: "115px", md: "17rem" }}
              h={{ md: "15rem", base: "95px" }}
              position={{ base: "relative" }}
              top={{ base: "10px" }}
            />
          </Box>
          <Box
            lineHeight={{ base: "2rem", md: "3.25rem" }}
            pt={{ base: "1rem", md: "1rem" }}
            position={{ base: "relative" }}
            // left={{ base: "5px" }}
            // ml={{ base: "6px" }}
          >
            <Heading
              color="#952C00"
              fontWeight={"600"}
              fontSize={{ base: "17px", md: "34px" }}
              p={0}
              m={0}
              lineHeight="1.25rem"
              w={{ base: "9rem", md: "25rem" }}
              // border={"2px solid yellow"}
              //  width={{md:"27rem"}}
              mb={{ base: "10px" }}
              position={"relative"}
              top={{ md: "25px" }}
            >
              Get Free Consultation
            </Heading>
            <Text
              color="#952C00"
              fontWeight={{ md: "600", base: "600" }}
              fontSize={{ base: "13px", md: "17px" }}
              p={0}
              m={0}
              position={"relative"}
              top={{ base: "-8px", md: "25px" }}
            >
              Recharge 1₹ Get 100₹ in wallet
            </Text>
            <Button
              as="a"
              href="/wallet"
              position={"relative"}
              top={{ base: "-8px", md: "60px" }}
              bg="#FF6726"
              w={{ base: "80px", md: "137px" }}
              h={{ base: "24px", md: "51px" }}
              color="white"
              boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
              alignItems="center"
              justifyContent="center"
              my="3px"
              fontSize={{ base: "12.1px", md: "18px" }}
              // mb={{base:"10px"}}
              onClick={logEvent(analytics, "Rs1recharge_banner_click")}
            >
              Recharge
            </Button>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default SearchBottomBanner;
