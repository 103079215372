import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import Gurucool from "./GurucoolApp";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { Helmet } from "react-helmet";
import { hydrate, render } from "react-dom";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Provider store={store}>
//         <Helmet
//           onChangeClientState={(newState) =>
//             window.history.replaceState(newState.title, "", newState.baseTag)
//           }
//         >
//           <title>
//             Gurucool - Online Astrology Consultation: Talk to Astrologer
//           </title>
//           <meta
//             name="description"
//             content="Gain insights on love, career & more. Expert astrologers. Unlock your potential with Gurucool's online astrology consultation."
//           />
//           <meta
//             name="keywords"
//             content="indian astrology ,
//             vastu consultant near me   ,
//             free astrology consultation  ,
//             best astrologer in india     ,
//             astrology predictions       ,
//             best astrologer near me      ,
//             talk to astrologer        ,
//             astrologer near me        ,
//             online jyotish        ,
//             chat with astrologer online free   ,
//             best astrology app             ,
//             astrologer near me with fees   ,
//             horoscope prediction      ,
//             online horoscope  ,
//             online free call  ,
//             astrology horoscope  ,
//             astrology by date of birth  ,
//             jyotish near me     ,
//             online astrology consultation  ,
//             astrology online"
//           />
//         </Helmet>
//         <HMSRoomProvider>
//           <Gurucool />
//         </HMSRoomProvider>
//       </Provider>
//     </BrowserRouter>
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes() && window.snapSaveState === undefined) {
  hydrate(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <Helmet
            onChangeClientState={(newState) =>
              window.history.replaceState(newState.title, "", newState.baseTag)
            }
          >
            <title>
              Gurucool - Free Online Astrology Predictions by Expert Tarot card
              & Vedic Astrologer
            </title>
            <meta
              name="description"
              content="Consult with the best astrologers at Gurucool and get the guidance of Vedic, Tarot, Kundli matching, Chakra, Ayurveda medicine, Numerology, Zodiac, Vastu Shastra, Palmistry, and much more."
            />
            <meta
              name="keywords"
              content="Gurucool, Gurucool life, best astrologers, vedic, tarot card reading, Kundli matching, Chakra healing, Ayurveda medicine, Numerology, Zodiac sign, Vastu Shastra, Palmistry, daily horoscope, best astrology readings, chat with astrologer, call with astrologer, astrologers near me, best astrologer in india online, gurucool registration, mental wellness"
            />
            <meta
              name="description"
              content="Gurucool is the best astrology website for online astrology predictions from the best astrologers of India. Our astrologer can get answers to all your worries."
            />
            <meta
              name="description"
              content="Get accurate astrology predictions from expert astrologers at Gurucool, the best online astrology website in India. Chat with astrologers and get answers to all your worries about love, career, health, and more. Try our free astrology chat and see what the stars have in store for you!"
            />
            <meta
              name="keywords"
              content="astrology predictions, expert astrologers, online astrology website, free astrology chat, talk to astrologer online, career horoscope, Vastu astrology, horoscope astrology, astroyogi, best astrologers near me, my horoscope online"
            />
          </Helmet>
          <HMSRoomProvider>
            <Gurucool />
          </HMSRoomProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <Helmet
            onChangeClientState={(newState) =>
              window.history.replaceState(newState.title, "", newState.baseTag)
            }
          >
            <title>
              Gurucool - Free Online Astrology Predictions by Expert Tarot card
              & Vedic Astrologer
            </title>
            <meta
              name="description"
              content="Consult with the best astrologers at Gurucool and get the guidance of Vedic, Tarot, Kundli matching, Chakra, Ayurveda medicine, Numerology, Zodiac, Vastu Shastra, Palmistry, and much more."
            />
            <meta
              name="keywords"
              content="Gurucool, Gurucool life, best astrologers, vedic, tarot card reading, Kundli matching, Chakra healing, Ayurveda medicine, Numerology, Zodiac sign, Vastu Shastra, Palmistry, daily horoscope, best astrology readings, chat with astrologer, call with astrologer, astrologers near me, best astrologer in india online, gurucool registration, mental wellness"
            />
            <meta
              name="description"
              content="Gurucool is the best astrology website for online astrology predictions from the best astrologers of India. Our astrologer can get answers to all your worries."
            />
            <meta
              name="description"
              content="Get accurate astrology predictions from expert astrologers at Gurucool, the best online astrology website in India. Chat with astrologers and get answers to all your worries about love, career, health, and more. Try our free astrology chat and see what the stars have in store for you!"
            />
            <meta
              name="keywords"
              content="astrology predictions, expert astrologers, online astrology website, free astrology chat, talk to astrologer online, career horoscope, Vastu astrology, horoscope astrology, astroyogi, best astrologers near me, my horoscope online"
            />
          </Helmet>
          <HMSRoomProvider>
            <Gurucool />
          </HMSRoomProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
}
