import React from 'react';
import Clickviewmore from '../../Clickviewmore';
import Horosscope from '../Horosscope';
import Formpersonalized2 from '../Formpersonalized2';

export default function Taurus1() {
  return (
    <>
    <Clickviewmore name={"Taurus"} />
    <Horosscope/>
    <Formpersonalized2/>
    </>
  )
}
