import React, { useState } from "react";
// Import Styling For Pagination
import "../Profile.css";
import ReactPaginate from "react-paginate";
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import nodata from "../Icons/Group 52624.svg";

const RechargeHistory = ({ userDetails }) => {
  //
  function convertIst(date) {
    const utcDate = new Date(date);
    const istDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDate.toLocaleString();
  }

  const calculateGST = (amount) => {
    const subtotal = amount / 1.18; // Assuming 18% GST
    const gst = amount - subtotal;
    return { subtotal, gst };
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const rechargeUpiItemsToDisplay =
    userDetails?.userDetails?.paymentsDetails.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
  // console.log("Recharge History", rechargeUpiItemsToDisplay.length);
  // console.log(userDetails);
  return (
    <>
      {rechargeUpiItemsToDisplay?.length === 0 && userDetails?.userDetails?.StripeInvoice?.length === 0 ? (
        <>
          <Image m={"auto"} src={nodata} />
          <Text
            textAlign={"center"}
            fontSize={"20px"}
            fontWeight={"500"}
            color={"grey"}
          >
            No Recharge Data Available
          </Text>
        </>
      ) : (
        <>
          {" "}
          <Box
            // h={{ md: "381px" }}
            w={"100%"}
            // borderRadius={"23px"}
            // border={"2px solid #FFC293"}
            py={{ md: "32px", base: "8px" }}
            px={{ md: "28px", base: "8px" }}
            bg={"#FAFAFA"}
          >
            {/* <Heading
          textAlign={"center"}
          fontSize={{ md: "24px", base: "18px" }}
          fontWeight={"500"}
        >
          Recharge History
        </Heading> */}
            <Tabs
              variant={"solid-rounded"}
              colorScheme="green"
              mx={"auto"}
              mt={{ md: "32px", base: "19px" }}
            >
              <TabList justifyContent={"center"} border={"none"}>
                <Tab
                  _selected={{ bg: "#FF5E1A", color: "white" }}
                  bg={"#FFE1CA"}
                  borderRadius={"6px 0 0 6px"}
                  w={{ md: "92px", base: "92px" }}
                  fontSize={{ md: "md", base: "sm" }}
                >
                  UPI
                </Tab>
                <Tab
                  _selected={{ bg: "#FF5E1A", color: "white" }}
                  bg={"#FFE1CA"}
                  borderRadius={"0 6px 6px 0"}
                  w={{ md: "92px", base: "92px" }}
                  fontSize={{ md: "md", base: "sm" }}
                >
                  Card
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    flexWrap={"wrap"}
                    gap={{ md: "18px" }}
                    justifyContent={"center"}
                  >
                    {rechargeUpiItemsToDisplay?.length===0 &&  <Center>No Data Available</Center>}
                    {rechargeUpiItemsToDisplay.map((curr) => {
                      return (
                        <Box
                          p={{ md: "16px", base: "9px" }}
                          w={{ md: "325px" }}
                          bg={"white"}
                          // mx={"auto"}
                          mt={{ base: "10px", md: "unset" }}
                        >
                          <HStack
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Text
                              mb={0}
                              fontSize={{
                                md: "19px",
                                base: "18px",
                              }}
                              fontWeight={"500"}
                              color={
                                curr.status === "captured"
                                  ? "#38CC24"
                                  : "#FF2D2D"
                              }
                            >
                              {curr.status === "captured"
                                ? "Success"
                                : "Failed"}
                            </Text>
                            <Text
                              mb={0}
                              fontSize={{
                                md: "12px",
                                base: "11px",
                              }}
                            >
                              Txn：{curr._id}
                            </Text>
                          </HStack>
                          <Divider
                            mt={{ md: "6px", base: "4px" }}
                            mb={0}
                            color={"orange.200"}
                          />
                          <VStack spacing={"8px"} py={"12px"}>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              w={"100%"}
                            >
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Time
                              </Text>
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                {convertIst(curr.createdAt)}
                              </Text>
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              w={"100%"}
                            >
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Method
                              </Text>
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                {curr.method}
                              </Text>
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              w={"100%"}
                            >
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Subtotal
                              </Text>
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                {curr.userAmount}
                              </Text>
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              w={"100%"}
                            >
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Tax
                              </Text>
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                {curr.gst}
                              </Text>
                            </HStack>
                          </VStack>
                          <Divider
                            mb={{ md: "8px" }}
                            mt={0}
                            color={"orange.200"}
                          />
                          <HStack
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            w={"100%"}
                          >
                            <Text
                              mb={0}
                              fontSize={{ md: "16px" }}
                              // color={"#707070"}
                            >
                              Total
                            </Text>
                            <Text
                              mb={0}
                              fontSize={{ md: "16px" }}
                              // color={"#707070"}
                            >
                              ₹ {curr.userAmount + curr.gst}
                            </Text>
                          </HStack>
                        </Box>
                      );
                    })}
                  </Flex>
                  <ReactPaginate
                    previousLabel="<< Prev"
                    nextLabel="Next >>"
                    pageCount={Math.ceil(
                      userDetails?.userDetails?.paymentsDetails.length /
                        itemsPerPage
                    )}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    previousLinkClassName="pagination__link"
                    nextLinkClassName="pagination__link"
                    disabledClassName="pagination__link--disabled"
                    activeClassName="pagination__link--active"
                    // style={paginationStyles}
                  />
                </TabPanel>
                <TabPanel>
                  <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    flexWrap={"wrap"}
                    gap={{ md: "18px" }}
                    justifyContent={"center"}
                  >
                    {userDetails.userDetails.StripeInvoice.length === 0 && (
                      <Center>No Data Available</Center>
                    )}
                    {userDetails.userDetails.StripeInvoice.map((curr) => {
                      //   const { subtotal, gst } = calculateGST(curr.amount);
                      //   {
                      //     "_id": "649bf08dfa29bfe4e1053019",
                      //     "user": "649beddcfa29bfe4e104ff88",
                      //     "uid": 4007,
                      //     "status": "succeeded",
                      //     "amount": 63,
                      //     "userAmount": 63,
                      //     "gst": 0,
                      //     "offerPrice": 1,
                      //     "currency": "inr",
                      //     "customer": "cus_OAE67oQQXaep39",
                      //     "phone": "9856652725",
                      //     "paymentGateway": "Stripe",
                      //     "createdAt": "2023-06-28T08:34:21.385Z",
                      //     "updatedAt": "2023-06-28T08:35:07.344Z",
                      //     "__v": 0
                      // }

                      return (
                        <Box
                          p={{ md: "16px", base: "9px" }}
                          w={{ md: "325px" }}
                          bg={"white"}
                          // mx={"auto"}
                          mt={{ base: "10px", md: "unset" }}
                        >
                          <HStack
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Text
                              mb={0}
                              fontSize={{
                                md: "19px",
                                base: "18px",
                              }}
                              fontWeight={"500"}
                              color={"#38CC24"}
                            >
                              {curr.status}
                            </Text>
                            <Text
                              mb={0}
                              fontSize={{
                                md: "14px",
                                base: "12px",
                              }}
                            >
                              Txn：{curr._id}
                            </Text>
                          </HStack>
                          <Divider
                            mt={{ md: "6px", base: "4px" }}
                            mb={0}
                            color={"orange.200"}
                          />
                          <VStack spacing={"8px"} py={"12px"}>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              w={"100%"}
                            >
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Time
                              </Text>
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                {convertIst(curr.createdAt)}
                              </Text>
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              w={"100%"}
                            >
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Method
                              </Text>
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Stripe
                              </Text>
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              w={"100%"}
                            >
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Subtotal
                              </Text>
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                {curr.userAmount}
                              </Text>
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              w={"100%"}
                            >
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                Tax
                              </Text>
                              <Text
                                mb={0}
                                fontSize={{ md: "16px" }}
                                color={"#707070"}
                              >
                                {curr.gst}
                              </Text>
                            </HStack>
                          </VStack>
                          <Divider
                            mb={{ md: "8px" }}
                            mt={0}
                            color={"orange.200"}
                          />
                          <HStack
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            w={"100%"}
                          >
                            <Text
                              mb={0}
                              fontSize={{ md: "16px" }}
                              // color={"#707070"}
                            >
                              Total
                            </Text>
                            <Text
                              mb={0}
                              fontSize={{ md: "16px" }}
                              // color={"#707070"}
                            >
                              ₹ {curr.userAmount}
                            </Text>
                          </HStack>
                        </Box>
                      );
                    })}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </>
      )}
    </>
  );
};

export default RechargeHistory;
