import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import { useParams } from 'react-router-dom'

const Setting = () => {
    
  return (
    <>
    <Flex height="100vh">
        <Sidebar/>
        <Box fontSize={"18px"}>Settings</Box>
    </Flex>
    </>
  )
}

export default Setting