import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  Textarea,
} from "@chakra-ui/react";
import chakraImg from "../../Assests/doctor.png";
import React from "react";
import backgroundImage from "../../Assests/BackgroundImage.png";
import { useNavigate } from "react-router-dom";
const Hero = () => {
  const navigate = useNavigate(); // Get the navigate function from the hook

  const handleConsultNowClick = () => {
    navigate("/astrologers"); // Navigate to "/astrologers" when the button is clicked
  };
  return (
    <>
      <Container
        maxW={"full"}
        pt={{ sm: "20px", base: "20px" }}
        px={0}
        // style={{
        //   backgroundImage: `url(${backgroundImage})`,
        // }}
        sx={{
          height: { md: "500px", base: "200px" },
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container maxW={"6xl"} px={0}>
          <Flex
            w={"100%"}
            justifyContent={{
              md: "space-between",
              sm: "center",
              base: "center",
            }}
            alignItems={"center"}
            // px={{ md: "110px", base: "18px" }}
          >
            <Flex
              w="100%"
              flexDirection={"column"}
              h={{ md: "520px" }}
              justifyContent="center"
              alignItems="left"
            >
              <Heading
                fontSize={{ md: "50px", base: "24px" }}
                color={"#373737"}
                fontWeight={"700"}
                ml={{ base: "18px" }}
                mr={{ base: "38px" }}
                margin={{ md: "0px", base: "auto", sm: "auto" }}
              >
                “Your Health, Our Priority ”
              </Heading>
              <Text
                fontWeight={"400"}
                fontSize={{ md: "16px", base: "12px" }}
                color={"#373737"}
                textAlign={{ md: "left", base: "center", sm: "center" }}
                margin={{ md: "0px", base: "auto", sm: "auto" }}
                mt={{ md: "24px", base: "10px" }}
                justifyContent="left"
                alignItems="left"
                w={{ base: "280px", sm: "280px", md: "auto" }}
                // ml={{ base: "18px" }}
                // mr={{ base: "102px" }}
              >
                Expert Online Doctor Consultations at Your Fingertips.{" "}
              </Text>
              {/* <Button
                variant={"solid"}
                colorScheme="orange"
                borderRadius={"full"}
                mt={{ md: "40px", base: "3px" }}
                h={{ md: "59", base: "36px" }}
                w={{ md: "218px", sm: "auto", base: "auto" }}
                ml={{ base: "18px" }}
                mr={{ base: "221px" }}
                // as="a" // Render the button as an anchor tag to use the href attribute
                // href="/astrologers" // Set the URL you want to link to
                onClick={handleConsultNowClick}
              >
                Claim Your FREE Consultation!
              </Button> */}
              <Button
                variant={"solid"}
                colorScheme="orange"
                borderRadius={"full"}
                margin={{ md: "0px", base: "auto", sm: "auto" }}
                mt={{ md: "40px", base: "10px", sm:"10px" }}
                h={{ md: "59", base: "36px" }}
                w={{ md: "248px", sm: "248px", base: "248px" }}
                // ml={{ base: "18px" }}
                // mr={{ base: "221px" }}
                // as="a" // Render the button as an anchor tag to use the href attribute
                // href="/astrologers" // Set the URL you want to link to
                onClick={handleConsultNowClick}
              >
                Claim Your FREE Consultation!
              </Button>
            </Flex>
            <Box
              boxSize={{ md: "500px" }}
              pt={{ md: "80px" }}
              display={{ md: "block", base: "none" }}
            >
              <Image src={chakraImg} />
            </Box>
          </Flex>
        </Container>
      </Container>
    </>
  );
};

export default Hero;
