import React from 'react'
import {
    Heading,
    Box,
    Flex,
    Text,
    // Link,
    Container,
    Spacer,
    Switch,
    Icon,
  } from "@chakra-ui/react";
  
  import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
  } from "@chakra-ui/react";
  
  import { FaPhone, FaComment, FaVideo, FaWallet } from "react-icons/fa";

const AstrologerDashboard = () => {
  return (
    <>
        <Container
        maxW="6xl"
        backgroundColor={"#f3f8f5"}
        p={0}
      >

        <Flex
          flexWrap="wrap"
          justifyContent="space-around"
          p={{ md: "20px 0 45px 25px", base: "20px 0 35px 20px" }}
          backgroundColor={"#ffffff"}
        >
          <Box flexBasis={["50%", "50%", "25%"]}>
            <Heading
              color={"#78abc7"}
              fontSize={{ md: "24px", base: "16px" }}
              fontWeight={500}
            >
              Go Online
            </Heading>
          </Box>

          <Box flexBasis={["50%", "50%", "25%"]}>
            <Flex>
              <Switch size={{ md: "md", base: "sm" }} colorScheme="blue" />
              <Text
                color={"#a8a9ab"}
                ml={3}
                fontSize={{ md: "16px", base: "14px" }}
              >
                Calls
              </Text>
            </Flex>
          </Box>

          <Box
            flexBasis={["50%", "50%", "25%"]}
            mt={{ md: "0px", base: "30px" }}
          >
            <Flex>
              <Switch size={{ md: "md", base: "sm" }} colorScheme="blue" />
              <Text
                color={"#a8a9ab"}
                ml={3}
                fontSize={{ md: "16px", base: "14px" }}
                width={{ md: "120px", base: "95px" }}
              >
                Chat (Coming Soon)
              </Text>
            </Flex>
          </Box>

          <Box
            flexBasis={["50%", "50%", "25%"]}
            mt={{ md: "0px", base: "30px" }}
          >
            <Flex>
              <Switch size={{ md: "md", base: "sm" }} colorScheme="blue" />
              <Text
                fontSize={{ md: "16px", base: "14px" }}
                color={"#a8a9ab"}
                ml={3}
                width={{ md: "120px", base: "95px" }}
              >
                Live Session (Coming Soon)
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Spacer height="30px" bg="gray.200" />

        <Flex
          flexWrap="wrap"
          p={{ md: "20px 0 0 25px", base: "20px 0 0 20px" }}
          backgroundColor={"#ffffff"}
        >
          <Box flexBasis={["50%", "50%", "25%"]}>
            <Box
              backgroundColor={"#f2f2f2"}
              width={{ md: "180px", base: "160px" }}
              height={{ md: "65px", base: "55px" }}
              fontSize={{ md: "24px", base: "16px" }}
              fontWeight={500}
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              p="15px"
            >
              <Icon as={FaPhone} transform="rotate(90deg)" color="#78abc6" />
              <Box>
                <Text fontSize={{ md: "12px", base: "10px" }} color="#78abc6">
                  Calls Today
                </Text>
                <Text
                  fontSize={{ md: "16px", base: "14px" }}
                  fontWeight={700}
                  color="#78abc6"
                  textAlign={"right"}
                >
                  0
                </Text>
              </Box>
            </Box>
          </Box>

          <Box flexBasis={["50%", "50%", "25%"]}>
            <Box
              backgroundColor={"#f2f2f2"}
              width={{ md: "180px", base: "160px" }}
              height={{ md: "65px", base: "55px" }}
              fontSize={{ md: "24px", base: "16px" }}
              fontWeight={500}
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              p="15px"
            >
              <Icon as={FaComment} color="#78abc6" />
              <Box>
                <Text fontSize={{ md: "12px", base: "10px" }} color="#78abc6">
                  Chats Today
                </Text>
                <Text
                  fontSize={{ md: "16px", base: "14px" }}
                  fontWeight={700}
                  color="#78abc6"
                  textAlign={"right"}
                >
                  0
                </Text>
              </Box>
            </Box>
          </Box>

          <Box
            flexBasis={["50%", "50%", "25%"]}
            mt={{ md: "0px", base: "20px" }}
          >
            <Box
              backgroundColor={"#f2f2f2"}
              width={{ md: "180px", base: "160px" }}
              height={{ md: "65px", base: "55px" }}
              fontSize={{ md: "24px", base: "16px" }}
              fontWeight={500}
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              p="15px"
            >
              <Icon as={FaVideo} color="#78abc6" />
              <Box>
                <Text fontSize={{ md: "12px", base: "10px" }} color="#78abc6">
                  Live Today
                </Text>
                <Text
                  fontSize={{ md: "16px", base: "14px" }}
                  fontWeight={700}
                  color="#78abc6"
                  textAlign={"right"}
                >
                  0
                </Text>
              </Box>
            </Box>
          </Box>

          <Box
            flexBasis={["50%", "50%", "25%"]}
            mt={{ md: "0px", base: "20px" }}
          >
            <Box
              backgroundColor={"#f2f2f2"}
              width={{ md: "180px", base: "160px" }}
              height={{ md: "65px", base: "55px" }}
              fontSize={{ md: "24px", base: "16px" }}
              fontWeight={500}
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              p="15px"
            >
              <Icon as={FaWallet} color="#78abc6" />
              <Box>
                <Text fontSize={{ md: "12px", base: "10px" }} color="#78abc6">
                  Total Earning
                </Text>
                <Text
                  fontSize={{ md: "16px", base: "14px" }}
                  fontWeight={700}
                  color="#78abc6"
                  textAlign={"right"}
                >
                  ₹ 0.00
                </Text>
              </Box>
            </Box>
          </Box>

          <Box flexBasis={["50%", "50%", "25%"]} m="20px 0">
            <Box
              backgroundColor={"#f2f2f2"}
              width={{ md: "180px", base: "160px" }}
              height={{ md: "65px", base: "55px" }}
              fontSize={{ md: "24px", base: "16px" }}
              fontWeight={500}
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
              p="15px"
            >
              <Icon as={FaWallet} color="#78abc6" />
              <Box>
                <Text fontSize={{ md: "12px", base: "10px" }} color="#78abc6">
                  Earning Today
                </Text>
                <Text
                  fontSize={{ md: "16px", base: "14px" }}
                  fontWeight={700}
                  color="#78abc6"
                  textAlign={"right"}
                >
                  ₹ 0
                </Text>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Spacer height="30px" bg="gray.200" />
        
        <Heading
          color={"black.500"}
          fontSize={{ md: "24px", base: "16px" }}
          fontWeight={500}
          bg="white"
          p={{ md: "20px 0 20px 25px", base: "20px 0 15px 20px" }}
        >
          Recent Consultaion
        </Heading>
        <TableContainer overflow="auto"
          css={{ "&::-webkit-scrollbar": { display: "none" } }}>
          
          <Table variant="simple" bg={"white"} width={{md:"full" , base:"450px"}}>
            <Thead borderBottom= "solid">
              <Tr >
                <Th>Patient Name</Th>
                <Th>Time</Th>
                <Th>Duration</Th>
                <Th>Earning</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Priyanka</Td>
                <Td> 11/04/2022 - 20:14</Td>
                <Td>156 Seconds</Td>
                <Td>₹ 0.00</Td>
              </Tr>
              <Tr>
              <Td>Priyanka</Td>
                <Td> 11/04/2022 - 20:12</Td>
                <Td>50 Seconds</Td>
                <Td>₹ 0.00</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <Spacer height="30px" bg="gray.200" />
      </Container>
    </>
  )
}

export default AstrologerDashboard