import { HStack, Img, Text } from "@chakra-ui/react";
import React, { useState, useEffect, Fragment } from "react";
import { G_LIVE_GET_CURRENT_GIFT } from "../../../apiLinks";
import gift1 from "../../../assets/gifts/1gift.png";
import gift2 from "../../../assets/gifts/2gift.png";
import gift3 from "../../../assets/gifts/3gift.png";
import gift4 from "../../../assets/gifts/4gift.png";
import gift5 from "../../../assets/gifts/5gift.png";
import gift6 from "../../../assets/gifts/6gift.png";
import axios from "axios";
import { useSelector } from "react-redux";
const loginToken = localStorage.getItem("loginToken");


const ShowGifts = () => {
  const astroRoomId = useSelector((state) => state?.astrologerProfile?.data?.roomId);
  const [giftId, setGiftId] = useState();
  const [giftInfo, setGiftInfo] = useState();
  const [userName, setUserName] = useState();
  const [showGift, setShowGift] = useState(false);
  const [fiveSec, setFiveSec] = useState(10);

  const giftLookup = {
    7: { name: 'Diya', img: gift1 },
    0.25: { name: 'Diya', img: gift1 },
    18: { name: 'Om', img: gift2 },
    1: { name: 'Om', img: gift2 },
    54: { name: 'Hamsa', img: gift3 },
    3: { name: 'Hamsa', img: gift3 },
    101: { name: 'GaneshJi', img: gift4 },
    5: { name: 'GaneshJi', img: gift4 },
    360: { name: 'Shiva Lingam', img: gift5 },
    10: { name: 'Shiva Lingam', img: gift5 },
    1008: { name: 'Bramhand', img: gift6 },
    30: { name: 'Bramhand', img: gift6 },
  };

  useEffect(() => {
    let currentGiftId = '';

    const fetchData = async () => {
      try {
        const { data } = await axios.get(G_LIVE_GET_CURRENT_GIFT, {
          params: {
            liveSessionId: await fetchSession(astroRoomId),
          },
        });

        if (data?.purchase && data?.purchase?.amount) {
          const { amount, _id, name } = data.purchase;

          if (_id !== currentGiftId) {
            currentGiftId = _id;
            setGiftId(_id);
            setUserName(name);
            setGiftInfo(giftLookup[amount] || '');
            setShowGift(true);

            setTimeout(() => {
              setShowGift(false);
            }, 10000);
          }
        }
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
    const timer = setInterval(fetchData, 10000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <HStack
      display={showGift ? 'flex' : 'none'}
      width={'80%'}
      px={6}
      py={1}
      bg={
        'linear-gradient(90.65deg, rgba(255, 107, 0, 0.8) 0.49%, rgba(255, 184, 0, 0) 109.48%)'
      }
      borderRadius={'50px'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Text color={'white'}>
        <Text p={0} m={0} mt={2} fontWeight="600" fontSize="16px">
          {userName}
        </Text>
        <Text p={0} m={0}>gifted {giftInfo?.name}</Text>
      </Text>
      <img src={giftInfo?.img} alt="Gift" />
    </HStack>
  );
};

export default ShowGifts;

// const ShowGifts = () => {
//   const astroRoomId = useSelector(
//     (state) => state?.astrologerProfile?.data?.roomId
//   );
//   const [giftId, setGiftId] = useState();
//   const [giftInfo, setGiftInfo] = useState();
//   const [userName, setUserName] = useState();
//   const [showGift, setShowGift] = useState(false);
//   const [fiveSec, setFiveSec] = useState(10);

//   const giftLookup = {
//     7: { name: "Diya", img: gift1 },
//     0.25: { name: "Diya", img: gift1 },
//     18: { name: "Om", img: gift2 },
//     1: { name: "Om", img: gift2 },
//     54: { name: "Hamsa", img: gift3 },
//     3: { name: "Hamsa", img: gift3 },
//     101: { name: "GaneshJi", img: gift4 },
//     5: { name: "GaneshJi", img: gift4 },
//     360: { name: "Shiva Lingam", img: gift5 },
//     10: { name: "Shiva Lingam", img: gift5 },
//     1008: { name: "Bramhand", img: gift6 },
//     30: { name: "Bramhand", img: gift6 },
//   };
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const { data } = await axios.get(G_LIVE_GET_CURRENT_GIFT, {
//           params: {
//             liveSessionId: await fetchSession(astroRoomId),
//           },
//         });

//         if (data?.purchase && data?.purchase?.amount) {
//          
//           setGiftId(data?.purchase?._id);
//             const { amount } = data?.purchase;
//             setUserName(data?.purchase?.name);
//             setGiftInfo(giftLookup[amount] || "");
//             setShowGift(true);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//     const timer = setInterval(fetchData, 10000);

//     return () => {
//       setShowGift(false);
//       clearInterval(timer);
//     };
//   }, []);

//   // useEffect(() => {
//   //   const fetchData = async () => {
//   //     try {
//   //       const response = await axios.get(G_LIVE_GET_CURRENT_GIFT, {
//   //         liveSessionId: await fetchSession(astroRoomId),
//   //       });
//   //       if (response.status === 200) {
//   //
//   //         setUserName(response.data.purchase.name);
//   //         if (
//   //           response.data.purchase.amount === 7 ||
//   //           response.data.purchase.amount === 0.25
//   //         ) {
//   //           setGiftInfo({ name: "Diya", img: gift1 });
//   //         } else if (
//   //           response.data.purchase.amount === 18 ||
//   //           response.data.purchase.amount === 1
//   //         ) {
//   //           setGiftInfo({ name: "Om", img: gift2 });
//   //         } else if (
//   //           response.data.purchase.amount === 54 ||
//   //           response.data.purchase.amount === 3
//   //         ) {
//   //           setGiftInfo({ name: "Hamsa", img: gift3 });
//   //         } else if (
//   //           response.data.purchase.amount === 101 ||
//   //           response.data.purchase.amount === 5
//   //         ) {
//   //           setGiftInfo({ name: "GaneshJi", img: gift4 });
//   //         } else if (
//   //           response.data.purchase.amount === 360 ||
//   //           response.data.purchase.amount === 10
//   //         ) {
//   //           setGiftInfo({ name: "Shiva Lingam", img: gift5 });
//   //         } else if (
//   //           response.data.purchase.amount === 1008 ||
//   //           response.data.purchase.amount === 30
//   //         ) {
//   //           setGiftInfo({ name: "Bramhand", img: gift6 });
//   //         } else {
//   //           setGiftInfo("");
//   //         }
//   //         // filterGift(response.data.purchase.amount);
//   //         setShowGift(true);
//   //       }
//   //     } catch (error) {
//   //       setShowGift(false);
//   //       console.error("Error fetching data:", error);
//   //     }
//   //   };
//   //   fetchData();
//   //   const timer = setInterval(fetchData, 10000);

//   //   return () => {
//   //     clearInterval(timer);
//   //   };
//   // }, []);

//   // function filterGift(amount) {
//   //
//   //   if (amount === 7 || amount === 0.25) {
//   //     setGiftInfo({ name: "Diya", img: gift1 });
//   //   } else if (amount === 18 || amount === 1) {
//   //     setGiftInfo({ name: "Om", img: gift2 });
//   //   } else if (amount === 54 || amount === 3) {
//   //     setGiftInfo({ name: "Hamsa", img: gift3 });
//   //   } else if (amount === 101 || amount === 5) {
//   //     setGiftInfo({ name: "GaneshJi", img: gift4 });
//   //   } else if (amount === 360 || amount === 10) {
//   //     setGiftInfo({ name: "Shiva Lingam", img: gift5 });
//   //   } else if (amount === 1008 || amount === 30) {
//   //     setGiftInfo({ name: "Bramhand", img: gift6 });
//   //   } else {
//   //     setGiftInfo("");
//   //   }
//   // }
//  
//   return (
//     <HStack
//       display={showGift ? "flex" : "none"}
//       width={"80%"}
//       px={6}
//       py={1}
//       bg={
//         "linear-gradient(90.65deg, rgba(255, 107, 0, 0.8) 0.49%, rgba(255, 184, 0, 0) 109.48%)"
//       }
//       borderRadius={"50px"}
//       alignItems={"center"}
//       justifyContent={"space-between"}
//     >
//       <Text color={"white"}>
//         <Text p={0} m={0} mt={2} fontWeight="600" fontSize="16px">
//           {userName}
//         </Text>
//         <Text p={0} m={0}>
//           gifted {giftInfo?.name}
//         </Text>
//       </Text>
//       <img src={giftInfo?.img}></img>
//     </HStack>
//   );
// };

// export default ShowGifts;
const fetchSession = async (roomId) => {
  const managementToken = await fetchManagementToken();
  const url = `https://api.100ms.live/v2/sessions?room_id=${roomId}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${managementToken}`,
    },
  });
  //
  if (response.status === 200 && response.data.data[0]?.active) {
    const sessionId = response.data.data[0].id;
    return sessionId;
  }
};

const fetchManagementToken = async () => {
  try {
    const response = await axios.get(
      "https://data.gurucool.life/api/v1/live/getManagementToken"
    );
    if (response.status === 200) {
      return response.data.token;
    }
  } catch (err) {}
};
