import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Center,
  Heading,
  // InputGroup,
  // InputLeftAddon,
  // InputLeftElement,
  // Modal,
  // ModalBody,
  ModalCloseButton,
  ModalContent,
  // ModalFooter,
  // ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Box,
  // color,
  Image,
} from "@chakra-ui/react";
import {
  Button,
  FormControl,
  Flex,
  // Input,
  Stack,
  // useColorModeValue,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { PinInput, PinInputField } from "@chakra-ui/react";
import { loginSystemAction } from "../../store/LoginSystemSlice";
import Register from "../Login&Signup/Register";
import "../Login&Signup/Login.css";
import gurujilogo from "../../assets/roundLogo2.png";
import lock from "../../assets/lock.png";
import "./PhoneCodeSelect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "libphonenumber-js";
// import PhoneNumberInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import {
  G_GET_USER_PROFILE,
  P_SEND_LOGIN_OTP,
  P_VERIFY_LOGIN_OTP,
} from "../../apiLinks";
// import './CountryCodeInput.css';
import "./Login.css";
// import { PhoneIcon } from "@chakra-ui/icons";
// import ReactFlagsSelect from "react-flags-select";
// import Select from "react-select";
// import { FlagIcon } from "react-flag-kit";
// import Flag from "react-world-flags";
// import FlagIcon from "./your-components-directory/FlagIcon.js";
// import FlagIcon from "react-flag-icon-css";
// import India from "../../assets/India.jpg";
import PhoneCodeSelect from "./PhoneCodeSelect";
import loginBanner1 from "../../assets/loginBanner1.png";
import loginBanner2 from "../../assets/loginBannerDesktop.jpeg";
import loginBanner3 from "../../assets/loginBanner3.png";
import loginGurucoolLogo from "../../assets/gurucoolLogo/gurucoolLogo.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import mobileLoginBanner1 from "../../assets/loginBanner.jpeg";
import mobileLoginBanner2 from "../../assets/loginBanner2.jpeg";
import mobileLoginBanner3 from "../../assets/loginBanner3.jpeg";
import { analytics, logEvent } from "../../firebaseEvents/firebase";
import { fetchLoggedInUserDetails } from "../../store/LoggedInUserDetails";

function sendPhoneNumberHandler(
  countryCode,
  phoneNumberWithoutCC,
  phoneNumberWithCC
) {
  if (countryCode === "+91") {
    return phoneNumberWithoutCC;
  } else {
    return phoneNumberWithCC;
  }
}
function isInternational(countryCode) {
  if (countryCode === "+91") {
    return false;
  } else {
    return true;
  }
}
export default function VerifyEmailForm() {
  const dispatch = useDispatch();
  const loginToken = localStorage.getItem("loginToken");

  const [otpSent, setOtpSent] = useState(false);
  // const [enteredMobileNumber, setEnteredMobileNumber] = useState("");
  const [enteredOtp, setEnteredOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [otpSentMsg, setOtpSentMsg] = useState("");
  const isNewUser = useSelector((state) => state.logIn.isNewUser);
  // const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCode, setSelectedCode] = useState("+91");

  const handleCodeChange = (newCode) => {
    setSelectedCode(newCode);
  };
  //

  const phone = `${selectedCode}${phoneNumber}`;
  const sendOtpHandler1 = () => {
    if (selectedCode && phoneNumber) {
      const phone = `${selectedCode}${phoneNumber}`;
      const parsedPhone = parsePhoneNumber(phone);
      if (parsedPhone && parsedPhone.isValid()) {
        setErrorMsg("");
        setOtpSent(true);
        setCountdown(30);
        axios
          .post(P_SEND_LOGIN_OTP, {
            code: selectedCode,
            phone: sendPhoneNumberHandler(selectedCode, phoneNumber, phone),
            isInternational: isInternational(selectedCode),
          })
          .then((response) => {
            if (!response.data.success) {
              setErrorMsg(response.data.message);
              //
            }
          });
      } else {
        setErrorMsg("Please enter a valid phone number.!!!");
      }
    } else {
      setErrorMsg("Please enter your phone number !!!");
    }
  };

  // ResendOtpHandler
  const resendOtphandler = () => {
    setErrorMsg("");
    axios
      .post(P_SEND_LOGIN_OTP, {
        code: selectedCode,
        phone: sendPhoneNumberHandler(selectedCode, phoneNumber, phone),
        isInternational: isInternational(selectedCode),
      })
      .then((response) => {
        //
        if (response.data.success) {
          setOtpSentMsg("Otp Resend successfully");
          setErrorMsg("");
        }
      });
  };

  useEffect(() => {
    if (enteredOtp.length === 6) {
      // Replace 6 with your maximum OTP length
      verifyOtpHandler();
    }
  }, [enteredOtp]);

  // verify the entered otp
  const verifyOtpHandler = () => {
    //
    if (enteredOtp.length === 6) {
      setErrorMsg("");
      dispatch(loginSystemAction.setMobileNumber(phone));
      dispatch(loginSystemAction.setMobileNumberWithoutCC(phoneNumber));
      dispatch(loginSystemAction.setCountryCode(selectedCode));

      axios
        .post(P_VERIFY_LOGIN_OTP, {
          code: selectedCode,
          phone: sendPhoneNumberHandler(selectedCode, phoneNumber, phone),
          userOTP: enteredOtp,
        })
        .then((response) => {
          if (response.data.success) {
            //
            if (response.data.newUser) {
              dispatch(loginSystemAction.setNewUser(response.data.newUser));
              logEvent(analytics, "New_User_Log_In");
            } else {
              notify();
              localStorage.setItem("loginToken", response.data.token);
              dispatch(loginSystemAction.setNewUser(response.data.newUser));
              dispatch(loginSystemAction.setUserLoggedIn(true));
              dispatch(fetchLoggedInUserDetails(response.data.token));
              logEvent(analytics, "Old_User_Log_In");
              axios
                .get(G_GET_USER_PROFILE, {
                  headers: { Authorization: `Bearer ${response.data.token}` },
                })
                .then((response) => {
                  const role = response.data.user.role;
                  if (
                    role === "user" ||
                    role === "admin" ||
                    role === "super-admin"
                  ) {
                    window.location.href = "/doctors";
                  } else if (role === "astrologer") {
                    window.location.href = "/myprofile";
                  }
                });
            }
            // <ToastContainer />
          } else {
            setErrorMsg(response.data.message);
          }
        })
        .catch((error) => {
          setOtpSentMsg("");
          //
          setErrorMsg(error.response.data.message);
        });
    } else {
      setErrorMsg("Please enter six digit OTP !!!");
    }
  };

  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  useEffect(() => {
    setOverlay(<OverlayOne />);
    onOpen();
  }, []);

  const notify = () => toast("LogIn Successful!");

  const [isTimerActive, setIsTimerActive] = useState(true);
  const [countdown, setCountdown] = useState(40);
  // const [displayText, setDisplayText] = useState("Didn't receive OTP?");

  useEffect(() => {
    let intervalId;
    if (isTimerActive) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isTimerActive]);

  useEffect(() => {
    if (countdown === 0) {
      setIsTimerActive(false);
      // setDisplayText("Didn't receive OTP? ");
    }
  }, [countdown]);

  function handleResendOTP() {
    setIsTimerActive(true);
    setCountdown(30);
    // setDisplayText("Didn't receive OTP? ");
    resendOtphandler();

    // Call your resend OTP API here
  }

  // function handlePasteOTP() {
  //   // Handle pasting of OTP here
  // }
  const options = {
    loop: true,
    dots: true,
    // autoplay: true,
    autoplayTimeout: 10000,
    // autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  // const options = {
  //   loop: true,
  //   // controlsClass: 'owl-controls',
  //   // center: true,
  //   // nav: true,
  //   dots: true,
  //   // margin: 18,
  //   // dotsEach: true,
  //   autoplay: true,
  //   autoplayTimeout: 10000,
  //   autoplayHoverPause: false,
  //   responsive: {
  //     0: {
  //       items: 1,
  //       // margin: 8,
  //       // nav: false,
  //       dots: false,
  //     },
  //     600: {
  //       items: 1,
  //     },
  //     1000: {
  //       items: 1,
  //     },
  //   },
  // };

  return (
    <>
      {/* <Modal isCentered isOpen={isOpen} onClose={onClose}> */}
      {overlay}
      <ModalContent borderRadius={{ md: "16px", base: "2px" }}>
        <Flex
        width={{md:"50%"}}
          justifyContent={"space-between"}
          direction={{ md: "row", base: "column" }}
        >
          {/* <Box
            w={{ md: "50%" }}
            h={{ md: "50%" }}
            id="login-banner"
            display={{ md: "none", base: "none" }}
            // zIndex={'-1'}
          >
            <OwlCarousel className="owl-theme" loop autoPlay {...options}>
              <Image
                src={mobileLoginBanner1}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
              />
              <Image
                src={mobileLoginBanner2}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
              />
              <Image
                src={mobileLoginBanner3}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
              />
            </OwlCarousel>
          </Box> */}
          <Box
            overflow={"hidden"}
            w={{ md: "100%", base: "100%" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* <Center>
              <Heading
                lineHeight={1.1}
                fontSize={{ base: "2xl", md: "3xl" }}
                p={4}
              >
                SignUp | SignIn
              </Heading>
            </Center> */}
            <ModalCloseButton
              style={{ border: "2px solid #DD7323" }}
              color={"#DD7323"}
              _hover={{ backgroundColor: "#fcf1e9" }}
              zIndex={"10"}
            />
            <Flex
              className="loginComponent"
              align={"center"}
              // justify={"center"}
              justify={"flex-start"}
            >
              {!isNewUser ? (
                <Stack
                  py={{ base: "23px" }}
                  className="loginBox"
                  // spacing={4}
                  w={"full"}
                  maxW={"sm"}
                  // rounded={"xl"}
                  // boxShadow={"lg"}
                  // p={6}
                  my={0}
                >
                  {otpSent ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {/* <Center
                        fontSize={{ base: "sm", sm: "md" }}
                        fontWeight="bold"
                      // p={4}

                      //                     display: flex;
                      // flex-direction: column;
                      // align-items: flex-start;
                      >
                        <img src={loginGurucoolLogo} alt="gurujilogo" />
                      </Center> */}

                      <Heading
                        colorScheme="#032D23"
                        fontWeight={"600"}
                        fontSize={{ base: "20px", md: "46px" }}
                        //  lineHeight={{base:'44px',md:'67px'}}
                        letterSpacing={{
                          base: "-0.837063px",
                          md: "-1.27971px",
                        }}
                      >
                        Login
                      </Heading>
                      <Text
                        fontWeight={"500"}
                        fontSize={{ md: "20px", base: "12px" }}
                        // lineHeight={{md:'34px', base:'21px'}}
                        letterSpacing={{ base: "-0.206143px", md: "-0.33px" }}
                      >
                        Enter OTP
                      </Text>
                      <Center
                        fontSize={{ base: "sm", sm: "md" }}
                        style={{ color: "red" }}
                      >
                        {errorMsg}
                      </Center>
                      <Center
                        fontSize={{ base: "sm", sm: "md" }}
                        style={{ color: "green" }}
                      >
                        {otpSentMsg}
                      </Center>
                      <FormControl pb={3}>
                        <Center>
                          <HStack>
                            <PinInput onChange={(e) => setEnteredOtp(e)}>
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                            </PinInput>
                          </HStack>
                        </Center>
                      </FormControl>
                      <Flex gap={"9px"}>
                        <Center fontSize={{ base: "10px", md: "14px" }}>
                          We have sent code to your Mobile
                        </Center>

                        <Center
                          fontSize={{ base: "10px", sm: "14px" }}
                          fontWeight="bold"
                        >
                          {`${selectedCode}${phoneNumber}`}
                        </Center>
                      </Flex>
                      <Flex
                        spacing={6}
                        pb={5}
                        px={{ base: 2, md: 0 }}
                        flexDirection={{ base: "column", md: "column" }}
                        alignItems="flex-end"
                        justifyContent="flex-end"
                        w={"100%"}
                      >
                        {/* <Text textAlign={{ base: "center", md: "center" }}>
                          Didn't receive OTP?
                        </Text> */}
                        <Box
                          // w="100%"
                          display={"flex"}
                          justifyContent="flex-end"
                        >
                          {isTimerActive ? (
                            <Link
                              isDisabled={isTimerActive}
                              style={{
                                width: "100%",
                                color: "#032D23",
                                textAlign: "center",
                                cursor: "no-drop",
                              }}
                            >
                              Resend OTP in {countdown} sec
                            </Link>
                          ) : (
                            <Link
                              isDisabled={isTimerActive}
                              onClick={() => handleResendOTP()}
                              style={{
                                fontWeight: "500",
                                width: "100%",
                                color: "#032D23",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                            >
                              Resend OTP?
                            </Link>
                          )}
                        </Box>
                      </Flex>
                      <Stack spacing={6} px={{ base: 2, md: 0 }}>
                        <Button
                          borderRadius={"4px"}
                          onClick={() => verifyOtpHandler()}
                          bg={"#FF4C00"}
                          color={"white"}
                          // w={'100%'}
                          _hover={{
                            bg: "#DD7340",
                          }}
                          mb={{ base: "13px", md: "17px" }}
                        >
                          Verify & Proceed
                        </Button>
                      </Stack>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {/* <Center
                        fontSize={{ base: "sm", sm: "md" }}
                        fontWeight="bold"
                        p={4}
                      > */}
                      <Box
                        fontSize={{ base: "sm", sm: "md" }}
                        fontWeight="bold"
                        // p={4}
                      >
                        {/* <img src={gurujilogo} alt="gurujilogo" /> */}
                        <Image
                          src={loginGurucoolLogo}
                          alt="gurujilogo"
                          // boxSize={"120px"}
                          display={{ base: "none", md: "block" }}
                        />
                      </Box>
                      {/* </Center> */}
                      {/* <Center
                        fontSize={{ base: "sm", sm: "md" }}
                        fontWeight="bold"
                        style={{ paddingBottom: "10px" }}
                      > */}
                      <Box
                        fontSize={{ base: "16px", md: "28px" }}
                        fontWeight="500"
                        letterSpacing={{
                          md: " -0.611207px",
                          base: "-0.399792px",
                        }}
                        lineHeight={{ md: "42px", base: "27px" }}
                        style={{ paddingBottom: "10px" }}
                        colorScheme="#032D23"
                      >
                        Welcome to Gurucool
                      </Box>

                      <Box>
                        <Heading
                          colorScheme="#032D23"
                          fontWeight={"600"}
                          fontSize={{ base: "20px", md: "56px" }}
                          //  lineHeight={{base:'44px',md:'67px'}}
                          letterSpacing={{
                            base: "-0.837063px",
                            md: "-1.27971px",
                          }}
                        >
                          Login
                        </Heading>
                      </Box>
                      <Box
                        fontSize={{ base: "sm", sm: "md" }}
                        // fontWeight="bold"
                        color="#032D23"
                        font-weight="500"
                        // fontSize={{ base: "12px", md: "20px" }}
                        lineHeight={{ base: "21px", md: "32px" }}
                        letterSpacing={{
                          base: "-0.206143px",
                          md: "-0.315153px",
                        }}
                        style={{ paddingBottom: "10px" }}
                        colorScheme="#032D23"
                      >
                        Enter Mobile Number
                      </Box>

                      {/* </Center> */}
                      <FormControl>
                        {/* <Center
                          fontSize={{ base: "sm", sm: "md" }}
                          style={{ color: "red" }}
                        > */}
                        <Box
                          fontSize={{ base: "sm", sm: "md" }}
                          style={{ color: "red" }}
                        >
                          {errorMsg}
                        </Box>
                        {/* </Center> */}
                        {/* <Center px={{ base: 2, md: 0 }}> */}
                        <Box
                        // px={{ base: 2, md: 0 }}
                        >
                          {/* <PhoneInputField /> */}
                          {/* <InputGroup>
                        <InputLeftAddon
                          children="+91"
                          bg={"#DD7323"}
                          color={"#FFFFFF"}
                          border="1px solid orange"
                        />
                        <Input
                          type="tel"
                          placeholder="Enter phone number"
                          onChange={(event) => handleNumberChange(event)}
                          _focus={{
                            border: "1px solid orange",
                            outline: "none",
                            boxShadow: "none",
                          }}
                        />
                      </InputGroup> */}
                          <Flex
                            direction={"column"}
                            // alignItems="center"
                            w={"100%"}
                            // border={"1px solid #848484"}
                            // px={{ base: 3, md: 6 }}
                          >
                            {/* <PhoneInput
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          defaultCountry="IN"
                          international
                          placeholder="Country"
                          className="phoneNumber"
                          readOnly={true}
                        /> */}
                            {/* <FlagSelect
                          searchable={true}
                          onSelect={handleSelect}
                          selected={phoneNumber}
                        /> */}
                            {/* <ReactFlagsSelect
                          selected={selected}
                          onSelect={(code) => setSelected(code)}
                          searchable={false}
                          customLabels={{
                            US: "+1",
                            GB: "+44",
                            IN: "+91",
                            JP: "+81",
                            CN: "+86",
                            AU: "+61",
                            // Add more custom labels as needed
                          }}
                        /> */}

                            {/* <label htmlFor="country-code">Country Code:</label>
                        <select
                          id="country-code"
                          value={selectedCode}
                          onChange={handleCodeSelect}
                        >
                          {codes.map((code) => (
                            <option key={code.code} value={code.code}>
                              <Flag code={`${code.isoCode}`} />{" "}
                              {`${code.name} (${code.code})`}
                            </option>
                          ))}
                        </select>
                        <Flag code={"US"} height="5"/>{" "} */}

                            <PhoneCodeSelect
                              value={selectedCode}
                              onChange={handleCodeChange}
                              className="countryCode"
                              wrapperClassName="countryCode"
                              w={{ base: "250px", md: "400px" }}
                              // border={"1px solid #848484"}
                              style={{
                                border: "1px solid #848484",
                              }}
                            />

                            {/* <InputGroup mt={{ base: "12px", md: "16px" }}>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<PhoneIcon color="orange.400" />}
                          />
                          <Input
                            style={{ touchAction: "manipulation" }}
                            type={"number"}
                            variant="filled"
                            placeholder="Enter Phone Number"
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            fontSize={{ md: "14px", base: "12px" }}
                            color={"orange.400"}
                            background={"antiquewhite"}
                            borderRadius={" 5px "}
                            border="1px"
                            _focus={{
                              outline: "1px solid white",
                              border: "1px solid darkorange",
                            }}
                          />
                        </InputGroup>  */}

                            <Box
                              display={"flex"}
                              alignItems="center"
                              w={"100%"}
                              border={"1px solid #848484"}
                              borderRadius={"4px"}
                              mt={{ base: "6px", md: "8px" }}
                              // height={{md:'57px', base:'36px'}}
                            >
                              <span
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "lightgrey",
                                  borderRadius: "4px 0 0 4px",
                                }}
                              >
                                <i
                                  className="fa fa-phone"
                                  style={{ color: "#848484" }}
                                ></i>
                              </span>
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault(); // Prevent form submission
                                  sendOtpHandler1();
                                }}
                              >
                                <input
                                  className="phoneNumberInput"
                                  type="number"
                                  placeholder="Enter Phone Number!"
                                  style={{
                                    padding: "5px 8px",
                                    borderRadius: "0 4px 4px 0",
                                    outline: "none",
                                    width: "100%",
                                    color: "#848484",
                                    backgroundColor: "white",
                                    letterSpacing: "2px",
                                  }}
                                  value={phoneNumber}
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                />
                              </form>
                            </Box>
                          </Flex>
                        </Box>
                        {/* </Center> */}
                      </FormControl>
                      <Box
                        fontSize={{ base: "10px", md: "12px" }}
                        px={{ base: 2, md: 2 }}
                        textAlign="center"
                        mt={"4px"}
                      >
                        We will send you a one-time verification code through
                        SMS
                      </Box>
                      <Stack
                        // p={6}
                        pt={6}
                        spacing={6}
                        width="-webkit-fill-available"
                      >
                        <Button
                          onClick={() => sendOtpHandler1()}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent form submission if inside a form
                              sendOtpHandler1();
                            }
                          }}
                          bg={"#FF4C00"}
                          borderRadius={" 5.73006px"}
                          color={"white"}
                          _hover={{
                            bg: "#DD7340",
                          }}
                        >
                          GET OTP
                        </Button>
                      </Stack>
                    </div>
                  )}
                </Stack>
              ) : (
                <Register />
              )}
            </Flex>
          </Box>
          {/* <Box
            w={{ md: "50%" }}
            h={{ md: "50%" }}
            id="login-banner"
            display={{ md: "block", base: "none" }}
          >
            <OwlCarousel {...options} className="owl-theme">
              <Image
                src={loginBanner1}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
              />
              <Image
                src={loginBanner2}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
              />
              <Image
                src={loginBanner3}
                w={"100%"}
                h={"100%"}
                objectFit={"cover"}
              />
            </OwlCarousel>
          </Box> */}
        </Flex>
      </ModalContent>
      {/* </Modal> */}
      <ToastContainer />
    </>
  );
}

//
