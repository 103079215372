import React, { useState, useEffect } from "react";
import { Box, ChakraProvider, Spinner } from "@chakra-ui/react";
import { Route, Routes } from "react-router";
import { useLocation } from "react-router-dom";
// import Footer from "./components/Footer/Footer";
import Footer from "./components/NewFooter/Footer";
import Header from "./components/Header/Header";
import HomePage from "./components/HomePage/HomePage";
import Profile from "./components/Profile/Profile";
import CallWithAstrologerPage from "./components/CallWithAstrologerPage/CallWithAstrologerPage";
import { useDisclosure } from "@chakra-ui/react";
import ChatBox from "./components/ChatFlow/ChatBox/ChatBox";
import Admin from "./components/Admin/Admin";
import Wallet from "./components/Wallet/Wallet";
import Setting from "./components/Admin/Setting/Setting";
import Help from "./components/Admin/Help/Help";
import AdminProfile from "./components/Admin/Profile/AdminProfile";
import AddAstrologer from "./components/Admin/AddAstrologer/AddAstrologer";
import ShowAstrologers from "./components/Admin/ShowAstrologers/ShowAstrologers";
import CallConsultationStarted from "./components/CallFlow/CallConsultationStarted/CallConsultationStarted";
import CallConsultationDetails from "./components/CallFlow/CallConsultationDetails/CallConsultationDetails";
import PrivacyPage from "./components/Privacy/PrivacyPage";
import AboutUsPage from "./components/AboutUs/AboutUsPage";
import TermsAndConditionPage from "./components/TermsAndConditions/TermsAndConditionPage";
import ChatConsultationStarted from "./components/ChatFlow/ChatConsultationStarted/ChatConsultationStarted";
import ChatConsultationDetails from "./components/ChatFlow/ChatConsultationDetails/ChatConsultationDetails";
import AstrologerDashboard from "./components/AstrologerDashboard/AstrologerDashboard";
import "./App.css";
import CommingSoon from "./components/ComingSoon/CommingSoon";
import EditAstrologer from "./components/Admin/EditAstrologer/EditAstrologer";
import DisabledAstrologer from "./components/Admin/DisabledAstrologers/DisabledAstrologers";
import AllAstrologers from "./components/AllAstrologers/AllAstrologers";
import PaymentResult from "./components/Payment/PaymentStatusPage";
import { Center, Container } from "@chakra-ui/layout";
import ContactUsPage from "./components/ContactUs/ContactUs";
import NoAccess from "./pages/Noaccess";
import ShowUsers from "./components/Admin/ShowUsers/ShowUsers";
import ShowRazorpayRecharges from "./components/Admin/ShowRazorpayRecharges/ShowRazorpayRecharges";
import GetConsultations from "./components/Admin/GetConsultations/GetConsultations";
import axios from "axios";
import AddBalance from "./components/Admin/AddBalance/AddBalance";
import { G_GET_USER_PROFILE } from "./apiLinks";
import AddFeedback from "./components/Admin/AddFeedback/AddFeedback";
import ShowStripeRecharges from "./components/Admin/ShowStripeRecharges/ShowStripeRecharges";
import AstroProfile from "./components/AstrologerProfile/AstroProfile";
import UserTestimonials from "./components/HomePage/AstroCard/UserTestimonials";
import AstroProfileCard from "./components/HomePage/AstroCard/AstroProfileCard";
import Conference from "./components/LiveStreaming/Conference/Conference";
import AstrologerConference from "./components/LiveStreaming/Conference/AstrologerConference";
import Unification from "./components/HomePage/Unification/Unification";
import UserReview from "./components/HomePage/UserReview/UserReview";
import Artigyan from "./components/HomePage/ArtiGyan/Artigyan";
import Square from "./components/Wallet/Square";
import Desclaimer from "./components/Desclaimer/Desclaimer";
import RefundAndCancelation from "./components/RefundandCalcelationPollicy/RefundAndCancelation";
import Edit from "./components/Profile/AstrologerProfileDashboard/Edit";
import Check from "./components/Profile/AstrologerProfileDashboard/Check";
import LoveAstrologers from "./components/CallWithAstrologerPage/AstrologerSpecialitiesPages/LoveAstrologers";
import CareerAstrologers from "./components/CallWithAstrologerPage/AstrologerSpecialitiesPages/CareerAstrologers";
import MarriageAstrologers from "./components/CallWithAstrologerPage/AstrologerSpecialitiesPages/MarriageAstrologers";
import ConsultAstrologer from "./components/ConsultAstrologer/ConsultAstrologer";
import Love from "./components/ConsultAstrologer/SpecialityPages/Love";
import Marriage from "./components/ConsultAstrologer/SpecialityPages/Marriage";
import ConsultAstroCard from "./components/ConsultAstrologer/SpecialityPages/ConsultAstroCard/ConsultAstroCard";
import Career from "./components/ConsultAstrologer/SpecialityPages/Career";
import Business from "./components/ConsultAstrologer/SpecialityPages/Business";
import Life from "./components/ConsultAstrologer/SpecialityPages/Life";
import Health from "./components/ConsultAstrologer/SpecialityPages/Health";
import Tarot from "./components/ConsultAstrologer/SpecialityPages/Tarot";
import LiveAstrologers from "./components/LiveAstrologers/LiveAstrologers";

// import ConsultAstrologerMyGuruji from "./components/MyGuruji/ConsultAstrologer/ConsultAstrologer";

// import Horoscopewithoutlogin from "./components/Horoscope/Horoscopewithoutlogin";
// import Clickzodiac from "./components/Horoscope/Clickzodiac";
// import Horoscopewithlogin from "./components/Horoscope/Horoscopewithlogin";
import Clickviewmore from "./components/Horoscope/Clickviewmore";
import Aries from "./components/Horoscope/Components/Zodiacsign/Aries";
import Taurus from "./components/Horoscope/Components/Zodiacsign/Taurus";
import Leo from "./components/Horoscope/Components/Zodiacsign/Leo";
import Virgo from "./components/Horoscope/Components/Zodiacsign/Virgo";
import Libra from "./components/Horoscope/Components/Zodiacsign/Libra";
import Aquarius from "./components/Horoscope/Components/Zodiacsign/Aquarius";
import Pisces from "./components/Horoscope/Components/Zodiacsign/Pisces";
import Scorpio from "./components/Horoscope/Components/Zodiacsign/Scorpio";
import Gemini from "./components/Horoscope/Components/Zodiacsign/Gemini";
import Cancer from "./components/Horoscope/Components/Zodiacsign/Cancer";
import Sagitarius from "./components/Horoscope/Components/Zodiacsign/Sagitarius";
import Capricon from "./components/Horoscope/Components/Zodiacsign/Capricon";
import Horoscopehome from "./components/Horoscope/Horoscopehome";
import GurucoolLove from "./components/GurucoolLove/GurucoolLove";
import Userdetails from "./components/Popups/AstrologerCallPopup/Userdetails";
import ConsultationHistory from "./components/Profile/Consultation_History/ConsultationHistory";
import GurucoolLoveAstro from "./components/GurucoolLoveAstro/App";
import ShowMoreConsultation from "./components/Profile/Consultation_History/ShowMoreConsultation";
import Aries1 from "./components/Horoscope/Components/Zodiacsignuser/Aries1";
import Taurus1 from "./components/Horoscope/Components/Zodiacsignuser/Taurus1";
import Gemini1 from "./components/Horoscope/Components/Zodiacsignuser/Gemini1";
import Cancer1 from "./components/Horoscope/Components/Zodiacsignuser/Cancer1";
import Leo1 from "./components/Horoscope/Components/Zodiacsignuser/Leo1";
import Virgo1 from "./components/Horoscope/Components/Zodiacsignuser/Virgo1";
import Libra1 from "./components/Horoscope/Components/Zodiacsignuser/Libra1";
import Aquarius1 from "./components/Horoscope/Components/Zodiacsignuser/Aquarius1";
import Pisces1 from "./components/Horoscope/Components/Zodiacsignuser/Pisces1";
import Scorpio1 from "./components/Horoscope/Components/Zodiacsignuser/Scorpio1";
import Capricon1 from "./components/Horoscope/Components/Zodiacsignuser/Capricon1";
import Sagittarius1 from "./components/Horoscope/Components/Zodiacsignuser/Sagittarius1";
import { useSelector } from "react-redux";
import { Global, css } from "@emotion/react";
import QueueConsultationStarted from "./components/CallFlow/QueueConsultationStarted/QueueConsultationStarted";
import BlogSinglePage from "./components/Blog/BlogSinglePage/BlogSinglePage";
import BlogMain from "./components/Blog/BlogMain/BlogMain";
import Newhomepage from "./components/Newhomepage/NewHomepage";

const GurujiLite = () => {
  const host = localStorage.getItem("host");
  const location = useLocation();
  const loggedIn = useSelector((state) => state.logIn.userLoggedIn);
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const hideHeaderFooter = ["/admin", "/chatbox", "/live"].some((path) =>
    location.pathname.startsWith(path)
  );

  const hideAdmin = ["/admin"].some((path) =>
    location.pathname.startsWith(path)
  );

  const [admin, setAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const loginToken = localStorage.getItem("loginToken");

  useEffect(() => {
    console.log("ran");
    if (loginToken) {
      axios
        .get(G_GET_USER_PROFILE, {
          headers: { Authorization: `Bearer ${loginToken}` },
        })
        .then((response) => {
          if (
            response.data.user.role === "admin" ||
            response.data.user.role === "super-admin"
          ) {
            setAdmin(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          // Handle error here
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []); // Empty array ensures the effect runs only once on mount

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [Price, setPrice] = useState(0);

  const handleDataChange = (newData) => {
    setPrice(newData);
  };
  const GlobalStyle = () => (
    <Global
      styles={css`
        @import url("https://fonts.googleapis.com/css2?family=Fondamento&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Ultra&display=swap");
      `}
    />
  );

  const isMobile = window.innerWidth <= 768;

  return (
    <Box minHeight="100vh" position="relative" overflowX={"hidden"}>
      {!hideHeaderFooter && (
        <Header
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          setIsWalletOpen={setIsWalletOpen}
        />
      )}
      <Container
        maxW={"100%"}
        mt={"-3px"}
        p="0px"
        onClick={onClose}
        className={location.pathname === "/wallet" ? "wallet-container" : ""}
      >
        <Routes>
          <Route
            exact
            path="/"
            element={
              localStorage.getItem("host") === "gurucool" ? (
                isMobile ? (
                  <Newhomepage />
                ) : (
                  <HomePage />
                )
              ) : (
                <GurucoolLoveAstro />
              )
            }
          />
          {/* <Route exact path="/" element={<HomePage />} /> */}
          {/* <Route exact path="/" element={<GurucoolLoveAstro />} /> */}
          {/* <Route
            exact
            path="/"
            element={isMobile ? <Newhomepage /> : <HomePage />}
          /> */}
          {/* Website 2 Gurucool HomePage */}
          <Route exact path="/myProfile" element={<Profile />} />
          <Route exact path="/myProfile/check" element={<Check />} />
          <Route exact path="/myProfile/edit/:id" element={<Edit />} />
          {/*  */}
          {/*  */}
          {/*  */} ADMIN DASHBOARD {/*  */}
          <Route exact path="/dashboard" element={<ConsultationHistory />} />
          {/*  */}
          {/*  */}
          {/*  */}
          {/* <Route exact path = "/newhomepage" element={<Newhomepage />} /> */}
          <Route
            exact
            path="/wallet"
            element={<Wallet onDataChange={handleDataChange} />}
          />
          <Route
            exact
            path="/wallet/checkout.square.com"
            element={<Square Price={Price} />}
          />
          <Route exact path="/chatbox" element={<ChatBox />} />
          <Route
            exact
            path="/streamWithAstrologer"
            element={<LiveAstrologers />}
          />
          <Route
            exact
            path="/showMoreConsultations"
            element={<ShowMoreConsultation />}
          />
          <Route
            exact
            path="/consult-astrologers"
            element={<CallWithAstrologerPage />}
          />
          <Route exact path="/doctors/" element={<ConsultAstrologer />} />
          {/* <Route exact path="/astrologers/" element={<ConsultAstrologerMyGuruji />} /> */}
          <Route exact path="/doctors/Love/" element={<Love />} />
          {/* <Route
            exact
            path="/astrologers/Career"
            element={<CareerAstrologers/>}
          /> */}
          <Route exact path="/doctors/Marriage/" element={<Marriage />} />
          <Route exact path="/doctors/Career/" element={<Career />} />
          <Route exact path="/doctors/Business/" element={<Business />} />
          <Route exact path="/doctors/Life/" element={<Life />} />
          <Route exact path="/doctors/Health/" element={<Health />} />
          <Route exact path="/doctors/Tarot/" element={<Tarot />} />
          <Route exact path="/all-astrologers/" element={<AllAstrologers />} />
          <Route exact path="/consult-astro/" element={<ConsultAstroCard />} />
          <Route exact path="/userdetails/" element={<Userdetails />} />
          <Route
            exact
            path="/astrologer-profile"
            element={<AstroProfileCard />}
          />
          <Route exact path="/doctor/:id" element={<AstroProfile />} />
          <Route
            exact
            path="user-testimononials"
            element={<UserTestimonials />}
          />
          <Route exact path="user-review" element={<UserReview />} />
          <Route exact path="unification" element={<Unification />} />
          <Route exact path="arti-gyan" element={<Artigyan />} />
          <Route exact path="/wallet" element={<Wallet />} />
          <Route
            exact
            path="/wallet/paymentDetails/:id"
            element={<PaymentResult />}
          />
          <Route
            exact
            path="/admin"
            element={
              admin ? (
                // <NoAccess />
                <Admin />
              ) : isLoading ? (
                <Center h={"100vh"} display="flex" alignItems={"center"}>
                  <Spinner />
                </Center>
              ) : (
                <NoAccess />
              )
            }
          />
          <Route exact path="/admin/no-access" element={<NoAccess />} />
          <Route exact path="/admin/settings" element={<Setting />} />
          <Route exact path="/admin/help" element={<Help />} />
          <Route
            exact
            path="/admin/settings/profile"
            element={<AdminProfile />}
          />
          <Route
            exact
            path="/admin/addAstrologer"
            element={
              admin ? (
                <AddAstrologer />
              ) : isLoading ? (
                <Center h={"100vh"} display="flex" alignItems={"center"}>
                  <Spinner />
                </Center>
              ) : (
                <NoAccess />
              )
            }
          />
          <Route
            exact
            path="/admin/editAstrologer"
            element={admin ? <EditAstrologer /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/showAstrologers"
            element={admin ? <ShowAstrologers /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/getConsultations"
            element={admin ? <GetConsultations /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/showRazorpayPayments"
            element={admin ? <ShowRazorpayRecharges /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/showStripePayments"
            element={admin ? <ShowStripeRecharges /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/addFeedback"
            element={admin ? <AddFeedback /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/showUsers"
            element={admin ? <ShowUsers /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/disableAstrologers"
            element={admin ? <DisabledAstrologer /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/addastrologer"
            element={admin ? <AddAstrologer /> : <NoAccess />}
          />
          <Route
            exact
            path="/admin/astrologerDashboard"
            element={<AstrologerDashboard />}
          />
          <Route
            exact
            path="/callconsultationstarted"
            element={<CallConsultationStarted />}
          />
          <Route
            exact
            path="/callconsultationdetails"
            element={<CallConsultationDetails />}
          />
          <Route
            exact
            path="/chatconsultationstarted"
            element={<ChatConsultationStarted />}
          />
          <Route
            exact
            path="/queueconsultationstarted"
            element={<QueueConsultationStarted />}
          />
          <Route
            exact
            path="/chatconsultationdetails"
            element={<ChatConsultationDetails />}
          />
          <Route exact path="/aboutus" element={<AboutUsPage />} />
          <Route exact path="/contact-us" element={<ContactUsPage />} />
          <Route
            exact
            path="/termsandconditions"
            element={<TermsAndConditionPage />}
          />
          <Route exact path="/privacy" element={<PrivacyPage />} />
          <Route exact path="/desclaimer" element={<Desclaimer />} />
          <Route
            exact
            path="/refund-policy"
            element={<RefundAndCancelation />}
          />
          <Route
            exact
            path="/admin/astrologerdashboard"
            element={<AstrologerDashboard />}
          />
          <Route exact path="/live/:id" element={<Conference />} />
          <Route
            exact
            path="/live-streaming/:id"
            element={<AstrologerConference />}
          />
          {/* <Route exact path="/withoutlogin" element={<Horoscopewithoutlogin />} /> */}
          <Route exact path="/horoscope" element={<Horoscopehome />} />
          <Route
            exact
            path="/horoscope/aries"
            element={loggedIn ? <Aries1 /> : <Aries />}
          />
          <Route
            exact
            path="/horoscope/taurus"
            element={loggedIn ? <Taurus1 /> : <Taurus />}
          />
          <Route
            exact
            path="/horoscope/gemini"
            element={loggedIn ? <Gemini1 /> : <Gemini />}
          />
          <Route
            exact
            path="/horoscope/cancer"
            element={loggedIn ? <Cancer1 /> : <Cancer />}
          />
          <Route
            exact
            path="/horoscope/leo"
            element={loggedIn ? <Leo1 /> : <Leo />}
          />
          <Route
            exact
            path="/horoscope/virgo"
            element={loggedIn ? <Virgo1 /> : <Virgo />}
          />
          <Route
            exact
            path="/horoscope/libra"
            element={loggedIn ? <Libra1 /> : <Libra />}
          />
          <Route
            exact
            path="/horoscope/aquarius"
            element={loggedIn ? <Aquarius1 /> : <Aquarius />}
          />
          <Route
            exact
            path="/horoscope/pisces"
            element={loggedIn ? <Pisces1 /> : <Pisces />}
          />
          <Route
            exact
            path="/horoscope/scorpio"
            element={loggedIn ? <Scorpio1 /> : <Scorpio />}
          />
          <Route
            exact
            path="/horoscope/sagitarius"
            element={loggedIn ? <Sagittarius1 /> : <Sagitarius />}
          />
          <Route
            exact
            path="/horoscope/capricorn"
            element={loggedIn ? <Capricon1 /> : <Capricon />}
          />
          <Route exact path="/blog/:id" element={<BlogSinglePage />} />
          <Route exact path="/blog" element={<BlogMain />} />
        </Routes>
      </Container>
      {!hideHeaderFooter && <Footer />}
    </Box>
  );
};

export default GurujiLite;
