import { Button } from "@chakra-ui/button";
import { Card } from "@chakra-ui/card";
import { Checkbox, CheckboxIcon } from "@chakra-ui/checkbox";
import { Icon } from "@chakra-ui/icon";
import {
  Container,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/layout";
import React, { useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { css } from "@emotion/react";
import axios from "axios";

const timeSlotsData = [
  {
    day: "Monday",
    timeSlots: [
      { startTime: "10:00 AM", endTime: "12:00 PM" },
      { startTime: "02:00 PM", endTime: "04:00 PM" },
    ],
  },
  {
    day: "Tuesday",
    timeSlots: [
      { startTime: "09:00 AM", endTime: "11:00 AM" },
      { startTime: "01:00 PM", endTime: "03:00 PM" },
    ],
  },
  {
    day: "Wednesday",
    timeSlots: [
      { startTime: "09:00 AM", endTime: "11:00 AM" },
      { startTime: "01:00 PM", endTime: "03:00 PM" },
    ],
  },
  {
    day: "Thursday",
    timeSlots: [
      { startTime: "09:00 AM", endTime: "11:00 AM" },
      { startTime: "01:00 PM", endTime: "03:00 PM" },
    ],
  },
  {
    day: "Friday",
    timeSlots: [
      { startTime: "09:00 AM", endTime: "11:00 AM" },
      { startTime: "01:00 PM", endTime: "03:00 PM" },
    ],
  },
  {
    day: "Saturday",
    timeSlots: [
      { startTime: "09:00 AM", endTime: "11:00 AM" },
      { startTime: "01:00 PM", endTime: "03:00 PM" },
    ],
  },
  {
    day: "Sunday",
    timeSlots: [
      { startTime: "09:00 AM", endTime: "11:00 AM" },
      { startTime: "01:00 PM", endTime: "03:00 PM" },
    ],
  },
  // Add more data for other days and time slots as needed
];

const Check = (astrologer) => {
  //
    const [leaveDays, setLeaveDays] = useState([]);

    const handleCheckboxChange = (day) => {
      if (leaveDays.includes(day)) {
        setLeaveDays(leaveDays.filter((d) => d !== day));
      } else {
        setLeaveDays([...leaveDays, day]);
      }
    };
  
    const isOnLeave = (day) => leaveDays.includes(day);


    // const axios = require('axios');

    axios.get('https://data.gurucool.life/api/v1/guru/updateTimeSchedule')
      .then(response => {
        const data = response.data;
       
        // Transform the API response into the desired format
        const timeSchedule = {
          sun: {
            morning: data.timeSchedule.sun.morning,
            evening: data.timeSchedule.sun.evening
          },
          mon: {
            morning: data.timeSchedule.mon.morning,
            evening: data.timeSchedule.mon.evening
          },
          tue: {
            morning: data.timeSchedule.tue.morning,
            evening: data.timeSchedule.tue.evening
          },
          wed: {
            morning: data.timeSchedule.wed.morning,
            evening: data.timeSchedule.wed.evening
          },
          thu: {
            morning: data.timeSchedule.thu.morning,
            evening: data.timeSchedule.thu.evening
          },
          fri: {
            morning: data.timeSchedule.fri.morning,
            evening: data.timeSchedule.fri.evening
          },
          sat: {
            morning: data.timeSchedule.sat.morning,
            evening: data.timeSchedule.sat.evening
          }
        };
    
        // Use the transformed data as needed
       
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
      });
    

  return (
    <>
    <Stack mx={{md:'146px'}} py={{md:'30px'}}>
    <Flex alignItems="baseline" 
    // mx={{ md: "146px" }} py={{ md: "30px" }}
    >
        <Text display={{ base: "none", md: "block" }}>Dashboard/</Text>
        <Text display={{ base: "none", md: "block" }} color="#A95210" fontWeight="500" ml="2">
          Time Table
        </Text>
      </Flex>
      <Text borderTop={'1px dotted rgba(135, 66, 12, 0.4)'}
      display={{base:'none', md:'block'}}
      ></Text>
      </Stack>
    <Container maxW={'4xl'}>
      <Flex display={{base:'block', md:'none'}} flexDirection={'row'}>
      <Icon as={FiArrowLeft}/>
      <Heading fontWeight={'600'} fontSize={{base:'18px'}}>Online Timing</Heading>
      <Divider color={'#EDEDED'}/>
      </Flex>
      {/* <Divider 
   
  borderBottomStyle="dotted"
  borderColor="rgba(135, 66, 12, 0.4)"
      /> */}
      
      {timeSlotsData.map(({ day, timeSlots }) => (
        <>
          <Flex key={day} justifyContent={'space-between'}>
            <VStack 
            // mr={{ md: "54px", base: "35px" }}
            alignItems={'flex-start'}
            w={{base:'12%'}}
            >
              <Text fontWeight={"500"} mb={"0 !important"} fontSize={{base:'14px', md:'20px'}}>
                {day}
              </Text>
              <Text mt={"0 !important"} fontWeight={'500'} fontSize={{base:'12px', md:'14px'}} color={'#8F8F8F'}>16 aug</Text>
            </VStack>
            <VStack px={{base:'35px', md:'54px'}} >
            {isOnLeave(day) ? (
              <Card
                bg="#FFEEE6"
                px={{ md: "60px", base: "13px" }}
                py={{ md: "15px", base: "6px" }}
                borderRadius="4px"
                mb={{ md: "8px", base: "4px" }}
                w={{ md: "301px", base: "163px" }}
              >
                <Text color={'#F54A45'} fontWeight={'500'} fontSize={{ base: "12px", md: "14px" }} textAlign="center" mb={'0 !important'}>
                  On Leave
                </Text>
              </Card>
            ) : (timeSlots.map(({ startTime, endTime }) => (
                <Card
                  key={`${day}-${startTime}-${endTime}`}
                  bg={"#FFEEE6"}
                  px={{ md: "60px", base: '13px' }}
                  py={{ md: "15px", base:'6px' }}
                  borderRadius={"4px"}
                  mb={{md:"8px" ,base:"4px"}}
                  //   pb={'0 !important'}
                  w={{md:'301px', base:'163px'}}

                >
                  <Text fontSize={{ base: "12px", md: "12px" }} display={'flex'} justifyContent={'center'} mb={'0 !important'}>
                    {startTime} to {endTime}
                  </Text>
                </Card>
              )))}
            </VStack>
            <Checkbox
  ml="8px"
  w={{ md: "max-content" }}
  gap={{ md: "15px" }}
  onChange={() => handleCheckboxChange(day)}
  _checked={{ color: "#F54A45", borderColor: "#F54A45" }}
colorScheme="red"
  sx={{
    display: "flex",
    alignItems: "center",
    mt: 1,
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  }}
>
  <span>Click for leave</span>
  <CheckboxIcon/>
</Checkbox>

          </Flex>
          <Divider color={'#EDEDED'}/>
        </>
      ))}
      <Flex justifyContent={{base:'flex-end', md:'center'}}>
      <Button mt={{md:"25px" , base:'29px'}} mb={{md:'58px', base:'100px'}} w={{md:'446px', base:'89px'}} color={'white'} bg={'#A95210'}>Save</Button>
      </Flex>
    </Container>
    </>
  );
};

export default Check;
