import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { G_GET_SINGLE_ASTROLOGER, G_GET_SINGLE_ASTROLOGER_BY_TOKEN } from "../apiLinks";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchAstrologerProfileData = createAsyncThunk(
  "astrologerProfile/fetchAstrologerProfileData",
  // async (id) => {
  //   const response = await axios.get(
  //     G_GET_SINGLE_ASTROLOGER(id)
  //   );
  // async ({userName}) => {
  //   const response = await axios.get(
  //     G_GET_SINGLE_ASTROLOGER(userName)
  //   );
  //   return response.data.guru;
  // }

  async ({ userName, isToken }) => {
    console.log(userName);
    let response;
    if (isToken === true) {
      response = await axios.get(G_GET_SINGLE_ASTROLOGER_BY_TOKEN(userName));
    } else {
      response = await axios.get(G_GET_SINGLE_ASTROLOGER(userName));
    }
    return response.data.guru;
  }
);

const astrologerProfileSlice = createSlice({
  name: "astrologerProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAstrologerProfileData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAstrologerProfileData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        //
      })
      .addCase(fetchAstrologerProfileData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default astrologerProfileSlice.reducer;