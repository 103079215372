import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedBirthDetailId: null,
};

const birthDetailsSlice = createSlice({
  name: 'birthDetails',
  initialState,
  reducers: {
    setSelectedBirthDetailId: (state, action) => {
      state.selectedBirthDetailId = action.payload;
    },
  },
});

export const { setSelectedBirthDetailId } = birthDetailsSlice.actions;

export default birthDetailsSlice.reducer;
