import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Consult_Astro_Profile from "../../../../assets/Consult_Astro_Profile.png";
import Btn_Bg from "../../../../assets/Btn_Bg.png";
import Live_Icon from "../../../../assets/Live_Icon.png";
import { SiGooglescholar } from "react-icons/si";
import MostTrusted from "../../../../assets/MostTrusted.svg";
import MostChoice from "../../../../assets/MostChoice.png";
import {
  BsFillStarFill,
  BsCameraVideoFill,
  BsCameraVideoOffFill,
} from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { IoMdBriefcase } from "react-icons/io";
import { IoLanguage, IoCall } from "react-icons/io5";
import { FaPhoneSlash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../firebaseEvents/firebase";
import ScholarCap from "../../../../assets/Cap.svg";
import Language from "../../../../assets/Language.svg";
import { fetchSelectedAstrologerData } from "../../../../store/selectedAstrologerSlice";

const ConsultAstroCard = (props) => {
  const guruToken = props?.currElem?.user?.guru;
  const isInternational = localStorage.getItem("isInternational") === "true";
  const loginToken = localStorage.getItem("loginToken");
  const host = localStorage.getItem("host");
  const loggedInUserDetails = useSelector(
    (state) => state.loggedInUserDetails.data
  );
  // console.log(loggedInUserDetails?.userDetails);
  //   console.log(props.currElem);
  const website = loggedInUserDetails?.userDetails?.website[0];
  const normalUserJourney2 =
    loggedInUserDetails?.userDetails?.normalUserJourney2;
  const timeWallet = loggedInUserDetails?.userDetails?.timeWallet;
  console.log(loggedInUserDetails);
  console.log(
    "website--",
    website,
    "normalUserJourney2--",
    normalUserJourney2,
    "host--",
    host
  );
  console.log(props.currElem);

  // Limit text upto 20 letters of astrologers skills
  // const concatenatedSkills = props.currElem.skills.join(", ");
  // const truncatedConcatenatedSkills =
  //   concatenatedSkills.length > 20
  //     ? concatenatedSkills.substring(0, 20) + "..."
  //     : concatenatedSkills;

  // Limit text upto 20 letters of astrologers skills
  // const concatenatedLanguages = props.currElem.languages.join(", ");
  // const truncatedConcatenatedLanguages =
  //   concatenatedLanguages.length > 20
  //     ? concatenatedLanguages.substring(0, 20) + "..."
  //     : concatenatedLanguages;

  // // Limit text upto 20 letters of astrologers Specialization
  // const concatenatedSpecialization = props.currElem.specialization.join(", ");
  // const truncatedConcatenatedSpecialization =
  //   concatenatedSpecialization.length > 20
  //     ? concatenatedSpecialization.substring(0, 20) + "..."
  //     : concatenatedSpecialization;

  // function getPrice(isInternationalUser, userDetails) {
  //   let price = {};
  //   if (isInternationalUser) {
  //     if (userDetails?.userDetails?.consultationCount === 0) {
  //       // todo return first price
  //       price = {
  //         fee: props?.currElem?.firstOfferPrice?.international?.fee,
  //         cutFee: props?.currElem?.internationalFee,
  //       };
  //       return price;
  //     } else {
  //       price = {
  //         fee: props?.currElem?.internationalFee,
  //         cutFee: "",
  //       };
  //       return price;
  //     }
  //   } else {
  //     if (userDetails?.userDetails?.consultationCount === 0 ) {
  //       // todo return first price
  //       if(website === 'gurucool.co' && normalUserJourney2 === false && host === "myguruji"){
  //         price = {
  //           fee: "Free",
  //           cutFee: `${timeWallet} minutes`,
  //         };
  //       }else{
  //       price = {
  //         fee: "₹" + props?.currElem?.firstOfferPrice?.national?.fee + "/Min",
  //         cutFee: "₹" + props?.currElem?.fee + "/Min",
  //       };
  //     }
  //       return price;
  //     } else {
  //       price = {
  //         fee: "₹" + props?.currElem?.fee + "/Min",
  //         cutFee: "₹" + props?.currElem?.cutfee + "/Min",
  //       };
  //       return price;
  //     }
  //   }
  // }

  // function getCuttedprice(isInternationalUser, userDetails) {
  //   if (isInternationalUser) {
  //     if (userDetails?.userDetails?.consultationCount === 0) {
  //       // todo return first price
  //       return "$" + props?.currElem?.internationalFee + "/Min";
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     if (userDetails?.userDetails?.consultationCount === 0) {
  //       // todo return first price
  //       return "₹" + props?.currElem?.fee + "/Min";
  //     } else {
  //       return "";
  //     }
  //   }
  // }

  // const host = localStorage.getItem("host");

  console.log("props>>>>>>>>>>>", props);
  return (
    <>
      <Flex
        // display={props?.currElem?.mostTrusted ? "none" : "flex"}
        // border={"2px solid red"}
        // mx={{base:"12px"}}
        // style={{
        //   filter:!props?.currElem?.normalUserJourney2 && props?.currElem?.mostTrusted ? "blur(2px)" : "blur(0px)",
        // }}  Apply the blur effect conditionally
        p={{ md: "16px 0 13px 11px", base: "10px 0 10px 10px" }}
        boxShadow={"0px 6px 7px 0px rgba(0,0,0,0.2)"}
        w={{ md: "395px", base: "374px" }}
        h={{ md: "158px", base: "132px" }}
        // background={{base:" linear-gradient(to right, white, rgba(255, 182, 197, 0.3))"}}
        // background={{
        //   base: !props?.currElem?.normalUserJourney2 && props?.currElem?.mostTrusted
        //     ? "linear-gradient(to right, white, white, #FF4C001C)"
        //     : "white",
        // }}
        // border={"1px"}
        // my={40}
        // mx={"auto"}
        borderRadius={"4px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={{ base: "5px", md: "0" }}
        // minW={{base:'354px', md:'auto'}}
        // bg={"yellow.200"}
      >
        {/* Astrologer Avatar, Exp., And Rating */}
        <VStack spacing={{ md: "8.5px", base: "12px" }}>
          <Box boxSize={{ md: "102px", base: "80px" }}>
            <NavLink
              onClick={() =>
                logEvent(analytics, "call_click", {
                  astrolger_name:
                    props?.currElem?.firstName +
                    " " +
                    props?.currElem?.lastName,
                })
              }
              to={`/doctor/${props.currElem?.id}`}
            >
              <Image
                src={props?.currElem?.imageURL}
                boxSize={"100%"}
                objectFit={"cover"}
                borderRadius="full"
              />
            </NavLink>
            <HStack
              w={{ md: "81px", base: "70px" }}
              h={{ md: "21px", base: "20px" }}
              border={"1px solid #FF4C00"}
              mx={"auto"}
              borderRadius={"22px"}
              position={"sticky"}
              zIndex={"10"}
              bg={"#FFFFFF"}
              mt={{ md: "-20px", base: "-15px" }}
              p={{ md: "2px 8px", base: "2px 5px" }}
              justifyContent={"center"}
             
            >
              <SiGooglescholar boxSize={"13px"} color={"#FF4C00"} />
              <Text
                fontSize={{ md: "10px", base: "8px", sm: "8px" }}
                fontWeight={"500"}
                color={"#363636"}
                // mb={0}
                // marginLeft="0"
              >
                {props.currElem.exp} Years
              </Text>
            </HStack>
          </Box>
          <HStack spacing={"4px"} alignItems={"center"} >
            <BsFillStarFill
              boxSize={{ md: "15px", base: "14px" }}
              color={"#FF4C00"}
            />
            <Text
              fontSize={{ md: "15px", base: "14px", sm: "14px" }}
              fontWeight={"500"}
              color={"#202020"}
              mb={0}
            >
              {props.currElem.rating}
            </Text>
          </HStack>
        </VStack>
        {/* Astrologer Name and Skills */}
        <Box h={{ base: "fit-content" }} ml="10px">
          <Box>
            <NavLink
              onClick={() =>
                logEvent(analytics, "call_click", {
                  astrolger_name:
                    props?.currElem?.user?.firstName +
                    " " +
                    props?.currElem?.user?.lastName,
                })
              }
              to={`/doctor/${props.currElem?.id}`}
            >
              <Heading
                as="h5"
                fontSize={{ md: "20px", base: "16px" }}
                fontWeight={"500"}
                color={"#212121"}
                width={{ md: "150px" }}
              >
                {props.currElem.firstName} {props.currElem.lastName}
              </Heading>
            </NavLink>
            <VStack
              spacing={{ md: "6px", base: "6px" }}
              alignItems={"flex-start"}
            >
              <HStack spacing={{ md: "12px", base: "4px" }}>
                <Text m="0px">•</Text>
                <Heading
                  as="h6"
                  fontSize={{ md: "13px", base: "11px" }}
                  fontWeight={"400"}
                  color={"#3F3F3F"}
                  mb={0}
                >
                  {`${props.currElem.specialization[0]}, ${props.currElem.specialization[1]}`}
                </Heading>
              </HStack>
              <HStack spacing={{ md: "12px", base: "4px" }}>
                <Text m="0px">•</Text>
                <Heading
                  as="h6"
                  fontSize={{ md: "13px", base: "11px" }}
                  fontWeight={"400"}
                  color={"#3F3F3F"}
                  mb={0}
                >
                  {`${props.currElem.specialization[2]}, ${props.currElem.specialization[3]}`}
                </Heading>
              </HStack>
              {/* <HStack spacing={{ md: "12px", base: "4px" }}>
                <IoLanguage boxSize={{ md: "16px" }} color={"#FF4C00"} />
                <Heading
                  as="h6"
                  fontSize={{ md: "13px", base: "11px" }}
                  fontWeight={"400"}
                  color={"#3F3F3F"}
                  mb={0}
                >
                  {truncatedConcatenatedLanguages}
                </Heading>
              </HStack> */}
            </VStack>
          </Box>
          <Text
            display={{ md: "none", base: "block" }}
            w={"fit-content"}
            mt={"10px"}
            pr={"20px"}
            bg={"#7AB400"}
            pl={"8px"}
            mb={0}
            color={"white"}
            fontSize={"10px"}
            style={{
              clipPath: " polygon(0% 0%, 100% 0, 87% 49%, 100% 100%, 0% 100%)",
            }}
          >
            {props?.currElem?.primarySkills}
          </Text>
        </Box>
        {/* Action Button - Call, Live and Pvt. Live */}
        <Box h={{ base: "full" }}>
          <Box
            h={{ md: "100%", base: "50%" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={{ base: "flex-end" }}
            justifyContent={"space-between"}
            // border={"2px solid green"}
          >
            <VStack spacing={{ md: "4px", base: "8px" }} alignItems={"end"}>
              {props.currElem.liveStatus === false ? null : (
                <Button
                  w={{ md: "80px", base: "80px" }}
                  h={{ md: "34px", base: "24px" }}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  bg={"#FF4C00"}
                  p={{ md: "4px 0 4px 8px", base: "5px 0 5px 9px" }}
                  borderRadius={"4px 0px 0px 4px"}
                  _hover={{
                    bg: "#fd7238",
                  }}
                >
                  <Image src={Live_Icon} alt="live-icon" />
                  <Text
                    mb={0}
                    ml={{ md: "9px", base: "11px" }}
                    fontSize={{ md: "14px", base: "13px" }}
                    fontWeight={"500"}
                    color={"white"}
                    // onClick={() => {
                    //   window.location.href = `/live/${props.currElem.user.firstName}${props.currElem.user.lastName}?id=${props.currElem.user.guru}`;
                    //   logEvent(analytics, "live_enter");
                    // }}
                  >
                    Live
                  </Text>
                </Button>
              )}

              {/* {props.currElem.callAvailability === "offline" && (
                <Button
                  w={{ md: "80px", base: "80px" }}
                  h={{ md: "34px", base: "24px" }}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  bg={"#D9D9D9"}
                  p={{ md: "4px 0 4px 8px", base: "5px 0 5px 9px" }}
                  borderRadius={"4px 0px 0px 4px"}
                  // onClick={() => {
                  //   props.callClicked();
                  //   localStorage.setItem("guruToken", props.currElem.user.guru);
                  // }}
                  style={{ cursor: "no-drop" }}
                >
                  <FaPhoneSlash
                    boxSize={{ md: "16px", base: "14px" }}
                    color={"#666666"}
                  />
                  <Text
                    mb={0}
                    ml={{ md: "9px", base: "11px" }}
                    fontSize={{ md: "14px", base: "13px" }}
                    fontWeight={"500"}
                    color={"#666666"}
                  >
                    Offline
                  </Text>
                </Button>
              )} */}

              {props.currElem.callAvailability === "online" && (
                <>
                  <Button
                    boxShadow={"0px 3px 4px 0px rgba(0, 0, 0, 0.20)"}
                    w={{ md: "80px", base: "80px" }}
                    h={{ md: "34px", base: "27px" }}
                    display={"flex"}
                    // justifyContent={"space-evenly"}
                    alignItems={"center"}
                    // gap={website === 'gurucool.co' && normalUserJourney2 === false && host === "myguruji" ? "15px" : "2px"}
                    gap={"2px"}
                    bg={"#FF4C00"}
                    p={{ md: "4px 0 4px 8px", base: "7px 11px 7px 4px" }}
                    borderRadius={"4px 0px 0px 4px"}
                    _hover={{
                      bg: "#fd7238",
                    }}
                    onClick={() => {
                      props.callClicked();
                      localStorage.setItem(
                        "guruToken",
                        props.currElem.user.guru
                      );
                    }}
                  >
                    <IoCall
                      boxSize={{ md: "16px", base: "14px" }}
                      color={"white"}
                      // style={{ marginRight: "6px" }}
                    />

                    {loginToken ? (
                      <Text
                        // as={"span"}
                        fontSize={{
                          base: "12px",
                          sm: "12px",
                          md: "14px",

                          // md:
                          //   loggedInUserDetails?.userDetails?.consultationCount === 0
                          //     ? "10px"
                          //     : "16px",
                          // base:
                          //   loggedInUserDetails?.userDetails?.consultationCount === 0
                          //     ? "9px"
                          //     : "16px",
                        }}
                        fontWeight={"500"}
                        color={"#fff"}
                        // pr={{ md: "8px" }}
                        // mt={{md: "8px"}}
                        pl={{ base: "4px", md: "0" }}
                        ml={2}
                        m="0px !important"
                        mb={0}
                        // textDecoration={
                        //   loggedInUserDetails?.userDetails?.consultationCount === 0
                        //     ? "line-through"
                        //     : "none"
                        // }
                      >
                        {isInternational
                          ? getPrice(true, loggedInUserDetails).fee
                          : getPrice(false, loggedInUserDetails).fee}{" "}
                      </Text>
                    ) : (
                      <Text
                        mb={0}
                        fontSize={{ md: "16px", base: "14px" }}
                        fontWeight={"700"}
                        color={"#fff"}
                        mr={{ base: "6px" }}
                        mx={"auto"}
                      >
                        Free
                      </Text>
                    )}
                  </Button>
                  {loginToken ? (
                    <>
                      <HStack
                        justifyContent={"flex-end"}
                        w={"100%"}
                        mt={"2px !important"}
                        pr={{ base: "8px", md: "0px" }}
                      >
                        <Text
                          // as={"span"}
                          // display={website === 'gurucool.co' && normalUserJourney2 === false && host === "myguruji" ? "none" : "block"}
                          fontSize={{ md: "12px", base: "10px" }}
                          fontWeight={"500"}
                          color={"#808080"}
                          // pr={{ md: "8px" }}
                          pl={"14px"}
                          ml={{ md: "unset", base: "24px" }}
                          mb={0}
                          textDecoration={
                            loggedInUserDetails?.userDetails
                              ?.consultationCount > 0 ||
                            !website === "gurucool.co"
                              ? "line-through"
                              : "none"
                          }
                          mx={{ md: "auto", base: "unset" }}
                        >
                          {isInternational
                            ? getPrice(true, loggedInUserDetails).cutFee
                            : getPrice(false, loggedInUserDetails).cutFee}{" "}
                        </Text>
                      </HStack>
                      {/* <VStack display={website === 'gurucool.co' && normalUserJourney2 === false && host === "myguruji" ? "block" : "none"}  mr={'10px !important'} alignItems={'flex-start'}>
                      <Heading m={'0 !important'} color={'#118C00'}>Free</Heading>
                      <Text 
                      my={'0 !important'}
                     
                        
                        fontSize={{ md: "14px", base: "12px" }}
                        fontWeight={"500"}
                        color={"#B4B4B4"}
                        ml={{ md: "unset", base: "24px" }}
                        mb={0}
                        mx={{ md: "auto", base: "unset" }}>{timeWallet} min</Text>
                    </VStack> */}
                    </>
                  ) : (
                    <HStack
                      justifyContent={"flex-end"}
                      w={"100%"}
                      style={{ marginTop: "0px !important" }}
                      pr={{ base: "8px", md: "0px" }}
                    >
                      <Text
                        // as={"span"}
                        fontSize={{ md: "14px", base: "12px" }}
                        fontWeight={"500"}
                        color={"#B4B4B4"}
                        pr={{ md: "8px" }}
                        ml={{ md: "unset", base: "24px" }}
                        mb={0}
                        textDecoration={"line-through"}
                        mx={{ md: "auto", base: "unset" }}
                      >
                        {isInternational
                          ? "$" +
                            props?.currElem?.firstOfferPrice?.international?.fee
                          : "₹: " +
                            props?.currElem?.firstOfferPrice?.national?.fee}
                        /Min
                      </Text>
                    </HStack>
                  )}
                </>
              )}

              {props.currElem.callAvailability === "busy" && (
                <Button
                  w={{ md: "80px", base: "80px" }}
                  h={{ md: "34px", base: "24px" }}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  bg={"#D9D9D9"}
                  p={{ md: "4px 0 4px 8px", base: "5px 0 5px 9px" }}
                  borderRadius={"4px 0px 0px 4px"}
                  // _hover={{
                  //   bg: "#fd7238",
                  // }}
                  // onClick={() => {
                  //   props.callClicked();
                  //   localStorage.setItem("guruToken", props.currElem.user.guru);
                  // }}
                  style={{ cursor: "no-drop" }}
                >
                  <FaPhoneSlash
                    boxSize={{ md: "16px", base: "14px" }}
                    color={"#FF3030"}
                  />
                  <Text
                    mb={0}
                    ml={{ md: "9px", base: "11px" }}
                    fontSize={{ md: "14px", base: "13px" }}
                    fontWeight={"500"}
                    color={"#FF3030"}
                  >
                    Busy
                  </Text>
                </Button>
              )}

              {/* {props.currElem.liveCallAvailability.private === true ? (
              <Button
                w={{ md: "80px", base: "80px" }}
                h={{ md: "34px", base: "24px" }}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                bg={"#FF4C00"}
                p={{ md: "4px 0 4px 8px", base: "5px 0 5px 9px" }}
                borderRadius={"4px 0px 0px 4px"}
                _hover={{
                  bg: "#fd7238",
                }}
              >
                <BsCameraVideoFill
                  boxSize={{ md: "16px", base: "14px" }}
                  color={"white"}
                />
                <Text
                  mb={0}
                  ml={{ md: "9px", base: "11px" }}
                  fontSize={{ md: "14px", base: "13px" }}
                  fontWeight={"500"}
                  color={"white"}
                >
                  P. Live
                </Text>
              </Button>
            ) : (
              <Button
                w={{ md: "80px", base: "80px" }}
                h={{ md: "34px", base: "24px" }}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                bg={"#D9D9D9"}
                p={{ md: "4px 0 4px 8px", base: "5px 0 5px 9px" }}
                borderRadius={"4px 0px 0px 4px"}
              >
                <BsCameraVideoOffFill
                  boxSize={{ md: "16px", base: "14px" }}
                  color={"#FF3030"}
                />
                <Text
                  mb={0}
                  ml={{ md: "9px", base: "11px" }}
                  fontSize={{ md: "14px", base: "13px" }}
                  fontWeight={"500"}
                  color={"#FF3030"}
                >
                  Busy
                </Text>
              </Button>
            )} */}
            </VStack>

            <Image
              display={props?.currElem?.mostTrusted ? "block" : "none"}
              src={MostChoice}
            />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default ConsultAstroCard;
