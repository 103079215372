import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Box, Container, Image } from "@chakra-ui/react";
import Trustbanner from "../../Assests/Supportasset/Trustbanner.svg";
import NUJOfferBannerMobile from "../../../../assets/NUJBannerWeb.png";

const NewBanner = () => {
  const host = localStorage.getItem("host");
  const options = {
    loop: true,
    // controlsClass: 'owl-controls',
    // center: true,
    // nav: true,
    dots: true,
    // margin: 18,
    // dotsEach: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
        // margin: 8,
        // nav: false,
        dots: false,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <Container maxW={{ md: "6xl", base: "4xl" }} mt={"50px"}>
        {/* <OwlCarousel className="owl-theme" loop {...options}>
          <Box w={"100%"} mb={{ md: "14px", base: "9px" }}>
            <Image
              objectFit={"cover"}
              src={Trustbanner}
              alt="hero-banner"
              w="100%"
              // effect="blur"
            />
          </Box>
          <Box w={"100%"} mb={{ md: "14px", base: "9px" }}>
            <Image
              objectFit={"cover"}
              src={Trustbanner}
              alt="hero-banner"
              w="100%"
              // effect="blur"
            />
          </Box>
          <Box w={"100%"} mb={{ md: "14px", base: "9px" }}>
            <Image
              objectFit={"cover"}
              src={Trustbanner}
              alt="hero-banner"
              w="100%"
              // effect="blur"
            />
            
          </Box>
        </OwlCarousel> */}
        <Image
          display={host === "myguruji" ? "block" : "none"}
          objectFit={"cover"}
          src={NUJOfferBannerMobile}
          alt="hero-banner"
          w="100%"
          // effect="blur"
        />
      </Container>
    </>
  );
};

export default NewBanner;
