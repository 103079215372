import {
  Container,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { P_ADD_WALLET_BALANCE } from "../../../apiLinks";

const AddBalance = (props) => {
  const loginToken = localStorage.getItem("loginToken");
  const { id } = useParams();
  const [addWalletMoney, setAddWalletMoney] = useState(0);

 
  
  const handleAddWalletMoney = (event) => {
    event.preventDefault();

    // const addWalletMonetUrl = `https://data.gurucool.life/api/v1/admin/superAdminRecharge?id=${props.idForUpdateBalance}&amount=${addWalletMoney}&international=${props.international}`;
    const config = {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    };
    try {
      axios.post(P_ADD_WALLET_BALANCE(props.idForUpdateBalance,addWalletMoney,props.international), null, config).then((response) => {
        if (response.status === 200) {
          // alert("Money Add Successfully");
          props.onClose()
        }
        //
      });
    } catch (error) {
      //
    }
  };

 

  //
  return (
    <>
      {/* <Container maxW={"full"} h="100vh"> */}
      <Flex w={"100%"} h={"100%"} alignItems={"center"}>
        <Box
          // w={{md: "40%", base:"80%"}}
          m="auto"
          //   mt="20%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          // boxShadow={"xl"}
          py={"12px"}
          // bg={"orange.500"}
          // borderRadius={"5px"}
        >
          <Heading as="h1" size="lg" mb="6" color={"whiteAlpha.800"}>
            Add Wallet Money
          </Heading>
          <Box boxShadow={"xl"} p={"3"} borderRadius={"5px"}>
            <form onSubmit={handleAddWalletMoney}>
              <FormControl id="money" mb="4">
                <FormLabel color={"whiteAlpha.900"}>Enter Amount</FormLabel>
                <Input
                  type="number"
                  placeholder="Enter amount"
                  min={0}
                  step={1}
                  required
                  bg={"whiteAlpha.500"}
                  value={addWalletMoney}
                  onChange={(e) => setAddWalletMoney(e.target.value)}
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" w="100%">
                Add Money
              </Button>
            </form>
          </Box>
        </Box>
      </Flex>
      {/* </Container> */}
    </>
  );
};

export default AddBalance;
