import React, { useState, useEffect } from "react";
// import { Box, Grid, Heading, Flex, Link,Text } from "@chakra-ui/react";
import Card from "../Card/Card";
import Pagination from "react-js-pagination";

import {
  Heading,
  // Avatar,
  Box,
  // Container,
  // Text,
  // Stack,
  // Button,
  // Link,
  // Image,
  // useDisclosure,
  // Modal,
  Flex,
  Grid,
} from "@chakra-ui/react";
// import CardGrid from "../CardGrid/CardGrid";

// import Chat_icon from "../../../assets/chat-blue.svg";
// import Chat_icon_gray from "../../../assets/chat-gray.svg";
// import Call_icon from "../../../assets/Call_icon.png";
// import Star from "../../../assets/Star.png";
// import ChatFlowModal from "../../ChatFlow/ChatFlowModal";
// import CallFlowModal from "../../CallFlow/CallFlowModal";
// import Login from "../../Login&Signup/Login";

import { useDispatch, useSelector } from "react-redux";
// import { fetchSelectedAstrologerData } from "../../../store/selectedAstrologerSlice";
import { fetchData } from "../../../store/TarotSlice";
// import { Center, Spinner } from "@chakra-ui/react";
// import { NavLink } from "react-router-dom";
import axios from "axios";
import { selectedAstrologerDetailsAction } from "../../../store/selectedAstrologerDetailsSlice";

const ITEMS_PER_PAGE = 12;
const CardGrid = () => {
  const token = localStorage.getItem("guruToken");
  //
  useEffect(() => {
    if (token) {
      axios
        .get(
          `https://data.gurucool.life/api/v1/guru/getSingleGuru?guruId=${token}`
        )
        .then((response) => {
          //
          dispatch(
            selectedAstrologerDetailsAction.setAstroDetails(response.data.guru)
          );
        });
    }
    // eslint-disable-next-line
  }, [token]);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  // const status = useSelector((state) => state.data.status);
  // const error = useSelector((state) => state.data.error);
  //

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // const itemsPerPage = 10;
  // const totalPages = Math.ceil(info.length / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const paginatedInfo = info.slice(startIndex, endIndex);

  const startIndex = (activePage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = data.slice(startIndex, endIndex);

  return (
    <>
      <Box as="div" mt={{ md: "40px", base: "20px" }}>
        <Heading
          fontSize={{ base: "20px", md: "30px" }}
          fontWeight={{ base: 500 }}
          textAlign={{ base: "center", md: "center" }}
          mb={{ base: "12px", md: "24px" }}
        >
          All Astrologers
        </Heading>
      </Box>

      <Grid
        templateColumns={{
          md: "repeat(4, 1fr)",
          base: ["repeat(1, 1fr)", "repeat(2, 1fr)"],
        }}
        w={{ md: "1024px", base: "auto" }}
        mx="auto"
        justifyContent={{base: "unset", md: "center"}}
      >
        {currentItems.map((item, index) => {
          return <Card key={index} info={item} />;
        })}
      </Grid>

      <Flex justifyContent={"center"} alignItems={"center"} m="70px">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={ITEMS_PER_PAGE}
          totalItemsCount={data.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          prevPageText="Previous"
          nextPageText="Next"
        />
      </Flex>
    </>
  );
};

export default CardGrid;
