import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { G_GET_ALL_ASTROLOGERS } from "../apiLinks";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchData = createAsyncThunk("data/fetchData", async () => {
  const response = await axios.get(G_GET_ALL_ASTROLOGERS);
  //
  return response.data.guru.docs;
  // const shuffledArray = [...response.data.guru.docs];
  // for (let i = shuffledArray.length - 1; i > 0; i--) {
  //   const j = Math.floor(Math.random() * (i + 1));
  //   [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  // }
  // return shuffledArray;
});

const tarotSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default tarotSlice.reducer;
