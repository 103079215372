import React, { useEffect } from "react"; // , { useState }
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Box, Container, Text } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { ChevronRightIcon } from "@chakra-ui/icons";
import "./UserTestimonials.css";
import ReactPlayer from "react-player";
import Aos from "aos";
import "aos/dist/aos.css";

const UserTestimonials = () => {
  const options = {
    loop: true,
    // controlsClass: 'owl-controls',
    center: true,
    nav: true,
    dots: false,
    // margin: 8,
    // dotsEach: false,
    responsive: {
      0: {
        items: 2.1,
        margin: 8,
        nav: false,
        loop: true,
      },
      600: {
        items: 3.2,
        loop: true,
      },
      1000: {
        items: 5,
      },
    },
  };

  const videos = [
    {
      id: 1,
      video: "https://www.youtube.com/shorts/C2bSwYi74Po",
    },
    {
      id: 2,
      video: "https://www.youtube.com/shorts/GIxJPmZp8RM",
    },
    {
      id: 3,
      video: "https://www.youtube.com/shorts/DE7tv1qAn58",
    },
    {
      id: 4,
      video: "https://www.youtube.com/shorts/h3ZAY9b9URo",
    },
    {
      id: 5,
      video: "https://www.youtube.com/shorts/fg6MZvnoiPk",
    },
    {
      id: 6,
      video: "https://www.youtube.com/shorts/ST5f39rSQMY",
    },
    {
      id: 7,
      video: "https://www.youtube.com/shorts/LdiVZm85tl0",
    },
    {
      id: 8,
      video: "https://www.youtube.com/shorts/-runrBBiy7k",
    },
  ];

  return (
    <>
      <Container maxW={"6xl"} id="user-testimonials">
        <Box
          as="div"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text
            fontSize={{ base: "18px", md: "32px" }}
            fontWeight={"700"}
            color={"#D97122"}
            mx={{ base: "12px", md: "19px" }}
          >
            User Testimonial
          </Text>
        </Box>
        <Swiper
          id="video-testimonials"
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          {videos.map((curr) => {
            const videoId = curr.video;
            //
            return (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                key={curr.id}
                // key={index}
              >
                <SwiperSlide>
                  <Box
                    as="div"
                    display={"flex"}
                    justifyContent={"center"}
                    borderRadius={"6px"}
                    position={"relative"}
                    height={"inherit"}
                  >
                    <ReactPlayer
                      as="div"
                      className="react-player"
                      // borderRadius={'12px'}
                      url={videoId}
                      width="100%"
                      height="100%"
                      controls={true}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 },
                        },
                      }}
                      playIcon={<button>Play</button>}
                      stopIcon={<button>Stop</button>}
                    />
                  </Box>
                </SwiperSlide>
              </motion.div>
            );
          })}

          <Box as="div" className="slider-controler">
            <Box
              as="div"
              className="swiper-button-prev slider-arrow"
              w={"5rem"}
            >
              <span>
                <div className="left-button">
                  <Icon
                    as={ChevronLeftIcon}
                    boxSize={6}
                    color={"#DFDFDF"}
                    _active={{ color: "#FF4C00" }}
                    position={"relative"}
                    right={"-13%"}
                    top={"4%"}
                  />
                </div>
              </span>
            </Box>
            <Box
              as="div"
              className="swiper-button-next slider-arrow"
              w={"5rem"}
            >
              <span>
                <div className="right-button">
                  <Icon
                    as={ChevronRightIcon}
                    boxSize={6}
                    color={"#DFDFDF"}
                    _active={{ color: "#FF4C00" }}
                    position={"relative"}
                    right={"-13%"}
                    top={"4%"}
                  />
                </div>
              </span>
            </Box>
          </Box>
        </Swiper>
      </Container>
    </>
  );
};

export default UserTestimonials;
