import React, { useEffect, useState } from "react";
import FreeLive from "../HomePage/FreeLive/FreeLive";
import Hero from "../HomePage/Hero/Hero";
import LiveBanner from "./LiveBanner";
import { Helmet } from "react-helmet";
import { Container } from "@chakra-ui/layout";
import { G_ALL_LIVE_ASTROLOGERS } from "../../apiLinks";
import axios from "axios";
import { Button, Image, Text } from "@chakra-ui/react";
import NoAstro from "../../assets/no_astro.png";
import { useNavigate } from "react-router-dom";

const LiveAstrologers = () => {
  const [astrologerLive, setAstrologerLive] = useState(false);
  const navigate = useNavigate();

  const getLiveAstrologers = async () => {
    try {
      const response = await axios.get(G_ALL_LIVE_ASTROLOGERS);
      if (response.data.guru.length !== 0) {
        setAstrologerLive(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLiveAstrologers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Gurucool || Live Streaming</title>
      </Helmet>

      <LiveBanner />
      {/* <Hero/> */}

      {astrologerLive ? (
        <FreeLive />
      ) : (
        <Container
          mb={"20px"}
          mt={"20px"}
          // bg={"#E8E8E8"}
          color={"#979491"}
          // height={"5rem"}
          fontSize={{ base: "1rem", sm: "1rem", md: "1.3rem", lg: "1.5rem" }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image w={{sm:"250px", base:"250px", md:"400px"}} src={NoAstro} />
          <Text mt="10px" color="#FF4C00" fontSize={{md:"24px", sm:"20px"}}>
            No Astrologer is taking live session at the moment{" "}
          </Text>

          <Button
            p="20px"
            w="150px"
            bgColor="#FF4C00"
            color={"#FFF"}
            _hover={{ boxShadow: "none" }}
            onClick={() => navigate("/astrologers")}
          >
            Explore
          </Button>
        </Container>
      )}
    </>
  );
};

export default LiveAstrologers;
