import {
  Container,
  Box,
  Flex,
  Image,
  Text,
  Divider,
  VStack,
  Stack,
  Button,
} from "@chakra-ui/react";
import React from "react";

import { useNavigate } from "react-router-dom";

import _user from "../../../assets/_user.png";

const ChatConsultationDetails = () => {
  const navigate = useNavigate();

  const redirectHome = () => {
    navigate("/");
  }
  return (
    <>
      <Container>
        <Box
          h={"90vh"}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Flex
            direction={"column"}
            borderRadius={"6px"}
            border="1px solid orange"
            w={"500px"}
            py={2}
          >
            <Box mb={{ base: "8px", md: "16px" }}>
              <Image
                mx={"auto"}
                src={_user}
                alt="user"
                maxW={{ base: "25%", md: "100%" }}
              />
              <Text
                fontSize={{ base: "16px", md: "22px" }}
                fontWeight={"500"}
                textAlign={"center"}
              >
                Astrologer Name
              </Text>
            </Box>
            <Divider alignSelf={"center"} width={"90%"} color={"gray.300"} />

            <Flex
              justifyContent={"space-between"}
              my={{ base: "8px", md: "12px" }}
            >
              <VStack borderRight="1px" w={"50%"}>
                <Text
                  fontSize={{ base: "14px", md: "18px" }}
                  fontWeight={500}
                  color={"#7B7B7B"}
                >
                  Consultation Charges
                </Text>
                <Text
                  fontSize={{ base: "14px", md: "25px" }}
                  fontWeight={500}
                  color={"#252525"}
                >
                  ₹40/min
                </Text>
              </VStack>
              <VStack w={"50%"}>
                <Text
                  fontSize={{ base: "14px", md: "18px" }}
                  fontWeight={500}
                  color={"#7B7B7B"}
                >
                  Your Balance
                </Text>
                <Text
                  fontSize={{ base: "14px", md: "25px" }}
                  fontWeight={500}
                  color={"#252525"}
                >
                  ₹ 400
                </Text>
                <Text
                  fontSize={{ base: "10px", md: "14px" }}
                  fontWeight={400}
                  color={"gray.500"}
                >
                  Max call duration: 3mins
                </Text>
              </VStack>
            </Flex>
            <Divider alignSelf={"center"} width={"90%"} color={"gray.300"} />
            <Flex justifyContent={"center"} mt={{ base: "12px",md: "16px" }} mb={{ base: "14px", md: "21px" }}>
              <Text
                fontSize={{ base: "12px",md: "17px" }}
                fontWeight={500}
                color={"green.400"}
                // textAlign={"center"}
              >
                Consultation Successfully Completed !!  
              </Text>
            </Flex>

            <Stack
              direction="row"
              spacing={4}
              justify={"space-between"}
              align="center"
              px={2}
            >
              <Button colorScheme="orange" w={"100%"} variant="solid" onClick={redirectHome}>
                Redirect To Home
              </Button>
            </Stack>
          </Flex>
        </Box>
      </Container>
    </>
  );
};

export default ChatConsultationDetails;
