import React, { useState } from "react";
import {
  Box,
  Button,
  Image,
  Flex,
  Text,
  Input,
  Textarea,
  Container,
  HStack,
  VStack,
  useToast,
} from "@chakra-ui/react";
import icon1 from "../../../assets/newhomepage/feebackemo.svg";
import icon2 from "../../../assets/newhomepage/plantemo.svg";
import axios from "axios";

const Feedback = () => {
  const loginToken = localStorage.getItem("loginToken");
  const [feedbackText, setFeedbackText] = useState("");
  console.log(feedbackText);

  const toast = useToast();

  const handleFeedbackSubmit = async () => {
    try {
      // Make an API call to save the feedback to the backend.
      const response = await axios.post(
        "https://data.gurucool.life/api/v1/user/saveUserFeedbackToGS",
        {
          userFeedback: feedbackText,
        },
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );

      // Optionally, you can handle the response or show a success message to the user.
      console.log("Feedback submitted successfully!", response);
      if (response.status === 200) {
        toast({
          title: "Feedback.",
          description: response?.data?.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
      // Clear the feedback input after submission (optional).
      setFeedbackText("");
    } catch (error) {
      // Handle any errors that might occur during the API call.
      console.error("Error submitting feedback:", error);
      toast({
        title: "Feedback.",
        description: "Error submitting feedback",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Container
      maxW={{ md: "10xl" }}
      mb={{ md: "20px", base: "-0.5rem" }}
      bg={"#fff"}
      h={{ base: "257px" }}
    >
      <Container
        maxW={"1115px"}
        py={{ base: "16px", md: "24px" }}
        h={{ base: "250px" }}
        px={0}
        justifyContent={{ md: "space-between" }}
        mt={{ base: "10px" }}
      >
        <Box
          display={"flex"}
          justifyContent={{ md: "center", base: "flex-start" }}
          h={{ base: "70px", md: "130px" }}
        >
          <VStack gap={0}>
            <HStack w={{ base: "100%" }} mb={"-11px"}>
              <Image
                src={icon1}
                h={{ base: "26px", md: "48px" }}
                w={{ base: "26px", md: "48px" }}
                m={{ base: "-0.6rem 0 0 0rem" }}
              />
              <Text
                ml={{ md: "16px", base: "12px" }}
                mb={0}
                pb={2}
                fontSize={{ md: "34px", base: "16px" }}
                fontWeight={"600"}
              >
                Share a
                <Text as="span" color="#FF4D00">
                  {" "}
                  Feedback
                </Text>
              </Text>
            </HStack>

            <HStack mx={0} pl={{ md: "50px" }}>
              <Text fontSize={{ md: "16px", base: "12px" }} fontWeight={"500"}>
                Your feedback helps us grow..
              </Text>
              <Image
                src={icon2}
                w={{ md: "42px", base: "14px" }}
                h={{ md: "62px" }}
                pb={6}
                m={{ md: "1.3rem 20rem 20rem 20rem" }}
              />
            </HStack>
          </VStack>
        </Box>

        <Box
          mt={{ base: "-11px" }}
          w={{ md: "full" }}
          display={{ md: "flex" }}
          flexDirection={{ md: "row", base: "column" }}
          justifyContent={{ md: "space-between" }}
          alignItems={{ md: "flex-end" }}
          gap={{ md: "32px", base: "16px" }}
        >
          <Textarea
            value={feedbackText}
            onChange={(e) => {
              setFeedbackText(e.target.value);
            }}
            fontSize={{ md: "18px", base: "16px" }}
            fontWeight="400"
            fontFamily="Poppins"
            type="textarea"
            borderRadius={4}
            border="0.5px solid"
            h={{ md: "88px", base: "120px" }}
            bg={{ md: "#F3F3F3" }}
            borderColor="1px solid #E8E8E8"
            outline="none"
            w={{ md: "1024px", base: "100%" }}
            _focus={{ outline: "none", boxShadow: "none" }}
            placeholder="Write you feedback here"
          />

          <Button
            fontSize={{ base: "14px", md: "16px" }}
            fontWeight="600"
            justifyContent={{ md: "flex-end", base: "center" }}
            alignItems={"center"}
            mt={{ base: "12px" }}
            w={{ base: "full", md: "auto" }}
            color="#FFF"
            bg="#FF5710"
            h={{ md: "40px", base: "35px" }}
            onClick={() => {
              handleFeedbackSubmit();
              logEvent(analytics, "feedback_submit");
            }}
          >
            SUBMIT
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default Feedback;

// import React, { useState } from 'react';
// import {
//   Box,
//   Button,
//   Image,
//   Flex,
//   Text,
//   Input,
//   Textarea,
//   Container,
//   HStack,
//   VStack,
//   Wrap,
//   WrapItem,
//   useToast,
// } from '@chakra-ui/react';
// import icon1 from "../../../assets/newhomepage/feebackemo.svg";
// import icon2 from "../../../assets/newhomepage/plantemo.svg";
// import axios from 'axios';

// const Feedback = () => {
//   const loginToken = localStorage.getItem("loginToken");
//   const [feedbackText, setFeedbackText] = useState('');
//   const [feedbackStatus, setFeedbackStatus] = useState(null); // State to track feedback status
//   const toast = useToast(); // useToast hook from Chakra UI

//   const handleFeedbackSubmit = async () => {
//     try {
//       // Make an API call to save the feedback to the backend.
//       const response = await axios.post(
//         'https://data.gurucool.life/api/v1/user/saveUserFeedbackToGS',
//         {
//           userFeedback: feedbackText,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${loginToken}`,
//           },
//         },
//       );

//       const toast = useToast({
//         position: 'top', // Set the position to bottom
//         duration: 5000, // Optional: Customize the duration the toast is shown (in milliseconds)
//       });

//       // Show success toast when feedback is submitted successfully
//       toast({
//         title: 'Feedback Submitted Successfully',
//         status: 'success',
//         isClosable: true,
//       });

//       // Clear the feedback input after submission (optional).
//       setFeedbackText('');
//     } catch (error) {
//       // Handle any errors that might occur during the API call.
//       console.error('Error submitting feedback:', error);

//       // Show error toast if there's an error during submission
//       toast({
//         title: 'Error Submitting Feedback',
//         description: 'An error occurred while submitting feedback.',
//         status: 'error',
//         isClosable: true,
//       });
//     }
//   };

//   return (
//     <Container maxW={{ md: "10xl" }} mb={{ md: "20px", base: "-0.5rem" }} bg={"#fff"} h={{ base: "257px" }}>
//       <Container
//         maxW={"1115px"}
//         py={{ base: "16px", md: "24px" }}
//         h={{ base: "250px" }}
//         px={0}
//         justifyContent={{ md: "space-between" }}
//         mt={{ base: "10px" }}
//       >
//         <Box display={"flex"} justifyContent={{ md: "center", base: "flex-start" }} h={{ base: "70px", md: "130px" }}>
//           <VStack gap={0}>
//             <HStack w={{ base: "100%" }} mb={"-11px"}>
//               <Image src={icon1} h={{ base: "26px", md: "48px" }} w={{ base: "26px", md: "48px" }} m={{ base: "-0.6rem 0 0 0rem" }} />
//               <Text ml={{ md: "16px", base: "12px" }} mb={0} pb={2} fontSize={{ md: "34px", base: "16px" }} fontWeight={"600"}>
//                 Share a<Text as="span" color="#FF4D00"> Feedback</Text>
//               </Text>
//             </HStack>

//             <HStack mx={0} pl={{ md: "50px" }}>
//               <Text fontSize={{ md: "16px", base: "12px" }} fontWeight={"500"}>Your feedback helps us grow..</Text>
//               <Image src={icon2} w={{ md: "42px", base: "14px" }} h={{ md: "62px" }} pb={6} m={{ md: "1.3rem 20rem 20rem 20rem" }} />
//             </HStack>
//           </VStack>
//         </Box>

//         <Box
//           mt={{ base: "-11px" }}
//           w={{ md: "full" }}
//           display={{ md: 'flex' }}
//           flexDirection={{ md: "row", base: "column" }}
//           justifyContent={{ md: "space-between" }}
//           alignItems={{ md: "flex-end" }}
//           gap={{ md: "32px", base: "16px" }}
//         >
//           <Textarea
//             value={feedbackText}
//             onChange={(e) => { setFeedbackText(e.target.value) }}
//             fontSize={{ md: "18px", base: "16px" }}
//             fontWeight="400"
//             fontFamily="Poppins"
//             type='textarea'
//             borderRadius={4}
//             border="0.5px solid"
//             h={{ md: "88px", base: "120px" }}
//             bg={{ md: "#F3F3F3" }}
//             borderColor="1px solid #E8E8E8"
//             outline="none"
//             w={{ md: "1024px", base: "100%" }}
//             _focus={{ outline: "none", boxShadow: "none" }}
//             placeholder='Write your feedback here'
//           />

//           <Button
//             fontSize={{ base: "14px", md: "16px" }}
//             fontWeight="600"
//             justifyContent={{ md: "flex-end", base: "center" }}
//             alignItems={"center"}
//             mt={{ base: "12px" }}
//             w={{ base: "full", md: "auto" }}
//             color="#FFF"
//             bg="#FF5710"
//             h={{ md: "40px", base: "35px" }}
//             onClick={handleFeedbackSubmit}
//           >
//             SUBMIT
//           </Button>
//         </Box>
//       </Container>
//     </Container>
//   );
// };

// export default Feedback;
