// import React, { useEffect } from "react";

import { useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Input,
  FormControl,
  FormLabel,
  Select,
  Button,
  Container,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { FaCalendarAlt, FaCreditCard, FaLock } from "react-icons/fa";
import { number } from "card-validator";
import { expirationDate, cvv } from "card-validator";

function Square({ Price }) {
  const [email, setEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [expiry, setExpiry] = useState("");
  const [isExpiryValid, setIsExpiryValid] = useState(false);
  const [cvvValue, setCvvValue] = useState("");
  const [isCvvValid, setIsCvvValid] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [pin, setPin] = useState("");
  const [state, setState] = useState("");

  // const [payments, setPayments] = useState(null);
  // const [card, setCard] = useState(null);
  // const [paymentStatus, setPaymentStatus] = useState(null);
  // const [paymentError, setPaymentError] = useState(null);

  // const appId = 'sandbox-sq0idb-GgqFVu7dRnkN2ANUl04kZw';
  // const locationId = 'LJTAX94P1581S';

  // const initializeCard = async (payments) => {
  //   const card = await payments.card();
  //   await card.attach('#card-container');
  //   setCard(card);
  // };

  // const createPayment = async (token, verificationToken) => {
  //   const body = JSON.stringify({
  //     locationId,
  //     sourceId: token,
  //     verificationToken,
  //     amount: 100,
  //     currency: 'USD',
  //   });

  //   const paymentResponse = await fetch(
  //     'https://data.gurucool.life/api/v1/payments/squarePayment',
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization:
  //           'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDIxNzc0MGQ4YjlmOWZmOGFhZGIyYWEiLCJpYXQiOjE2ODMyNzEzNjIsImV4cCI6MTY4NDU2NzM2Mn0.CHxqbHU_FBhAirJKB774Prk-TG8FCccEKUJIl5r8ZL8',
  //       },
  //       body,
  //     }
  //   );

  //   if (paymentResponse.ok) {
  //     return paymentResponse.json();
  //   }

  //   const errorBody = await paymentResponse.text();
  //   throw new Error(errorBody);
  // };

  // const tokenize = async (paymentMethod) => {
  //   const tokenResult = await paymentMethod.tokenize();
  //   if (tokenResult.status === 'OK') {
  //     return tokenResult.token;
  //   } else {
  //     let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
  //     if (tokenResult.errors) {
  //       errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
  //     }
  //     throw new Error(errorMessage);
  //   }
  // };

  // const verifyBuyer = async (payments, token) => {
  //   const verificationDetails = {
  //     amount: '1.00',
  //     billingContact: {
  //       addressLines: ['123 Main Street', 'Apartment 1'],
  //       familyName: 'Doe',
  //       givenName: 'John',
  //       email: 'jondoe@gmail.com',
  //       country: 'GB',
  //       phone: '3214563987',
  //       region: 'LND',
  //       city: 'London',
  //     },
  //     currencyCode: 'GBP',
  //     intent: 'CHARGE',
  //   };

  //   const verificationResults = await payments.verifyBuyer(
  //     token,
  //     verificationDetails
  //   );
  //   return verificationResults.token;
  // };

  // function DisplayPaymentResults({ status }) {
  //   const statusContainerRef = React.useRef(null);
  
  //   useEffect(() => {
  //     if (status === 'SUCCESS') {
  //       statusContainerRef.current.classList.remove('is-failure');
  //       statusContainerRef.current.classList.add('is-success');
  //     } else {
  //       statusContainerRef.current.classList.remove('is-success');
  //       statusContainerRef.current.classList.add('is-failure');
  //     }
  
  //     statusContainerRef.current.style.visibility = 'visible';
  //   }, [status]);
  
  //   return (
  //     <div id="payment-status-container" ref={statusContainerRef}>
        
  //     </div>
  //   );
  // }
  

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle payment submission logic here
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, ""); // remove any non-numeric characters
    value = value.substring(0, 16); // limit the input to a maximum of 16 digits
    value = value.replace(/(.{4})/g, "$1-"); // add hyphens after every 4 digits
    value = value.slice(0, -1) + value.slice(-1).replace(/-/g, ""); // remove hyphen after 16th digit

    setCardNumber(value);
    setIsCardNumberValid(number(value).isValid);
  };

  const handleExpiryChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, ""); // remove any non-numeric characters
    value = value.substring(0, 4); // limit the input to a maximum of 4 digits
    value = value.replace(/(.{2})/, "$1/"); // add a slash after the second digit

    setExpiry(value);
    setIsExpiryValid(expirationDate(value).isValid);
  };

  const handleCvvChange = (e) => {
    const value = e.target.value;
    setCvvValue(value);
    setIsCvvValid(cvv(value).isValid);
  };

 
 
 
  return (
    <Container maxW={"6xl"}>
      <Box p="4">
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <Heading size="lg">gurucool.life</Heading>
            <Box mt="4">
              <p>Consultation fee = {Price}$</p>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <form onSubmit={handleSubmit}>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormControl>

              <FormLabel mt={3}>Card Information</FormLabel>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={0}
                mt="3"
                border={"1px"}
                borderRadius={"6px"}
              >
                <GridItem colSpan={2}>
                  <FormControl id="cardNumber">
                    {/* <FormLabel>Card Information</FormLabel> */}
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FaCreditCard color="gray.300" />}
                      />
                      <Input
                        placeholder="Card Number"
                        type="text"
                        value={cardNumber}
                        onChange={handleCardNumberChange}
                        required
                        borderRadius={"6px 6px 0 0"}
                        className={isCardNumberValid ? "valid" : "invalid"}
                        maxLength="19"
                        bg={isCardNumberValid ? "green.100" : "red.100"}
                        // borderColor={isCardNumberValid ? "green.500" : "red.500"}
                        focusBorderColor={isCardNumberValid ? "green.500" : "red.500"}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="expiryDate">
                    {/* <FormLabel>Expiry Date</FormLabel> */}
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FaCalendarAlt color="gray.300" />}
                      />
                      <Input
                        placeholder="MM/YY"
                        type="text"
                        value={expiry}
                        onChange={handleExpiryChange}
                        required
                        borderRadius={"0 0 0 6px"}
                        className={isCardNumberValid ? "valid" : "invalid"}
                        maxLength="5"
                        bg={isExpiryValid ? "green.100" : "red.100"}
                        // borderColor={isExpiryValid ? "green.500" : "red.500"}
                        focusBorderColor={isExpiryValid ? "green.500" : "red.500"}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="cvv">
                    {/* <FormLabel>CVV</FormLabel> */}
                    <InputGroup borderRadius={"0 0 6px 0"}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<FaLock color="gray.300" />}
                      />
                      <Input
                        placeholder="CVV"
                        type="text"
                        value={cvvValue}
                        onChange={handleCvvChange}
                        required
                        borderRadius={"0 0 6px 0"}
                        className={isCardNumberValid ? "valid" : "invalid"}
                        maxLength={3}
                        bg={isCvvValid ? "green.100" : "red.100"}
                        // borderColor={isCvvValid ? "green.500" : "red.500"}
                        focusBorderColor={isCvvValid ? "green.500" : "red.500"}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
              </Grid>
              <FormControl id="nameOnCard" mt="3">
                <FormLabel>Name on Card</FormLabel>
                <Input
                  type="text"
                  value={nameOnCard}
                  onChange={(e) => setNameOnCard(e.target.value)}
                  required
                />
              </FormControl>

              <FormLabel mt={3}>Billing Address Country</FormLabel>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={0}
                mt="3"
                border={"1px"}
                borderRadius={"6px"}
              >
                <GridItem colSpan={2}>
                  <FormControl id="billingCountry">
                    {/* <FormLabel>Billing Address Country</FormLabel> */}
                    <Select
                      placeholder="Select country"
                      value={billingCountry}
                      onChange={(e) => setBillingCountry(e.target.value)}
                      required
                      borderRadius={"6px 6px 0 0"}
                    >
                      <option value="USA">USA</option>
                      <option value="Canada">Canada</option>
                      <option value="UK">UK</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="addressLine1">
                    {/* <FormLabel>Address Line 1</FormLabel> */}
                    <Input
                      type="text"
                      value={addressLine1}
                      onChange={(e) => setAddressLine1(e.target.value)}
                      required
                      placeholder="Address Line 1"
                      borderRadius={0}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="addressLine2">
                    {/* <FormLabel>Address Line 2</FormLabel> */}
                    <Input
                      type="text"
                      value={addressLine2}
                      onChange={(e) => setAddressLine2(e.target.value)}
                      placeholder="Address Line 2"
                      borderRadius={0}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="city">
                    {/* <FormLabel>City</FormLabel> */}
                    <Input
                      placeholder="City"
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                      borderRadius={0}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl id="pin">
                    {/* <FormLabel>Pin</FormLabel> */}
                    <Input
                      placeholder="Pin"
                      type="text"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                      required
                      borderRadius={0}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl id="state">
                    {/* <FormLabel>State</FormLabel> */}
                    <Input
                      placeholder="State"
                      type="text"
                      value={pin}
                      onChange={(e) => setState(e.target.value)}
                      required
                      borderRadius={"0 0 6px 6px"}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <Button type="submit" mt="4" colorScheme="blue">
                Pay Now
              </Button>
            </form>
          </GridItem>
        </Grid>
      </Box>
    </Container>
  );
}

export default Square;
